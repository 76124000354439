import {
  transformCustomColorsToVennColors,
  type CustomColors,
  createDivergingColors,
  createPeerGroupColors,
  type BaseColors,
} from '../../style/customColors';

const BaseColorScheme: BaseColors = {
  Primary: {
    Main: '#00CCCC', // Turquoise
    Dark: '#00818A', // Teal
    Light: '#D4F3F2', // Pale Turquoise
    Medium: '#94BEC3', // Pale Teal
  },
  PaleTeal: '#94BEC3',
  WhiteGrey: '#F7F7F7',
  PaleGrey: '#F1F1F2',
  LightGrey: '#B2B2B2',
  LightGrey2: '#E6E6E6',
  Grey: '#D8D8D8',
  MidGrey1: '#BBBDBF',
  MidGrey2: '#999999',
  HintGrey: '#767676',
  DarkGrey: '#666666',
  DarkGrey2: '#242A2F',
  HoverGrey: '#F3F1F1',
  Black: '#10161B',
  TransparentBlack: 'rgba(16, 22, 27, 0.9)',
  White: '#FFFFFF',
  Orange: '#F87E3D',
  HighlightDark: '#6600FF',
  HighlightDeepDark: '#5E00EB',
  HighlightLight: '#AC75FF',
  HighlightLightBackground: '#ECDEFF',
  HighlightBackground: '#302683',
  Alert: '#DE9F9B',
  Error: '#B73A3A',
  Warning: '#FFAB00',

  Gold: '#7C7026',
  PaleGold: '#A08D14',
  Green: '#008854',
  PaleBlue: '#189ACD',
  DarkBlue: '#0072AF',
  DeepDarkBlue: '#006499',
  Pink: '#AE5A88',

  PeerGroupCarouselColor: '#11696F',

  DataLibraryColor: {
    Warning: '#FFF0D9',
  },

  DEPRECATED_DataBarColor: {
    LightGold: '#9E9459',
    LightPaleGold: '#CBBC5B',
    LightPaleBlue: '#6ED6FF',
    LightPink: '#DE9BC0',
    LightDarkBlue: '#3396C7',
  },

  DEPRECATED_DataLineColor: {
    Gold: '#867A30',
    PaleGold: '#BEAB32',
    Green: '#00B072',
    PaleBlue: '#4ACCFF',
    DarkBlue: '#007CB9',
    Pink: '#D682B0',
    DeepGreen: '#00897B',
  },

  DEPRECATED_DivergingColor: {
    A5: '#4E2508',
    A4: '#8F5017',
    A3: '#B7793A',
    A2: '#DCB887',
    A1: '#EEDDC7',
    MID: '#F5F5F5',
    B1: '#CAE4E6',
    B2: '#8CC8CC',
    B3: '#3E9397',
    B4: '#11696F',
    B5: '#003638',
  },

  NavigationBarColor: {
    Active: '#2F3942',
    ActiveBackground: '#479EA5',
    ActiveLighter: '#009AA6',
    ShimmerBackground: '#505A63',
    InactiveBackground: '#2F3942',
    UpsellInactiveBackground: '#211249',
    UpsellActiveLighter: '#3B0B8D',
    UpsellActiveBackground: '#421595',
  },

  UNSAFE: {
    LightBlue: '#B7E3F5',
    Separator: '#E2E2E2',
    Silver: '#D4D8DC',
    Azure: '#2196F3',
    Steel: '#76848F',
    Red: '#B82A2B',
  },

  GreyScale: {
    Grey10: '#F5F7FA',
    Grey20: '#EBEFF5',
    Grey30: '#DDE3ED',
    Grey40: '#C8D1E0',
    Grey50: '#AFBACC',
    Grey60: '#8E99AB',
    Grey70: '#707A8A',
    Grey80: '#58606E',
    Grey90: '#434A54',
    Grey100: '#333840',
  },
};

const NEW_WARM = '#ba1c1c';
const NEW_COOL = '#177c34';
const NEW_MID = '#f5f5f5';

const studioColors: CustomColors = {
  StudioSubjectColor: {
    A1: '#0284b5',
    A2: '#e89366',
    A3: '#6c7b80',
    A4: '#f26686',
    A5: '#5fb3ba',
    A6: '#ff8731',
    A7: '#415463',
    A8: '#a55cc5',
    A9: '#d95b78',
  },
  HoldingsColor: {
    SectorColor: {
      Cyclical: '#0284b5',
      Defensive: '#e89366',
      Sensitive: '#6c7b80',
      'Cash And Equivalents': '#f26686',
      Corporate: '#5fb3ba',
      Derivative: '#ff8731',
      Government: '#415463',
      Municipal: '#a55cc5',
      Securitized: '#d95b78',
      Unknown: '#7c535c',
    },
    AssetColor: {
      'US Stock': '#0284b5',
      'Non-US Stock': '#e89366',
      'US Fixed Income': '#6c7b80',
      'Non-US Fixed Income': '#f26686',
      Preferred: '#5fb3ba',
      Convertible: '#ff8731',
      Cash: '#415463',
      Other: '#7798b3',
      Unknown: '#7c535c',
    },
  },
  CashFlowColor: {
    Historical: '#0284b5',
    Projected: '#e89366',
    Typical: '#6c7b80',
  },
  PeerGroupColor: createPeerGroupColors('#B2F0F0'),
  DivergingColor: createDivergingColors(NEW_WARM, NEW_COOL, NEW_MID),
};

export const NewColorScheme = transformCustomColorsToVennColors(BaseColorScheme, studioColors);
