import { type AnalysisSubject, isHistoricalPortfolio } from 'venn-utils';
import { ItemType } from 'venn-ui-kit';
import type { StudioRequestSubject } from 'venn-state';
import type { Fund } from 'venn-api';
import { isNil } from 'lodash';

export const convertAnalysisSubjectToItemType = (
  analysisSubject?: AnalysisSubject,
  isCommonBenchmark?: boolean,
): ItemType => {
  if (isCommonBenchmark) {
    return ItemType.CommonBenchmark;
  }
  if (analysisSubject?.private) {
    return analysisSubject.type === 'private-investment' ? ItemType.PrivateInvestment : ItemType.PrivatePortfolio;
  }
  if (analysisSubject?.type === 'portfolio') {
    if (analysisSubject.isInvestmentInPortfolio) {
      return ItemType.Investment;
    }
    if (analysisSubject.portfolio && isHistoricalPortfolio(analysisSubject.portfolio)) {
      return ItemType.HistoricalPortfolio;
    }
    return ItemType.Portfolio;
  }
  if (isBenchmark(analysisSubject)) {
    return ItemType.Benchmark;
  }
  return ItemType.Investment;
};

export const convertRequestSubjectToItemType = (
  requestSubject?: StudioRequestSubject,
  isCommonBenchmark?: boolean,
): ItemType => {
  if (isCommonBenchmark) {
    return ItemType.CommonBenchmark;
  }
  if (requestSubject?.private) {
    return requestSubject?.privatePortfolio || requestSubject?.modifiedPrivatePortfolio
      ? ItemType.PrivatePortfolio
      : ItemType.PrivateInvestment;
  }
  if (requestSubject?.subjectType === 'PORTFOLIO') {
    const isInvestmentInPortfolio =
      requestSubject.strategy?.id !== requestSubject.portfolio?.id && !isNil(requestSubject.strategy?.fund);
    if (isInvestmentInPortfolio) {
      return ItemType.Investment;
    }
    if (requestSubject.portfolio && isHistoricalPortfolio(requestSubject.portfolio)) {
      return ItemType.HistoricalPortfolio;
    }
    return ItemType.Portfolio;
  }
  if (isBenchmark(requestSubject)) {
    return ItemType.Benchmark;
  }
  return ItemType.Investment;
};

export function isBenchmark<T extends { fund?: Fund }>(subjectWithFund: T | undefined) {
  return subjectWithFund?.fund?.assetType === 'BENCHMARK';
}
