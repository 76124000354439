import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { SelectTypes } from 'venn-ui-kit';

import { FiltersContext } from './FiltersProvider';
import QuickFilterBar, { isHelpArticles, isSavedViews } from './QuickFilterBar';
import { ExpandHeaderCell, HeaderCell } from './shared';
import { VIEW_COLUMNS, ARTICLE_COLUMNS } from './Columns';
import type { CustomSelectProps, SearchFilter, SearchMenuItem } from '../types';
import { type MenuListProps } from 'react-select';
import { MenuListComponents } from './MenuListComponents';

const MenuList = <IsMulti extends boolean = false>(props: MenuListProps<SearchMenuItem, IsMulti>) => {
  const { setSelectedFilters, selectedFilters } = useContext(FiltersContext);
  const { options } = props;
  const {
    showQuickFilters,
    columns,
    footer,
    smallScreen,
    canSearchEverything,
    includeTags,
    includeBenchmarks,
    privateAssetSearchMode,
    investmentsOnly,
    portfoliosOnly,
  } = props.selectProps as unknown as CustomSelectProps<IsMulti>;
  const SelectMenuList = SelectTypes.components.MenuList<
    SearchMenuItem,
    IsMulti,
    SelectTypes.GroupBase<SearchMenuItem>
  >;

  const hasRecent = options?.some((item) => (item as SearchMenuItem).category === 'recent') ?? false;
  const hasSaved = options?.some((item) => (item as SearchMenuItem).category === 'saved') ?? false;
  const hasViews = isSavedViews(selectedFilters);
  const hasArticle = isHelpArticles(selectedFilters);
  const header = hasArticle
    ? 'Help Documents'
    : hasViews
      ? 'Saved Views'
      : hasRecent
        ? 'Recent '
        : hasSaved
          ? 'Saved Benchmarks '
          : 'Results ';
  const displayColumns = hasViews ? VIEW_COLUMNS : hasArticle ? ARTICLE_COLUMNS : columns;

  const onSelectFilter = useCallback(
    (filter: SearchFilter) => {
      setSelectedFilters((prev) => [...prev, filter]);
    },
    [setSelectedFilters],
  );

  const content = isEmpty(options) ? (
    props.children
  ) : (
    <>
      {showQuickFilters && (
        <QuickFilterBar
          privateAssetSearchMode={privateAssetSearchMode}
          selectedFilters={selectedFilters}
          onSelectFilter={onSelectFilter}
          smallScreen={smallScreen}
          canSearchEverything={canSearchEverything}
          includeTags={includeTags}
          includeBenchmarks={includeBenchmarks}
          investmentsOnly={investmentsOnly}
          portfoliosOnly={portfoliosOnly}
        />
      )}

      <MenuListComponents.TableWrapper>
        <TableScrollable>
          <StyledTable>
            <MenuListComponents.HeaderRow>
              <MenuListComponents.FirstHeaderCell>{header}</MenuListComponents.FirstHeaderCell>
              <HeaderCell />
              <ExpandHeaderCell />
              <MenuListComponents.HeaderColumns columns={displayColumns} />
            </MenuListComponents.HeaderRow>
            <tbody>{props.children}</tbody>
          </StyledTable>
        </TableScrollable>
      </MenuListComponents.TableWrapper>
    </>
  );

  return (
    <SelectMenuList {...props}>
      {content}
      {footer && footer({ ...props })}
    </SelectMenuList>
  );
};

export default MenuList;

const StyledTable = styled(MenuListComponents.Table)`
  tbody {
    tr {
      th:nth-child(1) {
        width: 0px;
      }
      th:nth-child(1),
      th:nth-child(2),
      th:nth-child(3) {
        padding-right: 0;
      }
    }
  }
  thead {
    tr {
      th:nth-child(2) {
        padding-right: 0;
      }
    }
  }
`;

const TableScrollable = styled.div`
  max-height: 286px;
  overflow-y: auto;
  position: relative;
`;
