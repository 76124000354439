import type { FC } from 'react';
import React from 'react';
import { GetColor } from '../../../style/color';
import styled from 'styled-components';
import CellLoader from '../../cell-loader/CellLoader';

interface TableRowMenuItemProps {
  label: React.ReactNode;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
  loading?: boolean;
  destructive?: boolean;
}

const TableRowMenuItemWrapper = styled.button<{ disabled?: boolean; destructive?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  white-space: nowrap;
  color: ${({ destructive }) => (destructive ? GetColor.Error : GetColor.Black)};
  ${(props) =>
    props.disabled &&
    `cursor: not-allowed;
    pointer-events: none;
    color: ${GetColor.Grey};`};
`;

const TableRowMenuItem: FC<React.PropsWithChildren<TableRowMenuItemProps>> = ({
  label,
  onClick,
  disabled,
  loading,
  destructive,
}) => (
  <TableRowMenuItemWrapper
    onClick={
      disabled
        ? (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.stopPropagation();
          }
        : onClick
    }
    disabled={disabled || loading}
    destructive={destructive}
  >
    <span>
      {label}
      {loading && <CellLoader />}
    </span>
  </TableRowMenuItemWrapper>
);

export default TableRowMenuItem;
