import type { SimpleFund } from 'venn-api';

type PortfolioLike<ChildT> = {
  name: string;
  children?: ChildT[];

  allocation?: number;
  fund?: SimpleFund;
};
type WithStrategies<T> = T & { strategyPath: string[] };

/**
 * Flattens a portfolio tree into a list of nodes, each with a 'strategyPath` property.
 *
 * The root node will have an empty `strategyPath`.
 *
 * By default, the root is not included in the `strategyPath` of any node.
 */
export const flattenNodeWithStrategyPath = <P extends PortfolioLike<P>>(
  root: P | undefined,
  options: {
    includeRoot?: boolean;
  } = {},
): WithStrategies<P>[] => {
  if (!root) {
    return [];
  }

  return innerFlatten(root, []);

  function innerFlatten(curr: P, strategyPath: string[]): WithStrategies<P>[] {
    const newStrategyPath = options.includeRoot || curr !== root ? [...strategyPath, curr.name] : [];
    const flattenedChildren = (curr.children ?? []).flatMap((node) => innerFlatten(node, newStrategyPath));
    return [{ ...curr, strategyPath }, ...flattenedChildren];
  }
};
