import type { ICellRendererParams } from '../../../../data-grid';
import ItemAllocation from '../../../../allocation-panel/ItemAllocation';
import React from 'react';
import styled from 'styled-components';
import { GetColor } from 'venn-ui-kit';

interface AllocationCellRendererProps extends ICellRendererParams {
  value: number;
  isRoot: boolean;
  originalValue: number;
  onUpdateAllocation: (value: number) => void;
  isStrategy: boolean;
  isExcluded: boolean;
}

export const AllocationCellRenderer = ({
  value,
  originalValue,
  onUpdateAllocation,
  isStrategy,
  isRoot,
  isExcluded,
}: AllocationCellRendererProps) => {
  return (
    <StyledItemAllocation
      isGhost={false}
      isStrategy // TODO: tweak this for V2 to allow allocations
      isLeafNode={!isStrategy}
      isRoot={isRoot}
      isModified={value !== originalValue}
      isExcluded={isExcluded}
      value={value}
      originalValue={originalValue}
      onUpdateAllocation={onUpdateAllocation}
      isOutsideOfSelectedSubtree={false}
      isPercentageMode={false}
      baseAllocation={undefined}
      orignalBaseAllocation={value}
      usePortalForTooltip
    />
  );
};

const StyledItemAllocation = styled(ItemAllocation)<{ isLeafNode?: boolean; isExcluded?: boolean }>`
  .item-allocation-value-wrapper {
    font-weight: ${(props) => (props.isLeafNode ? 'normal' : 'bold')};
    color: ${(props) => (props.isExcluded ? GetColor.GreyScale.Grey80 : GetColor.Black)};
    width: 110px;
    text-decoration: ${(props) => (props.isExcluded ? 'line-through' : 'none')};
  }
`;
