import { getFundReturnsRange, type FundReturnsRange } from 'venn-api';
import { createFundReturnsRangeKey, useQuery } from 'venn-utils';
import { getReturnsRangeFromError } from './utils';
import type { FundToProxy } from '../types';
import { useMemo } from 'react';

export function useInvestmentsReturnsRanges(investments: FundToProxy[]): FundReturnsRange[] {
  const unproxiedReturnsRanges: FundReturnsRange[] = useMemo(
    () =>
      investments.map((investment) => ({
        start: investment.unproxiedStartRange,
        end: investment.unproxiedEndRange,
        frequency: investment.unproxiedFrequency,
      })),
    [investments],
  );

  return unproxiedReturnsRanges;
}

/** Returns data as null if the proxyId is undefined. */
export function useProxyReturnsRange(selectedProxyId: string | undefined) {
  return useQuery(createFundReturnsRangeKey(selectedProxyId ?? '-'), async ({ signal }) =>
    selectedProxyId ? fetchFundReturnsRange(selectedProxyId, signal) : null,
  );
}

function fetchFundReturnsRange(id: string, signal: AbortSignal | undefined) {
  return getFundReturnsRange(true, id, signal)
    .then((res) => res.content)
    .catch((e) => getReturnsRangeFromError(e) ?? null);
}
