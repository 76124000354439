import React from 'react';
import styled, { css } from 'styled-components';
import { GetColor, Icon } from 'venn-ui-kit';

type NameWithMappingErrorRendererProps = {
  name?: string;
  needsMapping: boolean;
  duplicateInvestment: boolean;
  isExcluded: boolean;
  shortHistory: boolean;
};

export const NameWithMappingErrorRenderer = ({
  name,
  needsMapping,
  duplicateInvestment,
  isExcluded,
  shortHistory,
}: NameWithMappingErrorRendererProps) => {
  return (
    <Wrapper isCompact>
      <span>
        {!isExcluded &&
          (needsMapping || duplicateInvestment ? (
            <StyledIcon type="triangle-exclamation" />
          ) : shortHistory ? (
            <WarningIcon type="diamond-exclamation" />
          ) : null)}
        <Name isExcluded={isExcluded}>{name}</Name>
      </span>
      {!isExcluded && needsMapping && <ErrorSpan>Could not be mapped to an investment in the Data Library.</ErrorSpan>}
      {!isExcluded && duplicateInvestment && (
        <ErrorSpan>
          Duplicate investments are not allowed in the same strategy. Click {'\u22EF'} to exclude or merge.
        </ErrorSpan>
      )}
      {!isExcluded && shortHistory && (
        <div>
          Returns data for this investment does not cover all allocation dates. After upload, please add additional
          returns data for this investment.
        </div>
      )}
    </Wrapper>
  );
};

const ErrorSpan = styled.span`
  color: ${GetColor.Error};
`;

const StyledIcon = styled(Icon)`
  color: ${GetColor.Error};
  margin-right: 4px;
  font-size: 14px;
  font-weight: 900;
`;

const WarningIcon = styled(Icon)`
  color: ${GetColor.Orange};
  margin-right: 4px;
  font-size: 14px;
  font-weight: 900;
`;

const Name = styled.span<{ isExcluded: boolean }>`
  ${(props) =>
    props.isExcluded &&
    css`
      color: ${GetColor.GreyScale.Grey80};
      text-decoration: line-through;
    `};
`;

const Wrapper = styled.div<{ isCompact: boolean }>`
  height: 100%;
  padding: 4px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  ${(props) => props.isCompact && 'line-height: 16px;'};
`;
