import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { useAppPrintMode } from '../../../../print';
import { GetColor, Icon } from 'venn-ui-kit';

import { type HTMLProps } from '../factory';

export type BlockMetadataItemElement = React.ElementRef<'div'>;
export interface BlockMetadataItemProps extends HTMLProps<'div'> {
  iconType: string;
  children: React.ReactNode;
}

export const BlockMetadataItem = forwardRef<BlockMetadataItemElement, BlockMetadataItemProps>((props, ref) => {
  const { iconType, children } = props;

  const { inPrintModeOrReportLab: isCompact } = useAppPrintMode();

  return (
    <Container compact={isCompact} ref={ref}>
      <ItemIcon type={iconType} compact={isCompact} />
      <Text>{children}</Text>
    </Container>
  );
});

BlockMetadataItem.displayName = 'BlockMetadataItem';

const Container = styled.div<{ compact?: boolean }>`
  ${({ compact }) =>
    !compact
      ? css`
          margin: 5px 5px 0 0;
          border: 1px solid ${GetColor.GreyScale.Grey50};
          border-radius: 4px;
          padding: 3px 5px;
        `
      : 'padding-left: 0px;'}
`;

const ItemIcon = styled(Icon)<{ compact?: boolean }>`
  padding: 4px;
  ${({ compact }) =>
    !compact
      ? css`
          color: ${GetColor.GreyScale.Grey60};
          background-color: ${GetColor.GreyScale.Grey20};
          border-radius: 2px;
        `
      : 'padding-left: 0px;'}
`;

const Text = styled.span`
  padding-left: 3px;
  padding-right: 10px;
`;
