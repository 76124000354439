import React from 'react';
import styled from 'styled-components';

import { FootnoteSymbols } from 'venn-utils';
import { hasProxyState, useCachedLoadableValue, type StudioRequestSubject } from 'venn-state';
import { ItemType } from 'venn-ui-kit';

import { Block } from '../../core';

interface BenchmarkSubjectProps {
  subject: StudioRequestSubject;
}

export const BenchmarkSubject = ({ subject }: BenchmarkSubjectProps) => {
  const hasProxy = useCachedLoadableValue(
    hasProxyState((subject.individualBenchmark?.fundId ?? subject.individualBenchmark?.portfolioId?.toString())!),
    false,
  );

  return (
    <Block.SubjectsItem iconType={ItemType.CommonBenchmark}>
      {subject.individualBenchmark?.name ? (
        <NameContainer>
          {/* rendering individually causes unwanted whitespace to appear before <sup> */}
          {[subject.individualBenchmark?.name, hasProxy && <sup>{FootnoteSymbols.proxyUsage}</sup>]}
        </NameContainer>
      ) : (
        'No Benchmark'
      )}
    </Block.SubjectsItem>
  );
};

const NameContainer = styled.span`
  display: inherit;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  align-content: center;
`;
