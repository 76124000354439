import React, { memo } from 'react';

import { Block } from '../../components/core';
import { NotableHistoricalHeader } from './notable-historical-header';
import { NotableHistoricalContent } from './notable-historical-content';

import { MetricsErrorWrapper } from '../../../studio-blocks/components/error-wrappers/MetricsErrorWrapper';
import { COMMON_ERROR_WRAPPERS } from '../../../studio-blocks/components/error-wrappers/common';

interface NotableHistoricalBlockProps {
  id: string;
}

// TODO(houssein): memo is necessary here to prevent constant re-renders.
// In the future, we should investigate why this component is re-rendering so frequently based on the data/props it receives.
export const NotableHistoricalBlock = memo(function InternalNotableHistoricalBlock({
  id,
}: NotableHistoricalBlockProps) {
  return (
    <Block.Root id={id}>
      <Block.Header>
        <NotableHistoricalHeader id={id} />
      </Block.Header>

      <Block.Content
        id={id}
        errorWrappers={[
          ...COMMON_ERROR_WRAPPERS,
          // MetricsErrorWrapper is last because it requires analysis, so we might as well do all the client-side error checks first
          MetricsErrorWrapper,
        ]}
      >
        <NotableHistoricalContent id={id} />
      </Block.Content>
    </Block.Root>
  );
});
