import React from 'react';
import styled from 'styled-components';

import { GetColor, Icon } from 'venn-ui-kit';

export const BlockSubjectsSeparator = () => <SeparatorIcon type="pipe" />;

const SeparatorIcon = styled(Icon)`
  color: ${GetColor.Grey};
`;
