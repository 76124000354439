import * as React from 'react';
import { BaseDropMenu, BaseMenu, CheckboxWrapper, GetColor, Icon, SimpleTriggerComponents } from 'venn-ui-kit';
import { useCallback, useContext, useMemo } from 'react';
import moment from 'moment/moment';
import styled, { css } from 'styled-components';
import { buttonize } from 'venn-utils';
import { SPACING_M, SPACING_S, SPACING_XS } from '../../shared/layout';
import { MultiHistoricalPortfolioContext } from './MultiHistoricalPortfolioContext';
import { countErrors } from './multiHistoricalPortfolioUtils';
import type { SimpleTriggerComponentProps } from '../../../../../../../venn-ui-kit/src/components/drop-menu/triggers/components/SimpleTriggerComponents';

export const DropMenu = () => {
  const { allDates, selectDate, selectedDate, errors } = useContext(MultiHistoricalPortfolioContext);
  const [isFilteredToErroredDates, setIsFilteredToErroredDates] = React.useState(false);
  const numberOfErrors = countErrors(errors);
  const hasErrors = numberOfErrors > 0;
  const filteredDates = useMemo(
    () => (isFilteredToErroredDates ? allDates.filter((date) => errors[date.valueOf()]) : allDates),
    [allDates, errors, isFilteredToErroredDates],
  );
  const dropdownItems = useMemo(
    () => filteredDates.map((date) => ({ value: date, label: moment.utc(date).format('DD MMM YYYY') })),
    [filteredDates],
  );
  const selectedItem = useMemo(
    () => dropdownItems.find((item) => item.value.valueOf() === selectedDate.valueOf()),
    [dropdownItems, selectedDate],
  );
  const menuComponent: React.ComponentProps<typeof BaseDropMenu<Date>>['menuComponent'] = useCallback(
    (highlighted, forceCollapse, className) => (
      <MenuPopup className={className}>
        {hasErrors && (
          <StyledCheckboxWrapper
            justify="left"
            alignItems="center"
            checked={isFilteredToErroredDates}
            onChange={() => setIsFilteredToErroredDates((prev) => !prev)}
          >
            <DateFilterContent>
              <RedIcon type="triangle-exclamation" />
              Show only dates with errors
            </DateFilterContent>
          </StyledCheckboxWrapper>
        )}
        <StyledBaseMenu
          items={dropdownItems}
          itemsContainerCss={css`
            padding: 0;
          `}
          renderItem={({ value, label }) => (
            <DateItem
              key={label}
              {...buttonize((e) => {
                e.stopPropagation();
                selectDate(value);
                forceCollapse();
              })}
              isHighlighted={highlighted ? highlighted.value === value : false}
            >
              {label}
              {errors[value.valueOf()] && <RedIcon type="triangle-exclamation" />}
            </DateItem>
          )}
        />
      </MenuPopup>
    ),
    [dropdownItems, errors, hasErrors, isFilteredToErroredDates, selectDate],
  );
  const triggerComponent: React.ComponentProps<typeof BaseDropMenu<Date>>['triggerComponent'] = useCallback(
    (expanded, _, toggleMenu, handleKeyEsc, handleKeyEnter, handleKeyUp, handleKeyDown) => {
      const simpleTriggerComponentsProps: SimpleTriggerComponentProps = {
        expanded,
        onClick: toggleMenu,
        onKeyDown: handleKeyDown,
        onKeyUp: handleKeyUp,
        onKeyEsc: handleKeyEsc,
        onKeyEnter: handleKeyEnter,
        selection: selectedItem?.label ?? undefined,
      };
      return (
        <SimpleTriggerComponents.Container {...simpleTriggerComponentsProps} shouldHighlightBorderOnFocus={false}>
          <SimpleTriggerComponents.Input {...simpleTriggerComponentsProps} />
        </SimpleTriggerComponents.Container>
      );
    },
    [selectedItem?.label],
  );
  return (
    <BaseDropMenu<Date>
      selectedItem={selectedItem}
      filteredItems={dropdownItems}
      menuComponent={menuComponent}
      triggerComponent={triggerComponent}
    />
  );
};

const StyledCheckboxWrapper = styled(CheckboxWrapper)`
  border-bottom: 1px solid ${GetColor.PaleGrey};
  border-radius: 0;
  padding: ${SPACING_S} ${SPACING_M};
`;
const RedIcon = styled(Icon)`
  color: ${GetColor.Error};
`;
const DateFilterContent = styled.div`
  gap: ${SPACING_XS};
  display: inline-flex;
`;

const MenuPopup = styled.div`
  color: ${GetColor.Black};
  background-color: ${GetColor.White};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
`;

const StyledBaseMenu = styled(BaseMenu<Date>)`
  box-shadow: none;
`;
const DateItem = styled.div<{ isHighlighted: boolean }>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ isHighlighted }) => (isHighlighted ? GetColor.PaleGrey : 'inherit')};
  padding: ${SPACING_S} ${SPACING_M};
  &:hover {
    background-color: ${GetColor.PaleGrey};
  }
`;
