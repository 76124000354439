import { useContext } from 'react';
import { MultiPortfolioReviewContext } from '../components/common/MultiPortfolioReviewContext';
import { MultiHistoricalPortfolioContext } from '../components/specialized/MultiHistoricalPortfolioContext';
import { useUploadHistoricalPortfolioAllocator } from '../useUniversalUploaderAllocator';
import { useMultiHistoricalRightPaneContentPortfolio } from './useMultiHistoricalRightPaneContentPortfolio';
import { useSharedMultiPortfolioRightPaneContent } from './useSharedMultiPortfolioRightPaneContent';

export const useMultiHistoricalRightPaneContent = () => {
  const { selectedDate, rangeAnalysis } = useContext(MultiHistoricalPortfolioContext);
  const {
    data: { parsedResults, selectedIndex },
  } = useContext(MultiPortfolioReviewContext);
  const { portfolio } = useMultiHistoricalRightPaneContentPortfolio();
  const excludedInvestments = parsedResults[selectedIndex].excludedInvestments;
  const { accessMode, applyInvestmentRemap, includeInvestment, excludeInvestment } =
    useSharedMultiPortfolioRightPaneContent(portfolio);
  const { rowData, columnDefs, autoGroupColumnDefs, rowClassRules } = useUploadHistoricalPortfolioAllocator({
    portfolio,
    excludedInvestments,
    accessMode,
    applyInvestmentRemap,
    selectedDate,
    includeInvestment,
    excludeInvestment,
    rangeAnalysis,
  });
  return {
    rowData,
    columnDefs,
    autoGroupColumnDefs,
    rowClassRules,
  };
};
