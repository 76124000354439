import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { FiltersContext } from './components/FiltersProvider';
import { analyticsService, LibraryItemType } from 'venn-utils';
import useSearchMenu from './useSearchMenu';
import type { SelectTypes } from 'venn-ui-kit';
import type { GroupBase, OnChangeValue, SelectInstance } from 'react-select';
import { getOptionDisabledFunc, getOptionDisabledMessageFunc, itemEqualityCheck } from './utils';
import { placeholder } from './components/shared';
import { getDefaultColumns } from './components/Columns';
import { compact } from 'lodash';
import type { CustomSelectProps, GenericSearchMenuProps, SearchMenuItem } from './types';

type SearchSingleSelectProps = {
  forceHideResults?: boolean;
  onSelected: (selected: SearchMenuItem) => void;
  menuComponents: {
    [K in keyof SelectTypes.SelectComponentsConfig<
      SearchMenuItem,
      false,
      GroupBase<SearchMenuItem>
    >]?: React.ComponentType<never>;
  };
  shouldResetSearchOnSelect: boolean;
} & GenericSearchMenuProps;

const alwaysTrue = () => true;

export const useSearchSingleSelect = ({
  onSelected,
  onBlur,
  autofocus = true,
  defaultMenuIsOpen = true,
  investmentsOnly = false,
  portfoliosOnly = false,
  privateAssetSearchMode = 'PUBLIC_ONLY',
  excludedItems,
  defaultValue,
  onClear,
  value,
  getOptionValue,
  disabled,
  isOptionDisabled,
  onQueryChange,
  showRecentlyAnalyzed = true,
  className,
  classNamePrefix,
  initialQuery,
  shortPlaceholder,
  fixedMenuWidth,
  optionDisabledTooltipContent,
  usePortal,
  customPlaceholder,
  proxyable = false,
  displayResultsAsBlock,
  smallScreen = false,
  columns = getDefaultColumns(portfoliosOnly, smallScreen),
  location,
  showQuickFilters = false,
  footer,
  clearQueryOnBlur = false,
  darkPlaceholder = false,
  placeholderIds,
  menuStyles,
  canSearchEverything,
  closeOnSelect,
  forceHideResults = false,
  includeTags = true,
  includeBenchmarks = true,
  refreshedStyling = false,
  menuComponents,
  forceMenuIsOpen,
  shouldResetSearchOnSelect,
  pinSelected = true,
}: SearchSingleSelectProps) => {
  const selectRef = useRef<SelectInstance<SearchMenuItem> | null>(null);
  // Flag for if a value has been selected from the menu
  const hasSelectedValueRef = useRef<boolean>(false);
  const { selectedFilters, setSelectedFilters } = useContext(FiltersContext);
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(!!forceMenuIsOpen);
  const tagsPresent = selectedFilters.find((item) => item.itemType === LibraryItemType.TAGS);
  const {
    items,
    totalResults,
    loading,
    query,
    onSearch,
    onSelect,
    onTagsDropdown,
    onTagsClose,
    selectedTagDropdowns,
    openedTags,
    onCloseAllTags,
  } = useSearchMenu({
    onSelected,
    location,
    selectedFilters,
    menuIsOpen,
    excludedItems,
    investmentsOnly,
    portfoliosOnly,
    privateAssetSearchMode,
    selectedValue: getOptionValue && value ? getOptionValue(value) : undefined,
    showRecentlyAnalyzed,
    proxyable,
    initialQuery,
    placeholderIds,
    includeTags,
    shouldResetSearchOnSelect,
    pinSelected,
  });

  const noOptionsMessage = useCallback(() => {
    if (!forceHideResults) {
      if (loading) {
        return 'Searching...';
      }
      return (
        <>
          <div>
            <b>No results found for your query</b>
          </div>
          <div>
            {/* // VENN-20517 tags will not work in the library, we shouldn't suggest going to the library */}
            {footer && !tagsPresent
              ? 'You may want to try using different keywords or click below to see all of the items in the Library.'
              : `You may want to try using different keywords or searching by name${
                  !portfoliosOnly ? ' or ticker' : ''
                }.`}
          </div>
        </>
      );
    }
    return loading && !forceHideResults ? 'Loading...' : null;
  }, [forceHideResults, loading, footer, tagsPresent, portfoliosOnly]);

  const closeMenu = useCallback(() => {
    // blur causes onMenuBlur to fire which closes the menu
    // we don't directly close the menu because the input won't blur and we get to a weird state
    selectRef.current?.blur();
  }, []);

  const onInputChange = useCallback(
    (search: string, { action }: SelectTypes.InputActionMeta) => {
      switch (action) {
        case 'input-change':
          hasSelectedValueRef.current = false;
          onQueryChange?.(search);
          onSearch(search);
          break;
        case 'set-value':
          hasSelectedValueRef.current = true;
          break;
      }
    },
    [onQueryChange, onSearch],
  );

  const onChange = useCallback(
    (selection: OnChangeValue<SearchMenuItem, false>) => {
      if (selection) {
        onSelect(selection);
        if (forceMenuIsOpen) {
          return;
        }
        closeMenu();
      }
    },
    [closeMenu, forceMenuIsOpen, onSelect],
  );

  // used to differentiate the initial closed state from the actual close event
  const hasMenuOpened = useRef(false);
  useEffect(() => {
    // Whenever menuIsOpen is false, it must be a search dismissal
    // aside from the very first time, as denoted by hasMenuOpened.current being false
    if (hasMenuOpened.current && !menuIsOpen) {
      const noResultsShowing = !query && !showRecentlyAnalyzed;
      if (!hasSelectedValueRef.current && !noResultsShowing) {
        const quickFilters = compact(selectedFilters.map((filter) => filter.quickFilter));
        const typeFilters = compact(selectedFilters.map((filter) => filter.itemType));
        analyticsService.searchDismissed({
          query,
          location,
          totalResults,
          visibleResults: items.length,
          quickFilters,
          typeFilters,
        });
      }
      if (clearQueryOnBlur) {
        onClearSearch();
        setSelectedFilters(() => []);
      }
    } else if (menuIsOpen) {
      hasMenuOpened.current = true;
    }
    // Disabled 'location', 'query', and 'showRecentlyAnalyzed', as we want to track this event based only on menuIsOpen flag
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuIsOpen]);

  const onClearSearch = useCallback(() => {
    onSearch('');
  }, [onSearch]);

  // Do not show a placeholder if filters are selected
  const showPlaceholder = !selectedFilters.length;

  const onMenuBlur = useCallback(() => {
    onBlur?.();
    if (forceMenuIsOpen) {
      return;
    }
    setMenuIsOpen(false);
    onCloseAllTags();
  }, [forceMenuIsOpen, onBlur, onCloseAllTags]);

  const onMenuFocus = useCallback(() => {
    setMenuIsOpen(true);
    hasSelectedValueRef.current = false;
  }, []);

  const handleKeyEvents = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key !== 'Enter') return;

      const focusedClickedOption = selectRef.current?.state.focusedOption;
      if (!focusedClickedOption) {
        return;
      }

      if (focusedClickedOption?.category !== 'tag') {
        return;
      }
      e.preventDefault();

      const isTagOpen =
        openedTags.some((openedTag: SearchMenuItem) => itemEqualityCheck(openedTag, focusedClickedOption)) || false;

      isTagOpen ? onTagsClose(focusedClickedOption) : onTagsDropdown(focusedClickedOption);
    },
    [onTagsClose, onTagsDropdown, openedTags],
  );

  useEffect(() => {
    if (autofocus) {
      selectRef.current?.focus();
    }
  }, [autofocus, selectRef]);

  const customProps: CustomSelectProps = {
    columns,
    onClearSearch,
    fixedMenuWidth,
    showQuickFilters,
    investmentsOnly,
    portfoliosOnly,
    privateAssetSearchMode,
    footer,
    closeSearchMenu: closeMenu,
    onClear,
    onTagsDropdown,
    onTagsClose,
    selectedTagDropdowns,
    openedTags,
    smallScreen,
    menuStyles,
    canSearchEverything,
    includeTags,
    includeBenchmarks,
  };

  return {
    refreshedStyling,
    ref: selectRef,
    classNamePrefix: classNamePrefix ?? 'select',
    className: className ?? 'qa-search-menu-bar',
    value,
    getOptionValue,
    defaultValue,
    captureMenuScroll: false,
    placeholder:
      showPlaceholder &&
      placeholder({
        customPlaceholder,
        condition: shortPlaceholder || (darkPlaceholder && !menuIsOpen),
        portfoliosOnly,
        investmentsOnly,
        smallScreen,
      }),
    options: !forceHideResults ? items : undefined,
    inputValue: query,
    onInputChange,
    onChange,
    onBlur: onMenuBlur,
    onFocus: onMenuFocus,
    onKeyDown: handleKeyEvents,
    filterOption: alwaysTrue,
    isOptionDisabled: getOptionDisabledFunc(isOptionDisabled, investmentsOnly, portfoliosOnly),
    optionDisabledTooltipContent: getOptionDisabledMessageFunc(
      optionDisabledTooltipContent,
      investmentsOnly,
      portfoliosOnly,
    ),
    defaultMenuIsOpen,
    noOptionsMessage,
    isDisabled: disabled,
    menuPortalTarget: usePortal ? document.body : undefined,
    displayResultsAsBlock,
    hasFooter: !!footer,
    menuIsOpen: forceMenuIsOpen ? true : displayResultsAsBlock && !closeOnSelect ? true : menuIsOpen,
    closeMenuOnScroll: forceMenuIsOpen ? true : usePortal ? () => true : undefined,
    darkPlaceholder,
    tabSelectsValue: false,
    ...customProps,
    components: menuComponents,
  };
};
