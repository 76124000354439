import React, { forwardRef } from 'react';

import { Body1 } from 'venn-ui-kit';
import { blockFonts, getCustomFontSize } from 'venn-state';

import { type HTMLProps } from '../factory';
import { useRecoilValue } from 'recoil';

export type BlockSubHeadlineElement = React.ElementRef<'div'>;
export interface BlockSubHeadlineProps extends HTMLProps<'div'> {
  id: string;
  children: React.ReactNode;
}

export const BlockSubHeadline = forwardRef<BlockSubHeadlineElement, BlockSubHeadlineProps>((props, ref) => {
  const { id, children } = props;

  const subTitleFont = useRecoilValue(blockFonts.blockSubtitle(id));
  const fontSize = getCustomFontSize(subTitleFont);

  return (
    <Body1 ref={ref} style={{ fontSize }}>
      {children}
    </Body1>
  );
});

BlockSubHeadline.displayName = 'BlockSubHeadline';
