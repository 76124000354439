import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { blockDoesNotSupportBenchmark } from 'venn-state';
import { useAnalysis, useResponseParser } from '../../../studio-blocks/logic/useAnalysis';
import { filterRequests } from '..';

export const useFilteredRequests = (id: string) => {
  const { requests, analyses } = useAnalysis(id);
  const hideBenchmark = useRecoilValue(blockDoesNotSupportBenchmark(id));
  const responseParser = useResponseParser(id);
  const isTreeBlock = !!responseParser.isTree;

  const { requests: filteredRequests, analyses: filteredAnalyses } = useMemo(
    () =>
      requests.length === analyses.length
        ? filterRequests(requests, analyses, isTreeBlock, hideBenchmark)
        : {
            requests,
            analyses,
          },
    [analyses, hideBenchmark, requests, isTreeBlock],
  );

  return { requests: filteredRequests, analyses: filteredAnalyses };
};
