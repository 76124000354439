import React, { memo, useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import {
  blockBenchmarkConfig,
  blockDisplayHeader,
  blockExportMetadata,
  blockInfoGraphicType,
  isReportState,
  viewDataGridSizeType,
} from 'venn-state';

import { BaseGrid } from '../../components/visualizations/grids/base-grid';
import { useFilteredRequests } from '../../utils/hooks/use-filtered-requests';

import { useAppPrintMode } from '../../../print';
import { DataGridSizeType, type AnalysisRequest, type ExportMetaData } from '../../../studio-blocks';
import { useMetaRows } from '../../../studio-blocks/logic/useAnalysisGrid';

import { getAnalyzedSubjectFromRequestSubject } from 'venn-utils';
import { useSubjectColors } from '../../../studio-blocks/logic/useSubjectColors';
import type { AnalysisProps } from '../../types';

import { ScatterChart } from '../../../studio-blocks/components/charts/ScatterChart';
import { useSelectedMetrics } from '../../../studio-blocks/logic/useSelectedMetrics';
import { usePerformanceAndRiskData } from '../hooks/use-performance-and-risk-data';
import { useStandardColumnDefs } from '../hooks/use-standard-column-defs';
import type { Analysis } from 'venn-api';

interface PerformanceAndRiskContentProps {
  id: string;
}

export const PerformanceAndRiskContent = ({ id }: PerformanceAndRiskContentProps) => {
  const { inPrintModeOrReportLab } = useAppPrintMode();

  const { requests, analyses } = useFilteredRequests(id);

  const title = useRecoilValue(blockDisplayHeader(id));
  const isReport = useRecoilValue(isReportState);
  const infoGraphicType = useRecoilValue(blockInfoGraphicType(id));

  const isCompact = useRecoilValue(viewDataGridSizeType(id)) === DataGridSizeType.COMPACT;
  const exportMetaData = useRecoilValue(blockExportMetadata(id));
  const benchmarkConfig = useRecoilValue(blockBenchmarkConfig(id));

  return infoGraphicType === 'GRID' || infoGraphicType === 'PIVOTED_GRID' ? (
    <Grid
      id={id}
      requests={requests}
      analyses={analyses}
      inPrintMode={inPrintModeOrReportLab}
      isCompact={isCompact}
      exportMetaData={exportMetaData}
      isReport={isReport}
      pivoted={infoGraphicType === 'PIVOTED_GRID'}
    />
  ) : infoGraphicType === 'SCATTER' ? (
    <Chart
      requests={requests}
      analyses={analyses}
      inPrintMode={inPrintModeOrReportLab}
      exportMetaData={exportMetaData}
      title={title}
      relativeToBenchmark={!!benchmarkConfig?.relative}
    />
  ) : null;
};

type GridProps = AnalysisProps & { pivoted?: boolean };

const Grid = memo(function InternalGrid({
  id,
  requests,
  analyses,
  inPrintMode,
  isCompact = false,
  exportMetaData,
  isReport,
  pivoted,
}: GridProps) {
  const metaRows = useMetaRows(requests);
  const dataRows = usePerformanceAndRiskData(analyses);
  const rowData = useMemo(() => [...metaRows, ...dataRows], [metaRows, dataRows]);
  const columnDefs = useStandardColumnDefs(requests);

  return (
    <BaseGrid
      selectedRefId={id}
      isCompact={isCompact}
      inPrintMode={inPrintMode}
      exportMetaData={exportMetaData}
      exportable
      rowData={rowData}
      columnDefs={columnDefs}
      isReport={isReport}
      pivoted={pivoted}
    />
  );
});

export interface ChartProps {
  requests: AnalysisRequest[];
  analyses: (Analysis | undefined)[][];
  exportMetaData: ExportMetaData;
  inPrintMode: boolean;
  title: string;
  relativeToBenchmark: boolean;
}

const Chart = memo(function InternalGrid({
  analyses,
  inPrintMode,
  exportMetaData,
  requests,
  title,
  relativeToBenchmark,
}: ChartProps) {
  const rowData = usePerformanceAndRiskData(analyses);
  const analyzedSubjects = useMemo(
    () => requests.map(({ subject }) => getAnalyzedSubjectFromRequestSubject(subject)),
    [requests],
  );
  const subjectColors = useSubjectColors(analyzedSubjects);
  const metrics = useSelectedMetrics();

  return (
    <ScatterChart
      inPrintMode={inPrintMode}
      exportMetaData={exportMetaData}
      rowData={rowData}
      requests={requests}
      header={title}
      relativeToBenchmark={relativeToBenchmark}
      subjectColors={subjectColors}
      metrics={metrics}
    />
  );
});
