import React, { useCallback, useState, useEffect } from 'react';
import type { Subject, SubjectInputId, SubjectWithOptionalFee } from 'venn-state';
import {
  analysisSubjectQuery,
  subjectInputGroupSubjects,
  subjectInputGroupName,
  subjectInputGroups,
  nextSubjectInputNameState,
  getNewSubjectInputId,
} from 'venn-state';
import styled from 'styled-components';
import type { SearchMenuItem } from '../search-menu';
import { MultiSelectSearch, subjectToSearchMenuItem } from '../search-menu';
import type { Snapshot } from 'recoil';
import { useRecoilValue, waitForAll, constSelector, useRecoilCallback } from 'recoil';
import type { AnalysisSubject } from 'venn-utils';
import { MAX_SUBJECT_GROUP_SIZE, WARNING_SUBJECT_GROUP_SIZE, useHasFF, withSuspense } from 'venn-utils';
import { InputEditor, InputEditorLoadState } from './InputEditor';
import { ColorUtils, GetColor, Icon } from 'venn-ui-kit';

const EDITOR_MAX_HEIGHT = 650;

// TODO(will, collin): add escape to close functionality. Would be good to package it up into a hook.
// Check out Modal component for an example implementation.

interface SubjectGroupEditorProps {
  /** The inputId to edit, or undefined if a new input should be created. */
  groupId: SubjectInputId | undefined;
  /** Called with the saved input ID if the save was completed, or undefined if the user quit without saving. */
  onClose: (savedInputId?: SubjectInputId) => void;
  /** Callback to track when a subject group was created edited */
  trackGroupChanged?: (
    snapshot: Snapshot,
    groupId: SubjectInputId,
    groupName: string,
    newSubjects: Subject[],
    action: 'CREATE' | 'EDIT',
  ) => void;
}

const matchingFund = (subjects: SubjectWithOptionalFee[], fundId: string | undefined) =>
  subjects.find((subject) => fundId && subject.fundId === fundId);
const matchingPortfolio = (subjects: SubjectWithOptionalFee[], portfolioId: number | undefined) =>
  subjects.find((subject) => portfolioId && subject.portfolioId === portfolioId);

const matchingPrivateFund = (subjects: SubjectWithOptionalFee[], fundId: string | undefined) =>
  subjects.find((subject) => fundId && subject.privateFundId === fundId);
const matchingPrivatePortfolio = (subjects: SubjectWithOptionalFee[], portfolioId: string | undefined) =>
  subjects.find((subject) => portfolioId && subject.privatePortfolioId === portfolioId);

export const matchingSubject = (subjects: SubjectWithOptionalFee[], subject: AnalysisSubject | undefined) => {
  return (
    matchingFund(subjects, subject?.fund?.id) ??
    matchingPortfolio(subjects, subject?.portfolio?.id) ??
    matchingPrivateFund(subjects, subject?.privateFund?.id) ??
    matchingPrivatePortfolio(subjects, subject?.privatePortfolio?.id)
  );
};

/** Modal for editing an existing subject group or creating a new one. */
export const SubjectGroupEditor = withSuspense(
  <InputEditorLoadState maxHeight={EDITOR_MAX_HEIGHT / 2} />,
  ({ groupId: initialGroupId, onClose, trackGroupChanged }: SubjectGroupEditorProps) => {
    const groupIds = useRecoilValue(subjectInputGroups);
    const nextSubjectInputName = useRecoilValue(nextSubjectInputNameState);
    const originalName = useRecoilValue(
      initialGroupId ? subjectInputGroupName(initialGroupId) : constSelector(nextSubjectInputName),
    );
    const [tempName, setTempName] = useState(originalName);
    useEffect(() => setTempName(originalName), [originalName]);

    const groupSubjects = useRecoilValue(
      initialGroupId ? subjectInputGroupSubjects(initialGroupId) : constSelector([]),
    );
    const groupAnalysisSubjects = useRecoilValue(
      waitForAll(groupSubjects.map((subject) => analysisSubjectQuery(subject))),
    );
    const initialSelection = groupAnalysisSubjects.map((subject) => subjectToSearchMenuItem({ subject }));
    const [tempSubjects, setTempSubjects] = useState(groupSubjects);

    const onSaveChanges = useRecoilCallback(
      ({ set, snapshot }) =>
        () => {
          const finalGroupId = initialGroupId ?? getNewSubjectInputId();

          trackGroupChanged?.(
            snapshot,
            finalGroupId,
            tempName || originalName,
            tempSubjects,
            initialGroupId ? 'EDIT' : 'CREATE',
          );

          set(subjectInputGroupName(finalGroupId), tempName || originalName);
          set(subjectInputGroupSubjects(finalGroupId), tempSubjects);
          if (!initialGroupId) {
            set(subjectInputGroups, [...groupIds, finalGroupId]);
          }

          onClose(finalGroupId);
        },
      [initialGroupId, tempName, originalName, tempSubjects, onClose, groupIds, trackGroupChanged],
    );

    const onQuit = useCallback(() => {
      // TODO(will, collin): IMV2 onQuit should show an unsaved changes modal when groupId is defined and changes are made.
      // Modal should only show if the subject group is actually used somewhere OR is >3 subjects long. Use a useRecoilCallback to do this
      // combined with modal state and <ConfirmationModal ../>

      onClose(undefined);
    }, [onClose]);

    const onSelectionsChange = useCallback((selected: SearchMenuItem[]) => {
      setTempSubjects((tempSubjects) => {
        return selected.map(({ value }) => ({
          portfolioId: value?.portfolio?.id,
          fundId: value?.fund?.id,
          privateFundId: value?.privateFund?.id,
          privatePortfolioId: value?.privatePortfolio?.id,
          feesMapping: matchingSubject(tempSubjects, value)?.feesMapping,
        }));
      });
    }, []);

    const maxSelectionsReached = tempSubjects.length > MAX_SUBJECT_GROUP_SIZE;
    const warningSelectionsReached = tempSubjects.length > WARNING_SUBJECT_GROUP_SIZE;

    const hasPrivateAnalytics = useHasFF('private_analytics');
    return (
      <InputEditor
        title="Investments/Portfolios"
        name={tempName}
        setName={setTempName}
        onClose={onQuit}
        onSave={onSaveChanges}
        isNew={!initialGroupId}
        maxHeight={EDITOR_MAX_HEIGHT}
        disabled={maxSelectionsReached}
        maxSelectionsReached={maxSelectionsReached}
        warningSelectionsReached={warningSelectionsReached}
      >
        <StyledMultiSelect
          onSelectionsChange={onSelectionsChange}
          footer={() => null}
          forceMenuIsOpen
          defaultMenuIsOpen
          autofocus
          displayResultsAsBlock
          initialSelection={initialSelection}
          location="Studio"
          privateAssetSearchMode={hasPrivateAnalytics ? 'ALL' : 'PUBLIC_ONLY'}
        />
        {warningSelectionsReached && <ManySubjectsWarning />}
      </InputEditor>
    );
  },
);

const WarningBar = styled.div`
  height: 35px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  font-size: 10px;
  background-color: ${ColorUtils.hex2rgbaFrom(GetColor.HighlightLight, 0.1)};
  border-top: 1px solid ${GetColor.Grey};
  border-bottom: 1px solid ${GetColor.Grey};
`;

const StyledIcon = styled(Icon)`
  font-size: 14px;
  margin-right: 10px;
  color: ${GetColor.HighlightDark};
`;

const ManySubjectsWarning = () => (
  <WarningBar>
    <StyledIcon prefix="far" type="exclamation-circle" />
    Adding more than {WARNING_SUBJECT_GROUP_SIZE} objects may impact system responsiveness.
  </WarningBar>
);

const StyledMultiSelect = styled(MultiSelectSearch)`
  padding: 0 20px;
  .select__menu {
    position: unset;
    .select__menu-list {
      padding: 0;
    }
  }
`;
