import type { RefObject } from 'react';
import React from 'react';
import styled, { css } from 'styled-components';
import type { AgGridReactProps, AgGridReact as AgGridReactType } from 'ag-grid-react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/agGridAlpineFont.css';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import useStickyHeader from './useStickyHeader';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- any usage is to match type of AgGridReactProps
export interface DataGridProps<TData = any> extends AgGridReactProps<TData> {
  noCustomStickyHeader?: boolean;
  dataGridWrapperRef?: RefObject<HTMLDivElement>;
  gridRef?: RefObject<AgGridReactType<TData>>;
  className?: string;
  horizontalScrollbarHeight?: number;
}

const DataGrid = <TData,>({
  noCustomStickyHeader,
  dataGridWrapperRef,
  gridRef,
  className,
  horizontalScrollbarHeight,
  ...props
}: DataGridProps<TData>) => {
  useStickyHeader(noCustomStickyHeader);
  return (
    <DataGridWrapper
      className={className ?? 'ag-theme-alpine'}
      ref={dataGridWrapperRef}
      data-testid="datagrid-wrapper"
      horizontalScrollbarHeight={horizontalScrollbarHeight}
    >
      <AgGridReact ref={gridRef} {...props} />
    </DataGridWrapper>
  );
};

export default React.memo(DataGrid) as typeof DataGrid;

const DataGridWrapper = styled.div<{ horizontalScrollbarHeight?: number }>`
  width: 100%;
  height: 100%;

  .ag-root-wrapper {
    border: none;
  }

  .ag-center-cols-viewport,
  .ag-center-cols-clipper,
  .ag-center-cols-container {
    min-height: 35px !important;
  }

  /* 
  * ag grid doesn't allow us to customize the height of the horizontal scrollbar
  * so we work around this by overriding ag grid inline styles with !important
  * WARNING: this may break between ag grid updates
  */
  ${({ horizontalScrollbarHeight }) =>
    horizontalScrollbarHeight &&
    css`
      .ag-body-horizontal-scroll {
        height: ${horizontalScrollbarHeight}px !important;
        min-height: ${horizontalScrollbarHeight}px !important;
        max-height: ${horizontalScrollbarHeight}px !important;

        .ag-body-horizontal-scroll-viewport {
          height: ${horizontalScrollbarHeight}px !important;
          min-height: ${horizontalScrollbarHeight}px !important;
          max-height: ${horizontalScrollbarHeight}px !important;
        }
      }
    `}
`;
