import React, { useCallback, useContext, useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';
import { ColorUtils, FilterPill, GetColor, Icon, selectClasses, SelectTypes } from 'venn-ui-kit';
import { buttonize } from 'venn-utils';
import { isEmpty } from 'lodash';
import { FiltersContext } from './FiltersProvider';
import type { CustomMultiSelectProps, SearchFilter, SearchMenuItem } from '../types';
import type { Props } from 'react-select';

const ValueContainerComponent = SelectTypes.components.ValueContainer;

export type ValueContainerProps<IsMulti extends boolean> = SelectTypes.ValueContainerProps<SearchMenuItem, IsMulti> & {
  selectProps: Props<CustomMultiSelectProps, IsMulti> &
    CustomMultiSelectProps & { forceShowSearchIcon: boolean; refreshedStyling?: boolean };
};

const ValueContainer = <IsMulti extends boolean>(props: ValueContainerProps<IsMulti>) => {
  const { selectedFilters, setSelectedFilters } = useContext(FiltersContext);
  const inputContainerRef = useRef<HTMLDivElement>(null);
  const { options } = props;

  const refreshedStyling = !!props.selectProps.refreshedStyling;

  const { loading, scrollAddIntoView, forceShowSearchIcon } = props.selectProps;

  const onRemoveFilter = useCallback(
    (removed: SearchFilter) => setSelectedFilters((prev) => prev.filter((filter) => filter !== removed)),
    [setSelectedFilters],
  );

  const { hasValue, clearValue, isMulti } = props;
  const { onClearSearch, showQuickFilters, onClear, closeSearchMenu } = props.selectProps;

  const onClearQuery = useCallback(() => {
    setSelectedFilters(() => []);
    onClearSearch();
  }, [onClearSearch, setSelectedFilters]);

  const onClearValue = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      e.preventDefault();
      clearValue();
      onClear?.();
      closeSearchMenu();
    },
    [closeSearchMenu, onClear, clearValue],
  );
  const showClearQueryIcon = showQuickFilters && (selectedFilters.length || props.selectProps.inputValue);

  const showClearValueIcon = onClear && hasValue && !isMulti;

  useLayoutEffect(() => {
    if (!scrollAddIntoView) {
      return;
    }

    if (!loading && !isEmpty(options)) {
      inputContainerRef.current?.scrollIntoView();
    }
  }, [loading, options, scrollAddIntoView]);

  return (
    <SearchWrapper
      data-testid="search-value-container"
      className="search-value-container"
      ref={inputContainerRef}
      refreshedStyling={refreshedStyling}
    >
      {((!hasValue && !refreshedStyling) || forceShowSearchIcon) && (
        <SearchIcon type="search" prefix="fas" className={selectClasses.SearchIcon} />
      )}
      {selectedFilters.map((filter) => (
        <FilterPill onClose={() => onRemoveFilter(filter)} key={filter.name} name={filter.name} />
      ))}
      <ValueContainerComponent {...props} />
      {showClearQueryIcon && (
        <StyledIcon className="qa-clear-search" aria-label="Clear query" type="times" {...buttonize(onClearQuery)} />
      )}
      {showClearValueIcon && (
        <ButtonWrapper className="qa-clear" role="button" tabIndex={0} onMouseDown={onClearValue}>
          <StyledIcon aria-label="Clear selection" type="times" />
        </ButtonWrapper>
      )}
      {!hasValue && refreshedStyling && <StyledIcon type="angle-down" prefix="fas" />}
    </SearchWrapper>
  );
};

export default ValueContainer;

const ButtonWrapper = styled.div`
  cursor: pointer;
`;

const StyledIcon = styled(Icon)`
  font-size: 14px;
  color: ${GetColor.DarkGrey};

  :hover {
    color: ${ColorUtils.opacifyDarkFrom(GetColor.DarkGrey, 0.5)};
  }
`;

const SearchWrapper = styled.div<{ refreshedStyling: boolean }>`
  padding: ${(props) => (props.refreshedStyling ? '0 10px' : '0 20px')};
  display: flex;
  align-items: center;
  flex: 1;
  flex-wrap: wrap;
  height: 100%;
  overflow: hidden;
`;

const SearchIcon = styled(Icon)`
  font-size: 14px;
  margin-right: 5px;
`;
