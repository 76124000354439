import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { GetColor } from 'venn-ui-kit';

import { type HTMLProps } from '../factory';

export type BlockHeaderTopContainerElement = React.ElementRef<'div'>;
export interface BlockHeaderTopContainerProps extends HTMLProps<'div'> {
  inPrintMode: boolean;
  children: React.ReactNode;
}

export const BlockHeaderTopContainer = forwardRef<BlockHeaderTopContainerElement, BlockHeaderTopContainerProps>(
  (props, ref) => {
    const { inPrintMode, children } = props;

    return (
      <Container ref={ref} inPrintMode={inPrintMode}>
        {children}
      </Container>
    );
  },
);

BlockHeaderTopContainer.displayName = 'BlockHeaderTopContainer';

const Container = styled.div<{ inPrintMode: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${({ inPrintMode }) => `${inPrintMode ? '0.7rem 0.7rem 0.3rem 0.7rem' : '13px 15px 5px 15px'}`};

  label {
    font-size: 14px;
    padding-right: 5px;
  }

  border-bottom: 1px solid ${GetColor.Grey};
`;
