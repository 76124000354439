import { ErrorBanner } from './MutliProFormaReviewComponents.RightPane.ErrorBanner';
import { Footer } from './MultiProFormaReviewComponents.Footer';
import { Content } from './MutliProFormaReviewComponents.RightPane.Content';

export const MultiProFormaReviewComponents = {
  RightPane: {
    Content,
    ErrorBanner,
  },
  Footer,
};
