import { useRecoilValue } from 'recoil';
import { useVirtualization } from '../../../../logic/useVirtualization';
import { useAppPrintMode } from 'venn-components';
import {
  blockSettings,
  studioBlockCustomWidth,
  VIRTUALIZATION_SCROLL_BAR_PX,
  DEFAULT_MARGIN,
  COLUMN_GAP,
} from 'venn-state';

/**
 * You can set either the container width for the block or the intended width of the block, but not both.
 * TODO(collin.irwin): Receiving Width and ContainerWidth both is pretty confusing. Can we refactor this to just getting a single width,
 * or maybe some other clearer architecture?
 */
export type BlockWidths =
  | {
      width: number;
      containerWidth?: undefined;
    }
  | { width?: undefined; containerWidth: number };

export const useComputeBlockWidth = ({
  id,
  width,
  containerWidth,
}: {
  id: string;
} & BlockWidths) => {
  const blockSetting = useRecoilValue(blockSettings(id));
  const { inPrintModeOrReportLab } = useAppPrintMode();
  const blockRelativeWidth = useRecoilValue(studioBlockCustomWidth(id));
  const hasVirtualization = useVirtualization();

  const fullWidth = blockSetting.customBlockType === 'PAGE_BREAK';
  const getStudioBlockWidth = (pageWidth: number) => {
    const numberOfColumnGaps = 1 / blockRelativeWidth - 1;
    const virtuosoScrollBarWidth = hasVirtualization && numberOfColumnGaps <= 0.0001 ? VIRTUALIZATION_SCROLL_BAR_PX : 0;
    const printMarginPx = DEFAULT_MARGIN * (inPrintModeOrReportLab ? 2 : 0);

    const availablePageWidth = pageWidth - printMarginPx - COLUMN_GAP * numberOfColumnGaps - virtuosoScrollBarWidth;

    return availablePageWidth * blockRelativeWidth;
  };

  const blockWidthPx = width !== undefined ? getStudioBlockWidth(width) : containerWidth;

  const blockWidthStyle = containerWidth || fullWidth ? '100%' : `${blockWidthPx}px`;

  return {
    blockWidthPx,
    blockWidthStyle,
  };
};
