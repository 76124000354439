/* eslint-disable import/no-import-module-exports */
// the lint rule should be disabled for main module, but somehow the plugin can't figure it out

import './polyfills';
// Allow import of all css styles in the style module of venn-ui-kit
import 'venn-ui-kit/style';
import React from 'react';
import ReactDOM from 'react-dom';
import { initializeSentryLogger } from 'venn-utils';
import 'focus-visible';
import App from './app';

initializeSentryLogger();

if (module.hot) {
  module.hot.accept();
}

if (window.Cypress) {
  // eslint-disable-next-line react/no-deprecated
  ReactDOM.render(<App />, document.getElementById('root'));
} else {
  // eslint-disable-next-line react/no-deprecated
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root'),
  );
}
