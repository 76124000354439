import React from 'react';
import { MultiHistoricalPortfolioContext } from './MultiHistoricalPortfolioContext';
import { assertExhaustive } from 'venn-utils';
import { useMultiHistoricalReviewRightPane } from './logic/useMultiHistoricalReviewRightPane';

type RootProps = {
  children: React.ReactNode;
};

export const Root = ({ children }: RootProps) => {
  const result = useMultiHistoricalReviewRightPane();
  switch (result.kind) {
    case 'success': {
      return (
        <MultiHistoricalPortfolioContext.Provider value={result.context}>
          {children}
        </MultiHistoricalPortfolioContext.Provider>
      );
    }
    case 'error': {
      switch (result.error) {
        case 'duplicate dates':
          // todo: VENN-27935 handle errors when there are duplicate dates with different timestamps in a historical portfolio
          return null;
        case 'no dates':
          // todo: VENN-27935 handle errors when there are no dates in a historical portfolio
          return null;
        default:
          throw assertExhaustive(result.error);
      }
    }
    case 'loading': {
      return null;
    }
    default:
      throw assertExhaustive(result);
  }
};
