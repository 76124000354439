import { getTextThemeProvider } from '../../getTextThemeProvider';

// The links are exported here for convenience so that there's no need to manually change all the imports
const links = getTextThemeProvider().HelpCenterLinks;
export const INTERCOM_HELP_ALL_HREF = links.INTERCOM_HELP_ALL_HREF;
export const FACTOR_EXPOSURE_HREF = links.FACTOR_EXPOSURE_HREF;
export const FACTOR_CONTRIBUTION_TO_RISK_HREF = links.FACTOR_CONTRIBUTION_TO_RISK_HREF;
export const FACTOR_CONTRIBUTION_TO_RETURN_HREF = links.FACTOR_CONTRIBUTION_TO_RETURN_HREF;
export const INTERCOM_HELP_HREF = links.INTERCOM_HELP_HREF;
export const METRIC_DEFINITIONS_HREF = links.METRIC_DEFINITIONS_HREF;
export const FORECASTS_FAQ_HREF = links.FORECASTS_FAQ_HREF;
export const DEMO_PORTFOLIO_FAQ_HREF = links.DEMO_PORTFOLIO_FAQ_HREF;
export const DEMO_PRIVATE_PORTFOLIO_FAQ_HREF = links.DEMO_PRIVATE_PORTFOLIO_FAQ_HREF;
export const STUDIO_FAQ_HREF = links.STUDIO_FAQ_HREF;
export const BENCHMARK_FAQ_HREF = links.BENCHMARK_FAQ_HREF;
export const FORECAST_RETURN_DEF_HREF = links.FORECAST_RETURN_DEF_HREF;
export const EXCESS_RETURN_DEF_HREF = links.EXCESS_RETURN_DEF_HREF;
export const TRACKING_ERROR_DEF_HREF = links.TRACKING_ERROR_DEF_HREF;
export const INFORMATION_RATIO_DEF_HREF = links.INFORMATION_RATIO_DEF_HREF;
export const MAX_UNDERPERFORMANCE_DEF_HREF = links.MAX_UNDERPERFORMANCE_DEF_HREF;
export const HISTORICAL_RISK_STATISTICS_HREF = links.HISTORICAL_RISK_STATISTICS_HREF;
export const PORTFOLIO_LAB_FAQ_HREF = links.PORTFOLIO_LAB_FAQ_HREF;
export const ADVANCED_SEARCH_FAQ_HREF = links.ADVANCED_SEARCH_FAQ_HREF;
export const HOLDINGS_FAQ_HREF = links.HOLDINGS_FAQ_HREF;
export const VENNCAST_FAQ_HREF = links.VENNCAST_FAQ_HREF;
export const CURRENCY_CONVERSION_ARTICLE_HREF = links.CURRENCY_CONVERSION_ARTICLE_HREF;
export const REBALANCE_STRATEGY_ARTICLE_HREF = links.REBALANCE_STRATEGY_ARTICLE_HREF;
export const INVESTMENT_CORRELATION_TOOL_HREF = links.INVESTMENT_CORRELATION_TOOL_HREF;
export const FACTOR_LENS_FAQ_HREF = links.FACTOR_LENS_FAQ_HREF;
export const CURRENCY_FAQ_HREF = links.CURRENCY_FAQ_HREF;
export const PROXY_FAQ_HREF = links.PROXY_FAQ_HREF;
export const DESMOOTHING_FAQ_HREF = links.DESMOOTHING_FAQ_HREF;
export const INTERPOLATION_EXTRAPOLATION_FAQ_HREF = links.INTERPOLATION_EXTRAPOLATION_FAQ_HREF;
export const WORKSPACE_MANAGEMENT_ARTICLE_HREF = links.WORKSPACE_MANAGEMENT_ARTICLE_HREF;
export const GROWTH_SIMULATOR_FAQ_HREF = links.GROWTH_SIMULATOR_FAQ_HREF;
export const SCENARIO_ANALYSIS_FAQ_HREF = links.SCENARIO_ANALYSIS_FAQ_HREF;
export const SENSITIVITY_ANALYSIS_FAQ_HREF = links.SENSITIVITY_ANALYSIS_FAQ_HREF;
export const NOTABLE_PERIODS_FAQ_HREF = links.NOTABLE_PERIODS_FAQ_HREF;
export const CATEGORIES_FAQ_HREF = links.CATEGORIES_FAQ_HREF;
export const FACTOR_ALERTS_FAQ_HREF = links.FACTOR_ALERTS_FAQ_HREF;
export const HISTORICAL_DRAWDOWN_FAQ_HREF = links.HISTORICAL_DRAWDOWN_FAQ_HREF;
export const ACCESSING_ANALYSES_FAQ_HREF = links.ACCESSING_ANALYSES_FAQ_HREF;
export const INVESTMENT_UPLOADER_FAQ_HREF = links.INVESTMENT_UPLOADER_FAQ_HREF;
export const RETURNS_UPLOADER_FAQ_HREF = links.RETURNS_UPLOADER_FAQ_HREF;
export const MASTER_PORTFOLIO_ARTICLE_HREF = links.MASTER_PORTFOLIO_ARTICLE_HREF;
export const COMMON_BENCHMARKS_HELP_HREF = links.COMMON_BENCHMARKS_HELP_HREF;
export const PORTFOLIO_COMPARISON_HELP_HREF = links.PORTFOLIO_COMPARISON_HELP_HREF;
export const PRIVATE_ASSET_LAB_FAQ_HREF = links.PRIVATE_ASSET_LAB_HELP_HREF;
export const PEER_GROUP_ANALYSIS_FAQ_HREF = links.PEER_GROUP_ANALYSIS_HELP_HREF;
export const PRIVATE_ASSET_CASH_FLOW_HELP_HREF = links.PRIVATE_ASSET_CASH_FLOW_HELP_HREF;
export const PRIVATE_PERFORMANCE_HELP_HREF = links.PRIVATE_PERFORMANCE_HELP_HREF;
export const PRIVATE_ASSET_UPLOADER_FAQ_HREF = links.PRIVATE_ASSET_UPLOADER_FAQ_HREF;
export const PORTFOLIO_CONSTRUCTION_FAQ_HREF = links.PORTFOLIO_CONSTRUCTION_FAQ_HREF;
export const STUDIO_REPORT_LAB_AIS_FAQ_HREF = links.STUDIO_REPORT_LAB_AIS_FAQ_HREF;
export const MISSING_INVESTMENTS_FAQ_HREF = links.MISSING_INVESTMENTS_FAQ_HREF;
export const PUBLIC_PRIVATE_ASSET_GROWTH_HREF = links.PUBLIC_PRIVATE_ASSET_GROWTH_HREF;
export const MULTI_PORTFOLIO_UPLOADER_FAQ_HREF = links.MULTI_PORTFOLIO_UPLOADER_FAQ_HREF;
export const ANALYSIS_PERIOD_HELP_HREF = links.ANALYSIS_PERIOD_HELP_HREF;
export const HISTORICAL_PORTFOLIOS_HELP_HREF = links.HISTORICAL_PORTFOLIOS_HELP_HREF;
