import React from 'react';
import { SidePanelOverlay, SIDE_PANEL_CONTENT_CLASSNAME, ConfirmationModal, ModalSize } from 'venn-components';
import type { AddeparPanelState } from './useAddeparSetupPanel';
import AddeparPanelContent from './AddeparPanelContent';
import AddeparPanelButtons from './AddeparPanelButtons';
import useAddeparViewConfiguration from './useAddeparViewConfiguration';
import { VIEW_CONFIG_STEP } from './steps';
import { analyticsService } from 'venn-utils';
import { ZIndex } from 'venn-ui-kit';

interface AddeparSetupPanelProps extends AddeparPanelState {
  /** Refetch client integrations list */
  refetchClientIntegrations(): void;
  openHelpModal(): void;
}

const panelClassName = 'addepar-panel';

const AddeparSetupPanel = ({
  isOpen,
  onClose,
  goToPreviousStep,
  goToNextStep,
  step,
  addeparAuthUrl,
  isAuthorizing,
  addeparFirmId,
  addeparFirmBaseUrl,
  refetchClientIntegrations,
  openHelpModal,
}: AddeparSetupPanelProps) => {
  const {
    disableContinue,
    displayErrorMessage,
    isFirstTimeSetup,
    onFinish,
    onCancel,
    cancelModalOpen,
    onReset,
    closeCancelModal,
    ...viewConfigState
  } = useAddeparViewConfiguration({
    firmId: addeparFirmId,
    onClose,
    refetchClientIntegrations,
  });
  return (
    <>
      <SidePanelOverlay.Root
        margin={10}
        hideTopBackButton
        className={panelClassName}
        isOpen={isOpen}
        side="right"
        handleClose={onCancel}
        width={1280}
        content={
          <AddeparPanelContent
            step={step}
            isAuthorizing={isAuthorizing}
            addeparFirmBaseUrl={addeparFirmBaseUrl}
            addeparFirmId={addeparFirmId}
            containerSelector={`.${panelClassName} .${SIDE_PANEL_CONTENT_CLASSNAME}`}
            {...viewConfigState}
          />
        }
        footer={
          <AddeparPanelButtons
            addeparAuthUrl={addeparAuthUrl}
            goToNextStep={goToNextStep}
            goToPreviousStep={goToPreviousStep}
            step={step}
            onCancel={onCancel}
            errorMessage={step === VIEW_CONFIG_STEP ? displayErrorMessage : undefined}
            disableAll={isAuthorizing || viewConfigState.isRefreshing}
            disableContinue={step >= VIEW_CONFIG_STEP && disableContinue}
            isFirstTimeSetup={isFirstTimeSetup}
            onFinish={onFinish}
            openHelpModal={openHelpModal}
          />
        }
      />
      {cancelModalOpen && (
        <ConfirmationModal
          destructive
          header="Cancel integration set-up?"
          subhead="Any selections will not be saved and no data will be synced to Venn. Your Addepar data will not be affected."
          size={ModalSize.Large}
          proceedLabel="Cancel Set-up"
          onProceed={() => {
            onReset();
            analyticsService.ctaClicked({
              purpose: 'Cancel Set-up',
              text: 'Cancel Set-up',
              filled: true,
              locationOnPage: 'Addepar Cancel Confirmation Modal',
            });
          }}
          onCancel={closeCancelModal}
          zIndex={ZIndex.InterComFront2}
        />
      )}
    </>
  );
};

export default AddeparSetupPanel;
