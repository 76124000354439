import React, { memo, forwardRef } from 'react';
import Measure from 'react-measure';
import styled from 'styled-components';

import { ShimmerBlock } from 'venn-ui-kit';
import { recoilBlockMaxSize } from 'venn-state';

import {
  BOLD_CLASS,
  LEFT_ALIGN_CLASS,
  RIGHT_ALIGN_CLASS,
  JUSTIFY_HEADER_END_CLASS,
} from '../../../../studio-blocks/customAnalysisContants';
import { useSetBlockSize } from '../../../../studio-blocks/logic/useSetBlockSize';
import type { ErrorWrapper } from '../../../../studio-blocks/components/error-wrappers/errorWrapperTypes';
import { DOWNLOAD_BLOCK_CLASS } from '../../../../content-block/DownloadableContentBlock';
import { blockEmptyStateClass } from '../../../../empty-state/EmptyState';
import { StudioBlockWatermark, vennBlockWatermarkClass } from '../../../../block-watermark/BlockWatermark';
import { ErrorMultiWrapper } from '../../../../studio-blocks/components/error-wrappers/ErrorMultiWrapper';
import { BlockSuspenseFallback } from '../../../../studio-blocks/components/BlockSuspenseFallback';

export type BlockContentElement = React.ElementRef<'div'>;
export interface BlockContentProps {
  id: string;
  children: React.ReactNode;
  errorWrappers?: ErrorWrapper[];
}

const InternalBlockContent = forwardRef<BlockContentElement, BlockContentProps>(
  function InternalBlockContent(props, ref) {
    const { id, errorWrappers = [], children } = props;
    const onResize = useSetBlockSize(recoilBlockMaxSize.transformedState(id));

    return (
      <WatermarkContainer ref={ref}>
        <Measure bounds onResize={onResize}>
          {({ measureRef }) => (
            <MeasureContainer ref={measureRef}>
              <React.Suspense fallback={<BlockSuspenseFallback />}>
                <ErrorMultiWrapper blockId={id} wrappers={errorWrappers}>
                  {children}
                </ErrorMultiWrapper>
              </React.Suspense>
            </MeasureContainer>
          )}
        </Measure>
        <StudioBlockWatermark />
      </WatermarkContainer>
    );
  },
);

export const BlockContent = memo(InternalBlockContent);
BlockContent.displayName = 'BlockContent';

/** Contains the block content container and the block watermark, enabling the block watermark to overlay the block content container. */
const WatermarkContainer = styled.div`
  flex: 1;
  min-height: 0;
  position: relative;
  height: 100%;

  :has(.${blockEmptyStateClass}) .${vennBlockWatermarkClass} {
    display: none;
  }
`;

const MeasureContainer = styled.div`
  display: flex;
  /* needed to stop overflow */
  min-height: 0;
  height: 100%;

  /* Warning: Removing this CSS breaks many blocks in unexpected ways, because they stop growing and instead shrink down to their content's minimum size. */
  > div:last-child:not(${ShimmerBlock}) {
    width: 100%;
    height: 100%;
  }

  .${DOWNLOAD_BLOCK_CLASS} {
    width: 100%;
  }

  .${LEFT_ALIGN_CLASS} {
    text-align: left;
  }

  .${JUSTIFY_HEADER_END_CLASS} .ag-header-cell-label {
    justify-content: flex-end;
  }

  .${RIGHT_ALIGN_CLASS} {
    text-align: right;
  }
  .${BOLD_CLASS} {
    font-weight: bold;
  }
`;
