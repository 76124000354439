import { useRecoilValue, useSetRecoilState } from 'recoil';
import { blockSettings, type BlockId, DEFAULT_MARGIN, studioBlockHeight } from 'venn-state';
import { useHasFF } from 'venn-utils';
import { supportsNewBlock, BlockSwitch } from './BlockSwitch';
import React, { forwardRef, useCallback } from 'react';
import Measure from 'react-measure';
import styled from 'styled-components';
import { DeprecatedBlock } from './DeprecatedBlock';

type StudioModuleBlockSwitchProps = {
  id: string;
  index: number;
  width: number;
};

export const StudioModuleBlockSwitch = (props: StudioModuleBlockSwitchProps) => {
  const hasNewBlockArchitecture = useHasFF('new_block_architecture_ff');
  const blockSetting = useRecoilValue(blockSettings(props.id));
  const customBlockType = blockSetting.customBlockType;

  if (supportsNewBlock(customBlockType, hasNewBlockArchitecture)) {
    return (
      <StudioBlockContainer id={props.id}>
        <BlockSwitch {...props} />
      </StudioBlockContainer>
    );
  }

  return <DeprecatedBlock {...props} />;
};

type ContainerProps = {
  id: BlockId;
  children: React.ReactNode;
};
const StudioBlockContainer = forwardRef<HTMLDivElement, ContainerProps>((props, ref) => {
  const { id, children } = props;

  const setBlockHeight = useSetRecoilState(studioBlockHeight(id));
  const onResize = useCallback(
    (contentRect) => setBlockHeight((currVal) => Math.ceil(Math.max(contentRect?.bounds?.height ?? 0, currVal))),
    [setBlockHeight],
  );

  return (
    <Measure bounds key={id} onResize={onResize} innerRef={ref}>
      {({ measureRef }) => (
        <div ref={measureRef} style={{ alignSelf: 'start' }}>
          {children}
          <BlockSpacer />
        </div>
      )}
    </Measure>
  );
});

StudioBlockContainer.displayName = 'StudioBlockContainer';

const BlockSpacer = styled.div`
  height: ${DEFAULT_MARGIN}px;
`;
