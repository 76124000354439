import React, { useRef } from 'react';
import { useRecoilValue } from 'recoil';
import {
  CustomAnalysisBlock,
  CustomHoldingsBlock,
  PrivatesBlock,
  PortfolioComparisonBlock,
  useAppPrintMode,
  TextAndFormatBlock,
} from 'venn-components';
import { blockSettings, isReportState } from 'venn-state';
import { isAnalysisBlock, isHoldingsBlock, isPortfolioBlock, isPrivatesBlock, isTextOrFormatBlock } from 'venn-utils';
import { Block } from './components/core/Block';
import DeprecatedBlockHeader from './DeprecatedBlockHeader';
import { type BlockWidths } from './components/core/useComputeBlockWidth';

type BlockProps = {
  id: string;
  index: number;
  externalBlockWrapper?: boolean;
} & BlockWidths;

export const DeprecatedBlock = React.memo(function DeprecatedBlock({
  id,
  index,
  width,
  externalBlockWrapper,
  containerWidth,
}: BlockProps) {
  const blockSetting = useRecoilValue(blockSettings(id));
  const blockRef = useRef<HTMLDivElement>(null);
  const isReport = useRecoilValue(isReportState);
  const { inPrintModeOrReportLab } = useAppPrintMode();
  const rootProps = {
    id,
    index,
    externalBlockWrapper,
    blockRef,
    blockWidths: { width, containerWidth } as BlockWidths,
  };

  if (isTextOrFormatBlock(blockSetting.customBlockType)) {
    return (
      <Block.Root {...rootProps}>
        <TextAndFormatBlock id={id} />
      </Block.Root>
    );
  }
  return (
    <Block.Root {...rootProps}>
      <DeprecatedBlockHeader />
      <Block.ContentWrapper>
        {isHoldingsBlock(blockSetting.customBlockType) && (
          <CustomHoldingsBlock
            isPrinting={inPrintModeOrReportLab}
            downloadableContentRef={blockRef}
            isReport={isReport}
            selectedRefId={id}
          />
        )}
        {isPrivatesBlock(blockSetting.customBlockType) && (
          <PrivatesBlock
            isPrinting={inPrintModeOrReportLab}
            downloadableContentRef={blockRef}
            isReport={isReport}
            selectedRefId={id}
          />
        )}
        {isPortfolioBlock(blockSetting.customBlockType) && <PortfolioComparisonBlock />}
        {isAnalysisBlock(blockSetting.customBlockType) && (
          <CustomAnalysisBlock
            isPrinting={inPrintModeOrReportLab}
            downloadableContentRef={blockRef}
            isReport={isReport}
            selectedRefId={id}
          />
        )}
      </Block.ContentWrapper>
    </Block.Root>
  );
});
