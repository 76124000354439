import { toEndOfPeriod, FS } from 'venn-utils';
import moment from 'moment';
import type { FrequencyEnum } from 'venn-api';

export const DAY_SIZE = 213;
export const MONTH_SIZE = 189;
export const YEAR_SIZE = 141;
/** Available factors start date */
const NEW_FACTOR_LENS_START_DATE = '1998/08/07';

/** The maximum range possible: from the start of factor data to today. */
export const getFactorMaxRange = (frequency: FrequencyEnum) => ({
  from: FS.has('extend_full_history_ff')
    ? toEndOfFirstFullPeriod(NEW_FACTOR_LENS_START_DATE, frequency)
    : toEndOfPeriod(NEW_FACTOR_LENS_START_DATE, 'day'),
  to: toEndOfPeriod(new Date().valueOf(), 'day'),
});

const FREQUENCY_TO_PERIOD = {
  DAILY: 'day' as const,
  WEEKLY: 'week' as const,
  MONTHLY: 'month' as const,
  QUARTERLY: 'quarter' as const,
  YEARLY: 'year' as const,
  UNKNOWN: 'day' as const,
};

export const toEndOfFirstFullPeriod = (date: string | number | undefined, frequency: FrequencyEnum) => {
  let momentDate = moment.utc(date);
  const period = FREQUENCY_TO_PERIOD[frequency];
  if (period !== 'day') {
    momentDate = momentDate.add(1, period);
  }
  return momentDate.endOf(period).valueOf();
};
