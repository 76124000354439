import React, { useContext, useState } from 'react';
import type { MultiPortfolioParseResult } from 'venn-api';
import { assertExhaustive, assertNotNil, useHasFF } from 'venn-utils';
import UniversalUploaderContext from '../../../contexts/universal-uploader';
import {
  IPortfolioUploadConfirmationView,
  IReviewHistoricalPortfolioView,
  IReviewProFormaPortfolioView,
  IUploadPortfolioView,
} from '../constants';
import type { MultiPortfolioUploadConfirmationData, MultiPortfolioUploaderViewId } from '../types';
import { isHistoricalData } from '../utils';
import { MultiHistoricalReviewStep } from './MultiHistoricalReviewStep';
import MultiPortfolioConfirmationStep from './MultiPortfolioConfirmationStep';
import MultiPortfolioUploadStep from './MultiPortfolioUploadStep';
import { MultiProFormaReviewStep } from './MultiProFormaReviewStep';

type MultiPortfolioUploaderProps = Readonly<{
  viewId: MultiPortfolioUploaderViewId;
}>;

const useMultiPortfolioUploaderState = () => {
  const hasHistoricalPortfolioFeatureFlag = useHasFF('historical_portfolios_ff');
  const { setView } = useContext(UniversalUploaderContext);
  const [parsedData, setParsedData] = useState<MultiPortfolioParseResult>();
  const [uploadConfirmationData, setUploadConfirmationData] = useState<MultiPortfolioUploadConfirmationData>();

  const goToReviewStep = (parsedData: MultiPortfolioParseResult) => {
    setParsedData(parsedData);
    if (hasHistoricalPortfolioFeatureFlag && isHistoricalData(parsedData)) {
      setView(IReviewHistoricalPortfolioView);
    } else {
      setView(IReviewProFormaPortfolioView);
    }
  };

  const goBackToUploadStep = () => {
    setParsedData(undefined);
    setView(IUploadPortfolioView);
  };

  const goToUploadConfirmation = (data: MultiPortfolioUploadConfirmationData) => {
    setUploadConfirmationData(data);
    setView(IPortfolioUploadConfirmationView);
  };

  return {
    parsedData,
    uploadConfirmationData,
    goToReviewStep,
    goBackToUploadStep,
    goToUploadConfirmation,
  };
};

const MultiPortfolioUploader = ({ viewId }: MultiPortfolioUploaderProps) => {
  const { parsedData, uploadConfirmationData, goToReviewStep, goBackToUploadStep, goToUploadConfirmation } =
    useMultiPortfolioUploaderState();

  switch (viewId) {
    case 'UPLOAD_PORTFOLIO':
      return <MultiPortfolioUploadStep goToReviewStep={goToReviewStep} />;
    case 'REVIEW_PRO_FORMA_PORTFOLIO':
      return (
        <MultiProFormaReviewStep
          parsedData={assertNotNil(parsedData)}
          goBackToUploadStep={goBackToUploadStep}
          goToUploadConfirmation={goToUploadConfirmation}
        />
      );
    case 'REVIEW_HISTORICAL_PORTFOLIO':
      return (
        <MultiHistoricalReviewStep
          parsedData={assertNotNil(parsedData)}
          goBackToUploadStep={goBackToUploadStep}
          goToUploadConfirmation={goToUploadConfirmation}
        />
      );
    case 'PORTFOLIO_UPLOAD_CONFIRMATION':
      return <MultiPortfolioConfirmationStep uploadConfirmationData={assertNotNil(uploadConfirmationData)} />;
    default:
      throw assertExhaustive(viewId);
  }
};

export default MultiPortfolioUploader;
