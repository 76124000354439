import type { ProxyTypeEnum } from 'venn-api';
import { useHasFF } from 'venn-utils';
import { DisabledProxyReason } from '../types';

export function useProxyTypes() {
  const hasExtrapolationFf = useHasFF('extrapolation_ff');
  // Predefined arrays ensure reference stability
  return hasExtrapolationFf ? PROXY_TYPES_WITH_EXTRAPOLATION : STANDARD_PROXY_TYPES;
}

/**
 * Returns true if the provided reason is defined and is specific to the proxy type
 *
 * Other reasons may be due to other factors, such as the selected investment, and can be resolved by the user without changing the proxy type.
 */
export const isProxyTypeError = (reason?: DisabledProxyReason) => {
  return (
    !!reason &&
    [
      DisabledProxyReason.EXTRAPOLATE_QUARTERLY,
      DisabledProxyReason.BACKFILL_QUARTERLY,
      DisabledProxyReason.INTERPOLATE_NONQUARTERLY,
      DisabledProxyReason.DESMOOTH_DAILY,
      DisabledProxyReason.SUBSTITUTE_SYSTEM_FUND,
    ].includes(reason)
  );
};

const STANDARD_PROXY_TYPES = [
  'BACKFILL',
  'SUBSTITUTE',
  'DESMOOTH_INTERPOLATE',
  'INTERPOLATE',
  'DESMOOTH',
] as const satisfies ProxyTypeEnum[];

const PROXY_TYPES_WITH_EXTRAPOLATION = [...STANDARD_PROXY_TYPES, 'EXTRAPOLATE'] as const satisfies ProxyTypeEnum[];
