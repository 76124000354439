import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { Headline3 } from 'venn-ui-kit';
import { blockFonts, getCustomFontSize } from 'venn-state';

import { type HTMLProps } from '../factory';
import { useRecoilValue } from 'recoil';

export type BlockHeadlineElement = React.ElementRef<'div'>;
export interface BlockHeadlineProps extends HTMLProps<'div'> {
  id: string;
  isReport: boolean;
  children: React.ReactNode;
}

export const BlockHeadline = forwardRef<BlockHeadlineElement, BlockHeadlineProps>((props, ref) => {
  const { id, isReport, children } = props;

  const titleFont = useRecoilValue(blockFonts.blockTitle(id));
  const fontSize = getCustomFontSize(titleFont);

  return (
    <Headline ref={ref} data-testid="qa-block-header" style={{ fontSize }} isReport={isReport}>
      {children}
    </Headline>
  );
});

BlockHeadline.displayName = 'BlockHeadline';

const Headline = styled(Headline3)<{ isReport: boolean }>`
  margin: 0 10px 0 0;
  vertical-align: middle;
  font-size: ${({ isReport }) => (isReport ? '1.225rem' : '21px')};
  ${({ isReport }) => (isReport ? '' : '@media print { font-size: 16pt; }')}
`;
