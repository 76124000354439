import type { Portfolio } from 'venn-api';
import { find, isEqualWith } from 'lodash';

export const arePortfoliosEqual = (portfolio: Portfolio, other: Portfolio): boolean =>
  isEqualWith(portfolio, other, (_, __, key: string | number | symbol | undefined) =>
    key === 'updated' || key === 'compare' ? true : undefined,
  );

export const findInPortfolioBy = (
  portfolio: Portfolio,
  predicate: (portfolio: Portfolio) => boolean,
): Portfolio | undefined => {
  if (predicate(portfolio)) {
    return portfolio;
  }
  return find(portfolio.children.map((child) => findInPortfolioBy(child, predicate)));
};

export const findInPortfolio = (portfolio: Portfolio, id: number): Portfolio | undefined =>
  findInPortfolioBy(portfolio, (portfolio) => portfolio.id === id);

export const showReadOnlyWarning = (portfolio: Portfolio, isModelPortfolio: boolean): boolean => {
  return !!(portfolio.sourceId || portfolio.remoteId || isModelPortfolio);
};

export const isPortfolioEditable = (portfolio: Portfolio): boolean => {
  return !(portfolio.sourceId || portfolio.remoteId || portfolio.demo);
};
