import { noop } from 'lodash';
import React from 'react';
import type { Portfolio, PortfolioParseResult } from 'venn-api';

enum ConfirmationModalType {
  None,
  Discard,
  Upload,
}

export type PortfolioParseResultCategory = 'existing' | 'new' | 'excluded';

export type PortfolioParseResultWithReviewMetadata = PortfolioParseResult & {
  originalIndex: number;
  category: PortfolioParseResultCategory;
  // contains serialized paths of investments that are excluded from the portfolio
  excludedInvestments: Set<string>;
};
export type MultiPortfolioReviewContextValue = {
  data: {
    confirmationModalType: ConfirmationModalType;
    excludedParsedData: PortfolioParseResultWithReviewMetadata[];
    existingParsedData: PortfolioParseResultWithReviewMetadata[];
    newParsedData: PortfolioParseResultWithReviewMetadata[];
    parsedResults: PortfolioParseResultWithReviewMetadata[];
    selectedIndex: number;
    savePortfoliosMutationState: {
      isError: boolean;
      isLoading: boolean;
      isIdle: boolean;
    };
  };
  actions: {
    closeConfirmationModal: () => void;
    completeUpload: () => void;
    excludePortfolio: (index: number) => void;
    goBackToUploadStep: () => void;
    includePortfolio: (index: number) => void;
    onBackButtonClick: () => void;
    openDiscardConfirmationModal: () => void;
    openUploadConfirmationModal: () => void;
    savePortfolios: () => void;
    selectAnotherPortfolio: (index: number) => void;
    updatePortfolio: (index: number, portfolio: Portfolio) => void;
    excludeInvestment: (index: number, path: number[]) => void;
    includeInvestment: (index: number, path: number[]) => void;
  };
};

export const defaultMultiPortfolioReviewContextValue: MultiPortfolioReviewContextValue = {
  actions: {
    closeConfirmationModal: noop,
    completeUpload: noop,
    excludePortfolio: noop,
    goBackToUploadStep: noop,
    includePortfolio: noop,
    onBackButtonClick: noop,
    openDiscardConfirmationModal: noop,
    openUploadConfirmationModal: noop,
    savePortfolios: noop,
    selectAnotherPortfolio: noop,
    updatePortfolio: noop,
    excludeInvestment: noop,
    includeInvestment: noop,
  },
  data: {
    confirmationModalType: ConfirmationModalType.None,
    excludedParsedData: [],
    existingParsedData: [],
    newParsedData: [],
    parsedResults: [],
    selectedIndex: 0,
    savePortfoliosMutationState: {
      isError: false,
      isLoading: false,
      isIdle: true,
    },
  },
};

export const MultiPortfolioReviewContext = React.createContext<MultiPortfolioReviewContextValue>(
  defaultMultiPortfolioReviewContextValue,
);
MultiPortfolioReviewContext.displayName = 'MultiPortfolioReviewContext';
