import type { FC } from 'react';
import React from 'react';
import SidePanelOverlay from '../../../../side-panel-overlay/SidePanelOverlay';

interface FooterButtonsProps {
  disabled?: boolean;
  disabledMessage?: React.ReactNode;
  onContinue?: () => void;
  onCancel?: () => void;
  onStartOver?: () => void;
  hasNoData?: boolean;
  isSample?: boolean;
  primaryLabel?: string | JSX.Element;
  primaryTooltip?: string;
  secondaryLabel?: string | JSX.Element;
  cancelLabel?: string | JSX.Element;
  className?: string;
  noMargin?: boolean;
  destructive?: boolean;
}

export const UniversalUploaderFooter: FC<React.PropsWithChildren<FooterButtonsProps>> = ({
  disabled,
  disabledMessage,
  onContinue,
  onCancel,
  onStartOver,
  hasNoData,
  isSample,
  primaryLabel,
  primaryTooltip,
  cancelLabel,
  secondaryLabel,
  noMargin,
  destructive = false,
}) => {
  return (
    <SidePanelOverlay.Footer
      destructive={destructive}
      noMargin={noMargin}
      className="no-margin-footer"
      cancelLabel={cancelLabel ?? '< BACK'}
      cancelClassName="qa-upload-cancel"
      onCancel={isSample ? undefined : onCancel}
      rightChildren={disabledMessage}
      primaryLabel={hasNoData ? '' : (primaryLabel ?? 'Complete Upload')}
      primaryClassName="qa-accept-btn"
      primaryDisabled={disabled}
      primaryTooltip={primaryTooltip}
      onPrimaryClick={onContinue}
      secondaryLabel={secondaryLabel ?? (isSample ? 'Try with Your Data' : 'Start Over')}
      secondaryClassName="start-over-btn"
      onSecondaryClick={onStartOver}
    />
  );
};
