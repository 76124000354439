import type { Layout } from 'react-grid-layout';
import { useRecoilState } from 'recoil';
import { blockOverflowErrorState, type OverflowError } from 'venn-state';
import { ZIndex, GetColor, Icon } from 'venn-ui-kit';
import { assertExhaustive } from 'venn-utils';
import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { BLOCK_CONTAINER_CLASS } from './components/core';

interface ReportLabBlockContainerProps {
  id: string;
  children: React.ReactNode;
  blockLayout: Layout;
}

export const ReportLabBlockContainer = forwardRef<HTMLDivElement, ReportLabBlockContainerProps>((props, ref) => {
  const { id, children, blockLayout } = props;

  const [overflowError] = useRecoilState(blockOverflowErrorState(id));

  return (
    <OverflowErrorWrapper ref={ref} error={overflowError}>
      <BlockContainer height={blockLayout?.h ?? 0} width={blockLayout?.w ?? 0}>
        {children}
      </BlockContainer>
      <OverflowErrorMessage error={overflowError} />
    </OverflowErrorWrapper>
  );
});
ReportLabBlockContainer.displayName = 'ReportLabBlockContainer';

const OverflowErrorWrapper = styled.div<{ error: OverflowError | undefined }>`
  position: relative;

  width: 100%;
  height: 100%;

  & div:has(.${BLOCK_CONTAINER_CLASS}) {
    height: 100%;
    width: 100%;
  }

  ::before {
    content: '\\a0';
    display: block;
    z-index: ${ZIndex.Cover};
    width: 100%;
    height: 100%;
    position: absolute;
    pointer-events: none;
    ${({ error }) => {
      if (!error) {
        return undefined;
      }

      switch (error.position) {
        case 'Bottom':
          return css`
            border-bottom: 3px solid ${GetColor.Error};
          `;
        case 'Right':
          return css`
            border-right: 3px solid ${GetColor.Error};
          `;
        case 'Bottom-Right':
          return css`
            border-bottom: 3px solid ${GetColor.Error};
            border-right: 3px solid ${GetColor.Error};
          `;
        case 'Surround':
          return css`
            border: 3px solid ${GetColor.Error};
          `;
        default:
          throw assertExhaustive(error.position);
      }
    }}
  }
`;

const OverflowMessageWrapper = styled.div<{ error: OverflowError }>`
  z-index: ${ZIndex.Cover};
  width: 100%;
  height: 100%;
  position: absolute;
  pointer-events: none;
  ${({ error }) => {
    switch (error.position) {
      case 'Bottom':
      case 'Surround':
        return css`
          transform: translateX(50%);
        `;
      case 'Right':
        return css`
          transform: translate(100%, -50%);
        `;
      case 'Bottom-Right':
        return css`
          transform: translateX(100%);
        `;
      default:
        throw assertExhaustive(error.position);
    }
  }}
`;

const OverflowMessageContent = styled.div<{ error: OverflowError }>`
  color: ${GetColor.White};
  background-color: ${GetColor.Error};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 2px;
  gap: 5px;
  white-space: pre;
  font-size: 1rem;
  ${({ error }) => {
    switch (error.position) {
      case 'Bottom':
      case 'Surround':
        return css`
          transform: translate(-50%, -100%);
        `;
      case 'Right':
        return css`
          transform-origin: left;
          transform: rotate(90deg) translate(-50%, 50%);
        `;
      case 'Bottom-Right':
        return css`
          transform: translate(-100%, -100%);
        `;
      default:
        throw assertExhaustive(error.position);
    }
  }}
`;

const BlockContainer = styled.div<{ width: number; height: number }>`
  z-index: ${ZIndex.Front};
  overflow: hidden;
`;

const OverflowErrorMessage = ({ error }: { error: OverflowError | undefined }) => {
  if (!error) {
    return null;
  }

  const renderContent = () => {
    switch (error.position) {
      case 'Bottom':
        return (
          <>
            <Icon prefix="fal" type="arrow-down" />
            {error.message}
            <Icon prefix="fal" type="arrow-down" />
          </>
        );
      case 'Right':
      case 'Surround':
        return (
          <>
            <Icon prefix="fal" type="arrow-up" />
            {error.message}
            <Icon prefix="fal" type="arrow-up" />
          </>
        );
      case 'Bottom-Right':
        return (
          <>
            <Icon prefix="fal" type="arrow-down-right" />
            {`${error.message} `}
            <Icon prefix="fal" type="arrow-down-right" style={{ opacity: 0 }} />
          </>
        );
      default:
        throw assertExhaustive(error.position);
    }
  };

  return (
    <OverflowMessageWrapper error={error}>
      <OverflowMessageContent error={error}>{renderContent()}</OverflowMessageContent>
    </OverflowMessageWrapper>
  );
};
