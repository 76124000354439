import { useContext } from 'react';
import { MultiPortfolioReviewContext } from '../components/common/MultiPortfolioReviewContext';
import { MultiHistoricalPortfolioContext } from '../components/specialized/MultiHistoricalPortfolioContext';
import type { Portfolio } from 'venn-api';
import { memoize } from 'lodash';
import { recalculateAllocations } from '../review/helpers';

export const useMultiHistoricalRightPaneContentPortfolio = () => {
  const {
    data: { parsedResults, selectedIndex },
  } = useContext(MultiPortfolioReviewContext);
  const { selectedDate } = useContext(MultiHistoricalPortfolioContext);
  const selectedPortfolio = parsedResults[selectedIndex].parsedPortfolio;
  const excludedInvestments = parsedResults[selectedIndex].excludedInvestments;
  const timestamp = selectedDate.valueOf();
  let portfolio = remapAllocations(selectedPortfolio, timestamp);
  portfolio = recalculateAllocations(portfolio, excludedInvestments);
  return {
    portfolio,
  };
};

const remapAllocations = (portfolio: Portfolio, timestamp: number): Portfolio => {
  return {
    ...portfolio,
    allocation: sumAllocation(portfolio, timestamp),
    children: portfolio.children.map((childPortfolio) => remapAllocations(childPortfolio, timestamp)),
  };
};
const sumAllocation = (portfolio: Portfolio, timestamp: number): number => {
  const _sumAllocation = memoize((portfolio: Portfolio): number => {
    const fundAllocation = portfolio.closingAllocationsTs?.find(
      (closingAllocation) => closingAllocation[0] === timestamp,
    );
    const fundAllocationValue = fundAllocation ? fundAllocation[1] : 0;
    return (
      fundAllocationValue + portfolio.children.reduce((acc, childPortfolio) => acc + _sumAllocation(childPortfolio), 0)
    );
  });
  const result = _sumAllocation(portfolio);
  _sumAllocation.cache.clear?.();
  return result;
};
