import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { compact, get } from 'lodash';

import type { Analysis } from 'venn-api';
import { blockBenchmarkRelative } from 'venn-state';

import type { DataValue, MetricRowData } from '../../../studio-blocks/types';
import { useBlockId } from '../../../studio-blocks/contexts/BlockIdContext';
import { useSelectedMetrics } from '../../../studio-blocks/logic/useSelectedMetrics';

export const usePerformanceAndRiskData = (analysesGroup: (Analysis | undefined)[][]): MetricRowData[] => {
  const blockId = useBlockId();
  const selectedMetrics = useSelectedMetrics();
  const relativeToBenchmark = useRecoilValue(blockBenchmarkRelative(blockId));

  return useMemo(
    () =>
      compact(
        selectedMetrics.map((metric) => {
          if (!metric) {
            return null;
          }

          const label = relativeToBenchmark ? (metric.relativeLabel ?? metric.label) : metric.label;

          // Special case for periodReturn
          const analysis = analysesGroup?.[0]?.find((a) => a?.analysisType === metric.analysisType);
          const analysisResult = metric.analysisResultKey ? analysis?.[metric.analysisResultKey] : analysis;
          const cumulativeLabel =
            metric.key === 'periodReturn' && analysisResult?.[0]?.periodAnnualized === false ? ' (Cumulative)' : '';

          return {
            key: metric.key,
            label: `${label}${cumulativeLabel}`,
            value: (analysesGroup?.map((analyses) => {
              const analysis = analyses.find((a) => a?.analysisType === metric.analysisType);
              const path =
                relativeToBenchmark && metric.relativeMetricPath ? metric.relativeMetricPath : metric.metricPath;
              return path
                ? get(analysis?.[metric.analysisResultKey!]?.[0], path)
                : analysis?.[metric.analysisResultKey!]?.[0];
            }) ?? []) as DataValue[],
            type: metric.dataType,
          };
        }),
      ),
    [analysesGroup, selectedMetrics, relativeToBenchmark],
  );
};
