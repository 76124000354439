import type { FrequencyEnum } from 'venn-api';
import { FREQUENCY_DATEPICKER_MODES, FrequencyTypes } from './types';

export const getDateMode = (frequencyId: FrequencyTypes | FrequencyEnum | undefined) =>
  FREQUENCY_DATEPICKER_MODES[frequencyId || FrequencyTypes.MONTHLY];

export const getFrequencyType = (frequency?: FrequencyTypes | FrequencyEnum) =>
  typeof frequency === 'string' ? FrequencyTypes[frequency] : frequency;

const frequencyTypeToReturnFrequencyEnum: { [k in FrequencyTypes]: FrequencyEnum } = {
  [FrequencyTypes.DAILY]: 'DAILY',
  [FrequencyTypes.MONTHLY]: 'MONTHLY',
  [FrequencyTypes.QUARTERLY]: 'QUARTERLY',
  [FrequencyTypes.YEARLY]: 'YEARLY',
};

export const toReturnFrequency = (frequencyType: FrequencyTypes): FrequencyEnum => {
  return frequencyTypeToReturnFrequencyEnum[frequencyType];
};

const frequencyToSortRank = {
  [FrequencyTypes.DAILY]: 1,
  [FrequencyTypes.MONTHLY]: 30,
  [FrequencyTypes.QUARTERLY]: 90,
  [FrequencyTypes.YEARLY]: 365,
  DAILY: 1,
  WEEKLY: 7,
  MONTHLY: 30,
  QUARTERLY: 90,
  YEARLY: 365,
  UNKNOWN: undefined,
} as const satisfies Record<FrequencyTypes | FrequencyEnum, number | undefined>;

/**
 * Returns the lowest frequency of the given array of frequencies.
 * For example, returns 'YEARLY' if the array contains 'DAILY' and 'YEARLY'.
 *
 * Returns 'UNKNOWN' if the array contains only 'UNKNOWN'.
 * Returns undefined if the array is empty.
 */
export function lowestFrequencyOf(frequencies: FrequencyEnum[]): FrequencyEnum | undefined;
export function lowestFrequencyOf(frequencies: FrequencyTypes[]): FrequencyTypes | undefined;
export function lowestFrequencyOf(
  frequencies: FrequencyTypes[] | FrequencyEnum[],
): FrequencyTypes | FrequencyEnum | undefined {
  if (frequencies.length === 0) {
    return undefined;
  }

  return frequencies.reduce((lowest, current) =>
    (frequencyToSortRank[current] ?? Number.NEGATIVE_INFINITY) >
    (frequencyToSortRank[lowest] ?? Number.NEGATIVE_INFINITY)
      ? current
      : lowest,
  );
}

/**
 * Returns the highest frequency of the given array of frequencies.
 * For example, returns 'DAILY' if the array contains 'DAILY' and 'YEARLY'.
 *
 * Returns 'UNKNOWN' if the array contains only 'UNKNOWN'.
 * Returns undefined if the array is empty.
 */
export function highestFrequencyOf(frequencies: FrequencyEnum[]): FrequencyEnum | undefined;
export function highestFrequencyOf(frequencies: FrequencyTypes[]): FrequencyTypes | undefined;
export function highestFrequencyOf(
  frequencies: FrequencyTypes[] | FrequencyEnum[],
): FrequencyTypes | FrequencyEnum | undefined {
  if (frequencies.length === 0) {
    return undefined;
  }

  return frequencies.reduce((highest, current) =>
    (frequencyToSortRank[current] ?? Number.POSITIVE_INFINITY) <
    (frequencyToSortRank[highest] ?? Number.POSITIVE_INFINITY)
      ? current
      : highest,
  );
}
