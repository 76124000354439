import type { VennColors } from '../../../style';
import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import Pill from '../Pill';
import type { AnalysisViewTypeEnum } from 'venn-api';
import type { ItemType } from './itemType';
import { getItemLabel } from './getItemLabel';
import { getItemColor } from './getItemColor';

const getItemStyle = (
  Colors: VennColors,
  item: ItemType | AnalysisViewTypeEnum,
  fullCompositeBenchmarkLabel?: boolean,
) => {
  return {
    label: getItemLabel(item, fullCompositeBenchmarkLabel),
    color: getItemColor(Colors, item),
  };
};

export const ItemTypePill = ({
  item,
  isDisabled,
  fullCompositeBenchmarkLabel,
  type = 'normal',
}: {
  item: ItemType | AnalysisViewTypeEnum;
  isDisabled?: boolean;
  fullCompositeBenchmarkLabel?: boolean;
  type?: 'normal' | 'large';
}) => {
  const { Colors } = useContext(ThemeContext);
  const { color, label } = getItemStyle(Colors, item, !!fullCompositeBenchmarkLabel);

  if (type === 'large') {
    return (
      <LargeStyledPill className={`qa-${item}-type-pill`} color={isDisabled ? Colors.MidGrey1 : color}>
        {label}
      </LargeStyledPill>
    );
  }
  return (
    <StyledPill className={`qa-${item}-type-pill`} color={isDisabled ? Colors.MidGrey1 : color}>
      {label}
    </StyledPill>
  );
};

export default ItemTypePill;

const StyledPill = styled(Pill)<{ color: string }>`
  background: ${(props) => props.color};
  display: block;
  min-width: 72px;
  margin-right: 0;
`;

const LargeStyledPill = styled(StyledPill)`
  height: 20px;
  font-size: 13px;
  font-weight: 500;
  padding: 2px;
`;
