import React from 'react';
import styled, { css } from 'styled-components';
import { GetColor } from '../../../style/color';
import type { DropMenuItem, MenuContainerProps } from '../types';
import NoItem from './NoItem';

interface ItemsContainerProps {
  extraStyle?: ReturnType<typeof css>;
}

export interface BaseMenuProps<TValue = string, TItem = DropMenuItem<TValue>> extends MenuContainerProps {
  items: TItem[];
  renderItem: (item: TItem, index: number) => JSX.Element;
  itemsContainerCss?: ReturnType<typeof css>;
  headerComponent?: JSX.Element;
  footerComponent?: JSX.Element;
  emptyStateComponent?: JSX.Element;
  menuTrailingContent?: JSX.Element;
  /** Temporary flag enabling the new dropdown styles */
  newStyle?: boolean;
}

const BaseMenu = <TValue, TItem = DropMenuItem<TValue>>({
  items,
  width,
  height = 175,
  renderItem,
  headerComponent,
  footerComponent,
  menuTrailingContent,
  emptyStateComponent,
  className,
  itemsContainerCss,
  newStyle,
}: BaseMenuProps<TValue, TItem>) => {
  return (
    <Container width={width} height={height} tabIndex={-1} className={className} newStyle={newStyle}>
      {headerComponent}
      {items && items.length > 0 ? (
        <Items extraStyle={itemsContainerCss} newStyle={newStyle}>
          {items.map(renderItem)}
          {menuTrailingContent}
        </Items>
      ) : (
        (emptyStateComponent ?? <NoItem>(No results found)</NoItem>)
      )}
      {footerComponent}
    </Container>
  );
};

export const Container = styled.div<MenuContainerProps & { newStyle?: boolean }>`
  display: flex;
  flex-direction: column;
  ${(props) => (props.width ? `width: ${props.width}px` : 'min-width: 200px; max-width: 100%;')};
  /* The spec says the max height should be 169px, but it's too small to fit 3 items without scroll. so 175 it is. */
  max-height: ${(props) => (Number.isNaN(Number(props.height)) ? props.height : `${props.height}px`)};
  background-color: ${GetColor.White};
  box-shadow: 0 5px 10px 0 ${GetColor.MidGrey2};

  ${({ newStyle }) =>
    newStyle &&
    css`
      box-shadow: 0 4px 4px 0 ${GetColor.MidGrey2};
      border: 1px solid ${GetColor.GreyScale.Grey30};
    `}
`;

export const Items = styled.div<ItemsContainerProps & { newStyle?: boolean }>`
  overflow-y: auto;

  ${({ newStyle }) =>
    newStyle
      ? css`
          > *:not(:last-child) {
            border-bottom: 1px solid ${GetColor.GreyScale.Grey30};
          }
        `
      : css`
          padding-top: 7px;
          padding-bottom: 7px;
        `}

  ${(props) => props.extraStyle}
`;

export default BaseMenu;
