import React from 'react';

import { ItemType } from 'venn-ui-kit';

import { Block } from '../../core';
import { Name } from '../legend';
import { getRequestSubjectFromAnalysisSubject, type AnalysisSubject } from 'venn-utils';

interface CommonBenchmarkProps {
  id: string;
  benchmarkSubject: AnalysisSubject;
}

export const CommonBenchmark = ({ id, benchmarkSubject }: CommonBenchmarkProps) => {
  return (
    <Block.SubjectsItem iconType={ItemType.CommonBenchmark}>
      <Name id={id} subject={getRequestSubjectFromAnalysisSubject(benchmarkSubject)} />
    </Block.SubjectsItem>
  );
};
