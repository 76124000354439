import React from 'react';
import { MultiPortfolioReviewComponents } from '../common/MultiPortfolioReviewComponents';
import { useMultiProFormaRightPaneContent } from '../../hooks/useMultiProFormaRightPaneContent';

export const Content = () => {
  const { autoGroupColumnDefs, columnDefs, rowData, rowClassRules } = useMultiProFormaRightPaneContent();
  return (
    <MultiPortfolioReviewComponents.RightSideBarContent
      autoGroupColumnDefs={autoGroupColumnDefs}
      columnDefs={columnDefs}
      rowData={rowData}
      rowClassRules={rowClassRules}
    />
  );
};
