import React, { useCallback, type ComponentProps } from 'react';
import classNames from 'classnames';
import type { DateRange, Granularity } from './types';
import Trigger from './trigger/Trigger';
import { formatRange } from './trigger/logic';
import { SkeletalDropMenu } from '../drop-menu';
import noop from 'lodash/noop';
import type { RangeType } from './range-picker/RangePicker';
import { DateRangePickerContent, useDateRangePickerContent } from './DateRangePickerContent';
import { getRangeFromType } from './granularities/logic';
import { getFactorMaxRange } from './constants';
import type { FrequencyEnum } from 'venn-api';
import { FREQUENCY_DATEPICKER_MODES } from 'venn-utils';

type DateRangePickerProps = {
  /**
   * Dates (from and to) expressed in UTC Timestamp
   */
  value?: DateRange;
  /**
   * Maximum available range for the selected dates, in UTC Timestamp.
   * If not provided, you cannot select a date.
   */
  range?: DateRange;
  /**
   * Whether the picker will open on the right. The default is on the left.
   */
  right?: boolean;
  /**
   * If true, the component will be disabled
   */
  disabled?: boolean;
  /**
   * Alternate style with clear background, no borders and white text
   */
  altStyle?: boolean;
  /**
   * Whether datepicker opens in a portal or not.
   */
  usePortal?: boolean;
  /**
   * Range options to allow within the datepicker
   */
  options?: RangeType[];
  /**
   * Allows specifying a custom trigger
   */
  trigger?: (
    label: string,
    granularity: Granularity,
    opened: boolean,
    disabled: boolean,
    altStyle: boolean,
    onClick: () => void,
    value?: DateRange,
  ) => JSX.Element;
  className?: string;
  onChange?: (value: DateRange) => void;
  onApplyPeriod?: (range: RangeType) => void;
  maxFrequency?: FrequencyEnum;
} & Partial<Pick<ComponentProps<typeof DateRangePickerContent>, 'period' | 'onUpdateDate' | 'factorLens'>>;

const DateRangePicker = ({
  value,
  range,
  right,
  disabled,
  altStyle,
  className,
  options,
  trigger,
  onChange,
  usePortal,
  onApplyPeriod,
  onUpdateDate,
  maxFrequency = 'DAILY',
  factorLens,
  ...nonConstraintProps
}: DateRangePickerProps) => {
  const granularity = FREQUENCY_DATEPICKER_MODES[maxFrequency] ?? 'day';
  const { computedMaxRange, computedValue } = useDateRangePickerContent(
    range,
    granularity,
    value,
    maxFrequency,
    factorLens,
  );
  const MenuComponent = useCallback(
    (onApply: (value: DateRange) => void, onClose: () => void) => {
      return (
        <DateRangePickerContent
          maxFrequency={maxFrequency}
          value={computedValue}
          maxRange={computedMaxRange}
          onUpdateDate={onUpdateDate}
          onUpdatePeriod={(newRange: RangeType) => {
            onApply?.(
              getRangeFromType(
                newRange,
                computedMaxRange || value || getFactorMaxRange(maxFrequency),
                granularity,
                maxFrequency,
                factorLens,
              ),
            );
            onApplyPeriod?.(newRange);
          }}
          onApply={onApply}
          onCancel={onClose}
          options={options}
          layout={right ? 'left' : 'right'}
          granularity={granularity}
          factorLens={factorLens}
          {...nonConstraintProps}
        />
      );
    },
    [
      computedValue,
      computedMaxRange,
      onUpdateDate,
      options,
      right,
      granularity,
      nonConstraintProps,
      value,
      onApplyPeriod,
      maxFrequency,
      factorLens,
    ],
  );

  const triggerComponent = useCallback(
    (expanded: boolean, toggleMenu: () => void) => {
      return trigger ? (
        trigger(
          formatRange(computedValue, granularity),
          granularity,
          expanded,
          disabled!,
          !!altStyle,
          toggleMenu,
          computedValue,
        )
      ) : (
        <Trigger
          granularity={granularity}
          value={computedValue}
          disabled={disabled || !computedMaxRange}
          opened={expanded}
          className="qa-date-picker-trigger"
          onClick={toggleMenu}
          altStyle={altStyle}
        />
      );
    },
    [trigger, computedValue, granularity, disabled, computedMaxRange, altStyle],
  );

  return (
    <SkeletalDropMenu
      className={classNames(className, 'qa-date-picker')}
      triggerComponent={triggerComponent}
      menuComponent={MenuComponent}
      onApply={onChange ?? noop}
      disabled={disabled || !computedMaxRange}
      usePortal={usePortal}
      menuPosition={right ? 'right' : 'left'}
    />
  );
};

export default DateRangePicker;
