import React, { useContext } from 'react';
import type { RangeAnalysisResponse } from 'venn-api';
import { type AnalysisSubject, isHistoricalPortfolio } from 'venn-utils';
import { FundUtils } from 'venn-utils';
import {
  PrivatePortfolioIcon,
  type Theme,
  GetColor,
  Icon,
  Label,
  PrivateInvestmentIcon,
  HistoricalPortfolioIcon,
} from 'venn-ui-kit';
import type { DataRangeInfo, UseMetaDataReturn, UserData } from 'venn-components';
import {
  PrivateFundEditableName,
  DataDateRange,
  DataFrequency,
  EditableInvestmentName,
  EditablePortfolioName,
  FlexGroup,
  getCreatedDisplay,
  getUpdatedDisplay,
  InvestmentDownloadButton,
  ManageDataTitle,
  PlaceholderWrapper,
  PortfolioDownloadButton,
  Summary,
  SummaryBaseWrapper,
  SummaryItem,
  SummaryPlaceholder,
  SummaryWrapper,
  UserContext,
} from 'venn-components';
import styled, { ThemeContext } from 'styled-components';

interface ManageDataHeaderProps {
  subject?: AnalysisSubject;
  investmentUserData?: UserData;
  investmentUseMetaDataReturn?: UseMetaDataReturn;
  investmentReturnsRange?: DataRangeInfo;
  rangeAnalysis?: RangeAnalysisResponse;
  onNameUpdated?: (name: string) => void;
}

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default ({
  subject,
  rangeAnalysis,
  onNameUpdated,
  investmentUserData,
  investmentUseMetaDataReturn,
  investmentReturnsRange,
}: ManageDataHeaderProps) => {
  const { hasPermission, hasPermissionForResource } = useContext(UserContext);
  const { Colors } = useContext(ThemeContext);

  if (!subject) {
    return (
      <>
        <PlaceholderWrapper>
          <SummaryPlaceholder duration={3} />
        </PlaceholderWrapper>
        <SummaryBaseWrapper>
          <SummaryPlaceholder duration={3} />
          <SummaryPlaceholder duration={3} />
        </SummaryBaseWrapper>
      </>
    );
  }

  const icon = (() => {
    if (subject.portfolio) {
      if (isHistoricalPortfolio(subject.portfolio)) {
        return <HistoricalPortfolioIcon color={Colors.DEPRECATED_DataLineColor.PaleBlue} />;
      }
      return <StyledIcon type="th" colorGetter={GetColor.DEPRECATED_DataLineColor.PaleBlue} />;
    }
    if (subject?.privatePortfolio) {
      return <PrivatePortfolioIcon />;
    }
    if (subject?.privateFund) {
      return <PrivateInvestmentIcon />;
    }
    if (subject?.fund?.assetType === 'BENCHMARK') {
      return <StyledIcon type="layer-group" colorGetter={GetColor.DEPRECATED_DataLineColor.Pink} />;
    }
    return <StyledIcon type="square" colorGetter={GetColor.DEPRECATED_DataLineColor.Gold} />;
  })();

  const name = subject.portfolio ? (
    <EditablePortfolioName
      portfolio={subject.portfolio}
      onChange={onNameUpdated}
      disabled={!hasPermissionForResource('EDIT_PORTFOLIO', subject.portfolio)}
    />
  ) : subject.fund && investmentUseMetaDataReturn ? (
    <EditableInvestmentName
      useMetaDataReturn={investmentUseMetaDataReturn}
      disabled={!subject.fund.userEditable || !hasPermission('UPLOAD_RETURNS')}
      onChange={onNameUpdated}
    />
  ) : subject.privateFund ? (
    <PrivateFundEditableName
      onChange={onNameUpdated}
      disabled={!subject.privateFund.userUploaded}
      privateFund={subject.privateFund}
    />
  ) : subject.privatePortfolio ? (
    <EditablePortfolioName portfolio={subject.privatePortfolio} onChange={onNameUpdated} isPrivate />
  ) : null;
  const dateRangeInfo = subject.fund
    ? investmentReturnsRange
    : subject.portfolio?.children?.length === 0
      ? {}
      : rangeAnalysis;

  return (
    <>
      <SummaryBaseWrapper>
        <FlexGroup>
          <ManageDataTitle>
            {icon}
            {name}
          </ManageDataTitle>
        </FlexGroup>
        <FlexGroup>
          {subject.portfolio ? <PortfolioDownloadButton portfolio={subject.portfolio} /> : null}
          {investmentUserData && FundUtils.isPerformanceViewable(subject?.fund) ? (
            <InvestmentDownloadButton subjectId={subject.id} userData={investmentUserData} absolutePosition={false} />
          ) : null}
        </FlexGroup>
      </SummaryBaseWrapper>
      <SummaryWrapper className="qa-header-summary">
        {!subject.private && (
          <FlexGroup>
            <Summary>
              <SummaryItem>
                <Label>Available Date Range:</Label>
                <DataDateRange dataRangeInfo={dateRangeInfo} />
              </SummaryItem>
              <SummaryItem>
                <Label>Available Frequency:</Label>
                <DataFrequency dataRangeInfo={dateRangeInfo} />
              </SummaryItem>
            </Summary>
          </FlexGroup>
        )}
        <FlexGroup>
          <SummaryItem>
            <Label>Created:</Label>
            <>{getCreatedDisplay(subject.item)}</>
          </SummaryItem>
          <SummaryItem>
            <Label>Last Updated:</Label>
            <>{getUpdatedDisplay(subject.item)}</>
          </SummaryItem>
        </FlexGroup>
      </SummaryWrapper>
    </>
  );
};

const StyledIcon = styled(Icon)<{ colorGetter: (props: { theme: Theme }) => string }>`
  color: ${(props) => props.colorGetter};
  margin-right: 10px;
`;
