import type { ReturnsRangeInfo, SelectedProxy } from './utils';
import { getDisabledProxyTypeMessage } from './utils';
import type { FundToProxy } from 'venn-components';
import { getBulkCustomProxyOptions, type ProxyErrorEnum, type ProxyOption, type ProxyTypeEnum } from 'venn-api';
import { getCustomProxyOptionForFund } from 'venn-api';
import { useQuery } from '@tanstack/react-query';
import {
  IS_PROD,
  createProxyFeasibilityKey,
  isRequestSuccessful,
  logExceptionIntoSentry,
  createBulkProxyFeasibilityKey,
} from 'venn-utils';
import { useProxyTypeValidation } from './useProxyTypeValidation';

type ProxyErrorProps = {
  investment: FundToProxy;
  selectedProxyId?: string;
  selectedProxyType?: ProxyTypeEnum;
  numLags?: number;
  extrapolate?: boolean;
  rawInvestmentReturnsRange: ReturnsRangeInfo | null;
  rawProxyReturnsRange: ReturnsRangeInfo | null;
};

export const getInvalidProxyMessage = (reason: ProxyErrorEnum | undefined): string | undefined => {
  if (!reason) {
    return undefined;
  }
  switch (reason) {
    case 'INVALID_FREQUENCY':
      return 'Unable to apply this proxy. Proxy frequency is invalid.';
    case 'BELOW_THRESHOLD':
      return 'Unable to apply this proxy. The proxy sits outside our recommended threshold for this investment.';
    case 'NO_OVERLAP':
      return 'Unable to apply this proxy. Your investment and the chosen proxy do not have any overlapping period.';
    case 'INVESTMENT_SERIES_TOO_SHORT':
      return 'Unable to proxy. Your investment does not have enough datapoints.';
    case 'PROXY_SERIES_TOO_SHORT':
      return 'Unable to apply this proxy. The proxy does not have enough datapoints.';
    case 'NON_RETURNS_SERIES':
      return 'Unable to apply this proxy. Proxying is only allowed for returns timeseries';
    case 'UNABLE_TO_DESMOOTH_NOT_ENOUGH_OVERLAP':
      return 'Unable to desmooth. There is not enough overlap between your investment and the chosen proxy.';
    case 'UNABLE_TO_DESMOOTH_INVALID_N_LAGS':
      return 'Unable to desmooth. Invalid number of lags.';
    case 'UNABLE_TO_EXTRAPOLATE_SERIES_TOO_SHORT':
      return (
        'This investment does not have enough history to enable extrapolation. ' +
        'Choose an investment with a longer history of returns data, or continue without extrapolation.'
      );
    case 'UNABLE_TO_EXTRAPOLATE_NOT_ENOUGH_OVERLAP':
      return (
        'This proxy does not have sufficient overlapping history to extrapolate the returns of the investment. ' +
        'Choose a proxy with a longer history of returns data, or continue without extrapolation.'
      );
    case 'UNABLE_TO_EXTRAPOLATE_PROXY_NOT_EXTENDING':
      return (
        'This proxy does not extend the returns of your investment. ' +
        'Choose a proxy with a more recent history of returns data, or continue without extrapolation.'
      );
    case 'UNABLE_TO_EXTRAPOLATE':
      return (
        'Unable to extrapolate. ' +
        'Choose a proxy with a longer history of returns data, or continue without extrapolation.'
      );
    default:
      return 'Unable to apply proxy.';
  }
};

type ErrorInfo = {
  /** Reason for disabling the proxy */
  errorMessage: string | undefined;
  /** Whether current state is invalid and unable to be saved */
  disableSave: boolean;
};

/** Custom hook for error-related logic for proxies. */
const useProxyErrorMessage = ({
  investment,
  selectedProxyId,
  selectedProxyType,
  numLags,
  extrapolate,
  rawInvestmentReturnsRange,
  rawProxyReturnsRange,
}: ProxyErrorProps): ErrorInfo => {
  /** A check for proxy validity performed by the FE only. This is a quick, simplified check that doesn't catch
   * all reasons why the proxy could be invalid, but good enough in most cases */
  const disabledProxyReason = selectedProxyType
    ? getDisabledProxyTypeMessage(selectedProxyType, investment, rawInvestmentReturnsRange, rawProxyReturnsRange)
    : undefined;
  /** A "perfect" check for proxy validity fetched from the BE trying to do the proxy operation itself.
   *  The downside is that it's slower to fetch, but if this check passes then it's guaranteed that proxy is valid. */
  const { data: proxyFeasibilityInfo, status } = useQuery<ProxyOption | null>(
    createProxyFeasibilityKey(investment.id, selectedProxyId, selectedProxyType, numLags, extrapolate),
    async () => {
      if (!selectedProxyId || !selectedProxyType) {
        return null;
      }
      const result = await getCustomProxyOptionForFund(
        investment.id,
        selectedProxyId,
        selectedProxyType,
        numLags,
        extrapolate,
      );
      if (isRequestSuccessful(result)) {
        return result.content;
      }
      logExceptionIntoSentry(
        `Fetching proxy info failed for investment ${investment.id} and potential proxy ${selectedProxyId}.`,
      );
      return null;
    },
  );
  if (!selectedProxyId || !investment) {
    return { errorMessage: 'Please specify a proxy to enable save.', disableSave: true };
  }
  if (disabledProxyReason !== undefined) {
    return {
      errorMessage: disabledProxyReason,
      disableSave: true,
    };
  }
  switch (status) {
    case 'loading':
      return {
        errorMessage: undefined,
        disableSave: true,
      };
    case 'success':
      return {
        errorMessage: proxyFeasibilityInfo ? getInvalidProxyMessage(proxyFeasibilityInfo.error) : undefined,
        disableSave: proxyFeasibilityInfo ? !proxyFeasibilityInfo.valid : true,
      };
    default:
      return {
        errorMessage: 'Something went wrong. Please refresh the page or try again later.',
        disableSave: true,
      };
  }
};

export default useProxyErrorMessage;

type ProxyErrorPropsV2 = {
  investments: FundToProxy[];
  selectedProxyType: ProxyTypeEnum | undefined;
  extrapolate: boolean;
  /**
   * An individual undefined range represents that the endpoint had an unexpected error.
   * If the entire array is undefined, ranges are still loading
   */
  rawInvestmentReturnsRanges: (ReturnsRangeInfo | null)[] | undefined;
  /**
   * Undefined proxy returns range represents it is still loading or there was an unexpected error.
   * Null represents a proxy has not been selected.
   */
  rawProxyReturnsRange: ReturnsRangeInfo | undefined | null;

  selectedProxy?: SelectedProxy;
};

export type BulkProxyValidationState =
  | {
      state: 'invalidSelection';
      error: string;
    }
  | {
      state: 'loading';
      selectedProxy: SelectedProxy;
    }
  | {
      state: 'ready';
      error?: string;
      investmentErrors: Record<string, { disableSave: boolean; errorMessage: string | undefined } | null>;
      selectedProxy: SelectedProxy;
    };

export const useBulkProxyErrorMessage = ({
  investments,
  selectedProxy,
  selectedProxyType,
  extrapolate,
  rawInvestmentReturnsRanges,
  rawProxyReturnsRange,
}: ProxyErrorPropsV2): BulkProxyValidationState => {
  /** A check for proxy validity performed by the FE only. This is a quick, simplified check that doesn't catch
   * all reasons why the proxy could be invalid, but good enough in most cases */
  const proxyTypeValidationResults = useProxyTypeValidation(
    investments,
    rawInvestmentReturnsRanges ?? [],
    rawProxyReturnsRange,
  );

  const investmentIdsWithoutFrontendErrors = selectedProxyType
    ? investments
        .filter(
          (_investment, index) => !proxyTypeValidationResults[selectedProxyType].investmentInfo[index].disabledMessage,
        )
        .map(({ id }) => id)
    : [];

  /** Checks for proxy validity from the BE. This is costly and introduces latency, but covers cases that the frontend alone does not. */
  const { data: proxyFeasibilityInfo, status } = useQuery({
    staleTime: 60 * 1000,
    queryKey: createBulkProxyFeasibilityKey(
      selectedProxy?.id,
      selectedProxyType,
      extrapolate,
      ...investmentIdsWithoutFrontendErrors,
    ),
    queryFn: async () => {
      const result = await getBulkCustomProxyOptions(
        selectedProxy!.id,
        selectedProxyType,
        undefined,
        extrapolate,
        investmentIdsWithoutFrontendErrors,
      );

      if (!isRequestSuccessful(result)) {
        logExceptionIntoSentry(
          `Fetching proxy info failed for investments ${investmentIdsWithoutFrontendErrors}, extrapolate ${extrapolate}, and potential proxy ${selectedProxy}.`,
        );
        return null;
      }

      return Object.fromEntries(investmentIdsWithoutFrontendErrors.map((id, idx) => [id, result.content[idx]]));
    },
    enabled: !!selectedProxy && !!rawInvestmentReturnsRanges && !!rawProxyReturnsRange && !!selectedProxyType,
  });

  const investmentErrors = getAllInvestmentErrors();

  if (!selectedProxy || !selectedProxyType) {
    return { state: 'invalidSelection', error: 'Please specify a proxy to enable save.' };
  }

  switch (status) {
    case 'loading':
      return {
        state: 'loading',
        selectedProxy,
      };
    case 'success':
      const noValidInvestments = Object.values(investmentErrors).every((error) => error?.disableSave);
      return {
        state: 'ready',
        error: noValidInvestments ? 'No investments are valid for this proxy' : undefined,
        investmentErrors,
        selectedProxy,
      };
    default:
      return {
        state: 'ready',
        error: 'Something went wrong. Please refresh the page or try again later.',
        investmentErrors,
        selectedProxy,
      };
  }

  function getAllInvestmentErrors() {
    return Object.fromEntries(
      investments.map((investment, index) => [investment.id, getErrorForInvestment(investment, index)]),
    );
  }

  function getErrorForInvestment(
    { id }: FundToProxy,
    index: number,
  ): {
    disableSave: boolean;
    errorMessage: string | undefined;
  } | null {
    if (!selectedProxyType) {
      return null;
    }
    const investmentInfo = proxyTypeValidationResults[selectedProxyType].investmentInfo[index];
    if (investmentInfo.disabledMessage) {
      return {
        disableSave: true,
        errorMessage: investmentInfo.disabledMessage,
      };
    }

    const proxyInfo = proxyFeasibilityInfo?.[id];
    if (proxyInfo) {
      const errorMessage = getInvalidProxyMessage(proxyInfo.error);
      const disableSave = !proxyInfo.valid;

      if (!IS_PROD && disableSave) {
        // eslint-disable-next-line no-console
        console.error(
          `Found backend error for ${id} with proxy type ${selectedProxyType} even though frontend did not find an error.`,
          errorMessage,
          proxyInfo.error,
        );
      }

      return {
        disableSave,
        errorMessage,
      };
    }

    return {
      disableSave: false,
      errorMessage: undefined,
    };
  }
};
