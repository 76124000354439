import { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { blockContentSizeExceededState, isReportState, type OverflowError } from 'venn-state';

import { useAppPrintMode } from '../../../print';
import { RL_GRID_ROW_HEIGHT } from '../../../utils';

export const useAlertOutline = (
  id: string,
  internalRef: React.RefObject<HTMLDivElement>,
  pageFooterRef: React.RefObject<HTMLElement> | undefined,
): OverflowError | undefined => {
  const { inPrintMode } = useAppPrintMode();
  const isReport = useRecoilValue(isReportState);
  const blockSizeExceeded = useRecoilValue(blockContentSizeExceededState(id));
  const [isOverlapping, setIsOverlapping] = useState<boolean>(false);

  // TODO(collin.irwin): probably should use resize observer which is resilient to non-rerender changes such as CSS. Will follow up to explore that option.
  // eslint-disable-next-line react-hooks/exhaustive-deps -- not ideal, but we want to handle ref changing from undefined to defined so we use useEffect
  useEffect(() => {
    // Note that Y coordinates start at 0 at the top of the screen, and increases towards the bottom of the screen.
    // Effectively the Y coordinate is distance from the top of the viewport.
    const blockBottomEdge = internalRef.current?.getBoundingClientRect().bottom ?? Number.NEGATIVE_INFINITY;
    const footerTopEdge = pageFooterRef?.current?.getBoundingClientRect().top ?? Number.POSITIVE_INFINITY;
    /** Margin of error to not cause red alert outline when a block overlaps the footer. Basically helps make up for the imprecise grid placement and sizing. */
    setIsOverlapping(blockBottomEdge > footerTopEdge + RL_GRID_ROW_HEIGHT / 2 + 1);
  });

  const isAlertAllowed = !!isReport && !inPrintMode;

  return useMemo(() => {
    if (!isAlertAllowed) {
      return undefined;
    }
    if (isOverlapping) {
      return {
        position: 'Surround',
        message: 'Content overlap',
      };
    }
    if (blockSizeExceeded.width || blockSizeExceeded.height) {
      return {
        position:
          blockSizeExceeded.width && blockSizeExceeded.height
            ? 'Bottom-Right'
            : blockSizeExceeded.width
              ? 'Right'
              : 'Bottom',
        message: 'Data overflow',
      };
    }
    return undefined;
  }, [blockSizeExceeded.height, blockSizeExceeded.width, isOverlapping, isAlertAllowed]);
};
