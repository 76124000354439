import React, { useState } from 'react';
import styled from 'styled-components';
import TextTrigger from '../dropdown-text/TextTrigger';
import SearchMenuBar from '../search-menu/SearchMenuBar';
import { ExternalActivityListener, ZIndex, ItemType, ItemTypePill, Icon } from 'venn-ui-kit';
import { type AnalysisSubject, isHistoricalPortfolio } from 'venn-utils';
import { analyticsService } from 'venn-utils';

interface SelectorProps {
  selectedSubject?: AnalysisSubject;
  onChangeSubject?: (item: AnalysisSubject) => void;
  disabled?: boolean;
  /** Remove left padding */
  noLeftPadding?: boolean;
  showItemTypeBadge?: boolean;
  portfoliosOnly?: boolean;
  placeholder?: string;
  analyticsProps: {
    location: string;
    destinationPageTitle: string;
  };
}

export const getItemType = (item?: AnalysisSubject) => {
  if (!item) {
    return ItemType.Investment;
  }

  if (item.privatePortfolio) {
    return ItemType.PrivatePortfolio;
  }
  if (item.privateFund) {
    return ItemType.PrivateInvestment;
  }

  if (item.isCompositeBenchmark) {
    return ItemType.Benchmark;
  }
  if (item.portfolio) {
    return item.portfolio && isHistoricalPortfolio(item.portfolio) ? ItemType.HistoricalPortfolio : ItemType.Portfolio;
  }
  return ItemType.Investment;
};

const SubjectSelector = ({
  selectedSubject,
  onChangeSubject,
  disabled = false,
  noLeftPadding,
  showItemTypeBadge = false,
  portfoliosOnly = false,
  placeholder,
  analyticsProps,
}: SelectorProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const toggleOpen = () => setOpen(!open);
  const close = () => setOpen(false);

  if (disabled) {
    return <DisabledText>{selectedSubject?.name}</DisabledText>;
  }

  const { location, destinationPageTitle } = analyticsProps;
  return (
    <ExternalActivityListener listeningEnabled={open} onExternalActivity={close}>
      <Trigger onClick={toggleOpen} isOpen={open} noLeftPadding={noLeftPadding}>
        <Title>{selectedSubject?.name ?? placeholder}</Title>
        <DropdownIcon type={open ? 'angle-up' : 'angle-down'} />
        {showItemTypeBadge && (
          <ItemBadge>
            <ItemTypePill
              isDisabled={false}
              item={getItemType(selectedSubject)}
              fullCompositeBenchmarkLabel={false}
              type="large"
            />
          </ItemBadge>
        )}
      </Trigger>
      <SearchWrapper open={open}>
        <SearchMenuBar
          autofocus={open}
          defaultMenuIsOpen
          portfoliosOnly={portfoliosOnly}
          isOptionDisabled={(option) => option.category === 'investment' && portfoliosOnly}
          optionDisabledTooltipContent={() => 'Investments can not be added here'}
          value={
            selectedSubject
              ? {
                  label: selectedSubject.name,
                  value: selectedSubject,
                  category: 'recent',
                }
              : undefined
          }
          getOptionValue={(item) => item.value?.id?.toString() ?? ''}
          onSelected={(selectedItem) => {
            if (!selectedItem.value) {
              return;
            }
            onChangeSubject?.(selectedItem.value);
            setOpen(false);
            analyticsService.navigationTriggered({
              itemType: 'dropdown',
              userIntent: 'change object',
              destinationPageTitle,
            });
          }}
          location={location}
          privateAssetSearchMode="PUBLIC_ONLY"
        />
      </SearchWrapper>
    </ExternalActivityListener>
  );
};

export default SubjectSelector;

const Title = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SearchWrapper = styled.div<{ open: boolean }>`
  ${(props) => !props.open && 'display: none;'}
  position: absolute;
  z-index: ${ZIndex.StickyCover};
  width: 700px;
  font-weight: normal;
  font-size: 14px;
`;

interface TriggerProps {
  isOpen: boolean;
  noLeftPadding?: boolean;
}
const Trigger = styled(TextTrigger)<TriggerProps>`
  max-width: 100%;
  display: inline-flex;
  ${(props) => props.noLeftPadding && 'padding-left: 0'}
`;

const DisabledText = styled.span`
  max-width: 100%;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ItemBadge = styled.span`
  margin-left: 10px;
`;

const DropdownIcon = styled(Icon)`
  opacity: 0.4;
  font-size: 0.7em;
  padding-left: 0.3em;
  position: relative;
  align-items: center;
  display: flex;

  @media print {
    display: none;
  }
`;
