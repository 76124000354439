import type { SelectTypes } from 'venn-ui-kit';
import type { CustomSelectProps } from 'venn-components';
import { ExpandHeaderCell, HeaderCell } from './shared';
import React from 'react';
import { components } from 'react-select';
import styled from 'styled-components';
import { MenuListComponents } from './MenuListComponents';

export const MapperMenuList = ({ children, ...props }: SelectTypes.MenuListProps) => {
  const { selectProps } = props;
  const { columns } = selectProps as unknown as CustomSelectProps;
  return (
    <components.MenuList {...props}>
      <TableWrapper className="qa-search-menu-modal-table">
        <StyledTable>
          <MenuListComponents.HeaderRow>
            <MenuListComponents.FirstHeaderCell>Results</MenuListComponents.FirstHeaderCell>
            <HeaderCell />
            <ExpandHeaderCell />
            <MenuListComponents.HeaderColumns columns={columns} />
          </MenuListComponents.HeaderRow>
          <tbody>{children}</tbody>
        </StyledTable>
      </TableWrapper>
    </components.MenuList>
  );
};

const TableWrapper = styled(MenuListComponents.TableWrapper)`
  overflow-y: auto;
  max-height: 236px;
  margin-top: 20px;
`;

const StyledTable = styled(MenuListComponents.Table)`
  tbody {
    tr {
      td.no-padding {
        padding-right: 0;
      }

      td:nth-child(1),
      td:nth-child(2) {
        padding-right: 5px;
      }
    }
  }

  thead {
    tr {
      th:nth-child(1) {
        width: 0px;
      }
      th:nth-child(1),
      th:nth-child(2),
      th:nth-child(3) {
        padding-right: 0;
      }
    }
  }
`;
