import React from 'react';
import { useRecoilValue } from 'recoil';
import {
  NotableHistoricalBlock,
  PerformanceAndRiskBlock,
  BlockOuterContainer,
  PortfolioBreakdownBlock,
} from 'venn-components';
import { blockDisplayHeader, blockSettings, type BlockId } from 'venn-state';
import { useHasFF, type CustomBlockTypeEnum, assertExhaustive } from 'venn-utils';
import { useComputeBlockWidth, type BlockWidths } from './components/core/useComputeBlockWidth';
import { DeprecatedBlock } from './DeprecatedBlock';

type BlockSwitchProps = {
  id: string;
  index: number;
  pageFooterRef?: React.RefObject<HTMLElement>;
} & BlockWidths;

const NewBlockContainer = (
  props: BlockSwitchProps & {
    blockType: NewBlockType;
  },
) => {
  const { id, pageFooterRef } = props;
  const title = useRecoilValue(blockDisplayHeader(id));
  const { blockWidthPx, blockWidthStyle } = useComputeBlockWidth(props);
  const NewBlock = getNewBlockComponent(props.blockType);

  return (
    <BlockOuterContainer
      id={id}
      title={title}
      width={blockWidthPx}
      widthStyle={blockWidthStyle}
      pageFooterRef={pageFooterRef}
    >
      <NewBlock id={id} />
    </BlockOuterContainer>
  );
};

export const BlockSwitch = (props: BlockSwitchProps) => {
  const hasNewBlockArchitecture = useHasFF('new_block_architecture_ff');
  const blockSetting = useRecoilValue(blockSettings(props.id));
  const customBlockType = blockSetting.customBlockType;

  if (supportsNewBlock(customBlockType, hasNewBlockArchitecture)) {
    return <NewBlockContainer {...props} blockType={customBlockType} />;
  }

  return <DeprecatedBlock {...props} />;
};

const hasNewBlock = [
  'NOTABLE_PERIODS',
  'PERFORMANCE_SUMMARY',
  'PORTFOLIO_BREAKDOWN',
] as const satisfies CustomBlockTypeEnum[];
type NewBlockType = (typeof hasNewBlock)[number];

export function supportsNewBlock(
  customBlockType: CustomBlockTypeEnum,
  hasNewBlockArchitecture: boolean,
): customBlockType is NewBlockType {
  return hasNewBlockArchitecture && hasNewBlock.includes(customBlockType as NewBlockType);
}

/** You can use {@link supportsNewBlock} to check if a particular {@link CustomBlockTypeEnum} supports new block architecture, before calling this method. */
export function getNewBlockComponent(newBlockType: NewBlockType): React.FC<{ id: BlockId }> {
  switch (newBlockType) {
    case 'NOTABLE_PERIODS':
      return NotableHistoricalBlock;
    case 'PERFORMANCE_SUMMARY':
      return PerformanceAndRiskBlock;
    case 'PORTFOLIO_BREAKDOWN':
      return PortfolioBreakdownBlock;
    default:
      throw assertExhaustive(newBlockType);
  }
}
