import { useCallback, useContext } from 'react';
import type { Fund, Portfolio } from 'venn-api';
import { remapInvestment } from '../review/helpers';
import { MultiPortfolioReviewContext } from '../components/common/MultiPortfolioReviewContext';

export const useSharedMultiPortfolioRightPaneContent = (portfolio: Portfolio) => {
  const {
    data: { existingParsedData, selectedIndex },
    actions: { updatePortfolio, includeInvestment, excludeInvestment },
  } = useContext(MultiPortfolioReviewContext);
  const selectedAlreadyExistingPortfolio = existingParsedData.some(
    (existingParsedDatum) => existingParsedDatum.originalIndex === selectedIndex,
  );
  const accessMode = selectedAlreadyExistingPortfolio ? ('OVERWRITE_EXISTING' as const) : ('CREATE_NEW' as const);
  const applyInvestmentRemap = useCallback(
    (path: number[], fund: Fund) => {
      const newPortfolio = remapInvestment(portfolio, path, fund);
      updatePortfolio(selectedIndex, newPortfolio);
    },
    [updatePortfolio, selectedIndex, portfolio],
  );

  const includeInvestmentInSelectedPortfolio = useCallback(
    (path: number[]) => {
      includeInvestment(selectedIndex, path);
    },
    [includeInvestment, selectedIndex],
  );

  const excludeInvestmentFromSelectedPortfolio = useCallback(
    (path: number[]) => {
      excludeInvestment(selectedIndex, path);
    },
    [excludeInvestment, selectedIndex],
  );

  return {
    accessMode,
    applyInvestmentRemap,
    includeInvestment: includeInvestmentInSelectedPortfolio,
    excludeInvestment: excludeInvestmentFromSelectedPortfolio,
  };
};
