import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { ItemIcon, type ItemType } from 'venn-ui-kit';

import { type HTMLProps } from '../factory';

export type BlockSubjectsItemElement = React.ElementRef<'div'>;
export type BlockSubjectsItemProps = {
  children: React.ReactNode;
  className?: string;
} & ({ iconType: ItemType; customIcon?: never } | { customIcon: React.ReactNode; iconType?: never }) &
  HTMLProps<'div'>;

export const BlockSubjectsItem = forwardRef<BlockSubjectsItemElement, BlockSubjectsItemProps>((props, ref) => {
  const { iconType, customIcon, className, children } = props;

  const icon = customIcon || (iconType && <ItemIcon item={iconType} className={className} />);

  return (
    <Item ref={ref}>
      {icon}
      {children}
    </Item>
  );
});

BlockSubjectsItem.displayName = 'BlockSubjectsItem';

const Item = styled.div`
  display: flex;
  column-gap: 5px;
  overflow-x: clip;
  /* Prevent superscripts from being cut off */
  overflow-y: visible;
  white-space: nowrap;
  align-items: center;
  min-width: 0;
`;
