import { useMemo } from 'react';
import type { ColDef } from 'ag-grid-community';

import type { StudioAnalysisRequest } from 'venn-state';

import HeaderCellRenderer from '../../../studio-blocks/components/grid/renderers/HeaderCellRenderer';
import { getDefaultCellClass, getDefaultValueFormat } from '../../../studio-blocks/logic/gridStyling';
import ValueCellRenderer from '../../../studio-blocks/components/grid/renderers/ValueCellRenderer';
import { createLabelColumn } from '../../../studio-blocks/logic/columnParsers';
import { formatExportableSubjectWithOptionalFee } from '../../../legend';
import type { HeaderComponentParamsType } from '../../../studio-blocks';

export const useStandardColumnDefs = (requests: StudioAnalysisRequest[]) => {
  return useMemo(() => {
    const labelColumn = createLabelColumn({
      requests,
    });

    const columns: ColDef[] = requests.map((req, index) => {
      const headerName = formatExportableSubjectWithOptionalFee(req.subject);
      const headerComponentParams: HeaderComponentParamsType = {
        subject: req.subject,
        isCommonBenchmark: req.isBenchmark,
      };

      return {
        headerName,
        headerComponent: HeaderCellRenderer,
        headerComponentParams,
        field: `value.${index}`,
        valueFormatter: getDefaultValueFormat,
        cellRenderer: ValueCellRenderer,
        cellClass: getDefaultCellClass,
      };
    });

    return [labelColumn, ...columns];
  }, [requests]);
};
