import React, { forwardRef } from 'react';
import styled from 'styled-components';

import StudioShimmerBlock from '../../../../studio-blocks/components/StudioShimmerBlock';

import { type HTMLProps } from '../factory';

export type BlockHeaderElement = React.ElementRef<'div'>;
export interface BlockHeaderProps extends HTMLProps<'div'> {
  children: React.ReactNode;
}

export const BlockHeader = forwardRef<BlockHeaderElement, BlockHeaderProps>((props, ref) => {
  const { children } = props;

  return (
    <React.Suspense fallback={<StudioShimmerBlock ref={ref} />}>
      <Wrapper ref={ref}>{children}</Wrapper>
    </React.Suspense>
  );
});

BlockHeader.displayName = 'BlockHeader';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
