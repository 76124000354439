import { ConditionalOverlay, DataGrid, StickyNode } from 'venn-components';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState, useLayoutEffect } from 'react';
import type { AgGridReact } from 'ag-grid-react';
import type {
  CellClickedEvent,
  GridReadyEvent,
  IServerSideGetRowsParams,
  SelectionChangedEvent,
  SortChangedEvent,
  RowSelectionOptions,
} from 'ag-grid-community';
import { noop } from 'lodash';
import {
  isRequestSuccessful,
  LibraryFilterSectionKey,
  logExceptionIntoSentry,
  scrollToElementByQuerySelector,
  useApi,
} from 'venn-utils';
import styled, { ThemeContext } from 'styled-components';
import { ColorUtils, GetColor, Pagination, ZIndex } from 'venn-ui-kit';
import { getFiltersStateFromPrivatesSearchParams } from 'venn-state';
import { searchPrivates } from './filters/privates-search';
import type { LibrarySearchEntity } from 'venn-api';
import { globalSearch } from 'venn-api';
import { usePrivateLibraryState } from './useLibraryState';
import { LibraryTableAnnotation } from '../LibraryTableAnnotation';
import { getFilterPills } from './logic/filterPills';
import { LIBRARY_CONTENT_SELECTOR } from '../LibraryLayout';
import type { PrivatesTableRow } from './privatesColumns';
import { defaultPrivatesColumnStyling, getPrivatesTableColumns } from './privatesColumns';
import EmptyStateV2 from './EmptyStateV2';
import { PRIVATES_PAGE_SIZE } from './utils';
import { LibraryTypeaheadSearchBar } from './LibraryTypeaheadSearchBar';
import { getPrivatesFilters, SimpleFilter } from './filters';
import { PrivateBulkActionsIcons } from './BulkActions/PrivateBulkActionsIcons';
import { LibraryTabs } from './LibraryTabs';

export interface ResultsInfo {
  displayedRowsCount: number;
  totalRowsCount: number;
  isLoading: boolean;
}

export const toPrivatesRowData = (results: LibrarySearchEntity[]): PrivatesTableRow[] => {
  return results.map((result) => {
    const concatenatedIndustries = (result.industries ?? []).join(', ');
    return {
      itemType: result.privatePortfolioId ? 'PORTFOLIO' : 'INVESTMENT',
      id: result.privatePortfolioId ?? result.privateFundId,
      name: result.name ?? '—',
      userUploaded: result.userUploaded,
      vintage: result.vintage,
      status: result.status ?? '—',
      privateFundAssetClass: result.privateFundAssetClass ?? '—',
      strategy: result.strategy ?? '—',
      fundManager: result.manager ?? '—',
      fundSize: result.size,
      geographicFocus: result.geographicFocus ?? '—',
      industries: concatenatedIndustries || '—',
      netIrrPct: result.privateFundPerformance?.netIrrPct,
      netMultiple: result.privateFundPerformance?.netMultiple,
      rvpiPct: result.privateFundPerformance?.rvpiPct,
      dpiPct: result.privateFundPerformance?.dpiPct,
      calledPct: result.privateFundPerformance?.calledPct,
      lastUpdated: result.updated,
      capitalCommitment: result.capitalCommitment,
      ownerId: result.owner?.id,
      ownerContextFriendlyName: result.ownerContextFriendlyName,
    };
  });
};

export const resultsPendingState: ResultsInfo = {
  displayedRowsCount: 0,
  totalRowsCount: 0,
  isLoading: true,
};

const MAX_TABLE_HEIGHT = '63vh';

export const PrivatesTable = () => {
  const gridRef = useRef<AgGridReact>(null);

  const {
    librarySearchParams: privatesSearchParams,
    toggleParamItem,
    updateLibrarySearchParam,
    resetLibrarySearchParams,
  } = usePrivateLibraryState();
  const stateRef = useRef(privatesSearchParams);

  // This effect is necessary to sync ag-grid with recoil state
  // reason being, ag-grid's server side datasource is registered on mount with a function telling how to fetch data
  // When it is registered, it captured some initial value of the state in a closure
  // which is sadly not updated even if callbacks are used. See the blog below:
  // https://dmitripavlutin.com/react-hooks-stale-closures/
  useEffect(() => {
    stateRef.current = privatesSearchParams;
    gridRef.current?.api?.refreshServerSide();
  }, [privatesSearchParams]);

  const selectRowOnClick = useCallback((event: CellClickedEvent) => {
    if (event.column?.getColId() !== 'actions') {
      // disable selection for menu column
      const previousSelection = event.node.isSelected();
      event.node.setSelected(!previousSelection);
    }
  }, []);

  const searchApi = useApi(globalSearch);
  const getRowId = useCallback((params) => params.data.id, []);
  const [selectedRows, setSelectedRows] = useState<PrivatesTableRow[]>([]);
  const [{ displayedRowsCount, totalRowsCount, isLoading }, setResultsInfo] =
    useState<ResultsInfo>(resultsPendingState);

  const theme = useContext(ThemeContext);
  const [quickFiltersSection, itemTypesSection] = useMemo(() => getPrivatesFilters(theme), [theme]);
  const onSelectionChanged = useCallback((event: SelectionChangedEvent) => {
    event.api.refreshHeader();
    const selectedRows = event.api.getSelectedRows() as PrivatesTableRow[];
    setSelectedRows(selectedRows);
  }, []);

  const setSelectedRecoilState = useCallback(
    (selectedIds: string[]) => {
      if (stateRef.current.selectedIds.length > 0) {
        updateLibrarySearchParam({
          key: 'selectedIds',
          value: selectedIds,
        });
      }
    },
    [updateLibrarySearchParam],
  );

  /** Clears user selection */
  const clearSelectedItems = useCallback(() => {
    updateLibrarySearchParam({
      key: 'selectedIds',
      value: [],
    });
    gridRef.current?.api.deselectAll();
  }, [updateLibrarySearchParam]);

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      params.api.setGridOption('serverSideDatasource', {
        getRows: async (getRowParams: IServerSideGetRowsParams) => {
          setResultsInfo((prevState) => {
            return {
              ...prevState,
              isLoading: true,
            };
          });
          try {
            const result = await searchPrivates(stateRef.current, searchApi);
            if (isRequestSuccessful(result)) {
              getRowParams.success({
                rowData: toPrivatesRowData(result.content.results),
                rowCount: result.content.results.length,
              });
              setResultsInfo({
                totalRowsCount: result.content.total,
                displayedRowsCount: result.content.results.length,
                isLoading: false,
              });
            } else {
              getRowParams.fail();
            }
          } catch (e) {
            if (e.name === 'AbortError') {
              return;
            }
            getRowParams.fail();
            logExceptionIntoSentry(e);
          }

          // synchronize "selected" ag-grid state with the source of truth atom
          const currentlySelectedIds: string[] = stateRef.current.selectedIds;
          params.api.forEachNode((node) => {
            if (!node.data) {
              return;
            }
            const shouldBeSelected = currentlySelectedIds.includes(node.data.id);
            if (shouldBeSelected !== node.isSelected()) {
              node.setSelected(shouldBeSelected);
            }
          });
        },
      });
    },
    [searchApi],
  );

  const onSortChanged = useCallback(
    (event: SortChangedEvent) => {
      const sortedColumns = event.api.getColumnState().filter((s) => s.sort);
      if (sortedColumns.length === 0) {
        updateLibrarySearchParam({ key: 'resetSort' });
      } else {
        const sortedColumn = sortedColumns[0];
        updateLibrarySearchParam({ key: 'sortBy', column: sortedColumn.colId, order: sortedColumn.sort ?? 'asc' });
      }
    },
    [updateLibrarySearchParam],
  );

  const onPageChange = useCallback(
    (index: number) => {
      updateLibrarySearchParam({
        key: 'page',
        value: index,
      });
      clearSelectedItems();
    },
    [updateLibrarySearchParam, clearSelectedItems],
  );

  /** Called after the user modified the text search field */
  const onSearchTextChange = useCallback(
    (searchQuery: string) => {
      setResultsInfo(resultsPendingState);
      updateLibrarySearchParam({
        key: 'filter',
        filterName: 'name',
        value: searchQuery,
      });
    },
    [updateLibrarySearchParam],
  );

  const libraryFiltersState = useMemo(() => {
    return getFiltersStateFromPrivatesSearchParams(privatesSearchParams);
  }, [privatesSearchParams]);

  const refreshSearch = useCallback(() => {
    clearSelectedItems();
    gridRef.current?.api.refreshServerSide();
  }, [clearSelectedItems]);

  const pills = useMemo(() => {
    return getFilterPills({
      filtersState: libraryFiltersState,
      toggleParamItem: (key: string, item: string) => {
        toggleParamItem(key, item);
      },
      updateSearchParam: (key, value) => {
        clearSelectedItems();
        updateLibrarySearchParam({
          key: 'filter',
          filterName: key,
          value,
        });
      },
      removeAdvancedQuery: noop,
      hasFullHistoryFlag: false,
    });
  }, [libraryFiltersState, toggleParamItem, updateLibrarySearchParam, clearSelectedItems]);

  useLayoutEffect(() => {
    if (!isLoading) {
      scrollToElementByQuerySelector(LIBRARY_CONTENT_SELECTOR, 0, null, 'auto');
    }
  }, [isLoading]);

  const showZeroState = !isLoading && displayedRowsCount === 0;
  const bulkActionsComponent = (
    <PrivateBulkActionsIcons
      selectedItems={selectedRows}
      clearSelectedItems={clearSelectedItems}
      updateData={refreshSearch}
    />
  );
  const defaultColDef = useMemo(
    () => ({
      resizable: false,
      lockPinned: true,
      sortable: false,
      suppressHeaderMenuButton: true,
      suppressMovable: true,
      editable: false,
      headerClass: 'ag-right-aligned-header',
      cellStyle: defaultPrivatesColumnStyling,
    }),
    [],
  );
  const rowStyle = useMemo(() => ({ cursor: 'pointer' }), []);
  const rowSelection: RowSelectionOptions = useMemo(
    () => ({
      mode: 'multiRow' as const,
      selectAll: 'currentPage' as const,
      headerCheckbox: false,
      checkboxes: false,
      // disable row selection on click via `suppressRowClickSelection` and implement manually with grid-api
      // in `selectRowOnClick`. Reason: ag-grid doesn't support enabling the event for some but not all columns
      // and we don't want selection when the menu is clicked
      // See relevant: https://github.com/ag-grid/ag-grid/issues/2224
      enableClickSelection: false,
    }),
    [],
  );

  const headerCheckboxSelected = selectedRows.length === displayedRowsCount && displayedRowsCount > 0;
  return (
    <PrivatesTableWrapper showsZeroState={showZeroState}>
      <LibraryTopMenuWrapper>
        <LibraryTabs />
        <SearchAndFiltersContainer>
          <TextSearchContainer>
            <LibraryTypeaheadSearchBar
              searchText={privatesSearchParams.name}
              onSearchTextChange={onSearchTextChange}
              placeholder="Search library by name"
            />
          </TextSearchContainer>
          <SimpleFiltersContainer>
            {[quickFiltersSection, itemTypesSection]
              .filter(({ advancedOnly }) => !advancedOnly)
              .map((section, index) => {
                const selected =
                  index === 0 ? privatesSearchParams[LibraryFilterSectionKey.FILTERS] : privatesSearchParams.itemType;
                return (
                  <SimpleFilter
                    applyLabel="Select"
                    menuWidth={295}
                    closeOnOnlyClick
                    singleSelection={!section.checkbox}
                    key={section.key}
                    label={section.title!}
                    initialSelected={typeof selected === 'string' ? [selected] : selected}
                    items={section.items}
                    onFilter={(updated) =>
                      updateLibrarySearchParam({
                        key: 'filter',
                        filterName: section.key,
                        value: updated,
                      })
                    }
                  />
                );
              })}
          </SimpleFiltersContainer>
        </SearchAndFiltersContainer>

        <ActionBar>
          <LibraryTableAnnotation
            bulkActionsComponent={bulkActionsComponent}
            userSelectedRowsCount={selectedRows.length}
            displayedRowsCount={displayedRowsCount}
            totalRowsCount={totalRowsCount}
            clearAllFilters={resetLibrarySearchParams}
            displayedPage={privatesSearchParams.page}
            pageSize={PRIVATES_PAGE_SIZE}
            pills={pills}
          />
          <div>
            <Pagination
              pagesCount={Math.ceil(totalRowsCount / PRIVATES_PAGE_SIZE)}
              selectedPage={privatesSearchParams.page}
              onPageChange={onPageChange}
            />
          </div>
        </ActionBar>
      </LibraryTopMenuWrapper>
      <ConditionalOverlay condition={isLoading} className="qa-library-table">
        <DataGrid
          columnDefs={useMemo(
            () =>
              getPrivatesTableColumns({
                updateData: refreshSearch,
                clearSelectedItems,
                setSelectedRecoilState,
                headerCheckboxSelected,
              }),
            [refreshSearch, clearSelectedItems, setSelectedRecoilState, headerCheckboxSelected],
          )}
          gridRef={gridRef}
          defaultColDef={defaultColDef}
          domLayout="normal"
          cacheBlockSize={PRIVATES_PAGE_SIZE}
          onGridReady={onGridReady}
          rowStyle={rowStyle}
          onSortChanged={onSortChanged}
          getRowId={getRowId}
          onSelectionChanged={onSelectionChanged}
          pagination
          paginationPageSize={PRIVATES_PAGE_SIZE}
          suppressPaginationPanel
          rowBuffer={PRIVATES_PAGE_SIZE}
          rowSelection={rowSelection}
          headerHeight={41}
          rowHeight={37}
          rowModelType="serverSide"
          onCellClicked={selectRowOnClick}
          suppressContextMenu
          horizontalScrollbarHeight={15}
        />
      </ConditionalOverlay>
      {showZeroState && <EmptyStateV2 onClear={resetLibrarySearchParams} canClearQuery canClearFilters />}
    </PrivatesTableWrapper>
  );
};

const PrivatesTableWrapper = styled.div<{
  showsZeroState: boolean;
}>`
  .ag-header {
    border-top: 1px solid ${GetColor.Grey};
    border-bottom: 1px solid ${GetColor.Grey};
    background-color: ${GetColor.White};
    font-size: 12px;
    font-family: ${(props) => props.theme.Typography.fontFamily};
  }

  /* START setting max height of the table */
  .ag-body.ag-layout-normal {
    max-height: ${MAX_TABLE_HEIGHT};
  }

  .ag-root.ag-layout-normal {
    height: auto;
  }
  /* END setting max height of the table */

  .ag-root-wrapper {
    border: none;
  }

  .ag-header-cell {
    padding: 2px 4px;
    font-weight: bold;

    &[col-id='vintageDate'] {
      padding-left: 10px;
    }
    &[col-id='ownerContextFriendlyName'] {
      padding-left: 10px;
    }
    &[col-id='updated'] {
      padding-right: 10px;
    }
  }

  .ag-header-viewport {
    background-color: ${GetColor.PaleGrey};
  }

  .ag-row-hover {
    background-color: ${ColorUtils.hex2rgbaFrom(GetColor.Primary.Main, 0.15)};
  }

  .ag-column-hover {
    background-color: transparent;
  }

  // resetting hardcoded styles declared in datagrid.tsx //
  .ag-center-cols-clipper {
    min-height: 0 !important;
  }

  // resetting hardcoded styles declared in datagrid.tsx //
  .ag-center-cols-container {
    min-height: 0 !important;
  }

  .ag-pinned-left-header {
    background-color: ${GetColor.PaleGrey};
    box-shadow: 4px 0px 10px 0px #00000026;
    z-index: ${ZIndex.Front};
    border-right: none !important;
  }

  .ag-pinned-right-header {
    background-color: ${GetColor.PaleGrey};
    box-shadow: -4px 0px 10px 0px #00000026;
    z-index: ${ZIndex.Front};
    border-left: none !important;
  }

  .ag-pinned-left-cols-container {
    box-shadow: 4px 0px 10px 0px #00000026;
    z-index: ${ZIndex.Front};
  }

  .ag-pinned-right-cols-container {
    box-shadow: -4px 0px 10px 0px #00000026;
    z-index: ${ZIndex.Front};
  }

  .ag-cell {
    padding: 2px 4px;
    border-bottom: 1px solid ${GetColor.PaleGrey};
    font-family: ${(props) => props.theme.Typography.fontFamily};
    font-size: 14px;
  }

  .ag-body-horizontal-scroll-viewport {
    ${(props) => props.showsZeroState && 'display: none;'}
  }

  .ag-horizontal-right-spacer {
    border-left: none !important;
  }

  // we don't want to show right border for left pinned horizontal scroll //
  .ag-horizontal-left-spacer {
    border-right: 0px !important;
  }

  .ag-root-wrapper-body.ag-layout-normal {
    height: 100% !important;
  }

  // setting height of the entire table //
  .ag-body-viewport {
    padding-bottom: 2px;
    overflow: auto !important;
  }

  // Prevents mid-word wrapping
  .ag-cell-wrap-text {
    word-break: break-word;
  }
  .privates-actions-menu-dropdown {
    z-index: ${ZIndex.ModalFront};
  }
`;

const SearchAndFiltersContainer = styled.div`
  padding-top: 27px;
  padding-bottom: 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
`;

const TextSearchContainer = styled.div`
  width: 300px;
  margin-right: 30px;
  margin-top: 5px;
`;

const ActionBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding-left: 10px;
`;

const SimpleFiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 15px;
  margin-left: 49px;
  margin-right: 51px;
`;

const LibraryTopMenuWrapper = styled(StickyNode)`
  display: flex;
  flex-direction: column;
  margin-bottom: -1px;
  background: ${GetColor.White};
  z-index: ${ZIndex.StickyFront};
  border-bottom: 1px solid ${GetColor.Grey};
`;
