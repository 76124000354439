import React from 'react';

import { Dates, type ComputedDateRange } from 'venn-utils';
import type { FrequencyEnum } from 'venn-api';

import { Block } from '../../core';

interface EndDateRangeProps {
  to?: number;
  frequency?: FrequencyEnum;
  dateRange?: ComputedDateRange;
  endDateRangePrefix: string;
}

export const EndDateRange = ({
  dateRange,
  to: toOverride,
  frequency: frequencyOverride,
  endDateRangePrefix = '',
}: EndDateRangeProps) => {
  const to = toOverride ?? dateRange?.range.to;
  const frequency = frequencyOverride ?? dateRange?.frequency;
  const toText = to && frequency ? Dates.toDDMMMYYYY(to, frequency) : '--';

  return (
    <Block.MetadataItem iconType="calendar">
      {endDateRangePrefix}
      {toText}
    </Block.MetadataItem>
  );
};
