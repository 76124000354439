import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { GetColor } from 'venn-ui-kit';
import { blockFonts, type CustomFont, getCustomFontSize } from 'venn-state';

import { type HTMLProps } from '../factory';
import { useRecoilValue } from 'recoil';

export type BlockSubjectsElement = React.ElementRef<'div'>;
export interface BlockSubjectsProps extends HTMLProps<'div'> {
  id: string;
}

export const BlockSubjects = forwardRef<BlockSubjectsElement, BlockSubjectsProps>((props, ref) => {
  const { id, children } = props;
  const headerInfoFont = useRecoilValue(blockFonts.blockHeaderInfo(id));

  return (
    <Wrapper ref={ref} font={headerInfoFont} data-testid="qa-block-subjects">
      {children}
    </Wrapper>
  );
});

BlockSubjects.displayName = 'BlockSubjects';

const Wrapper = styled.div<{ font: CustomFont | undefined }>`
  display: flex;
  flex-wrap: wrap;
  column-gap: ${({ font }) => (font ? '0.6em' : '20px')};
  row-gap: ${({ font }) => (font ? '0.3em' : '10px')};
  align-items: center;
  ${({ font }) => `font-size: ${font ? getCustomFontSize(font) : '11px'};`}
  max-width: 100%;
  padding: 0.5em 20px;
  border-bottom: 1px solid ${GetColor.Grey};
`;
