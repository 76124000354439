import { compact, get, isEmpty, isNil } from 'lodash';
import type {
  Analysis,
  CustomizedBlock,
  FactorExposureComponents,
  PortfolioContributionFactorExposureNode,
  PortfolioContributionPerformanceSummary,
  PortfolioContributionPerformanceSummaryResponse,
  ReturnsFactorAnalysis,
  ReturnsHistogram,
  Scenario,
} from 'venn-api';
import {
  type CategoryMetric,
  type CustomizableMetric,
  FACTOR_PORTFOLIO_CONTRIBUTION_BLOCK_SETTINGS,
  isMetricAlwaysInPercentFormat,
  Numbers,
  PORTFOLIO_CONTRIBUTION_BLOCK_SETTINGS,
  RESIDUAL_FACTOR_ID,
  RISK_FREE_RATE_FACTOR_ID,
  TOTAL_FACTOR_ID,
} from 'venn-utils';
import { convertByUnits, getUnitPrecision, getUnitSymbol } from '../../utils/scenario';

import { getExcelFormattedScenarioShock, getYAxisLabel } from '../components/charts/returns-distribution/utils';
import { FACTOR_CONTRIBUTION_ANALYSIS_TYPES, TOTAL_LABEL, TOTAL_LABEL_CUMULATIVE } from '../customAnalysisContants';
import type {
  ColumnData,
  DataValue,
  FactorRowData,
  MetricRowData,
  PortfolioBreakdownRowData,
  PortfolioRowData,
  ScenarioColumnData,
  ScenarioRowData,
  ValueMapper,
} from '../types';

export const getRelativeValue = (relativeToTotal?: boolean, totalValue?: number, value?: number) => {
  if (isNil(value) || totalValue === 0 || !relativeToTotal) {
    return value;
  }

  if (isNil(totalValue)) {
    return undefined;
  }

  return Number(value) / totalValue;
};

export const portfolioStrategyBreakdownParser = (
  _selectedBlock: CustomizedBlock,
  analysesGroup?: (Analysis | undefined)[][],
): PortfolioRowData<PortfolioBreakdownRowData>[] => {
  const analysis = analysesGroup?.[0]?.[0];
  const portfolioBreakdownItems = analysis?.portfolioBreakdown?.[0]?.items;

  if (isNil(portfolioBreakdownItems)) {
    return [];
  }

  return portfolioBreakdownItems.map((item) => {
    return {
      label: item.label,
      key: item.label,
      allocation: [item.allocation],
      isStrategy: isNil(item.fund),
      fundId: item.fund?.id,
      path: item.path,
      value: [{ weight: item.weight }],
    };
  });
};

export const scenarioParser =
  (scenarios: Scenario[]) =>
  (
    selectedBlock: CustomizedBlock,
    analysesGroup?: (Analysis | undefined)[][],
  ): ScenarioColumnData[] | ScenarioRowData[] => {
    const firstSuccessfulScenario = analysesGroup?.find((s) => s?.[0]?.scenarios?.length);

    if (selectedBlock.infoGraphicType === 'DISTRIBUTE_BAR') {
      if (!analysesGroup) {
        return [];
      }

      return analysesGroup.flatMap((group) =>
        group[0]
          ? ({
              xAxisCategories:
                firstSuccessfulScenario && firstSuccessfulScenario[0]?.scenarios
                  ? firstSuccessfulScenario[0]?.scenarios
                      .find((s) => !!s)
                      ?.map(
                        (groupScenario, index) =>
                          `${scenarios[index].fundName}:\n${scenarios[index].shock > 0 ? '+' : ''}${parseFloat(
                            convertByUnits(scenarios[index].shock, scenarios[index].units),
                          ).toFixed(getUnitPrecision(scenarios[index].units))} ${getUnitSymbol(
                            scenarios[index].units,
                          )}`,
                      )
                  : [],
              yAxisTitle: `Estimated${selectedBlock.relativeToBenchmark ? ' Relative' : ''} Return`,
              percent: true,
              seriesData: group[0]?.scenarios
                ? group[0]?.scenarios
                    .find((s) => !!s)
                    ?.map((groupScenario) =>
                      !isNil(groupScenario.predicted)
                        ? Number.parseFloat(Numbers.safeFormatNumber(groupScenario.predicted * 100, 2))
                        : '--',
                    )
                : [],
              exportable: true,
              relative: selectedBlock.relativeToBenchmark,
              scenarioAnalyses: group?.length && group[0]?.scenarios?.length ? group[0].scenarios[0] : undefined,
              scenarios,
            } as ScenarioColumnData)
          : [],
      );
    }

    const rows =
      analysesGroup && firstSuccessfulScenario
        ? firstSuccessfulScenario
            .find((s) => s?.scenarios?.length)
            ?.scenarios[0]?.map((scenario, index) => {
              const scenarioAnalyses = analysesGroup.map((group) => {
                return group?.[0]?.scenarios?.[0]?.[index];
              });
              return {
                label: scenarios[index].fundName,
                scenarioShockXLS: getExcelFormattedScenarioShock(scenarios[index]),
                scenarioAnalysis: scenarioAnalyses,
                scenario: scenarios[index],
                relative: selectedBlock.relativeToBenchmark,
                hasError: scenarioAnalyses.some((analysis) => analysis?.status === 'FAILURE'),
              } as ScenarioRowData;
            })
        : [];

    return rows || [];
  };

export const performanceSummaryParser =
  (metrics: CustomizableMetric[]) =>
  (selectedBlock: CustomizedBlock, analysesGroup?: (Analysis | undefined)[][]): MetricRowData[] => {
    const relative = selectedBlock.relativeToBenchmark;
    const rows = selectedBlock.selectedMetrics.map((key) => {
      const metric = metrics.find((m) => m.key === key);
      if (!metric) {
        return undefined;
      }

      const label = relative ? (metric.relativeLabel ?? metric.label) : metric.label;

      // Special case for periodReturn
      const analysis = analysesGroup?.[0]?.find((a) => a?.analysisType === metric.analysisType);
      const analysisResult = metric.analysisResultKey ? analysis?.[metric.analysisResultKey] : analysis;
      const cumulativeLabel =
        key === 'periodReturn' && analysisResult?.[0]?.periodAnnualized === false ? ' (Cumulative)' : '';

      return {
        key,
        label: `${label}${cumulativeLabel}`,
        value: (analysesGroup?.map((analyses) => {
          const analysis = analyses.find((a) => a?.analysisType === metric.analysisType);
          const path = relative && metric.relativeMetricPath ? metric.relativeMetricPath : metric.metricPath;
          return path
            ? get(analysis?.[metric.analysisResultKey!]?.[0], path)
            : analysis?.[metric.analysisResultKey!]?.[0];
        }) ?? []) as DataValue[],
        type: metric.dataType,
      };
    });

    return compact(rows);
  };

type DataMapper = { [key: string]: PortfolioRowData<ValueMapper> };

export const portfolioContributionParser =
  (metrics: CustomizableMetric[]) =>
  (selectedBlock: CustomizedBlock, analysesGroup?: (Analysis | undefined)[][]): PortfolioRowData<ValueMapper>[] => {
    const rows: PortfolioRowData<ValueMapper>[] = [];

    const totalLabel =
      analysesGroup?.[0]?.[0]?.historicalPerformancePortfolioContributions?.[0]?.periodAnnualized === false
        ? TOTAL_LABEL_CUMULATIVE
        : TOTAL_LABEL;
    const totalRow: PortfolioRowData<ValueMapper> = {
      label: totalLabel,
      path: [totalLabel],
      value: [],
      isStrategy: true,
      allocation: [],
    };
    const mapper: DataMapper = {};
    const relative = selectedBlock.relativeToBenchmark;
    const relativeToTotal = selectedBlock.contributionToPercentage;

    prefillDataMapperRows(mapper, analysesGroup);

    analysesGroup?.forEach((analyses, index) => {
      parsePortfolioContributionRows(metrics, analyses, relative, totalRow, index, relativeToTotal, mapper);
      parsePortfolioFactorContributionRows(analyses, metrics, relative, totalRow, index, mapper, relativeToTotal);
    });
    rows.push(totalRow);
    rows.push(...Object.values(mapper));
    return rows;
  };

const gatherAllPortfolios = (analysesGroup?: (Analysis | undefined)[][]) => {
  const allAnalysisResultKeys = [
    ...new Set(
      [PORTFOLIO_CONTRIBUTION_BLOCK_SETTINGS, FACTOR_PORTFOLIO_CONTRIBUTION_BLOCK_SETTINGS].flatMap((settings) =>
        settings.metrics.map((metric) => metric.analysisResultKey),
      ),
    ),
  ];

  return analysesGroup?.flatMap((analyses) => {
    return compact(
      analyses.flatMap((analysis) =>
        allAnalysisResultKeys.map((key) => {
          const response = analysis?.[key]?.[0];

          if (!response) {
            return null;
          }

          if ('portfolio' in response) {
            return response.portfolio;
          }

          if ('portfolioContribution' in response) {
            return response.portfolioContribution;
          }

          return null;
        }),
      ),
    );
  });
};

export type Node = string[];
export type Graph = Map<string, Node[]>;
type PortfolioNode =
  | PortfolioContributionFactorExposureNode
  | PortfolioContributionPerformanceSummary
  | PortfolioContributionPerformanceSummaryResponse;

/**
 * Does a DFS traversal and calls the callback for each path
 * This is reused for portfolio contribution and more recent portfolio comparison blocks
 */
export const runDfs = (graph: Graph, topLevelPaths: Node[], callback: (path: Node) => void) => {
  const visited = new Set<string>();

  const dfs = (path: Node) => {
    const key = toKey(path);
    if (visited.has(key)) {
      return;
    }
    visited.add(key);

    callback(path);

    for (const child of graph.get(key) ?? []) {
      dfs(child);
    }
  };

  for (const path of topLevelPaths) {
    dfs(path);
  }
};

const buildGraph = (
  portfolios: PortfolioNode[],
): { graph: Graph; topLevelPaths: Node[]; pathToDataMap: DataMapper } => {
  const pathToDataMap: DataMapper = {};
  const graph: Graph = new Map();
  const topLevelPaths: Node[] = [];

  const generatePaths = (portfolio: PortfolioNode, usedNamesMap: Record<string, number>) => {
    const generatePathsImpl = (portfolio: PortfolioNode, parentPath: string[]) => {
      const currentNameIdx = usedNamesMap[portfolio.name] ?? 0;
      usedNamesMap[portfolio.name] = currentNameIdx + 1;
      const currentName = `${portfolio.name}(${currentNameIdx})`;
      const currentPath = [...parentPath, currentName];

      const key = toKey(currentPath);
      pathToDataMap[key] = {
        label: portfolio.name,
        path: currentPath,
        value: [],
        allocation: [],
        fundId: portfolio?.fundId,
        isStrategy: !portfolio.fundId,
      };

      if (isEmpty(parentPath)) {
        topLevelPaths.push(currentPath);
      } else {
        const parentKey = toKey(parentPath);
        if (!graph.has(parentKey)) {
          graph.set(parentKey, []);
        }
        graph.get(parentKey)?.push(currentPath);
      }

      portfolio.children?.map((child) => generatePathsImpl(child, currentPath));
    };

    // We should skip the portfolio itself (root node) and start from top-level strategies
    portfolio.children?.forEach((portfolio) => generatePathsImpl(portfolio, []));
  };

  portfolios.forEach((portfolio) => generatePaths(portfolio, {}));

  return {
    graph,
    topLevelPaths,
    pathToDataMap,
  };
};

const prefillDataMapperRows = (mapper: DataMapper, analysesGroup?: (Analysis | undefined)[][]) => {
  const allPortfolios = gatherAllPortfolios(analysesGroup) ?? [];

  const { graph, topLevelPaths, pathToDataMap } = buildGraph(allPortfolios);

  runDfs(graph, topLevelPaths, (path) => {
    const key = toKey(path);
    mapper[key] = pathToDataMap[key];
  });
};

export const returnsDistributionParser =
  (metrics: CustomizableMetric[]) =>
  (selectedBlock: CustomizedBlock, analysesGroup?: (Analysis | undefined)[][]): ColumnData[] => {
    const analysis = analysesGroup?.[0]?.[0];
    const metric = metrics?.[0];
    if (isNil(analysis) || isNil(metric)) {
      return [];
    }

    const analysisResults = get(analysis, metric.analysisResultKey!) as ReturnsHistogram[];
    const seriesData = analysisResults.map<(number | null)[]>((returnsHistogram) =>
      (returnsHistogram?.returns || []).map(({ count }) => count),
    );
    const yAxisLabel = getYAxisLabel(analysisResults?.[0]?.frequency ?? 'MONTHLY');
    const xAxisCategories = analysisResults?.[0]?.returns?.map(({ name }: { name: string }) => name);

    return seriesData.map((serieData, index) => ({
      seriesData: serieData,
      xAxisCategories,
      yAxisLabel,
      exportable: analysis.exportable[index],
    }));
  };

// This contains null byte so should be safe to use as separator
const SEPARATOR = '#\u0000#';

export const toKey = (path: string[]) => path.join(SEPARATOR);

const fillTreeRowData = (
  summary: PortfolioContributionPerformanceSummary,
  index: number,
  parentPath: string[],
  mapper: DataMapper,
  isRoot: boolean,
  relativeToTotal: boolean,
  totalAllocation: number,
  totalValue: number | undefined,
  key: string,
  valuePath: string | undefined,
  usedNamesMap: { [name: string]: number },
) => {
  const currentNameIdx = usedNamesMap[summary.name] ?? 0;
  usedNamesMap[summary.name] = currentNameIdx + 1;
  const currentName = `${summary.name}(${currentNameIdx})`;

  if (!isRoot) {
    const currentPath = [...parentPath, currentName];
    const path = currentPath.join(SEPARATOR);
    if (!mapper[path]) {
      mapper[path] = {
        label: summary.name,
        path: currentPath,
        value: [],
        allocation: [],
        isStrategy: !summary.fundId,
        fundId: summary?.fundId,
      };
    }

    if (key === 'allocation') {
      mapper[path].allocation[index] = getRelativeValue(relativeToTotal, totalAllocation, summary.allocation);
    } else if (key === 'allocation-VALUE' || key === 'allocation-PERCENT') {
      const actualRelativeToTotal = isMetricAlwaysInPercentFormat(key);
      const parsedValue = getRelativeValue(actualRelativeToTotal, totalAllocation, summary.allocation);
      mapper[path].value[index] = mapper[path].value[index] ?? {};
      mapper[path].value[index][key] = parsedValue;
    } else {
      const parsedValue = getRelativeValue(
        relativeToTotal,
        totalValue,
        valuePath ? get(summary.value, valuePath) : summary.value,
      );
      mapper[path].value[index] = mapper[path].value[index] ?? {};
      mapper[path].value[index][key] = parsedValue;
    }
  }

  if (summary?.children && summary.children.length > 0) {
    const childrenNamesMap = {};
    const newParentPath = isRoot ? [] : [...parentPath, currentName];
    summary.children.forEach((p) =>
      fillTreeRowData(
        p,
        index,
        newParentPath,
        mapper,
        false,
        relativeToTotal,
        totalAllocation,
        totalValue,
        key,
        valuePath,
        childrenNamesMap,
      ),
    );
  }
};

const fillFactorTreeRowData = (
  summary: PortfolioContributionFactorExposureNode,
  index: number,
  parentPath: string[],
  mapper: DataMapper,
  isRoot: boolean,
  relativeToTotal: boolean,
  totalAllocation: number,
  totalValue: { [key: number]: number },
  key: string,
  factors: number[] | undefined,
  valueField: string | undefined,
  valuePath: string | undefined,
  usedNamesMap: { [key: string]: number },
) => {
  const currentNameIdx = usedNamesMap[summary.name] ?? 0;
  usedNamesMap[summary.name] = currentNameIdx + 1;
  const currentName = `${summary.name}(${currentNameIdx})`;

  if (!isRoot && factors) {
    for (const factor of factors) {
      const currentPath = [...parentPath, currentName];
      const path = currentPath.join(SEPARATOR);
      if (!mapper[path]) {
        mapper[path] = {
          label: summary.name,
          path: currentPath,
          value: [],
          allocation: [],
          isStrategy: !summary.fundId,
        };
      }

      if (key === 'allocation') {
        mapper[path].allocation[index] = getRelativeValue(relativeToTotal, totalAllocation, summary.allocation);
      } else {
        const parsedValue = getRelativeValue(
          relativeToTotal,
          totalValue[factor] ?? 0.0,
          // @ts-expect-error: TODO fix strictFunctionTypes
          valuePath ? get(summary.value, `${valueField}.${factor}.${valuePath}`) : summary.value,
        );

        mapper[path].value[index] = mapper[path].value[index] ?? {};
        mapper[path].value[index][`${key}.${factor}`] = parsedValue;
      }
    }
  }

  if (summary?.children && summary.children.length > 0) {
    const newParentPath = isRoot ? [] : [...parentPath, currentName];
    const childrenNamesMap = {};
    summary.children.forEach((p) =>
      fillFactorTreeRowData(
        p,
        index,
        newParentPath,
        mapper,
        false,
        relativeToTotal,
        totalAllocation,
        totalValue,
        key,
        factors,
        valueField,
        valuePath,
        childrenNamesMap,
      ),
    );
  }
};

export const factorsParser =
  (metrics: CustomizableMetric[], factors?: CategoryMetric[]) =>
  (selectedBlock: CustomizedBlock, analysesGroup?: (Analysis | undefined)[][]): FactorRowData[] => {
    const relativeToTotal = selectedBlock.contributionToPercentage;
    const totalValues: ValueMapper[] = (analysesGroup ?? []).map((analyses) => {
      const totalValue = {};
      metrics.forEach((metric) => {
        const analysis = analyses.find((a) => a?.analysisType === metric.analysisType);
        if (analysis) {
          if (analysis.analysisType === 'FACTOR_CONTRIBUTION_TO_RISK') {
            totalValue[metric.key] = analysis[metric.analysisResultKey!]?.[0]?.annualizedTotalRisk;
          } else if (analysis.analysisType === 'FACTOR_CONTRIBUTION_TO_RETURN') {
            totalValue[metric.key] = analysis[metric.analysisResultKey!]?.[0]?.periodTotalReturn;
          }
        }
      });
      return totalValue;
    });

    const selectedFactors = compact(
      selectedBlock.selectedFactors?.map((f) => factors?.find((factor) => f === factor.id)),
    );

    return selectedFactors.map((f) => ({
      key: f.id,
      label: f.name,
      value: getFactorValues(relativeToTotal, totalValues, f.id, metrics, analysesGroup),
    }));
  };

const getFactorValues = (
  relativeToTotal: boolean,
  totalValue: ValueMapper[],
  factorId: string,
  metrics: CustomizableMetric[],
  analysesGroup?: (Analysis | undefined)[][],
) => {
  const specialRows = [TOTAL_FACTOR_ID, RISK_FREE_RATE_FACTOR_ID, RESIDUAL_FACTOR_ID];
  const isSpecialRows = specialRows.includes(factorId);

  return (analysesGroup ?? []).map((analyses, index) => {
    const parsedValue = {};
    metrics.forEach((metric) => {
      const analysis = analyses.find((a) => a?.analysisType === metric.analysisType);
      if (analysis) {
        if (isSpecialRows) {
          if (analysis.analysisType === 'FACTOR_CONTRIBUTION_TO_RISK') {
            if (factorId === TOTAL_FACTOR_ID) {
              parsedValue[metric.key] = analysis.factorContributionToRisk?.[0]?.annualizedTotalRisk;
            } else if (factorId === RESIDUAL_FACTOR_ID) {
              parsedValue[metric.key] = analysis.factorContributionToRisk?.[0]?.residualMarginalRisk;
            }
          } else if (analysis.analysisType === 'FACTOR_CONTRIBUTION_TO_RETURN') {
            if (factorId === TOTAL_FACTOR_ID) {
              parsedValue[metric.key] = analysis.factorContributionToReturn?.[0]?.periodTotalReturn;
            } else if (factorId === RESIDUAL_FACTOR_ID) {
              parsedValue[metric.key] = analysis.factorContributionToReturn?.[0]?.periodResidualReturn;
            } else if (factorId === RISK_FREE_RATE_FACTOR_ID) {
              parsedValue[metric.key] = analysis.factorContributionToReturn?.[0]?.riskFreeReturn;
            }
          }
        } else {
          const contribution = (
            analysis[metric.analysisResultKey!]?.[0] as ReturnsFactorAnalysis | undefined
          )?.factors.find((factor) => String(factor.id) === factorId);
          parsedValue[metric.key] = contribution?.marginalContribution;
        }
        parsedValue[metric.key] = getRelativeValue(
          relativeToTotal,
          getOptionalNumber(totalValue?.[index]?.[metric.key]),
          parsedValue[metric.key],
        );
      }
    });
    return parsedValue;
  });
};

const parsePortfolioContributionRows = (
  metrics: CustomizableMetric[],
  analyses: (Analysis | undefined)[],
  relative: boolean | undefined,
  totalRow: PortfolioRowData<ValueMapper>,
  index: number,
  relativeToTotal: boolean | undefined,
  mapper: DataMapper,
) => {
  metrics.forEach((metric) => {
    if (FACTOR_CONTRIBUTION_ANALYSIS_TYPES.includes(metric.analysisType!)) {
      return;
    }

    const analysis = analyses.find((a) => a?.analysisType === metric.analysisType);
    const contribution = analysis?.[metric.analysisResultKey!]?.[0]?.portfolioContribution as
      | PortfolioContributionPerformanceSummary
      | undefined;
    if (contribution) {
      const path = relative && metric.relativeMetricPath ? metric.relativeMetricPath : metric.metricPath;
      const totalValue = path ? get(contribution.value, path) : contribution;
      const totalAllocation = contribution.allocation;
      totalRow.allocation[index] = getRelativeValue(relativeToTotal, totalAllocation, totalAllocation);
      const parsedValue =
        metric.key === 'allocation-VALUE' || metric.key === 'allocation-PERCENT'
          ? getRelativeValue(isMetricAlwaysInPercentFormat(metric.key), totalAllocation, totalAllocation)
          : getRelativeValue(relativeToTotal, totalValue, totalValue);
      totalRow.value[index] = totalRow.value[index] ?? {};
      totalRow.value[index][metric.key] = parsedValue;

      fillTreeRowData(
        contribution,
        index,
        [],
        mapper,
        true,
        !!relativeToTotal,
        totalAllocation,
        totalValue,
        metric.key,
        path,
        {},
      );
    }
  });
};

const parsePortfolioFactorContributionRows = (
  analyses: (Analysis | undefined)[],
  metrics: CustomizableMetric[],
  relative: boolean | undefined,
  totalRow: PortfolioRowData<ValueMapper>,
  index: number,
  mapper: DataMapper,
  relativeToTotal: boolean | undefined,
) => {
  metrics.forEach((metric) => {
    if (!FACTOR_CONTRIBUTION_ANALYSIS_TYPES.includes(metric.analysisType!)) {
      return;
    }

    const analysis = analyses.find((a) => a?.analysisType === metric.analysisType);
    const contribution = analysis?.[metric.analysisResultKey!]?.[0] as FactorExposureComponents | undefined;
    const key = metric.key;
    if (contribution) {
      const factors = contribution.factors.map((factor) => factor.id);
      const field = 'factorContribution';
      const path = relative && metric.relativeMetricPath ? metric.relativeMetricPath : metric.metricPath;
      const totalValue: { [key: number]: number } = {};
      const totalAllocation = contribution.portfolio.allocation;
      totalRow.allocation[index] = getRelativeValue(relativeToTotal, totalAllocation, totalAllocation);
      for (const factor of factors) {
        const valuePath = `${field}.${factor}.${path}`;
        totalValue[factor] = valuePath ? get(contribution.portfolio.value, valuePath) : contribution.portfolio.value;
        totalRow.allocation[index] = getRelativeValue(relativeToTotal, totalAllocation, totalAllocation);
        const parsedValue = getRelativeValue(relativeToTotal, totalValue[factor], totalValue[factor]);
        totalRow.value[index] = totalRow.value[index] ?? {};
        totalRow.value[index][`${key}.${factor}`] = parsedValue;
      }

      fillFactorTreeRowData(
        contribution.portfolio,
        index,
        [],
        mapper,
        true,
        !!relativeToTotal,
        totalAllocation,
        totalValue,
        key,
        factors,
        field,
        path,
        {},
      );
    }
  });
};

function getOptionalNumber(value: string | number | undefined | null) {
  if (isNil(value)) {
    return undefined;
  }

  const numericValue = typeof value === 'number' ? value : Number.parseFloat(value);
  return Number.isNaN(numericValue) ? undefined : numericValue;
}
