import type { Layout } from 'react-grid-layout';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { isSelectedBlockState, blockSettings, hideBlockBorders, blockOverflowErrorState } from 'venn-state';
import { GetColor } from 'venn-ui-kit';
import { SpecialCssClasses } from 'venn-utils';
import { BlockIdContext, BlockWidthContextProvider, BLOCK_BORDER_WIDTH_PX } from '../studio-blocks';
import { BlockContext } from './contexts/block-context';
import { useAlertOutline } from './utils/hooks/use-alert-outline';
import React, { forwardRef, useEffect, useMemo, useRef } from 'react';
import styled, { css } from 'styled-components';
import classNames from 'classnames';

const ACTIVE_BLOCK_CLASS = 'active-block';

interface BlockOuterContainerProps {
  id: string;
  title: string;
  children: React.ReactNode;
  pageFooterRef?: React.RefObject<HTMLElement>;
  blockLayout?: Layout;
  width: number;
  widthStyle: string;
  className?: string;
}

export const BlockOuterContainer = forwardRef<HTMLDivElement, BlockOuterContainerProps>((props, ref) => {
  const { id, children, pageFooterRef, width, widthStyle, className } = props;

  const blockRef = useRef<HTMLDivElement>(null);
  const blockContextValue = useMemo(() => ({ blockRef }), [blockRef]);

  const active = useRecoilValue(isSelectedBlockState(id));
  const settings = useRecoilValue(blockSettings(id));
  const hideBorder = useRecoilValue(hideBlockBorders(id));
  const transparent = settings.customBlockType === 'PAGE_BREAK' || hideBorder;

  // TODO(houssein): Overflow warnings are only needed for report lab.
  // We may want to lift this up to ReportLabBlockContainer and refactor how pageFooterRef is passed around
  const setOverflowError = useSetRecoilState(blockOverflowErrorState(id));
  const overflowError = useAlertOutline(id, blockRef, pageFooterRef);

  useEffect(() => {
    setOverflowError(overflowError);
  }, [overflowError, setOverflowError]);

  return (
    <BlockContext.Provider value={blockContextValue}>
      <BlockIdContext.Provider value={id}>
        <BlockWidthContextProvider blockWidthPx={width}>
          <BlockOuterBorder
            ref={ref}
            style={{ width: widthStyle }}
            data-testid={props['data-testid']}
            className={classNames(className, active && ACTIVE_BLOCK_CLASS)}
            active={active}
            transparent={transparent}
          >
            <BlockInnerBorder active={active} transparent={transparent}>
              {children}
            </BlockInnerBorder>
          </BlockOuterBorder>
        </BlockWidthContextProvider>
      </BlockIdContext.Provider>
    </BlockContext.Provider>
  );
});
BlockOuterContainer.displayName = 'BlockOuterContainer';

const BlockOuterBorder = styled.div<{
  active: boolean;
  transparent: boolean;
}>`
  border-style: solid;
  border-width: ${({ active, transparent }) => (active ? `${BLOCK_BORDER_WIDTH_PX}px` : transparent ? 0 : '1px')};
  border-color: ${({ active }) => (active ? GetColor.Primary.Main : GetColor.Grey)};
  background-color: ${({ transparent }) => (transparent ? 'transparent' : GetColor.White)};
  width: 100%;
  height: 100%;
  @media print {
    page-break-inside: avoid;
  }

  .${SpecialCssClasses.ExportAsImage} & {
    border-color: ${GetColor.Grey};
  }

  ${({ active }) =>
    !active &&
    css`
      cursor: pointer;
      :hover {
        border: 2px solid ${GetColor.Grey};
        > div {
          border: 0;
        }
      }
    `}
`;

const BlockInnerBorder = styled.div<{ active: boolean; transparent: boolean }>`
  border: ${({ active, transparent }) => (active ? 0 : transparent ? '2px' : '1px')} solid transparent;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
