import React, { useCallback, useState } from 'react';
import { type ProxyTypeEnum, type Fund, type FrequencyEnum } from 'venn-api';
import { GetColor, Icon, Tooltip } from 'venn-ui-kit';
import { useHasFF } from 'venn-utils';
import SliderToggle from '../../../../slider-toggle/SliderToggle';
import { typeSupportsInterpolate } from '../utils';
import styled from 'styled-components';

export const ExtrapolationToggle = ({ canToggleExtrapolation, extrapolationToggled, setExtrapolationToggled }) => {
  return (
    canToggleExtrapolation && (
      <ToggleContainer data-testid="qa-proxy-extrapolation-toggle">
        Extrapolation:
        <Tooltip
          usePortal
          data-testid="qa-extrapolation-tooltip"
          content="Enable extrapolation to extend performance forward."
        >
          <QuestionMark type="question-circle" />
        </Tooltip>
        <SliderToggle toggled={extrapolationToggled} onToggle={() => setExtrapolationToggled((v) => !v)} />
      </ToggleContainer>
    )
  );
};

export function useExtrapolateToggle(investments: Fund[], selectedProxyType: ProxyTypeEnum | undefined) {
  const hasExtrapolationFf = useHasFF('extrapolation_ff');

  const [extrapolationToggled, setExtrapolationToggled] = useState(() => {
    return investments.every((investment) => investment.extrapolate);
  });

  const canToggleExtrapolation =
    hasExtrapolationFf &&
    investments.some((investment) => canShowExtrapolationToggle(selectedProxyType, investment.unproxiedFrequency));
  const shouldExtrapolate = hasExtrapolationFf && (selectedProxyType === 'EXTRAPOLATE' || extrapolationToggled);

  return {
    canToggleExtrapolation,
    shouldExtrapolate,
    extrapolationToggled,
    setExtrapolationToggled: useCallback(
      (value: boolean) => {
        if (hasExtrapolationFf && canToggleExtrapolation) {
          setExtrapolationToggled(value);
        }
      },
      [canToggleExtrapolation, hasExtrapolationFf, setExtrapolationToggled],
    ),
  };
}

const canShowExtrapolationToggle = (proxyType: ProxyTypeEnum | undefined, frequency: FrequencyEnum) => {
  return (
    proxyType !== undefined &&
    proxyType !== 'SUBSTITUTE' &&
    proxyType !== 'EXTRAPOLATE' &&
    (frequency !== 'QUARTERLY' || typeSupportsInterpolate(proxyType))
  );
};

const ToggleContainer = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
`;
const QuestionMark = styled(Icon)`
  margin-left: 4px;
  color: ${GetColor.Primary.Dark};
  font-size: 10px;
`;
