import React, { useMemo, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import type { GeneralChartProps } from './types';
import { DownloadableContentBlock } from '../../../content-block';
import { createVennScatterChartConfig } from '../../../charts/analysis-charts/chart-config-logic';
import useExportUpdate from '../../logic/useExportUpdate';
import { WATERMARK_POSITION_TOP } from '../../customAnalysisContants';
import { isNil } from 'lodash';
import StyledEmptyState from '../StyledEmptyState';
import { getScatterLabels, useScatterChartData, useScatterChartMetricError } from './scatter-chart-logic';
import { useBlockId } from '../../contexts/BlockIdContext';
import { useRecoilValue } from 'recoil';
import { blockFonts } from 'venn-state';
import { EmptyHeaderSpace } from '../../../content-block/DownloadableContentBlock';
import { StudioHighchart } from '../../../highchart/Highchart';
import type { MetricRowData } from 'venn-components';

type ScatterChartProps = Omit<
  GeneralChartProps,
  'responseParser' | 'selectedRefId' | 'downloadableContentRef' | 'selectedBlock'
> & {
  rowData: MetricRowData[];
  relativeToBenchmark: boolean;
  header: string;
};

export const ScatterChart = ({
  metrics,
  analyses,
  requests,
  height,
  subjectColors,
  exportMetaData,
  inPrintMode,
  rowData,
  relativeToBenchmark,
  header,
}: ScatterChartProps) => {
  const blockId = useBlockId();
  const legendFont = useRecoilValue(blockFonts.blockChartLegend(blockId));
  const axisFont = useRecoilValue(blockFonts.blockChartAxis(blockId));
  const theme = useContext(ThemeContext);
  useScatterChartMetricError();
  const { xUnit, yUnit, xLabel, yLabel } = getScatterLabels(metrics, relativeToBenchmark);

  const [series, excelDataFn] = useScatterChartData(metrics, rowData, analyses, requests, subjectColors);

  useExportUpdate({ exportMetaData, excelDataFn, selectedRefId: blockId });

  const config = useMemo(
    () =>
      !series?.length
        ? undefined
        : createVennScatterChartConfig(
            series,
            xUnit,
            yUnit,
            xLabel ?? '',
            yLabel ?? '',
            theme.Typography,
            theme.Colors,
            theme.Schemes,
            legendFont.fontSizePt,
            axisFont.fontSizePt,
            height,
          ),
    [series, xUnit, yUnit, xLabel, yLabel, theme, height, legendFont.fontSizePt, axisFont.fontSizePt],
  );

  const chart = isNil(config) ? (
    <StyledEmptyState selectedRefId={blockId} header="Unable to run analysis" message="Chart is empty" />
  ) : (
    <StudioHighchart options={config} blockId={blockId} style={{ height: '100%' }} />
  );

  if (inPrintMode) {
    return chart;
  }

  return (
    <DownloadableContentBlock
      header={<EmptyHeaderSpace />}
      noBorder
      downloadable={{
        png: true,
        options: {
          fileName: header,
          watermark: { top: WATERMARK_POSITION_TOP, right: 20 },
        },
        tracking: {
          description: 'BUILDER',
          relativeToBenchmark,
          userUploaded: false,
          subjectType: undefined,
          subjectId: undefined,
        },
        disabled: series ? series.length === 0 : true,
      }}
    >
      {chart}
    </DownloadableContentBlock>
  );
};

export const ScatterChartDeprecated = (props: GeneralChartProps) => {
  const { responseParser, selectedBlock, analyses } = props;
  const rowData = responseParser.parser(selectedBlock, analyses) as MetricRowData[];

  return (
    <ScatterChart
      {...props}
      rowData={rowData}
      relativeToBenchmark={!!selectedBlock?.relativeToBenchmark}
      header={selectedBlock.header ?? 'Builder Chart'}
    />
  );
};
