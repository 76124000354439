import type { AnalysisViewTypeEnum } from 'venn-api';
import { ItemType } from './itemType';

export const getItemLabel = (item: ItemType | AnalysisViewTypeEnum, fullCompositeBenchmarkLabel?: boolean) => {
  switch (item) {
    case ItemType.Benchmark:
    case ItemType.CommonBenchmark:
      return fullCompositeBenchmarkLabel ? 'Composite Benchmark' : 'Benchmark';
    case ItemType.Portfolio:
    case ItemType.PrivatePortfolio:
    case ItemType.HistoricalPortfolio:
      return 'Portfolio';
    case ItemType.Strategy:
      return 'Strategy';
    case ItemType.Investment:
    case ItemType.PrivateInvestment:
      return 'Investment';
    case ItemType.File:
      return 'File';
    case ItemType.Compare:
      return 'Compare';
    case ItemType.Analysis:
      return 'Analysis';
    case ItemType.Custom:
      return 'Builder View';
    case ItemType.Studio:
      return 'Studio View';
    case ItemType.Tag:
      return 'Tag';
    case ItemType.StudioTearsheet:
      return 'Studio';
    case ItemType.StudioReport:
      return 'Report';
    case ItemType.TearsheetTemplate:
      return 'Studio Template';
    case ItemType.ReportTemplate:
      return 'Report Template';
    case ItemType.EditTemplate:
      return 'Edit Template';
    case ItemType.Article:
      return 'Help Article';
    case ItemType.PeerGroup:
      return 'Peer Group';
    case ItemType.SavedSearch:
      return 'Saved Search';
    default:
      return '';
  }
};
