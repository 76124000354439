import type { ICellRendererParams } from 'ag-grid-community';
import { isNil } from 'lodash';
import React from 'react';
import type { Portfolio } from '../../../../../../venn-api/src';
import { TableRowOptionsMenu } from '../../../../../../venn-ui-kit/src';
import type { RowData } from '../../types';

type MutliPortfolioActionsCellProps = ICellRendererParams<RowData<Portfolio>> &
  Readonly<{
    isHistorical: boolean;
    isExcluded: boolean;
    includeInvestment: () => void;
    excludeInvestment: () => void;
  }>;

const getLabelForAction = (isExcluded: boolean, isHistorical: boolean) => {
  if (isExcluded) {
    return 'Re-include investment in upload';
  }
  return isHistorical ? 'Exclude investment from all dates' : 'Exclude investment from upload';
};

const MutliPortfolioActionsCell = ({
  data,
  isHistorical,
  isExcluded,
  includeInvestment,
  excludeInvestment,
}: MutliPortfolioActionsCellProps) => {
  if (isNil(data?.node?.fund) || isNil(data?.path)) {
    return null;
  }

  return (
    <TableRowOptionsMenu
      usePortal
      portalProps={{
        topOffset: 20,
        rightOffset: 210,
      }}
      options={[
        {
          label: getLabelForAction(isExcluded, isHistorical),
          destructive: !isExcluded,
          onClick: () => {
            if (isExcluded) {
              includeInvestment();
            } else {
              excludeInvestment();
            }
          },
        },
      ]}
    />
  );
};

export default MutliPortfolioActionsCell;
