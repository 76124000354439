import React, { useCallback, useContext, useState } from 'react';
import {
  historicalSubjectAsOfDate,
  isFactorTrendMetric,
  isPortfolioBlock,
  isPublicPrivateAssetGrowthBlock,
  MAX_BLOCK_SUBJECT_INPUTS,
  plural,
  PRO_FORMA_HISTORICAL_SIMULATION_DATE_BLOCKS,
  useHover,
  withSuspense,
} from 'venn-utils';
import { Info, Warning } from 'venn-ui-kit';
import { constSelector, useRecoilValue } from 'recoil';
import {
  blockDateRange,
  blockLimitedRequestSubjects,
  blockMaxSubjects,
  blockMetrics,
  blockRequestSubjects,
  blockSettings,
  blockSubjectInputGroups,
  customizedBlock,
  subjectInputGroupSubjects,
} from 'venn-state';
import { FlexHeader } from '../../../../shared';
import { HoverIcon } from './HoverIcon';
import { BlockSubjectInputList } from './BlockSubjectInputList';
import { UserContext } from 'venn-components';
import styled from 'styled-components';
import { noop } from 'lodash';
import { ComparisonOrderSubSection } from './ComparisonOrderSubSection';

/** Section for editing a block config with regard to its {@link blockSubjectInputGroups}: viewing, adding, editing, and deleting subject input groups. */
export const SubjectInputSection = React.memo(function SubjectInputSection({
  selectedBlockId,
}: {
  selectedBlockId: string;
}) {
  const { hasPermission } = useContext(UserContext);
  const [isRootHovered, rootHoverHandlers] = useHover();
  const blockSetting = useRecoilValue(blockSettings(selectedBlockId));

  const [isAdding, setIsAdding] = useState(false);
  const setIsAddingTrue = useCallback(() => setIsAdding(true), [setIsAdding]);
  const setIsAddingFalse = useCallback(() => setIsAdding(false), [setIsAdding]);
  const canAddSubjectGroup = hasPermission('STUDIO_EDIT_SUBJECTS') && hasPermission('STUDIO_EDIT');

  return (
    <div {...rootHoverHandlers} data-testid="qa-block-subject-group">
      <FlexHeader style={{ justifyContent: 'space-between', marginBottom: '12px' }}>
        <span>Subjects</span>
        {canAddSubjectGroup && (
          <AddSubjectHoverButton
            selectedBlockId={selectedBlockId}
            forceVisible={isRootHovered}
            onClick={setIsAddingTrue}
          />
        )}
      </FlexHeader>
      <LayoutWrapper>
        <FactorTrendLineChartMessage selectedBlockId={selectedBlockId} />
        <NeedsPublicPrivateMessage selectedBlockId={selectedBlockId} />
        <ExcludedSubjectsMessage selectedBlockId={selectedBlockId} />

        <BlockSubjectInputList
          selectedBlockId={selectedBlockId}
          isAdding={isAdding}
          onAddingFinished={setIsAddingFalse}
        />
        <HistoricalProFormaSubjectsMessage selectedBlockId={selectedBlockId} />
      </LayoutWrapper>
      {selectedBlockId && isPortfolioBlock(blockSetting.customBlockType) && (
        <ComparisonOrderSubSection selectedBlockId={selectedBlockId} />
      )}
    </div>
  );
});

const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
const AddSubjectHoverButton = React.memo(
  ({
    selectedBlockId,
    forceVisible,
    onClick,
  }: {
    selectedBlockId: string;
    forceVisible?: boolean;
    onClick: () => void;
  }) => {
    const blockInputIds = useRecoilValue(blockSubjectInputGroups(selectedBlockId));
    const maxSubjects = useRecoilValue(blockMaxSubjects(selectedBlockId));

    const haxMaxSubjectGroups = blockInputIds.length >= Math.min(maxSubjects, MAX_BLOCK_SUBJECT_INPUTS);
    const action = 'Add subject group';

    const plusButtonProps = haxMaxSubjectGroups
      ? {
          tooltip:
            maxSubjects < MAX_BLOCK_SUBJECT_INPUTS
              ? `This block supports a max of ${maxSubjects} subjects`
              : `Blocks support up to ${MAX_BLOCK_SUBJECT_INPUTS} subject input groups`,
          disabled: true,
          buttonLabel: action,
          onClick: noop,
        }
      : {
          onClick,
          buttonLabel: action,
          disabled: false,
        };

    return (
      <HoverIcon type="plus" tabIndex={0} visible={forceVisible || blockInputIds.length === 0} {...plusButtonProps} />
    );
  },
);

/** Historical Pro-Forma Portfolio Warning. */
const HistoricalProFormaSubjectsMessage = withSuspense(null, ({ selectedBlockId }: { selectedBlockId: string }) => {
  const subjects = useRecoilValue(blockRequestSubjects(selectedBlockId));
  const blockType = useRecoilValue(blockSettings(selectedBlockId)).customBlockType;
  const dateRange = useRecoilValue(blockDateRange(selectedBlockId));
  const selectedMetrics = useRecoilValue(blockMetrics(selectedBlockId));
  const hasHistoricalProForma = subjects.some(
    (subject) => !!historicalSubjectAsOfDate(subject, blockType, selectedMetrics, dateRange),
  );

  const message = PRO_FORMA_HISTORICAL_SIMULATION_DATE_BLOCKS.includes(blockType)
    ? 'On this block, historical portfolios’ allocations correspond to the Simulation Start Date set below.'
    : 'Historical portfolios on this block use latest reported allocations “and drift influence”.';

  return hasHistoricalProForma && <Info text={message} />;
});

/** Warning message about subjects being excluded from the selected block. */
const ExcludedSubjectsMessage = withSuspense(null, ({ selectedBlockId }: { selectedBlockId: string }) => {
  const subjects = useRecoilValue(blockRequestSubjects(selectedBlockId));
  const blockType = useRecoilValue(blockSettings(selectedBlockId)).customBlockType;
  const maxSubjects = useRecoilValue(blockMaxSubjects(selectedBlockId));

  if (subjects.length <= maxSubjects) {
    return null;
  }

  const maxSubjectsMsg = isPublicPrivateAssetGrowthBlock(blockType)
    ? 'This block accommodates a maximum of 1 Returns-Based subject and 1 Private Asset subject'
    : `This block accommodates a max of ${maxSubjects} ${plural(maxSubjects, {
        1: 'subject',
        other: 'subjects',
      })}`;

  return (
    <Warning
      text={`${maxSubjectsMsg}. ${subjects.length - maxSubjects} 
        ${plural(subjects.length - maxSubjects, {
          1: ' applicable subject has',
          other: 'applicable subjects have',
        })} been excluded`}
    />
  );
});

/** Warning message about block needing private and public subjects. */
const NeedsPublicPrivateMessage = withSuspense(null, ({ selectedBlockId }: { selectedBlockId: string }) => {
  const limitedSubjects = useRecoilValue(blockLimitedRequestSubjects(selectedBlockId));
  const blockType = useRecoilValue(blockSettings(selectedBlockId)).customBlockType;

  if (!isPublicPrivateAssetGrowthBlock(blockType) || limitedSubjects.length >= 2) {
    return null;
  }
  return <Warning text="Your Subject Group must include one Returns-Based subject and one Private Asset subject." />;
});

/** Warning message about factor trend line chart being optimized for only a single investment or portfolio. */
const FactorTrendLineChartMessage = ({ selectedBlockId }: { selectedBlockId: string }) => {
  const selectedBlock = useRecoilValue(customizedBlock(selectedBlockId));
  const blockSubjectGroups = useRecoilValue(blockSubjectInputGroups(selectedBlockId));

  const firstGroup = blockSubjectGroups[0];
  const firstGroupSubjects = useRecoilValue(firstGroup ? subjectInputGroupSubjects(firstGroup) : constSelector([]));

  const isFactorTrendLineChart =
    selectedBlock.infoGraphicType === 'LINE' && isFactorTrendMetric(selectedBlock.selectedMetrics);
  if (!isFactorTrendLineChart) {
    return null;
  }

  if (blockSubjectGroups.length <= 1 && firstGroupSubjects.length <= 1) {
    return null;
  }

  return <Warning text="For best results select a single factor or a single investment/portfolio." />;
};
