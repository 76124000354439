// This file replicates functionality from ./deprecated-columns in the same directory but modifies all columns to support DataGrid instead of BasicTable

import React from 'react';
import styled, { css } from 'styled-components';
import type { ColDef, ICellRendererParams, IRowNode } from 'ag-grid-community';
import { compact, isNil, last } from 'lodash';

import type { Theme } from 'venn-ui-kit';
import { CellLoader, GetColor, Icon, Tooltip, TooltipPosition } from 'venn-ui-kit';
import {
  assertNotNil,
  capitalizeFirstLetter,
  getFormattedFrequency,
  itemHasNoReturns,
  lowestFrequencyOf,
} from 'venn-utils';
import type { AnalysisRequest, FrequencyEnum, RangeAnalysisResponse, SubjectRangeAnalysis } from 'venn-api';

import type { BulkManageRow, BulkManageUpdateFn } from './types';
import { BulkManageAction } from './types';
import InvestmentActions from '../investment-actions/InvestmentActions';
import { formatDate } from '../manage-data/utils';
import AnalysisPeriodLegend from './AnalysisPeriodLegend';
import { atStartOfDay, getDataColor, getRangeLogic, getRowRangeLogic } from './analysisPeriodUtils';
import ProxiedInvestmentTooltipContent from './ProxiedInvestmentTooltipContent';

const DATE_COLUMN_MIN_WIDTH = 95;

const dataSourceColDef: ColDef<BulkManageRow> = {
  cellRenderer: ({ node }: ICellRendererParams<BulkManageRow>) => {
    if (node?.group || !node?.data) {
      return null;
    }

    const { isLive, dataSource, isIntegration } = node.data;

    return (
      <DefaultCell disabled={isLive === false}>
        {dataSource} {isIntegration && <Icon type="exchange" prefix="fas" />}
      </DefaultCell>
    );
  },
  headerName: 'Data Source',
  flex: 1,
  comparator: bulkManageSortFunction(),
};

const frequencyColDef: ColDef<BulkManageRow, FrequencyEnum | undefined> = {
  cellRenderer: ({ node, value: frequency }: ICellRendererParams<BulkManageRow>) => {
    const rangeLoading = node?.data?.rangeLoading;
    const startDate = node?.data?.startDate;
    const endDate = node?.data?.endDate;

    if (rangeLoading) {
      return <CellLoader />;
    }

    if (!frequency && !node?.data) {
      return null;
    }

    if (frequency) {
      return capitalizeFirstLetter(getFormattedFrequency(frequency));
    }

    if (itemHasNoReturns(startDate, endDate)) {
      return getNoReturnsTooltippedCell('N/A');
    }

    return 'Unknown';
  },
  headerName: 'Frequency',
  field: 'frequency',
  flex: 1,
  sortable: true,
  comparator: bulkManageSortFunction(),
  aggFunc: ({ values }) => lowestFrequencyOf(compact(values)),
};

const getProxyInterpolationColumn = (canEditProxies: boolean, onUpdate: BulkManageUpdateFn): ColDef<BulkManageRow> => {
  return {
    cellRenderer: ({ node, api }) => {
      if (node?.group || !node?.data) {
        return null;
      }

      const { data, rowIndex } = node;

      if (!data.investment) {
        return null;
      }
      const count = api.getDisplayedRowCount();
      const dropdownDirection = count > 10 && rowIndex > count / 2 ? 'up' : 'down';
      return (
        <InvestmentActions
          fund={data.investment}
          context="bulk-management"
          onFundDataUpdated={() => onUpdate(BulkManageAction.FUND_MODIFIED, data, data.investmentId)}
          dropdownVerticalDirection={dropdownDirection}
          isReadOnly={!canEditProxies}
          usePortal
        />
      );
    },
    headerName: 'Proxy',
    sortable: false,
    flex: 2,
  };
};

const startDateColDef: ColDef<BulkManageRow, number | undefined> = {
  headerName: 'Start Date',
  field: 'startDate',
  valueFormatter: ({ value }) => formatDate(value) ?? '',
  flex: 1,
  minWidth: DATE_COLUMN_MIN_WIDTH,
  comparator: bulkManageSortFunction(),
  aggFunc: 'max',
};

const endDateColDef: ColDef<BulkManageRow, number | undefined> = {
  headerName: 'End Date',
  field: 'endDate',
  valueFormatter: ({ value }) => formatDate(value) ?? '',
  flex: 1,
  minWidth: DATE_COLUMN_MIN_WIDTH,
  comparator: bulkManageSortFunction(),
  aggFunc: 'min',
};

type RangeRowValue = BulkManageRow & {
  isAggregated?: boolean | undefined;
};
const getRangeColumn = (
  theme: Theme,
  hasFullHistory: boolean,
  rangeAnalysis?: RangeAnalysisResponse,
  primaryRangeAnalysis?: SubjectRangeAnalysis | null,
): ColDef<BulkManageRow, RangeRowValue> => {
  if (!rangeAnalysis) {
    return {
      headerName: 'Analysis Period',
      cellRenderer: () => null,
      flex: 1,
    };
  }
  const { earliestStart, latestEnd, fullRange, overlap } = getRangeLogic(
    hasFullHistory,
    rangeAnalysis,
    primaryRangeAnalysis,
  );
  return {
    headerName: 'Analysis Period',
    headerComponentParams: AnalysisPeriodLegend,
    flex: 3,
    cellRenderer: ({ node, value }: ICellRendererParams<BulkManageRow, RangeRowValue>) => {
      if (!value) {
        return null;
      }

      if (value.rangeLoading) {
        return <CellLoader />;
      }
      if (itemHasNoReturns(value.startDate, value.endDate)) {
        return getNoReturnsTooltippedCell(<TooltipTrigger />);
      }
      const frequency = assertNotNil(value.frequency);
      const startDate = assertNotNil(atStartOfDay(value.startDate));
      const endDate = assertNotNil(value.endDate);
      const zeroAllocation = value.allocation === 0;

      const { investmentColor, proxyColor } = getDataColor({
        theme,
        isGreyedOut: zeroAllocation,
        secondaryData: node.data?.secondary ?? false,
      });

      const { proxy, investment, extrapolation } = getRowRangeLogic({
        startDate,
        endDate,
        fullRange,
        earliestStart,
        latestEnd,
        proxyStartDate: atStartOfDay(value.proxyStartDate),
        proxyEndDate: value.proxyEndDate,
        extrapolateEndDate: value.extrapolateEndDate,
        extrapolateStartDate: atStartOfDay(value.extrapolateStartDate),
      });
      const dashedRangeBar = frequency === 'QUARTERLY' || frequency === 'YEARLY';
      return (
        <RangeCell>
          {overlap.percentageWidth > 0 ? (
            <OverlapBar widthPercentage={overlap.percentageWidth} leftMarginPercentage={overlap.percentageStart} />
          ) : null}
          <RangeBar
            widthPercentage={investment.percentageWidth}
            leftMarginPercentage={investment.percentageStart}
            dashed={dashedRangeBar}
            color={value.isAggregated ? theme.Colors.HighlightLight : investmentColor}
          >
            <StyledTooltip
              position={TooltipPosition.Top}
              maxWidth={400}
              content={node.data ? <ProxiedInvestmentTooltipContent data={node.data} /> : null}
              usePortal
            >
              {proxy.percentageWidth > 0 && (
                <RangeBar
                  widthPercentage={proxy.percentageWidth}
                  leftMarginPercentage={proxy.percentageStart}
                  color={proxyColor}
                  dashed={dashedRangeBar}
                />
              )}
              {extrapolation.percentageWidth > 0 && (
                <RangeBar
                  widthPercentage={extrapolation.percentageWidth}
                  leftMarginPercentage={extrapolation.percentageStart}
                  color={theme.Colors.DEPRECATED_DataBarColor.LightPaleBlue}
                  dashed={false}
                />
              )}
            </StyledTooltip>
          </RangeBar>
        </RangeCell>
      );
    },
    comparator: bulkManageSortFunction({
      getter: (r) => (r.endDate ?? 0) - (r.startDate ?? 0),
      valueGetter: (v) => (v?.endDate ?? 0) - (v?.startDate ?? 0),
    }),
    valueGetter: ({ data }) => data,
    aggFunc: ({ values, rowNode }) => {
      // TODO(VER-816): try to do all of the aggFunc functions by actually providing data for the rows instead.
      const firstChild = rowNode.childrenAfterGroup?.[0];
      const firstChildStrategyPath: string[] = (firstChild?.data ?? firstChild?.aggData)?.strategyPath ?? [];
      const name = last(firstChildStrategyPath) ?? '';
      const strategyPath = firstChildStrategyPath?.slice(0, -1) ?? [];

      const aggregatedAnalysisRange = values.reduce<RangeRowValue>(
        (acc, v) => {
          if (!v) return acc;

          const maxStartDate = Math.max(
            acc.startDate ?? Number.NEGATIVE_INFINITY,
            v.startDate ?? Number.NEGATIVE_INFINITY,
          );
          const minEndDate = Math.min(acc.endDate ?? Number.POSITIVE_INFINITY, v.endDate ?? Number.POSITIVE_INFINITY);

          return {
            name: acc.name,
            strategyPath: acc.strategyPath,
            rangeLoading: acc.rangeLoading || v.rangeLoading,
            startDate: maxStartDate,
            endDate: minEndDate,
            frequency: lowestFrequencyOf([acc.frequency ?? 'UNKNOWN', v.frequency ?? 'UNKNOWN']) ?? 'UNKNOWN',
            allocation: (acc.allocation ?? 0) + (v.allocation ?? 0),
            isAggregated: true,
          };
        },
        {
          name,
          strategyPath,
        },
      );
      return aggregatedAnalysisRange;
    },
  };
};

export const getBulkManagementColumns = (
  rangeAnalysis: RangeAnalysisResponse | undefined,
  onRowUpdate: BulkManageUpdateFn,
  theme: Theme,
  canEditProxies: boolean,
  hasFullHistory: boolean,
  rangeAnalysisRequest?: Partial<AnalysisRequest>,
  hasAggregation?: boolean,
): ColDef<BulkManageRow>[] => {
  const primaryIndex = rangeAnalysisRequest?.subjects?.findIndex((s) => s.comparisonType === 'PRIMARY');
  const primaryRangeAnalysis = !isNil(primaryIndex) ? rangeAnalysis?.rangeAnalyses[primaryIndex] : null;

  return compact([
    getRangeColumn(theme, hasFullHistory, rangeAnalysis, primaryRangeAnalysis),
    startDateColDef,
    endDateColDef,
    frequencyColDef,
    dataSourceColDef,
    getProxyInterpolationColumn(canEditProxies, onRowUpdate),
  ]).map((colDef) =>
    hasAggregation
      ? colDef
      : {
          ...colDef,
          aggFunc: undefined,
        },
  );
};

/** Simple cell with tooltip indicating content is empty due to no returns */
const getNoReturnsTooltippedCell = (cellContent: React.ReactNode) => {
  return (
    <StyledTooltip usePortal maxWidth={240} content={<div>This item does not have a return stream.</div>}>
      {cellContent}
    </StyledTooltip>
  );
};

/* Convenience method to sort any row by a property while ensuring that excluded or secondary rows are always last. */
export function bulkManageSortFunction<R extends { secondary?: boolean }, V>({
  getter,
  exclude,
  valueGetter = (v: unknown) => {
    if (typeof v === 'number') return v;
    if (isNil(v)) return v;
    return String(v);
  },
}: {
  valueGetter?: (r: V) => number | string | undefined | null;
  getter?: (r: R) => number | string | undefined | null;
  exclude?: (r: R) => boolean;
} = {}) {
  const isSecondary = (r: R) => r.secondary ?? false;
  const compareUsingExclude = (a: R, b: R) => {
    const excludeResult = exclude ? compareExclude(a, b, exclude) : undefined;
    return excludeResult ?? compareExclude(a, b, isSecondary);
  };
  return (initialValueA: V, initialValueB: V, nodeA: IRowNode<R>, nodeB: IRowNode<R>, isDescending: boolean) => {
    const rowA = nodeA.data;
    const rowB = nodeB.data;

    const valueA = valueGetter?.(initialValueA);
    const valueB = valueGetter?.(initialValueB);

    if (!rowA || !rowB) {
      // Secondary and excluded rows should be sorted even after rows with no data
      if (rowA && !rowB && (isSecondary(rowA) || exclude?.(rowA))) {
        return isDescending ? -1 : 1;
      }
      if (rowB && !rowA && (isSecondary(rowB) || exclude?.(rowB))) {
        return isDescending ? 1 : -1;
      }

      // If neither row is secondary or excluded, then we can just compare values if we have them
      if (!isNil(valueA) && !isNil(valueB)) {
        return compare(valueA, valueB);
      }
      return 0;
    }

    const excludeComparisonResult = compareUsingExclude(rowA, rowB);
    if (excludeComparisonResult !== undefined) {
      // Undo the reversal that descending sort causes in order to force excluded rows back to the bottom
      return excludeComparisonResult * (isDescending ? -1 : 1);
    }
    return compare(getter?.(rowA) ?? valueA, getter?.(rowB) ?? valueB);
  };
}

/* Ensures that rows marked as excluded are always after non-excluded rows. */
const compareExclude = <V,>(a: V, b: V, exclude?: (r: V) => boolean) => {
  if (exclude?.(a) && exclude?.(b)) {
    return 0;
  }
  if (exclude?.(a)) {
    return 1;
  }
  if (exclude?.(b)) {
    return -1;
  }
  return undefined;
};

const compare = (a: unknown, b: unknown) => {
  if (a === b) {
    return 0;
  }

  if (typeof a === 'string' && typeof b === 'string') {
    return a.localeCompare(b);
  }

  if (!isNil(a) && isNil(b)) {
    return 1;
  }

  if (isNil(a) && !isNil(b)) {
    return -1;
  }

  if (typeof a === 'number' && typeof b === 'number') {
    return a > b ? 1 : -1;
  }

  return 0;
};

const DefaultCell = styled.div<{ disabled?: boolean }>`
  ${({ disabled }) =>
    disabled &&
    css`
      color: ${GetColor.HintGrey};
    `}
`;

const StyledTooltip = styled(Tooltip)`
  width: 100%;
  height: 100%;
  display: block;
`;

/* Internal cell padding to trigger tooltips to show on no text */
const TooltipTrigger = styled.div`
  height: 16px;
  width: 100%;
`;

const RangeCell = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
`;

const RangeBar = styled.div<{
  widthPercentage: number;
  leftMarginPercentage: number;
  dashed?: boolean;
  color: string;
}>`
  ${({ dashed, widthPercentage, leftMarginPercentage, color }) => `
    background: ${
      dashed ? `repeating-linear-gradient(to right, ${color} 0, ${color} 8px, transparent 8px,transparent 16px)` : color
    };
    height: 8px;
    width: ${widthPercentage * 100}%;
    margin-left: ${leftMarginPercentage * 100 ?? 0}%;
    padding: 0;
    top: calc(50% - 4px);
    position: absolute;
    `}
`;

const OverlapBar = styled.div<{
  widthPercentage: number;
  leftMarginPercentage: number;
}>`
  background-color: ${GetColor.PaleTeal};
  height: 100%;
  width: ${({ widthPercentage }) => widthPercentage * 100}%;
  margin-left: ${({ leftMarginPercentage }) => leftMarginPercentage * 100 ?? 0}%;
  padding: 0;
  top: 0;
  opacity: 0.5;
  border-left: 1px solid ${GetColor.LightGrey};
  border-right: 1px solid ${GetColor.LightGrey};
  position: absolute;
`;
