import { createContext, useContext } from 'react';

export const BlockContext = createContext<{
  blockRef: React.RefObject<HTMLDivElement>;
} | null>(null);
BlockContext.displayName = 'BlockContext';

export const useBlockContext = () => {
  const context = useContext(BlockContext);
  if (!context) {
    throw new Error('useBlockContext must be used within a BlockContext provider');
  }
  return context;
};
