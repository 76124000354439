import React from 'react';
import FiltersProvider from './components/FiltersProvider';
import type { GenericSearchMenuProps, SearchMenuItem } from './types';

import { StyledSelect } from './styled';
import ValueContainer from './components/ValueContainer';
import Menu from './components/Menu';
import Option from './components/Option';
import styled from 'styled-components';
import { MapperMenuList } from './components/MapperMenuList';
import { useSearchSingleSelect } from './useSearchSingleSelect';

type SearchMenuModalProps = {
  onSelected: (selected: SearchMenuItem) => void;
} & Pick<
  GenericSearchMenuProps,
  | 'refreshedStyling'
  | 'autofocus'
  | 'defaultMenuIsOpen'
  | 'privateAssetSearchMode'
  | 'investmentsOnly'
  | 'isOptionDisabled'
  | 'customPlaceholder'
  | 'optionDisabledTooltipContent'
  | 'getOptionValue'
  | 'location'
  | 'displayResultsAsBlock'
>;

const menuComponents = {
  ValueContainer,
  MenuList: MapperMenuList,
  Menu,
  Option,
};

const SearchMenuModal = ({
  displayResultsAsBlock,
  refreshedStyling,
  autofocus,
  defaultMenuIsOpen,
  privateAssetSearchMode,
  investmentsOnly,
  isOptionDisabled,
  customPlaceholder,
  optionDisabledTooltipContent,
  getOptionValue,
  onSelected,
  location,
}: SearchMenuModalProps) => {
  const selectProps = useSearchSingleSelect({
    onSelected,
    location,
    investmentsOnly,
    portfoliosOnly: false,
    privateAssetSearchMode,
    getOptionValue,
    showRecentlyAnalyzed: true,
    forceHideResults: false,
    onBlur: undefined,
    autofocus,
    displayResultsAsBlock,
    defaultMenuIsOpen,
    customPlaceholder,
    refreshedStyling,
    menuComponents,
    isOptionDisabled,
    optionDisabledTooltipContent,
    forceMenuIsOpen: true,
    shouldResetSearchOnSelect: false,
  });
  return <Select {...selectProps} controlShouldRenderValue={false} forceShowSearchIcon closeMenuOnSelect={false} />;
};

const MemoizedSearchMenuModal = React.memo((props: SearchMenuModalProps) => (
  <FiltersProvider>
    <SearchMenuModal {...props} />
  </FiltersProvider>
));

MemoizedSearchMenuModal.displayName = 'MemoizedSearchMenuModal';

export default MemoizedSearchMenuModal;

const Select = styled(StyledSelect)`
  .select__menu {
    position: unset;
    .select__menu-list {
      padding: 0;
    }
  }
  padding: 0 20px;
`;
