import React from 'react';
import { useRecoilValue } from 'recoil';

import { blockBenchmarkConfig, blockBenchmarkType, blockSettings } from 'venn-state';

import { Block } from '../../core';
import { hasDisabledIndividualBenchmark } from 'venn-utils';

interface BenchmarkTypeProps {
  id: string;
}

const benchmarkTypes = {
  COMMON: 'Common Benchmark',
  INDIVIDUAL: 'Individual Benchmarks',
  NONE: 'No Benchmark',
};

export const BenchmarkType = ({ id }: BenchmarkTypeProps) => {
  const customBenchmarkType = useRecoilValue(blockBenchmarkType(id));
  const benchmarkConfig = useRecoilValue(blockBenchmarkConfig(id));
  const blockSetting = useRecoilValue(blockSettings(id));

  if (
    customBenchmarkType === 'NONE' ||
    (customBenchmarkType === 'INDIVIDUAL' && hasDisabledIndividualBenchmark(blockSetting?.customBlockType)) ||
    (customBenchmarkType === 'COMMON' && !benchmarkConfig.subject)
  ) {
    return null;
  }

  return <Block.MetadataItem iconType="arrows-h">{benchmarkTypes[customBenchmarkType]}</Block.MetadataItem>;
};
