import { groupBy, isEmpty, isNil } from 'lodash';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import type { Portfolio, PortfolioPersistStatusEnum } from 'venn-api';
import { Flexbox, GetColor, HISTORICAL_PORTFOLIOS_HELP_HREF, Icon, ItemIcon, ItemType } from 'venn-ui-kit';
import { Dates, isHistoricalPortfolio, LibraryItemType, LibraryTab, navigateToLibrary } from 'venn-utils';
import UniversalUploaderContext from '../../../contexts/universal-uploader';
import { UniversalUploaderFooter } from '../components/page-parts/UniversalUploaderFooter';
import type { MultiPortfolioUploadConfirmationData } from '../types';
import { getFundsWithShortHistory, getPortfolioAllocationRange } from './review/helpers';
import { ScrollableSectionWithStickyHeader } from './review/ScrollableSectionWithStickyHeader';
import { MainUploadWrapper } from './shared/layout';

type MultiPortfolioConfirmationStepProps = Readonly<{
  uploadConfirmationData: MultiPortfolioUploadConfirmationData;
}>;

const STATUSES_IN_ORDER: PortfolioPersistStatusEnum[] = ['UPDATED', 'CREATED', 'SKIPPED', 'FAILED'];

const STATUS_LABELS: Record<PortfolioPersistStatusEnum, string> = {
  UPDATED: 'Existing portfolios updated',
  CREATED: 'New portfolios created',
  SKIPPED: 'Portfolios excluded from upload',
  FAILED: 'Portfolios failed to upload',
};

type PortfolioRowProps = Readonly<{
  portfolio: Portfolio;
  fundsWithShortHistory: string[];
  rightContent?: string;
  allocationRange?: { from: number; to: number };
}>;

const PortfolioRow = ({ portfolio, fundsWithShortHistory, rightContent, allocationRange }: PortfolioRowProps) => {
  const hasWarning = !isEmpty(fundsWithShortHistory);
  const [detailsExpanded, setDetailsExpanded] = useState(false);
  const isHistorical = isHistoricalPortfolio(portfolio);

  return (
    <PortfolioRowWrapper data-testid="portfolio-row-wrapper" hasWarning={hasWarning}>
      <Flexbox direction="row" justifyContent="space-between">
        <Flexbox direction="row" gap={4}>
          <ItemIcon item={isHistorical ? ItemType.HistoricalPortfolio : ItemType.Portfolio} />
          <PortfolioName hasWarning={hasWarning}>{portfolio.name}</PortfolioName>
          {allocationRange && (
            <AllocationRange>{`(${Dates.toDayMonthYear(allocationRange.from)} - ${Dates.toDayMonthYear(allocationRange.to)})`}</AllocationRange>
          )}
        </Flexbox>
        <RightContent>{rightContent}</RightContent>
      </Flexbox>
      {hasWarning && (
        <>
          <Flexbox direction="row" justifyContent="space-between">
            <Flexbox direction="row" gap={4}>
              <WarningIcon type="diamond-exclamation" />
              <span>Returns data for some investments in this portfolio does not cover all allocation dates</span>
            </Flexbox>
            <Flexbox direction="row" gap={8}>
              <Link href={HISTORICAL_PORTFOLIOS_HELP_HREF} target="_blank">
                <Icon type="circle-question" /> Learn more
              </Link>
              <span>|</span>
              <LinkStyledButton onClick={() => setDetailsExpanded((prevState) => !prevState)}>
                {!detailsExpanded ? (
                  <>
                    Show details <Icon type="caret-down" />
                  </>
                ) : (
                  <>
                    Hide details <Icon type="caret-up" />
                  </>
                )}
              </LinkStyledButton>
            </Flexbox>
          </Flexbox>
          {detailsExpanded && (
            <FundsList>
              {fundsWithShortHistory.map((fund) => (
                <li key={fund}>{fund}</li>
              ))}
            </FundsList>
          )}
        </>
      )}
    </PortfolioRowWrapper>
  );
};

const MultiPortfolioConfirmationStep = ({
  uploadConfirmationData: { persistResult, rangeAnalysis },
}: MultiPortfolioConfirmationStepProps) => {
  const resultsByStatus = groupBy(persistResult.portfolioPersistResults, 'status');

  const { closeFlyout } = useContext(UniversalUploaderContext);

  const history = useHistory();

  const openLibrary = () => {
    navigateToLibrary(history, {
      tab: LibraryTab.ReturnsData,
      selectedFilters: {
        itemType: LibraryItemType.PRO_FORMA,
        quickFilters: [],
        tags: [],
      },
      sortByUpdated: true,
    });
  };

  let sectionIndex = -1;

  return (
    <MainUploadWrapper>
      <Scrollable>
        {STATUSES_IN_ORDER.map((status) => {
          const results = resultsByStatus[status];

          if (isNil(results)) {
            return null;
          }

          sectionIndex++;

          return (
            <ScrollableSectionWithStickyHeader
              key={status}
              title={STATUS_LABELS[status]}
              count={results.length}
              groupIndex={sectionIndex}
            >
              {results.map(({ portfolio }, index) => (
                <PortfolioRow
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  portfolio={portfolio}
                  fundsWithShortHistory={getFundsWithShortHistory(portfolio, rangeAnalysis)}
                  allocationRange={getPortfolioAllocationRange(portfolio)}
                  rightContent={status === 'UPDATED' ? 'Entire portfolio overwritten' : undefined}
                />
              ))}
            </ScrollableSectionWithStickyHeader>
          );
        })}
      </Scrollable>
      <UniversalUploaderFooter
        noMargin
        secondaryLabel="Close"
        onStartOver={closeFlyout}
        primaryLabel="Go To Data Library"
        onContinue={() => {
          closeFlyout();
          openLibrary();
        }}
      />
    </MainUploadWrapper>
  );
};

const Scrollable = styled.div`
  flex: 1;
  overflow: auto;
  align-self: stretch;
`;

const PortfolioRowWrapper = styled.div<{ hasWarning: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 12px 24px;
  gap: 4px;
  border-bottom: 1px solid ${GetColor.GreyScale.Grey30};
  ${({ hasWarning }) =>
    hasWarning &&
    css`
      background-color: ${GetColor.DataLibraryColor.Warning};
    `};
`;

const PortfolioName = styled.span<{ hasWarning: boolean }>`
  ${({ hasWarning }) =>
    hasWarning &&
    css`
      font-weight: bold;
    `};
`;

const AllocationRange = styled.span`
  color: ${GetColor.GreyScale.Grey80};
`;

const RightContent = styled.div`
  color: ${GetColor.GreyScale.Grey80};
`;

const WarningIcon = styled(Icon)`
  color: ${GetColor.Orange};
`;

const Link = styled.a`
  font-weight: bold;
  color: ${GetColor.DeepDarkBlue};
`;

const LinkStyledButton = styled.button`
  font-weight: bold;
  color: ${GetColor.DeepDarkBlue};

  :hover,
  :active {
    color: ${GetColor.DeepDarkBlue};
  }
`;

const FundsList = styled.ul`
  padding-inline-start: 20px;
`;

export default MultiPortfolioConfirmationStep;
