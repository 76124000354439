import React from 'react';
import type { Theme } from 'venn-ui-kit';
import {
  CheckboxWrapper,
  ColorUtils,
  EllipsisAutoPositionTooltipSpan,
  ItemIcon,
  ItemType,
  ItemTypePill,
  Link as LinkStyle,
} from 'venn-ui-kit';
import type { Id, SearchResultWithUIState, SearchState } from './types';
import { getId, setAllSelected, toggleItemSelected } from './utils';
import { StyledCell, StyledIdentifier } from './StyledComponents';
import styled, { css } from 'styled-components';
import { formatAllocation, shouldUserUpdateDataForFund, showFactorAnalysisWarning } from 'venn-utils';
import Tag from './Tag';
import OptionalMenu from './OptionalMenu';
import type { PortfoliosContextValue, SORTDIR } from 'venn-components';
import { PercentageSpan } from 'venn-components';
import { emptyRenderer } from './Shared';
import type { ICellRendererParams } from 'ag-grid-community';
import { PrivatesActionMenu } from './PrivatesActionMenu';
import type { PrivatesTableRow } from './privatesColumns';
import { get } from 'lodash';

export const getActionsColumnRenderer =
  (
    updateData: React.Dispatch<React.SetStateAction<SearchState>>,
    sortDir: SORTDIR.ASC | SORTDIR.DESC,
    refetchSearch: (hideLoading?: boolean) => Promise<void>,
    findSimilar: (item: SearchResultWithUIState) => void,
    portfoliosContext: PortfoliosContextValue,
    max: number,
    min: number,
    setIdWithMenuOpen: (id: Id) => void,
  ) =>
  // TODO(VENN-24534): add a display name to this React component
  // eslint-disable-next-line react/display-name
  (item: SearchResultWithUIState) => {
    return (
      <OptionalMenu
        item={item}
        setIdWithMenuOpen={setIdWithMenuOpen}
        refetchSearch={refetchSearch}
        portfoliosContext={portfoliosContext}
        findSimilar={findSimilar}
        updateData={updateData}
      />
    );
  };

export const identifierColumnRenderer = (item: SearchResultWithUIState) => {
  return (
    <StyledIdentifier flex maxWidth={100}>
      {item.symbol || '—'}
    </StyledIdentifier>
  );
};

export const currencyColumnRenderer = ({ currency }: SearchResultWithUIState) => {
  if (!currency) {
    return emptyRenderer();
  }

  return <EllipsisAutoPositionTooltipSpan>{currency}</EllipsisAutoPositionTooltipSpan>;
};

export const getRowStyle = (
  item: SearchResultWithUIState,
  itemIdWithMenuOpen: string | number | undefined,
  theme: Theme,
) => {
  const { Colors } = theme;

  if (itemIdWithMenuOpen === getId(item) || item.selected) {
    return css`
      background-color: ${ColorUtils.hex2rgba(Colors.Primary.Main, 0.1)};
    `;
  }

  if (shouldUserUpdateDataForFund(item)) {
    return css`
      background-color: ${ColorUtils.hex2rgba(Colors.Alert, 0.1)};
      &:hover {
        background-color: ${ColorUtils.hex2rgba(Colors.Error, 0.1)};
      }
    `;
  }

  if (showFactorAnalysisWarning(item)) {
    return css`
      background-color: ${ColorUtils.hex2rgba(Colors.DEPRECATED_DataBarColor.LightPaleGold, 0.2)};
      &:hover {
        background-color: ${ColorUtils.hex2rgba(Colors.DEPRECATED_DataBarColor.LightPaleGold, 0.3)};
      }
    `;
  }

  return css`
    &:hover {
      background-color: ${ColorUtils.hex2rgba(Colors.Primary.Main, 0.1)};
    }
  `;
};

export const menuHeaderRenderer = () => {
  return <StyledCell className="empty-table-cell" />;
};

export const checkboxRenderer =
  // TODO(VENN-24534): add a display name to this React component
  // eslint-disable-next-line react/display-name
  (updateData: React.Dispatch<React.SetStateAction<SearchState>>) => (item: SearchResultWithUIState) => {
    const onChange = () => toggleItemSelected(item, updateData);
    return <CheckboxWrapper className="qa-library-select-item" onChange={onChange} checked={!!item.selected} />;
  };

export const checkboxMenuHeaderRenderer =
  (updateData: React.Dispatch<React.SetStateAction<SearchState>>) =>
  // TODO(VENN-24534): add a display name to this React component
  // eslint-disable-next-line react/display-name
  (label: string, items: SearchResultWithUIState[]) => {
    // don't show it as checked if there are no items
    const allChecked = items.length && items.every((item) => item.selected);
    const onChange = () => setAllSelected(!allChecked, updateData);
    return <CheckboxWrapper className="qa-library-select-all" onChange={onChange} checked={!!allChecked} />;
  };

export const tagRenderer =
  (setIdWithMenuOpen: (ItemId: Id) => void, updateData: React.Dispatch<React.SetStateAction<SearchState>>) =>
  // TODO(VENN-24534): add a display name to this React component
  // eslint-disable-next-line react/display-name
  (item: SearchResultWithUIState) => {
    return <Tag item={item} setIdWithMenuOpen={setIdWithMenuOpen} updateData={updateData} />;
  };

export const getPrivatesActionsMenuRenderer =
  // TODO(VENN-24534): add a display name to this React component
  // eslint-disable-next-line react/display-name
  (updateData: () => void, clearSelectedItems: () => void) => (item: ICellRendererParams<PrivatesTableRow>) => {
    return <PrivatesActionMenu item={item.data} updateData={updateData} clearSelectedItems={clearSelectedItems} />;
  };

const ItemTypeContainer = styled.span`
  display: inline-flex;
  justify-content: space-between;
  padding-top: 4px;
  padding-right: 0;
  align-items: center;
  gap: 6px;
`;

export const itemTypeRenderer = (item: ICellRendererParams<PrivatesTableRow>) => {
  return (
    <ItemTypeContainer>
      <ItemTypePill
        item={item?.data?.itemType === 'PORTFOLIO' ? ItemType.PrivatePortfolio : ItemType.PrivateInvestment}
      />
      <ItemIcon
        item={item?.data?.itemType === 'PORTFOLIO' ? ItemType.PrivatePortfolio : ItemType.PrivateInvestment}
        isUploaded={item?.data?.itemType === 'PORTFOLIO' ? false : item?.data?.userUploaded}
      />
    </ItemTypeContainer>
  );
};

export const ItemNameRenderer = (item: ICellRendererParams<PrivatesTableRow>) => {
  return <LinkStyle>{item?.data?.name ?? '—'}</LinkStyle>;
};

export const percentageValueRenderer = (item: ICellRendererParams<PrivatesTableRow>, column: string) => {
  if (!item.data) {
    return '—';
  }
  const value = get(item.data, column) as unknown as number;
  if (value === undefined || value === null) {
    return '—';
  }
  return <PercentageSpan value={value / 100} />;
};
export const getFormattedValue = (value?: number) => {
  if (value === undefined || value === null) {
    return '—';
  }
  return formatAllocation(value, true, false, undefined, false, 1);
};
export const fundSizeRenderer = (item: ICellRendererParams<PrivatesTableRow>) => {
  return getFormattedValue(item?.data?.fundSize);
};
export const netIrrRenderer = (item: ICellRendererParams<PrivatesTableRow>) => {
  return percentageValueRenderer(item, 'netIrrPct');
};
export const netMultipleRenderer = (item: ICellRendererParams<PrivatesTableRow>) => {
  const value = item?.data?.netMultiple;
  if (value === undefined || value === null) {
    return '—';
  }
  return value.toFixed(2);
};
export const rvpiRenderer = (item: ICellRendererParams<PrivatesTableRow>) => {
  return percentageValueRenderer(item, 'rvpiPct');
};
export const dpiRenderer = (item: ICellRendererParams<PrivatesTableRow>) => {
  return percentageValueRenderer(item, 'dpiPct');
};
export const calledRenderer = (item: ICellRendererParams<PrivatesTableRow>) => {
  return percentageValueRenderer(item, 'calledPct');
};
