import { Banner } from './MultiHistoricalReviewComponents.RightPane.Banner';
import { Content } from './MultiHistoricalReviewComponents.RightPane.Content';
import { Root } from './MultiHistoricalReviewComponents.RightPane.Root';
import { Footer } from './MultiHistoricalReviewComponents.Footer';

export const MultiHistoricalReviewComponents = {
  RightPane: {
    Root,
    Banner,
    Content,
  },
  Footer,
};
