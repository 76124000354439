import HeaderCellRenderer from '../components/grid/renderers/HeaderCellRenderer';
import MetricNameHeaderRenderer from '../components/grid/renderers/MetricNameHeaderRenderer';
import ValueCellRenderer from '../components/grid/renderers/ValueCellRenderer';
import { SubjectRowCellRenderer } from '../components/grid/renderers/SubjectRowCellRenderer';
import {
  VALUE_CELL_RENDERER,
  SUBJECT_HEADER_RENDERER,
  SUBJECT_ROW_CELL_RENDERER,
  METRIC_NAME_HEADER_RENDERER,
  DUAL_SUBJECT_HEADER_RENDERER,
  ALLOCATION_CELL_RENDERER,
  MAPPED_PRO_FORMA_PORTFOLIO_NODE_CELL_RENDERER,
  MAPPED_HISTORICAL_PORTFOLIO_NODE_CELL_RENDERER,
} from '../customAnalysisContants';
import { useMemo } from 'react';
import type { ColDef, GridOptions, HeaderClassParams } from 'ag-grid-community';
import { clone, compact, maxBy, merge } from 'lodash';
import {
  HEADER_LAST_COL_CLASS,
  HEADER_LAST_COL_OF_COL_GROUP_CLASS as HEADER_LAST_COL_OF_GROUP_CLASS,
} from '../components/grid/AgGridThemeOverrides';
import { HeaderDualSubjectCellRenderer } from '../components/grid/renderers/HeaderDualSubjectCellRenderer';
import { AllocationCellRenderer } from '../components/grid/renderers/AllocationCellRenderer';
import { MappedProFormaPortfolioNodeCellRenderer } from '../components/grid/renderers/mapped-portfolio-node-cell-renderer/MappedProFormaPortfolioNodeCellRenderer';
import type { DataGridProps } from 'venn-components';
import { MappedHistoricalPortfolioNodeCellRenderer } from '../components/grid/renderers/mapped-portfolio-node-cell-renderer/MappedHistoricalPortfolioNodeCellRenderer';

export const baseHeaderClassFn = <TData>(props: HeaderClassParams<TData>): string[] => {
  // The DOM structure is such that we can't determine from selectors alone if a header cell is the last cell in a column group
  // so instead we must use this javascript to check that.
  // Similarly, we have to use the left setting of the column and not just the ordering in the array, because the array may be
  // non-deterministically reordered versus what is on the page.
  const rightMostColumn = maxBy(props.api.getAllDisplayedColumns(), (c) => c.getLeft());
  const isRightMostColumn = props.column === rightMostColumn;

  const parent = props.column?.getParent();
  const rightMostColumnInGroup = maxBy(parent?.getDisplayedChildren(), (c) => c.getLeft());
  const isRightMostColumnInGroup = props.column === rightMostColumnInGroup;

  return compact([
    isRightMostColumnInGroup && HEADER_LAST_COL_OF_GROUP_CLASS,
    isRightMostColumn && HEADER_LAST_COL_CLASS,
  ]);
};

/** Grid props common to all kinds of grids. */
const baseStaticProps = (): Partial<GridOptions> & Partial<DataGridProps> => {
  return {
    components: {
      [SUBJECT_HEADER_RENDERER]: HeaderCellRenderer,
      [DUAL_SUBJECT_HEADER_RENDERER]: HeaderDualSubjectCellRenderer,
      [METRIC_NAME_HEADER_RENDERER]: MetricNameHeaderRenderer,
      [SUBJECT_ROW_CELL_RENDERER]: SubjectRowCellRenderer,
      [VALUE_CELL_RENDERER]: ValueCellRenderer,
      [ALLOCATION_CELL_RENDERER]: AllocationCellRenderer,
      [MAPPED_PRO_FORMA_PORTFOLIO_NODE_CELL_RENDERER]: MappedProFormaPortfolioNodeCellRenderer,
      [MAPPED_HISTORICAL_PORTFOLIO_NODE_CELL_RENDERER]: MappedHistoricalPortfolioNodeCellRenderer,
    },
    defaultColDef: {
      resizable: false,
      wrapText: true,
      autoHeaderHeight: true,
      wrapHeaderText: true,
      // Hide column menu
      suppressHeaderMenuButton: true,
      suppressMovable: true,
      headerClass: baseHeaderClassFn,
      cellDataType: false,
    },
    domLayout: 'autoHeight' as const,
    suppressContextMenu: true,
    suppressDragLeaveHidesColumns: true,
    noCustomStickyHeader: true,
    onColumnResized: () => {
      // update RelativePortal instances position
      window.dispatchEvent(new Event('resize'));
    },
  };
};

/** Grid props for tree grids that don't depend on any inputs. */
const baseStaticTreeGridProps = (): Partial<GridOptions> & Partial<DataGridProps> => ({
  ...baseStaticProps(),
  animateRows: false,
  groupDefaultExpanded: -1,
  treeData: true,
  getDataPath: (data) => data.path,
});

/** Grid props for non-tree grids that don't depend on any inputs. */
const baseStaticGridProps = (): Partial<GridOptions> & Partial<DataGridProps> => ({
  ...baseStaticProps(),
  animateRows: true,
});

/**
 * Memoized/optimized function to generate grid props for tree grids using our common stylings and behavior
 * that should be shared amongst all grid blocks in Studio and Report Lab.
 *
 * Takes additional props that are merged with the baseline props.
 */
export const useCommonTreeGridProps = <T>(extraDefaultColDef?: ColDef<T>): GridOptions<T> => {
  return usePropsHelper<T>(
    useMemo(() => baseStaticTreeGridProps(), []),
    extraDefaultColDef,
  );
};

/**
 * Memoized/optimized function to generate grid props for non-tree grids using our common stylings and behavior
 * that should be shared amongst all grid blocks in Studio and Report Lab.
 *
 * Takes additional props that are merged with the baseline props.
 */
export const useCommonGridProps = <T>(extraDefaultColDef?: ColDef<T>): GridOptions<T> & Partial<DataGridProps> => {
  return usePropsHelper<T>(
    useMemo(() => baseStaticGridProps(), []),
    extraDefaultColDef,
  );
};

function usePropsHelper<T>(initialProps: GridOptions<T>, extraDefaultColDef: ColDef<T> | undefined): GridOptions<T> {
  const gridOptions = useMemo(() => {
    // Shallow clone the base props so that any changes made here are reversible and don't affect other components.
    const basePropsCopy = clone(initialProps);

    // Mutate fields of basePropsCopy one at a time (as opposed to a full merge), so that we don't change unrelated fields.
    // For example, change the defaultColDef field without changing the components field.
    if (extraDefaultColDef) {
      basePropsCopy.defaultColDef = merge({}, basePropsCopy.defaultColDef, extraDefaultColDef);
    }

    return basePropsCopy;
  }, [extraDefaultColDef, initialProps]);

  // One thing we could consider is deep freezing the grid options when in dev mode. RecoilJs does something like this to
  // help ensure clients don't modify objects they aren't supposed to modify. But we don't have a deepFreeze function, so
  // it seems like it isn't worth the effort to do that.
  return gridOptions;
}
