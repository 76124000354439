import React, { useRef } from 'react';
import { useAppPrintMode } from '../../print';
import { useRecoilValue } from 'recoil';
import {
  blockDisplayHeader,
  blockFonts,
  blockLimitedRequestSubjects,
  isReportState,
  customizedBlock,
} from 'venn-state';
import { Numbers } from 'venn-utils';
import { portfolioStrategyBreakdownParser as makePortfolioBreakdownRowData } from '../../studio-blocks/logic/parsers';
import { Block } from '../components/core';
import { useTheme } from 'styled-components';
import { getColorOnIndex, type Theme } from 'venn-ui-kit';
import { type PortfolioBreakdownRowData, type PortfolioRowData } from 'venn-components';
import type { VennSeriesPieDataOptions } from '../../studio-blocks/logic/pieChartParsers';
import { createVennPieChartConfig } from '../../charts/analysis-charts/chart-config-logic';
import { StudioHighchart } from '../../highchart/Highchart';
import type HighchartsReact from 'highcharts-react-official';
import DownloadableContentBlock from '../../content-block/DownloadableContentBlock';
import { useFilteredRequests } from '../utils/hooks/use-filtered-requests';
import { DefaultSubjects } from '../components/secondary/subjects/default-subjects';
import { useBlockContext } from '../contexts/block-context';
import { WATERMARK_POSITION_TOP } from '../../studio-blocks/customAnalysisContants';

interface PortfolioBreakdownBlockProps {
  id: string;
}
export const PortfolioBreakdownBlock = ({ id }: PortfolioBreakdownBlockProps) => {
  const { blockRef } = useBlockContext();
  const chartRef = useRef<HighchartsReact.RefObject>(null);
  const legendFont = useRecoilValue(blockFonts.blockChartLegend(id));
  const theme = useTheme();
  const { inPrintModeOrReportLab } = useAppPrintMode();
  const block = useRecoilValue(customizedBlock(id));
  const title = useRecoilValue(blockDisplayHeader(id));
  const isReport = useRecoilValue(isReportState);
  const { analyses } = useFilteredRequests(id);
  const subjects = useRecoilValue(blockLimitedRequestSubjects(id));

  const chartData = makePieChartData(makePortfolioBreakdownRowData(block, analyses), theme);
  const options = createVennPieChartConfig(
    subjects[0].name,
    chartData,
    theme,
    'percent',
    undefined,
    legendFont.fontSizePt,
    inPrintModeOrReportLab,
    false,
    false,
  );

  return (
    <Block.Root id={id} draggable={isReport}>
      <Block.Header>
        <Block.HeaderTopContainer inPrintMode={inPrintModeOrReportLab}>
          <Block.Title>
            <Block.Headline id={id} isReport={isReport}>
              {title}
            </Block.Headline>
            {block.subHeader && <Block.SubHeadline id={id}>{block.subHeader}</Block.SubHeadline>}
          </Block.Title>
        </Block.HeaderTopContainer>

        <DefaultSubjects
          id={id}
          metric={block.selectedMetrics[0] || ''}
          relativeToBenchmark={block.relativeToBenchmark}
          forceShowSubjects
        />
      </Block.Header>
      <Block.Content id={id}>
        <DownloadableContentBlock
          header={null}
          noBorder
          floatingOptions
          downloadable={{
            png: true,
            options: {
              fileName: block.header,
              watermark: { top: WATERMARK_POSITION_TOP, right: 20 },
            },
            tracking: {
              description: 'BUILDER',
              relativeToBenchmark: block.relativeToBenchmark,
              userUploaded: false,
              subjectType: undefined,
              subjectId: undefined,
            },
            disabled: chartData ? chartData.length === 0 : true,
            target: blockRef,
          }}
        >
          <StudioHighchart options={options} blockId={id} chartRef={chartRef} style={{ height: '100%' }} />
        </DownloadableContentBlock>
      </Block.Content>
    </Block.Root>
  );
};

const makePieChartData = (
  rowData: PortfolioRowData<PortfolioBreakdownRowData>[],
  theme: Theme,
): VennSeriesPieDataOptions[] =>
  rowData.map((row, index) => ({
    name: row.label,
    y: row.value[0].weight,
    legend: `${row.label} ${Numbers.safeFormatPercentage(row.value[0].weight)}`,
    color: getColorOnIndex(theme.Colors, index),
  }));
