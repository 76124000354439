import React from 'react';

import { type StudioRequestSubject } from 'venn-state';
import { Icon } from 'venn-ui-kit';

import { Block } from '../../core';
import { Name } from '../legend';
import { convertRequestSubjectToItemType } from '../../../../analysis';

interface InvestmentSubjectProps {
  id: string;
  subject: StudioRequestSubject;
}

export const InvestmentSubject = ({ id, subject }: InvestmentSubjectProps) => {
  const props =
    subject.portfolioComparisonType === 'SAVED'
      ? { customIcon: <Icon type="clock-rotate-left" /> }
      : { iconType: convertRequestSubjectToItemType(subject) };

  return (
    <Block.SubjectsItem {...props}>
      <Name id={id} subject={subject} />
    </Block.SubjectsItem>
  );
};
