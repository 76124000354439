import React, { memo, useMemo } from 'react';

import { useCommonGridProps } from '../../../../studio-blocks/logic/columnUtils';
import { DATA_TYPE_EXCEL_STYLES } from '../../../../studio-blocks';
import { analysisGridDefaultColDefOverrides } from '../../../../studio-blocks/components/grid/AnalysisGrid';
import ExportableGrid, {
  type ExportableAnalysisGridProps,
} from '../../../../studio-blocks/components/grid/ExportableGrid';
import { GridWrapper } from '../../../../studio-blocks/common';
import { isReadyToPivotData } from '../../../../studio-blocks/logic/typeUtils';
import { pivotGridData } from '../../../../studio-blocks/logic/pivotGridData';

interface BaseGridProps extends ExportableAnalysisGridProps {
  isReport: boolean;
  pivoted?: boolean;
}

export const BaseGrid = memo(function InternalBaseGrid({
  selectedRefId,
  rowData: givenRowData,
  columnDefs: givenColumnDefs,
  isCompact,
  isReport,
  inPrintMode,
  exportMetaData,
  exportable,
  pivoted,
}: BaseGridProps) {
  const { rowData, columnDefs } = useMemo(
    () =>
      pivoted && isReadyToPivotData(givenRowData ?? [])
        ? pivotGridData(givenRowData ?? [], givenColumnDefs ?? [])
        : { rowData: givenRowData, columnDefs: givenColumnDefs },
    [givenColumnDefs, givenRowData, pivoted],
  );
  const commonGridProps = useCommonGridProps(analysisGridDefaultColDefOverrides);

  return (
    <GridWrapper isReport={isReport}>
      <ExportableGrid
        {...commonGridProps}
        selectedRefId={selectedRefId}
        rowData={rowData}
        columnDefs={columnDefs}
        isCompact={isCompact}
        inPrintMode={inPrintMode}
        exportMetaData={exportMetaData}
        exportable={exportable}
        excelStyles={DATA_TYPE_EXCEL_STYLES}
      />
    </GridWrapper>
  );
});
