import styled from 'styled-components';
import { GetColor, Headline2, Shimmer } from 'venn-ui-kit';

export const ManageDataTitle = styled(Headline2)`
  display: flex;
  font-weight: bold;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const PageContentContainer = styled.div`
  min-height: 200px;
  margin-top: 40px;
`;

export const SummaryBaseWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
  padding: 10px 60px;
`;

export const SummaryWrapper = styled(SummaryBaseWrapper)`
  background-color: ${GetColor.PaleGrey};
`;

export const FlexGroup = styled.div`
  display: flex;
  align-items: center;
`;

export const SummaryItem = styled.span`
  &:not(:last-child) {
    padding-right: 40px;
  }
  font-size: 12px;
  label {
    display: block;
  }
`;

export const Summary = styled.div`
  display: flex;
  padding: 4px 0;
  height: 34px;
`;

export const SummaryPlaceholder = styled(Summary)`
  ${Shimmer}
  width: 30%;
`;

export const PlaceholderWrapper = styled.div`
  margin: 10px 60px 0;
`;

export const StyledMetaDataFields = styled.div`
  max-width: 600px;
  font-size: 14px;
  margin: 0 auto;
`;
