import type { Analysis } from 'venn-api';
import type { AnalysisRequest } from '../../studio-blocks';
import type { StudioAnalysisRequest } from 'venn-state';

export const filterRequests = (
  allRequests: AnalysisRequest[],
  allAnalyses: (Analysis | undefined)[][],
  isTreeBlock: boolean,
  hideBenchmark?: boolean,
) => {
  const shouldKeepRequest = (req?: StudioAnalysisRequest) =>
    req && !(isTreeBlock && req.subject.subjectType === 'INVESTMENT') && !(req.isBenchmark && hideBenchmark);

  const filterPredicate = (_foo: unknown, index: number) => shouldKeepRequest(allRequests[index]);
  const requests = allRequests.filter(filterPredicate);
  const analyses = allAnalyses.filter(filterPredicate);

  return {
    requests,
    analyses,
  };
};
