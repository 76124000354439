import React from 'react';
import { useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import type { ComputedInvestmentResidual } from 'venn-api';
import type { InvestmentOverrideType } from 'venn-state';
import { selectedInvestmentOverrideTypeAtom } from 'venn-state';
import { assertExhaustive } from 'venn-utils';
import ForecastsMenuLauncher from './ForecastsMenuLauncher';

import ForecastsMenuLink from './ForecastsMenuLink';

interface ForecastModalLauncherSpanButtonWithTooltipProps {
  onResidualForecastUpdated: (fundId?: string) => void;
  onOpenResidualForecastTarget?: ComputedInvestmentResidual;
  /** Purpose for opening the modal. Used for tracking */
  ctaPurpose: string;
  isReadOnly: boolean;
  overrideType: InvestmentOverrideType;
  useTooltipPortal?: boolean;
}

const ForecastModalLauncherSpanButton = ({
  overrideType,
  onOpenResidualForecastTarget,
  useTooltipPortal,
  ...rest
}: ForecastModalLauncherSpanButtonWithTooltipProps) => {
  const tooltip = (() => {
    switch (overrideType) {
      case 'residual':
        return 'Edit Residual Forecast';
      case 'return':
        return 'Edit Return Forecast';
      default:
        throw assertExhaustive(overrideType, 'unexpected overrideType');
    }
  })();

  const operation = onOpenResidualForecastTarget?.isManualOverride ? 'EDIT' : 'CREATE';

  const setOverrideType = useSetRecoilState(selectedInvestmentOverrideTypeAtom(operation));

  return (
    <ResidualForecastButtonWrapper>
      <ForecastsMenuLauncher
        iconOnly
        onOpenResidualForecastTarget={onOpenResidualForecastTarget}
        renderCustomMenuOpener={({ onClick, ...menuOpenerProps }) => (
          <ForecastsMenuLink
            tooltip={tooltip}
            usePortal={useTooltipPortal}
            {...menuOpenerProps}
            onClick={() => {
              onClick();
              setOverrideType(overrideType);
            }}
          />
        )}
        {...rest}
      />
    </ResidualForecastButtonWrapper>
  );
};

export default ForecastModalLauncherSpanButton;

const ResidualForecastButtonWrapper = styled.div`
  margin-left: 2px;

  > div {
    margin-top: -2px;
  }

  span {
    background-color: transparent;
    font-size: 15px;
  }
`;
