import type { GetRecoilValue } from 'recoil';
import { atomFamily, atom, selector, waitForAll, selectorFamily } from 'recoil';
import type { BlockId, StudioRequestSubject, Subject } from '../../types';
import {
  noDuplicatesValidatorEffect,
  truthyKeyValidatorEffect,
  maxLengthValidatorEffect,
  validateSetEffect,
} from '../../../effects/validateSetEffect';
import type { ComputedDateRange, Nominal } from 'venn-utils';
import { MAX_INPUTS_PER_KIND, assertExhaustive, getRandomId } from 'venn-utils';
import { getNextInputName } from './getNextInputName';
import type { DateRange } from 'venn-ui-kit';
import { analysisSubjectQuery, benchmarkRequestSubjects, blockSubjects, requestSubjects } from '../subjects';
import { compact, uniq } from 'lodash';
import type { DateRangeResolutionEnum } from 'venn-api';
import { blockBenchmarkSubjects } from '../benchmark';
import { recoilGetSubjectGroupRange } from '../../async';
import { benchmarkInputs, benchmarkInputSubject, benchmarkInputType } from './benchmarkInput';
import { subjectInputGroups, subjectInputGroupSubjects } from './subjectInput';
import { allBlockIdsState } from '../../grid';
import { resetOnStudioReset } from '../../../effects/signalEffects';
import { getDefaultRange } from '../../consts';

// TODO(will, collin): IMV2 P0 implement an exported recoil callback for deleting a date range input

export type DateRangeInputId = Nominal<string, 'DateRangeInputId'>;
export const getNewDateRangeInputId = getRandomId as () => DateRangeInputId;

/**
 * Consistency setting for a date range input that may be applied to many blocks.
 *
 * Global consistency: date range is computed based on all subjects in the view, regardless of their usage.
 * Block consitency: date range is computed independently for each block, based on the subjects applied to that block.
 *
 * A third mode of consistency that is not implemented would be input-level consistency: date range is computed using all subjects applied to all blocks
 * that the particular date range input is attached to.
 *
 * String literals used for easy serialization and deserialization.
 */
export type DateRangeConsistency = (typeof DATE_RANGE_CONSISTENCIES)[number];
const DATE_RANGE_CONSISTENCIES = ['input_level', 'block_level'] as const;

export const DEFAULT_CONSISTENCY: DateRangeResolutionEnum = 'GLOBAL';
export const DEFAULT_DATE_RANGE: Readonly<DateRange> = { period: 'full' };

export const nextDateRangeInputNameState = selector<string>({
  key: 'nextDateRangeInputNameState',
  get: ({ get }) => {
    const allIds = get(dateRangeInputsState);
    if (allIds.length === 0) {
      return 'Default Date Range';
    }

    const allNames = get(waitForAll(allIds.map((id) => dateRangeInputNameState(id))));
    return getNextInputName('Untitled Date Range ', allNames);
  },
  cachePolicy_UNSTABLE: {
    // Only the most recent is used; don't need to cache all historical name states created during this session.
    eviction: 'most-recent',
  },
});

/**
 * The user defined name for a date range input.
 */
export const dateRangeInputNameState = atomFamily<string, DateRangeInputId>({
  key: 'dateRangeInputNameState',
  effects: (key) => [truthyKeyValidatorEffect(key)],
});

/**
 * All date range inputs (by ID) for a particular view, by insertion or user-defined order.
 */
export const dateRangeInputsState = atom<DateRangeInputId[]>({
  key: 'dateRangeInputsState',
  default: [],
  effects: [maxLengthValidatorEffect(MAX_INPUTS_PER_KIND), noDuplicatesValidatorEffect, resetOnStudioReset],
});

/** The date range setting stored in a date range input. */
export const dateRangeInputDateRangeState = atomFamily<DateRange, DateRangeInputId>({
  key: 'dateRangeInputDateRangeState',
  effects: (key) => [
    truthyKeyValidatorEffect(key),
    validateSetEffect((range) => (!range.from && !range.to && !range.period ? 'date range is completely empty' : null)),
  ],
});

/** The user-defined consistency setting stored in a date range input. */
export const dateRangeInputConsistencyState = atomFamily<DateRangeResolutionEnum, DateRangeInputId>({
  key: 'dateRangeInputConsistencyState',
  effects: (key) => [truthyKeyValidatorEffect(key)],
});

/**
 * For some {@link DateRangeConsistency} settings, every block the input is applied to will use the same computed date range.
 * In that case, this selector returns the computed date range, computed using the relevant subjects.
 *
 * Otherwise, undefined will be returned because the consistency setting dictates that each block can have a different computed date range.
 */
export const dateRangeInputBlockComputedRange = selectorFamily<
  ComputedDateRange,
  { inputId: DateRangeInputId; blockId: BlockId }
>({
  key: 'dateRangeInputBlockComputedRange',
  get:
    ({ inputId, blockId }) =>
    ({ get }) => {
      const consistencySetting = get(dateRangeInputConsistencyState(inputId));
      switch (consistencySetting) {
        case 'BLOCK':
          return computeBlockRange(get, blockId);
        case 'INPUT':
          return computeInputRange(get, inputId);
        case 'GLOBAL':
          return get(fullGlobalDateRange);
        default:
          throw assertExhaustive(consistencySetting);
      }
    },
});

/**
 * Selector for the blockless computed range for the date range input.
 *
 * Global in the sense that this is not specific to a block, but not global in the sense that it takes into account all subjects in the entire view.
 * Exact behavior depends on the {@link dateRangeInputConsistencyState} setting.
 * Some consistency settings may not support a block-less range, and so will return undefined.
 */
export const dateRangeInputGlobalComputedRange = selectorFamily<ComputedDateRange, DateRangeInputId>({
  key: 'dateRangeInputGlobalComputedRange',
  get:
    (inputId) =>
    ({ get }) => {
      const consistencySetting = get(dateRangeInputConsistencyState(inputId));
      if (consistencySetting === 'BLOCK') {
        /**
         * Block level consistency allows the full factor range
         * Frequency is daily, since we want to allow maximal granularity in that case.
         */
        return getDefaultRange();
      }
      if (consistencySetting === 'INPUT' || consistencySetting === 'GLOBAL') {
        return get(fullGlobalDateRange);
      }
      throw assertExhaustive(consistencySetting);
    },
});

/**
 * The date range input applied to a block, or undefined if none are applied.
 * None might be applied when no date ranges exist at all.
 */
export const blockDateRangeInputState = atomFamily<DateRangeInputId | undefined, BlockId>({
  key: 'blockDateRangeInputState',
  effects: (key) => [truthyKeyValidatorEffect(key)],
  default: undefined,
});

/**
 * Selector for the full overlapping date range of all subjects in the view.
 *
 * Includes all common benchmarks and individual benchmarks (if set in a benchmark input).
 */
export const fullGlobalDateRange = selector({
  key: 'fullGlobalDateRange',
  get: ({ get }) => {
    const allSubjectGroups = get(subjectInputGroups);
    const allSubjects = uniq(get(waitForAll(allSubjectGroups.map(subjectInputGroupSubjects))).flat());
    const subjectRequests = get(requestSubjects(allSubjects));

    const allBenchmarks = get(benchmarkInputs);
    const benchmarkSubjects = compact(uniq(get(waitForAll(allBenchmarks.map(benchmarkInputSubject))).flat()));
    const benchmarkRequests: StudioRequestSubject[] = get(benchmarkRequestSubjects(benchmarkSubjects));

    const hasIndividualBenchmarks = get(waitForAll(allBenchmarks.map(benchmarkInputType))).some(
      (type) => type === 'INDIVIDUAL',
    );
    const individualBenchmarkRequests = hasIndividualBenchmarks ? getIndividualBenchmarks(get, allSubjects) : [];

    return recoilGetSubjectGroupRange(
      get,
      [...subjectRequests, ...benchmarkRequests, ...individualBenchmarkRequests].filter((s) => !s.private),
    );
  },
});

function getSubjectRequests(get: GetRecoilValue, blockIds: BlockId[]) {
  const subjects = uniq(get(waitForAll(blockIds.map(blockSubjects))).flat());
  const subjectRequests: StudioRequestSubject[] = get(requestSubjects(subjects));
  return subjectRequests;
}

function getBenchmarkRequests(get: GetRecoilValue, blockIds: BlockId[]) {
  const benchmarkSubjects = uniq(get(waitForAll(blockIds.map(blockBenchmarkSubjects))).flat());
  const benchmarkRequests: StudioRequestSubject[] = get(benchmarkRequestSubjects(benchmarkSubjects));
  return benchmarkRequests;
}

function getIndividualBenchmarks(get: GetRecoilValue, allSubjects: Subject[]) {
  const analysisSubjects = get(waitForAll(allSubjects.map((s) => analysisSubjectQuery(s))));
  const benchmarks = compact(analysisSubjects.map((subject): Subject | undefined => subject.activeBenchmark));
  return get(requestSubjects(benchmarks));
}

function computeInputRange(get: GetRecoilValue, inputId: DateRangeInputId) {
  const allBlocks = get(allBlockIdsState);
  const blocksIdsWithThisInput = allBlocks.filter((blockId) => get(blockDateRangeInputState(blockId)) === inputId);

  const subjectRequests = getSubjectRequests(get, blocksIdsWithThisInput);
  const benchmarkRequests = getBenchmarkRequests(get, blocksIdsWithThisInput);

  return recoilGetSubjectGroupRange(get, [...subjectRequests, ...benchmarkRequests]);
}

export function computeBlockRange(get: GetRecoilValue, blockId: string) {
  const subjectRequests = getSubjectRequests(get, [blockId]);
  const benchmarkRequests = getBenchmarkRequests(get, [blockId]);

  return recoilGetSubjectGroupRange(get, [...subjectRequests, ...benchmarkRequests]);
}
