export enum ItemType {
  Benchmark = 'Benchmark',
  Portfolio = 'Portfolio',
  Strategy = 'Strategy',
  Investment = 'Investment',
  File = 'File',
  Compare = 'COMPARE',
  Analysis = 'ANALYSIS',
  Custom = 'CUSTOM',
  Studio = 'ASSEMBLY',
  Tag = 'Tag',
  // In comparison/studio
  CommonBenchmark = 'CommonBenchmark',
  StudioTearsheet = 'TEARSHEET',
  StudioReport = 'REPORT',
  ReportTemplate = 'REPORT_TEMPLATE',
  TearsheetTemplate = 'TEARSHEET_TEMPLATE',
  EditTemplate = 'EDIT_TEMPLATE',
  Article = 'ARTICLE',
  PeerGroup = 'PEER_GROUP',
  PrivatePortfolio = 'Private Portfolio',
  PrivateInvestment = 'Private Investment',
  SavedSearch = 'Saved Search',
  HistoricalPortfolio = 'Historical Portfolio',
}
