import React, { useContext, useState, useRef, useMemo, useEffect } from 'react';
import styled, { css, ThemeContext } from 'styled-components';
import { Link } from 'react-router-dom';
import {
  GetColor,
  Tooltip,
  ItemIcon,
  getItemColor,
  ItemType,
  Icon,
  TooltipPosition,
  type VennColors,
} from 'venn-ui-kit';
import { Numbers, Routes } from 'venn-utils';
import {
  ITEM_WIDTH,
  TOOLTIP_WIDTH,
  getLargestNumber,
  getWaterfallStartPositions,
  getRelativeSize,
  TOOLTIP_ROW_HEIGHT,
  isSpecialRows,
  getItemTypeFromHeader,
} from './factorBarUtils';
import type { HorizontalBarsProps, BarType, HorizontalBarsItem, HeaderItem } from './types';
import DynamicBar, { backgroundStyles } from './DynamicBar';
import { FactorDescriptionTooltip } from '../../../../factor-chart';
import { debounce, isNil } from 'lodash';

const zeroPosition: TooltipProps = { x: undefined, y: undefined, item: undefined, benchmarkItem: undefined };

type SetTooltipPositionTrigger = 'enter' | 'leave';

interface TooltipProps {
  x?: number;
  y?: number;
  item?: HorizontalBarsItem;
  benchmarkItem?: HorizontalBarsItem;
}

export const HorizontalBars = ({
  headers,
  items,
  isWaterfall,
  format,
  fontSize,
  headerFontSize,
  legendFontSize,
}: HorizontalBarsProps) => {
  const largestNumber = getLargestNumber(items, !!isWaterfall);
  const formatter = format === 'PERCENTAGE' ? Numbers.safeFormatPercentage : Numbers.safeFormatNumber;
  const sumItems = getWaterfallStartPositions(items);

  const [tooltipInfo, setTooltipInfo] = useState<TooltipProps>(zeroPosition);
  const blockElementRef = useRef<HTMLDivElement>(null);

  const theme = useContext(ThemeContext);

  const getTooltipPosition = (
    event: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>,
    item: HorizontalBarsItem,
  ) => {
    if (!blockElementRef.current) {
      return null;
    }
    const dimension = blockElementRef.current.getBoundingClientRect();
    // Make sure the tooltips is above current row
    const padding = 6;
    const y =
      (event.target as HTMLTableDataCellElement).getBoundingClientRect().top -
      dimension.top -
      TOOLTIP_ROW_HEIGHT * (item.values.length + 1) -
      padding;
    let x = event.clientX - dimension.left - TOOLTIP_WIDTH / 2;
    if (dimension.right - event.clientX < TOOLTIP_WIDTH / 2) {
      x -= TOOLTIP_WIDTH / 2;
    } else if (x < TOOLTIP_WIDTH / 2) {
      x += TOOLTIP_WIDTH / 2;
    }
    return { x, y, item };
  };

  const setTooltipPositionDebounced = useMemo(() => {
    const setTooltipPosition = (
      trigger: SetTooltipPositionTrigger,
      event?: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>,
      item?: HorizontalBarsItem,
    ) => {
      if (!blockElementRef.current) {
        return;
      }

      const tooltipPosition = trigger === 'enter' && event && item ? getTooltipPosition(event, item) : zeroPosition;

      if (tooltipPosition) {
        setTooltipInfo(tooltipPosition);
      }
    };
    return debounce(setTooltipPosition, 15);
  }, []);

  useEffect(() => {
    return () => {
      setTooltipPositionDebounced.cancel();
    };
  }, [setTooltipPositionDebounced]);

  const getKey = (header: HeaderItem | undefined) => {
    return `${header?.id} ${header?.isBenchmark} ${header?.portfolioComparisonType}`;
  };

  return (
    <Wrapper fontSize={fontSize} ref={blockElementRef}>
      <Legend fontSize={legendFontSize}>{getLegends(items, theme.Colors)}</Legend>
      <Table>
        <tbody>
          <tr>
            <LabelCell />
            <td style={{ width: '100%' }} />
            {headers.map((header) => (
              <HeaderCell
                fontSize={headerFontSize}
                key={getKey(header)}
                style={{
                  color:
                    header.isBenchmark || !isNil(header.portfolioComparisonType)
                      ? theme.Colors.Black
                      : getItemColor(theme.Colors, getItemTypeFromHeader(header)),
                }}
              >
                {header.portfolioComparisonType === 'SAVED' ? (
                  <Icon type="clock-rotate-left" />
                ) : (
                  <ItemIcon item={header.isBenchmark ? ItemType.CommonBenchmark : getItemTypeFromHeader(header)} />
                )}
                {header.label}
              </HeaderCell>
            ))}
          </tr>
          {items.map((item, index) => {
            return (
              <HoverRow key={item.id}>
                <LabelCell>
                  <NameLabel item={item} white={theme.Colors.White} />
                </LabelCell>
                <BarCell
                  onMouseLeave={() => {
                    setTooltipPositionDebounced('leave');
                  }}
                  onMouseEnter={(event) => {
                    event.persist();
                    setTooltipPositionDebounced('enter', event, item);
                  }}
                >
                  <DynamicBar
                    width={Math.abs(getRelativeSize(item.values[0].value, largestNumber))}
                    type={item.type}
                    negative={!!item.values[0]?.value && item.values[0].value < 0}
                    inSignificant={!item.values[0].significant}
                    startPosition={isWaterfall ? getRelativeSize(sumItems[index], largestNumber) + 0.5 : 0.5}
                  />
                </BarCell>
                {item.values.map((v, index) => (
                  <Value key={getKey(headers[index])} inSignificant={!v.significant}>
                    {formatter(v.value)}
                  </Value>
                ))}
              </HoverRow>
            );
          })}
          <Row>
            <td />
            <td>
              <XAxis>
                <ScaleLabel>-{formatter(largestNumber)}</ScaleLabel>
                <ScaleLabel>{formatter(0)}</ScaleLabel>
                <ScaleLabel>{formatter(largestNumber)}</ScaleLabel>
              </XAxis>
            </td>
            {headers.map((header) => (
              <HeaderCell fontSize={fontSize} key={getKey(header)} />
            ))}
          </Row>
        </tbody>
      </Table>
      {tooltipInfo.item && (
        <RowTooltip style={{ left: tooltipInfo.x, top: tooltipInfo.y }}>
          <TooltipHeader fontSize={fontSize}>{tooltipInfo.item.label}</TooltipHeader>
          {tooltipInfo.item.values.map((v, index) => (
            <div key={getKey(headers[index])}>
              {headers[index].label}:
              {v.value ? (
                <>
                  <TooltipBold>{formatter(v.value)}</TooltipBold>
                  {tooltipInfo.item?.type !== 'TOTAL' && tooltipInfo.item?.type !== 'RISK-FREE' && (
                    <TooltipItalic>(T-Stat: {Numbers.safeFormatNumber(v.tValue)})</TooltipItalic>
                  )}
                </>
              ) : (
                ' Excluded'
              )}
            </div>
          ))}
        </RowTooltip>
      )}
    </Wrapper>
  );
};

const getLegends = (items: HorizontalBarsItem[], colors: VennColors) => {
  const hasResidual = items.find((item) => item.type === 'RESIDUAL');
  const hasRiskFree = items.find((item) => item.type === 'RISK-FREE');
  const hasTotal = items.find((item) => item.type === 'TOTAL');
  return (
    <>
      <LegendItem>
        <LegendIcon />
        Insignificant value
      </LegendItem>
      {hasResidual && (
        <LegendItem>
          <LegendIcon type="RESIDUAL" /> Residual
        </LegendItem>
      )}
      {hasRiskFree && (
        <LegendItem>
          <LegendIcon type="RISK-FREE" /> Risk-Free Rate
        </LegendItem>
      )}
      {hasTotal && (
        <LegendItem>
          <LegendIcon type="TOTAL" color={colors.Black} />
          Total
        </LegendItem>
      )}
    </>
  );
};

const NameLabel = ({ item, white }: { item: HorizontalBarsItem; white: string }) => {
  const itemWithTooltip = (
    <Tooltip
      content={
        item.description ? (
          <Description>
            <FactorDescriptionTooltip name={item.label} description={item.description} />
          </Description>
        ) : undefined
      }
      position={TooltipPosition.Right}
      background={white}
      maxWidth={320}
      showShadow
      block
      largerPointer
    >
      <ItemName className="qa-factorName">{item.label}</ItemName>
    </Tooltip>
  );

  if (isSpecialRows(item.id)) {
    return itemWithTooltip;
  }
  return (
    <Link target="_blank" rel="noopener noreferrer" to={`${Routes.FACTOR_LENS_DEEP_DIVE}/${item.id}`}>
      {itemWithTooltip}
    </Link>
  );
};

const Wrapper = styled.div<{ fontSize?: string }>`
  position: relative;
  ${({ fontSize }) => `font-size: ${fontSize};`}
`;

const Table = styled.table`
  border: 1px solid ${GetColor.GreyScale.Grey30};
  width: 100%;
  height: 1px;
`;

const HeaderCell = styled.td<{ fontSize?: string }>`
  min-width: ${ITEM_WIDTH}px;
  text-align: right;
  font-size: ${({ fontSize }) => fontSize || '10px'};
  font-weight: bold;
  overflow-wrap: break-word;
  padding: 0.3rem;
  border-right: 1px solid ${GetColor.GreyScale.Grey30};

  i {
    margin-right: 2px;
  }
`;

const LabelCell = styled.td`
  text-align: right;
  white-space: nowrap;
`;

const Row = styled.tr`
  border-top: 1px solid ${GetColor.GreyScale.Grey30};

  td:not(:last-child) {
    border-right: 1px solid ${GetColor.GreyScale.Grey30};
  }
`;

const BarCell = styled.td`
  position: relative;
  padding-top: 0;
  padding-bottom: 0;
  height: inherit;
`;

const HoverRow = styled(Row)`
  &:hover {
    background: rgba(74, 204, 255, 0.2);
    outline: rgba(211, 216, 220, 0.13);
  }
`;

const Value = styled.td<{ inSignificant: boolean }>`
  padding-right: 0.3rem;
  text-align: right;
  color: ${({ inSignificant }) => (inSignificant ? GetColor.GreyScale.Grey70 : GetColor.Black)};
`;

const XAxis = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ScaleLabel = styled.span`
  color: ${GetColor.GreyScale.Grey70};
`;

const Legend = styled.div<{ fontSize?: string }>`
  display: flex;
  justify-content: flex-end;
  font-size: ${({ fontSize }) => fontSize || '10px'};
  padding: 3.5px;
  column-gap: 0.5em;
  line-height: 1;
`;

const LegendItem = styled.div`
  display: flex;
  gap: 0.25em;
  align-items: center;
`;

const LegendIcon = styled.span<{ type?: BarType; color?: string }>`
  display: inline-block;
  margin: 0 1px 0 3px;
  width: 1em;
  height: 1em;
  background: ${({ color }) => color ?? GetColor.GreyScale.Grey30};
  ${({ type }) =>
    type === 'TOTAL' &&
    css`
      transform: rotate(45deg);
    `}
  ${backgroundStyles}
`;

const Description = styled.div`
  text-align: left;
`;

const ItemName = styled.div`
  color: ${GetColor.Black};
  padding-right: 2px;
`;

const RowTooltip = styled.div`
  position: absolute;
  background: ${GetColor.Black};
  color: ${GetColor.White};
  width: ${TOOLTIP_WIDTH}px;
  padding: 5px;
  pointer-events: none;
`;

const TooltipHeader = styled.div<{ fontSize?: string }>`
  font-size: ${({ fontSize }) => `${fontSize ?? '10px'}`};
  font-weight: bold;
`;

const TooltipBold = styled.span`
  font-weight: bold;
  margin: 0 2px;
`;

const TooltipItalic = styled.span`
  font-style: italic;
`;
