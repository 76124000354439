import React, { useContext } from 'react';
import { MultiHistoricalPortfolioContext } from './MultiHistoricalPortfolioContext';
import { GetColor, Icon } from 'venn-ui-kit';
import styled from 'styled-components';
import { plural } from 'venn-utils';
import { BANNER_HEIGHT, SPACING_M, SPACING_S } from '../../shared/layout';
import { DropMenu } from './MultiHistoricalReviewDateDropMenu';
import { countErrors } from './multiHistoricalPortfolioUtils';

const AllClear = (
  <>
    <Icon type="check" />
    no errors
  </>
);
const ErrorText = ({ numberOfErrors }: { numberOfErrors: number }) => (
  <>
    <Icon type="triangle-exclamation" />
    {plural(numberOfErrors, {
      1: '{{count}} date in this portfolio contains errors',
      other: '{{count}} dates in this portfolio contain errors',
    })}
  </>
);
export const Banner = () => {
  const { errors } = useContext(MultiHistoricalPortfolioContext);
  const numberOfErrors = countErrors(errors);
  const hasErrors = numberOfErrors > 0;
  return (
    <Container hasErrors={hasErrors}>
      <Left>
        <DatePickerText>As of date</DatePickerText>
        <DropMenu />
      </Left>
      <Right>{hasErrors ? ErrorText({ numberOfErrors }) : AllClear}</Right>
    </Container>
  );
};

const Container = styled.div<{ hasErrors: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ hasErrors }) => (hasErrors ? GetColor.Error : GetColor.Green)};
  padding: ${SPACING_S} ${SPACING_M};
  color: ${GetColor.White};
  height: ${BANNER_HEIGHT};
`;

const Left = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${SPACING_S};
`;

const DatePickerText = styled.div`
  display: inline-flex;
  align-items: center;
`;

const Right = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: ${SPACING_S};
`;
