import React, { memo, useMemo } from 'react';

import { useRecoilValue } from 'recoil';
import { compact, isEmpty, isNil } from 'lodash';

import {
  blockExportMetadata,
  blockInfoGraphicType,
  blockSettings,
  customizedBlock,
  isReportState,
  PRINT_CHART_HEIGHT,
  viewDataGridSizeType,
} from 'venn-state';

import { BaseGrid } from '../../components/visualizations/grids/base-grid';
import { ColumnChart } from '../../components/visualizations/charts/column-chart';
import { useFilteredRequests } from '../../utils/hooks/use-filtered-requests';

import { useAppPrintMode } from '../../../print';
import { DataGridSizeType } from '../../../studio-blocks';
import { useMetaRows } from '../../../studio-blocks/logic/useAnalysisGrid';
import {
  useNotablePeriodsChartData,
  useNotablePeriodsGridData,
} from '../../../studio-blocks/components/blocks/notable-historical/useNotablePeriodsData';
import { useNotablePeriodsColumnDefs } from '../../../studio-blocks/components/blocks/notable-historical/useNotablePeriodsColumnDefs';
import { getAnalyzedSubjectFromRequestSubject } from 'venn-utils';
import { useSubjectColors } from '../../../studio-blocks/logic/useSubjectColors';
import type { AnalysisProps } from '../../types';

import { useBlockContext } from '../../contexts/block-context';

interface NotableHistoricalContentProps {
  id: string;
}

export const NotableHistoricalContent = ({ id }: NotableHistoricalContentProps) => {
  const { inPrintModeOrReportLab } = useAppPrintMode();

  const { requests, analyses } = useFilteredRequests(id);

  const isReport = useRecoilValue(isReportState);
  const infoGraphicType = useRecoilValue(blockInfoGraphicType(id));

  const isCompact = useRecoilValue(viewDataGridSizeType(id)) === DataGridSizeType.COMPACT;
  const exportMetaData = useRecoilValue(blockExportMetadata(id));

  return infoGraphicType === 'GRID' ? (
    <Grid
      id={id}
      requests={requests}
      analyses={analyses}
      inPrintMode={inPrintModeOrReportLab}
      isCompact={isCompact}
      exportMetaData={exportMetaData}
      isReport={isReport}
    />
  ) : infoGraphicType === 'DISTRIBUTE_BAR' ? (
    <Chart
      id={id}
      requests={requests}
      analyses={analyses}
      inPrintMode={inPrintModeOrReportLab}
      exportMetaData={exportMetaData}
      isReport={isReport}
    />
  ) : null;
};

const Grid = memo(function InternalGrid({
  id,
  requests,
  analyses,
  inPrintMode,
  isCompact = false,
  exportMetaData,
  isReport,
}: AnalysisProps) {
  const metaRows = useMetaRows(requests);
  const dataRows = useNotablePeriodsGridData(analyses);
  const rowData = useMemo(() => [...metaRows, ...dataRows], [metaRows, dataRows]);

  const columnDefs = useNotablePeriodsColumnDefs({
    requests,
    analysesGroup: analyses,
    gridData: dataRows,
  });

  return (
    <BaseGrid
      selectedRefId={id}
      isCompact={isCompact}
      inPrintMode={inPrintMode}
      exportMetaData={exportMetaData}
      exportable
      rowData={rowData}
      columnDefs={columnDefs}
      isReport={isReport}
    />
  );
});

const Chart = memo(function InternalChart({
  id,
  requests,
  analyses,
  inPrintMode,
  exportMetaData,
  isReport,
}: AnalysisProps) {
  const { blockRef } = useBlockContext();

  const selectedBlock = useRecoilValue(customizedBlock(id));
  const blockSetting = useRecoilValue(blockSettings(id));

  const analyzedSubjects = useMemo(
    () => requests.map(({ subject }) => getAnalyzedSubjectFromRequestSubject(subject)),
    [requests],
  );
  const subjectColors = useSubjectColors(analyzedSubjects);

  const height = inPrintMode && !isReport ? PRINT_CHART_HEIGHT : undefined;
  // We filter selectedMetrics on blockSetting.metrics because metric order should be preserved,
  // even though reversing this logic would have simpler code.
  // We do this filter to remove any unsupported selectedMetrics.
  const metrics = useMemo(
    () => compact(selectedBlock.selectedMetrics.map((m) => blockSetting.metrics.find((metric) => metric.key === m))),
    [selectedBlock, blockSetting.metrics],
  );

  const rowData = useNotablePeriodsChartData(analyses);

  if (isNil(rowData) || isEmpty(rowData)) {
    return null;
  }

  return (
    <ColumnChart
      refId={id}
      requests={requests}
      inPrintMode={inPrintMode}
      exportMetaData={exportMetaData}
      rowData={rowData}
      downloadableContentRef={blockRef}
      height={height}
      selectedBlock={selectedBlock}
      metrics={metrics}
      customBlockType={blockSetting.customBlockType}
      subjectColors={subjectColors}
    />
  );
});
