import React from 'react';
import { noop } from 'lodash';

type PortfolioError = {
  portfolioId: number;
};
type Timestamp = number;
export type HistoricalPortfolioErrorState = Record<Timestamp, PortfolioError[]>;
export type HistoricalFundRange = { start: number; end: number };
export type HistoricalFundRangeAnalysis = { [key: string]: HistoricalFundRange };

export type MultiHistoricalPortfolioContextValue = {
  selectedDate: Date;
  selectDate: (date: Date) => void;
  allDates: Date[];
  errors: HistoricalPortfolioErrorState;
  rangeAnalysis: HistoricalFundRangeAnalysis;
};

export const defaultMultiHistoricalPortfolioContextValue: MultiHistoricalPortfolioContextValue = {
  selectDate: noop,
  selectedDate: new Date(Date.UTC(0)),
  allDates: [],
  errors: {},
  rangeAnalysis: {},
};

export const MultiHistoricalPortfolioContext = React.createContext<MultiHistoricalPortfolioContextValue>(
  defaultMultiHistoricalPortfolioContextValue,
);
MultiHistoricalPortfolioContext.displayName = 'MultiHistoricalPortfolioContext';
