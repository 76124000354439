import { DateRangeErrorWrapper } from './DateRangeErrorWrapper';
import { InfoGraphicTypeErrorWrapper } from './InfoGraphicTypeErrorWrapper';
import { SubjectsErrorWrapper } from './SubjectsErrorWrapper';
import { UnexpectedErrorWrapper } from './UnexpectedErrorWrapper';

export const COMMON_ERROR_WRAPPERS = [
  UnexpectedErrorWrapper,
  SubjectsErrorWrapper,
  // Date range wrapper should generally be first, since other error wrappers may need a date range to function properly.
  // TODO(houssein): We may want to add a check somewhere to ensure that the date range is set before any other error wrappers are run.
  DateRangeErrorWrapper,
  InfoGraphicTypeErrorWrapper,
];
