import React, { type ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { ButtonIcon, ColorUtils, GetColor, Headline3, Icon, TOP_NAV_HEIGHT, ZIndex } from 'venn-ui-kit';
import FocusTrap from 'focus-trap-react';
import Portal from '../../../venn-ui-kit/src/components/relative-portal/Portal';
import { isFunction, noop } from 'lodash';
import { ModalFooter, type ModalFooterProps } from '../modal';

const CLOSE_PANEL_CLASSNAME = 'qa-close-panel';
export const SIDE_PANEL_CONTENT_CLASSNAME = 'side-panel-content';
interface SidePanelOverlayRootProps {
  /** Which side of the page the panel is located */
  side: 'left' | 'right';
  /** The title on the panel */
  title?: JSX.Element;
  /** The content of th panel after the title */
  content?: JSX.Element;
  /** The footer of the panel */
  footer?: JSX.Element;

  /** Callback when the panel is closed */
  handleClose(): void;

  /** The width of the panel */
  width?: number;
  /** The width of the left margin */
  margin?: number;
  /** Whether the panel is open/visible or not */
  isOpen: boolean;
  /** Class name for the panel */
  className?: string;
  /**
   * Element to focus on when the panel opens.
   * If not provided, the focus will be on the first element in the panel, i.e. the button
   * to close the panel.
   */
  focusOnOpen?: React.MutableRefObject<HTMLElement | undefined>;
  hideTopBackButton?: boolean;
  scrollAlignRight?: boolean;
  noPadding?: boolean;

  closeOnEsc?: boolean;

  /** Whether to use focus trap or not */
  useFocusTrap?: boolean;

  zIndex?: number;
}

const ANIMATION_DURATION_MS = 500;
/**
 * A panel that opens and closes (with animation) from the left or the right,
 * and overlays the main content. When opened, focus is by default brought to the panel,
 * and when closed, the focus is brought back to the last focused element before the panel was opened.
 */
const SidePanelOverlayRoot = ({
  className,
  side,
  title,
  content,
  footer,
  handleClose,
  width,
  margin = 65,
  isOpen,
  focusOnOpen,
  scrollAlignRight,
  hideTopBackButton = false,
  noPadding = false,
  closeOnEsc = false,
  useFocusTrap = true,
  zIndex = ZIndex.InterComFront,
}: SidePanelOverlayRootProps) => {
  useEffect(() => {
    if (!closeOnEsc || !isOpen) {
      return () => {};
    }

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleClose();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleClose, closeOnEsc, isOpen]);

  const [isMounted, setIsMounted] = useState(false);
  const dismountTimeoutRef = React.useRef<NodeJS.Timeout | null>(null);
  useEffect(
    function delayedCloseForAnimation() {
      if (isOpen) {
        dismountTimeoutRef.current && clearTimeout(dismountTimeoutRef.current);
        setIsMounted(true);
        return;
      }

      dismountTimeoutRef.current = setTimeout(() => {
        setIsMounted(false);
      }, ANIMATION_DURATION_MS);
    },
    [isOpen],
  );

  const contextValue = useMemo(
    () => ({
      onClose: handleClose,
    }),
    [handleClose],
  );

  return isMounted ? (
    <SidePanelOverlayContext.Provider value={contextValue}>
      <Portal>
        <PortalRoot isOpen={isOpen}>
          <PortalContent
            isOpen={isOpen}
            side={side}
            style={{
              width,
              zIndex,
            }}
            className={className}
          >
            <FocusTrap
              active={isOpen && useFocusTrap}
              focusTrapOptions={{
                initialFocus: focusOnOpen ? () => focusOnOpen.current! : undefined,
                fallbackFocus: `.${CLOSE_PANEL_CLASSNAME}`,
                allowOutsideClick: true,
              }}
            >
              <Panel>
                <PanelContainer noPadding={noPadding} scrollAlignRight={scrollAlignRight}>
                  {!hideTopBackButton && (
                    <BackButtonContainer panelSide={side}>
                      <ButtonIcon
                        className={CLOSE_PANEL_CLASSNAME}
                        dominant
                        iconType={`arrow-${side}`}
                        onClick={handleClose}
                      />
                    </BackButtonContainer>
                  )}
                  <PanelContent side={side} margin={margin}>
                    {title && <Headline3>{title}</Headline3>}
                    <InnerContent className={SIDE_PANEL_CONTENT_CLASSNAME}>{content}</InnerContent>
                  </PanelContent>
                </PanelContainer>
                {footer && <Footer>{footer}</Footer>}
              </Panel>
            </FocusTrap>
          </PortalContent>
        </PortalRoot>
      </Portal>
    </SidePanelOverlayContext.Provider>
  ) : null;
};

interface SidePanelOverlayHeaderProps {
  title: string;
  subtitle?: ReactNode | (() => ReactNode);
}

const SidePanelOverlayHeader = ({ title, subtitle }: SidePanelOverlayHeaderProps) => {
  const { Colors } = useTheme();
  const { onClose } = useContext(SidePanelOverlayContext);
  return (
    <HeaderContainer>
      <HeaderText>
        <HeaderTitle>{title}</HeaderTitle>
        {subtitle && <HeaderSubtitle>{isFunction(subtitle) ? subtitle() : subtitle}</HeaderSubtitle>}
      </HeaderText>
      <button type="button" aria-label="Close" onClick={onClose} className={CLOSE_PANEL_CLASSNAME}>
        <Icon type="close" style={{ color: Colors.Black }} prefix="fal" />
      </button>
    </HeaderContainer>
  );
};

const StyledFooter = styled(ModalFooter)<{ noMargin?: boolean }>`
  width: 100%;
  background-color: ${GetColor.White};
  padding: 12px 24px;
  min-height: 64px;
  border-top: 1px solid ${GetColor.GreyScale.Grey30};
  ${({ noMargin }) => noMargin && 'margin-top: 0;'}

  .qa-upload-cancel, .qa-accept-btn, .start-over-btn {
    font-size: 12px;
    letter-spacing: 1px;
  }
`;

const SidePanelOverlayFooter = (props: Omit<ModalFooterProps, 'simplifiedButtonStyles'> & { noMargin?: boolean }) => {
  return <StyledFooter {...props} simplifiedButtonStyles />;
};

const SidePanelOverlayBody = styled.div`
  padding: 24px 24px 0 24px;
  flex: 1;
`;

interface SidePanelOverlayContextValue {
  onClose: () => void;
}

const SidePanelOverlayContext = React.createContext<SidePanelOverlayContextValue>({
  onClose: noop,
});
SidePanelOverlayContext.displayName = 'SidePanelOverlayContext';

export default {
  Root: SidePanelOverlayRoot,
  Header: SidePanelOverlayHeader,
  Footer: SidePanelOverlayFooter,
  Body: SidePanelOverlayBody,
  Context: SidePanelOverlayContext,
};

const PortalRoot = styled.div<{ isOpen: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: clip;

  background-color: transparent;
  animation: ${({ isOpen }) => (isOpen ? 'fade-in' : 'fade-out')} ${ANIMATION_DURATION_MS}ms forwards;
  @keyframes fade-in {
    100% {
      background-color: rgba(16, 22, 27, 0.75);
    }
  }
  @keyframes fade-out {
    0% {
      background-color: rgba(16, 22, 27, 0.75);
    }
    100% {
      background-color: transparent;
    }
  }

  z-index: ${ZIndex.Modal};
`;

const PortalContent = styled.div<{
  isOpen: boolean;
  side: 'left' | 'right';
}>`
  max-width: 100%;
  height: calc(100% - ${TOP_NAV_HEIGHT}px);

  transform: translateX(${({ side }) => (side === 'right' ? '' : '-')}100vw);
  animation: ${({ isOpen, side }) => `${isOpen ? 'slide-in' : `slide-out-${side}`}`} ${ANIMATION_DURATION_MS}ms forwards;

  position: absolute;
  z-index: ${ZIndex.InterComFront};
  right: ${({ side }) => (side === 'right' ? 0 : '')};
  left: ${({ side }) => (side === 'left' ? 0 : '')};
  top: ${TOP_NAV_HEIGHT}px;

  background-color: ${GetColor.White};

  @keyframes slide-in {
    100% {
      transform: translateX(0);
    }
  }

  @keyframes slide-out-left {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100vw);
    }
  }

  @keyframes slide-out-right {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(100vw);
    }
  }
`;

const Panel = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const PanelContainer = styled.div<{ noPadding: boolean; scrollAlignRight?: boolean }>`
  ${({ scrollAlignRight, noPadding }) =>
    noPadding
      ? css`
          padding: 0;
        `
      : scrollAlignRight
        ? css`
            padding: 30px 0 0 30px;
          `
        : css`
            padding: 30px 30px 0 30px;
          `}

  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
`;

const PanelContent = styled.div<Pick<SidePanelOverlayRootProps, 'side' | 'margin'>>`
  padding-left: ${({ side, margin }) => (side === 'right' ? margin : undefined)}px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
`;

const InnerContent = styled.div`
  flex-grow: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const BackButtonContainer = styled.div<{ panelSide: 'left' | 'right' }>`
  width: 100%;
  display: flex;
  ${({ panelSide }) => panelSide === 'left' && 'justify-content: flex-end'};
  margin-bottom: 25px;
`;

const Footer = styled.footer`
  box-shadow: 0px -2px 2px ${ColorUtils.opacify('#000', 0.06)};
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 20px;
`;

const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
`;
const HeaderTitle = styled.div`
  font-style: normal;
  font-weight: 300;
`;
const HeaderSubtitle = styled.div`
  margin-top: 8px;
  font-size: 16px;
  font-weight: 400;
`;

const HeaderContainer = styled.div`
  display: flex;
  padding: 20px 24px;
  font-size: 32px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  border-bottom: 1px solid ${GetColor.GreyScale.Grey30};
  line-height: normal;
`;
