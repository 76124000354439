import { isNil } from 'lodash';
import type { AnalysisView, AnalysisViewTypeEnum, Fund, Portfolio, PrivateFund, PrivatePortfolioNode } from 'venn-api';
import type { StudioRequestSubject, SubjectWithOptionalFee } from 'venn-state';
import type AnalysisSubject from '../analysis/AnalysisSubject';
import type { CustomBlockTypeEnum, CustomizableBlockSetting, CustomizableMetric } from '../blocksettings';
import { selectStrategy } from '../portfolio';
import { getRandomId } from './getRandomId';
import type { ComputedDateRange } from '../builder/types';

export const isViewTemplate = (type?: AnalysisViewTypeEnum) =>
  type === 'REPORT_TEMPLATE' || type === 'TEARSHEET_TEMPLATE';

export const toggleStudioTemplateType = (type?: AnalysisViewTypeEnum): AnalysisViewTypeEnum => {
  if (!type) {
    return 'TEARSHEET';
  }
  if (type.includes('_TEMPLATE')) {
    return type.replace('_TEMPLATE', '') as AnalysisViewTypeEnum;
  }
  return `${type}_TEMPLATE` as AnalysisViewTypeEnum;
};

export const getNonTemplateType = (type?: AnalysisViewTypeEnum) =>
  (type?.replace('_TEMPLATE', '') ?? 'TEARSHEET') as AnalysisViewTypeEnum;
export const getTemplateType = (type?: AnalysisViewTypeEnum) =>
  `${getNonTemplateType(type)}_TEMPLATE` as AnalysisViewTypeEnum;

export const areCumulativeReturnMetrics = (selectedMetrics: string[] = []) =>
  selectedMetrics.length ? selectedMetrics.every((metric) => metric === 'cumulativeReturn') : false;

export const getSecondaryStrategyForStudioRequestSubject = (analysisSubject: AnalysisSubject): Portfolio | undefined =>
  isNil(analysisSubject.secondaryStrategy) ||
  analysisSubject.secondaryStrategy.id === analysisSubject.secondaryPortfolio?.id
    ? undefined
    : analysisSubject.secondaryStrategy;

export const getStrategyForStudioRequestSubject = (analysisSubject: AnalysisSubject): Portfolio | undefined =>
  isNil(analysisSubject.strategyId) ||
  isNil(analysisSubject.portfolio) ||
  analysisSubject.portfolio.id === analysisSubject.strategy?.id
    ? undefined
    : (selectStrategy(analysisSubject.strategyId, analysisSubject.portfolio) ?? undefined);

/**
 * Always returns a StudioRequestSubject without `modifiedPortfolio`, since that info can't be derived from AnalysisSubject.
 */
export const getRequestSubjectFromAnalysisSubject = (
  analysisSubject: AnalysisSubject,
  feesMapping?: { [key: string]: number },
): StudioRequestSubject & { modifiedPortfolio: undefined } => {
  return {
    id: String(analysisSubject.id),
    name: analysisSubject.name,
    ...(analysisSubject.superType === 'private-investment'
      ? {
          privateFund: analysisSubject.superItem as PrivateFund,
          subjectType: 'INVESTMENT',
          private: true,
        }
      : analysisSubject.superType === 'private-portfolio'
        ? {
            privatePortfolio: analysisSubject.superItem as PrivatePortfolioNode,
            subjectType: 'PORTFOLIO',
            private: true,
          }
        : analysisSubject.superType === 'investment'
          ? {
              fund: analysisSubject.superItem as Fund,
              subjectType: 'INVESTMENT',
              private: false,
            }
          : {
              // TODO: VENN-21001: fix case where we have an instrument-only portfolio (usually as individual benchmark)
              portfolio: (analysisSubject.getOptionsCopy().instrumentIdOnly
                ? {
                    id: analysisSubject.id,
                    name: analysisSubject.name,
                  }
                : analysisSubject.superItem) as Portfolio,
              strategy: getStrategyForStudioRequestSubject(analysisSubject),
              subjectType: 'PORTFOLIO',
              private: false,
            }),
    individualBenchmark: analysisSubject.activeBenchmark,
    portfolioComparisonType: undefined,
    primaryPortfolio: undefined,
    feesMapping,
    modifiedPortfolio: undefined,
    modifiedPrivatePortfolio: undefined,
  };
};

export const getRequestSubjectForSecondaryAnalysisSubject = (
  analysisSubject: AnalysisSubject,
): StudioRequestSubject | undefined => {
  const primary = analysisSubject.portfolio;
  const secondary = analysisSubject.secondaryPortfolio;
  const portfolioComparisonType =
    analysisSubject.secondaryPortfolioComparisonType === 'NONE'
      ? undefined
      : analysisSubject.secondaryPortfolioComparisonType;

  if (isNil(primary) || isNil(secondary) || isNil(portfolioComparisonType)) {
    return undefined;
  }

  // A sub-strategy selected on AnalysisSubject has no corresponding sub-strategy in secondary portfolio
  if (analysisSubject.strategy?.id !== primary.id && isNil(analysisSubject.secondaryStrategy)) {
    return undefined;
  }

  if (analysisSubject.private) {
    return undefined;
  }

  return {
    id: String(secondary.id),
    name: secondary.name,
    subjectType: 'PORTFOLIO',
    portfolio: secondary,
    strategy: getSecondaryStrategyForStudioRequestSubject(analysisSubject),
    modifiedPortfolio: portfolioComparisonType === 'SAVED' ? secondary : undefined,
    individualBenchmark: secondary.compare?.find((benchmark) => benchmark.primary),
    portfolioComparisonType,
    primaryPortfolio: primary,
    private: false,
  };
};

export const getSubjectFromRequestSubject = (requestSubject: StudioRequestSubject): SubjectWithOptionalFee =>
  getSubjectFromStudioRequestSubject(requestSubject, undefined);

/**
 * Gets the analyzed subject from the request subject
 * eg, if a sleeve is selected, the subject of the sleeve rather than the root portfolio
 * @param requestSubject the given request subject
 */
export const getAnalyzedSubjectFromRequestSubject = (requestSubject: StudioRequestSubject): SubjectWithOptionalFee => {
  const strategy = requestSubject.strategy;
  return getSubjectFromStudioRequestSubject(requestSubject, strategy);
};

const getSubjectFromStudioRequestSubject = (requestSubject: StudioRequestSubject, strategy: Portfolio | undefined) => {
  const portfolioNode = strategy ?? requestSubject.portfolio;
  return {
    fundId: requestSubject.subjectType === 'INVESTMENT' && !requestSubject.private ? requestSubject.id : undefined,
    privateFundId:
      requestSubject.subjectType === 'INVESTMENT' && requestSubject.private ? requestSubject.id : undefined,
    portfolioId:
      requestSubject.subjectType === 'PORTFOLIO' && !requestSubject.private
        ? strategy && !strategy?.fund && strategy?.id
          ? Number(strategy.id)
          : portfolioNode?.id
            ? Number(portfolioNode.id)
            : undefined
        : undefined,
    privatePortfolioId:
      requestSubject.subjectType === 'PORTFOLIO' && requestSubject.private ? requestSubject.id : undefined,
    portfolioVersion:
      requestSubject.portfolioComparisonType === 'SAVED' ? requestSubject.portfolio?.version : undefined,
    feesMapping: requestSubject.feesMapping,
  };
};

export const getBlockSubjectTypeName = (block: CustomizableBlockSetting) => {
  let prefix = '';
  if (block.supportsPrivateInvestments && !block.supportsPublicInvestments) {
    prefix = 'private ';
  }
  return `${prefix}${block.supportsPortfoliosOnly ? 'portfolio' : 'subject'}`;
};
export const isReport = (analysisView: AnalysisView | AnalysisViewTypeEnum) => {
  if (typeof analysisView === 'string') {
    return analysisView === 'REPORT' || analysisView === 'REPORT_TEMPLATE';
  }
  return (
    analysisView?.analysisViewType === 'REPORT' ||
    analysisView?.analysisViewType === 'REPORT_TEMPLATE' ||
    analysisView?.customViewOptions?.reportingStyle === 'REPORT'
  );
};

export const makeCustomMetric = (name: string): CustomizableMetric => ({
  key: getRandomId(),
  dataType: 'TEXT',
  label: name,
  analysisType: 'CUSTOM_ENTRY',
});

/**
 * returns as-of-date for historical blocks that require it
 */
export const PRO_FORMA_HISTORICAL_SIMULATION_DATE_BLOCKS = [
  'GROWTH_SIMULATION',
  'PUBLIC_PRIVATE_ASSET_GROWTH_PERCENTILES',
  'PUBLIC_PRIVATE_ASSET_GROWTH_BREAKDOWN',
];
export const PRO_FORMA_HISTORICAL_BLOCKS = ['SCENARIO', ...PRO_FORMA_HISTORICAL_SIMULATION_DATE_BLOCKS];

export const PRO_FORMA_HISTORICAL_METRICS = [
  // correlation metrics
  'portfolioCorrelation',
  'residualPortfolioCorrelation',
  'portfolioDownsideCorrelation',
  // performance summary metrics
  'FORECAST-annualizedReturn',
  'FORECAST-sharpe',
  'FORECAST-volatility',
];

export const historicalSubjectAsOfDate = (
  subject: StudioRequestSubject,
  customBlockType: CustomBlockTypeEnum | undefined,
  selectedMetrics: string[] | undefined,
  range: ComputedDateRange | undefined,
): number | undefined => {
  const isHistorical = subject.portfolio?.historical || subject.modifiedPortfolio?.historical;
  const isProFormaBlock = customBlockType && PRO_FORMA_HISTORICAL_BLOCKS.includes(customBlockType);
  const isProFormaMetric = selectedMetrics?.some((metric) => PRO_FORMA_HISTORICAL_METRICS.includes(metric));
  return isHistorical && (isProFormaBlock || isProFormaMetric) ? range?.range.to : undefined;
};
