import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { Body1 } from 'venn-ui-kit';

import { type HTMLProps } from '../factory';

export type BlockTitleElement = React.ElementRef<'div'>;
export interface BlockTitleProps extends HTMLProps<'div'> {
  children: React.ReactNode;
}

export const BlockTitle = forwardRef<BlockTitleElement, BlockTitleProps>((props, ref) => {
  const { children } = props;

  return <Container ref={ref}>{children}</Container>;
});

BlockTitle.displayName = 'BlockTitle';

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  word-break: break-word;
  padding-bottom: 5px;

  ${Body1} {
    font-size: 1rem;
  }
`;
