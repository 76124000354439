import {
  type CellClassParams,
  type ColDef,
  type ICellRendererParams,
  type RowClassParams,
  type RowClassRules,
  type ValueGetterParams,
} from 'ag-grid-community';
import { compact, isNil, memoize, noop } from 'lodash';
import { useMemo, useState } from 'react';
import type { Fund, Portfolio } from 'venn-api';
import { GetColor, MULTI_PORTFOLIO_UPLOADER_FAQ_HREF } from 'venn-ui-kit';
import { assertNotNil, useHasFF } from 'venn-utils';
import { HeaderRendererWithLink } from '../../../studio-blocks/components/grid/renderers/HeaderRendererWithLink';
import {
  ALLOCATION_CELL_RENDERER,
  MAPPED_HISTORICAL_PORTFOLIO_NODE_CELL_RENDERER,
  MAPPED_PRO_FORMA_PORTFOLIO_NODE_CELL_RENDERER,
} from '../../../studio-blocks/customAnalysisContants';
import type { PortfolioAccessMode, RowData } from '../types';
import MutliPortfolioActionsCell from './cells/MutliPortfolioActionsCell';
import type { HistoricalFundRangeAnalysis } from './components/specialized/MultiHistoricalPortfolioContext';
import { NameWithMappingErrorRenderer } from './mapping/NameWithMappingErrorRenderer';
import {
  getAllFundIds,
  historicalNodeHasShortHistory,
  historicalNodeIsDuplicated,
  nodeNeedsMapping,
  serializePath,
} from './review/helpers';

const useUploadPortfolioAllocatorColumnDefs = ({
  portfolio,
  excludedInvestments,
  onApplyMapping,
  isHistorical,
  includeInvestment,
  excludeInvestment,
}: {
  portfolio: Portfolio;
  excludedInvestments: Set<string>;
  onApplyMapping: (path: number[], fund: Fund) => void;
  isHistorical: boolean;
  includeInvestment: (path: number[]) => void;
  excludeInvestment: (path: number[]) => void;
}): ColDef<RowData<Portfolio>>[] => {
  const hasHistoricals = useHasFF('historical_portfolios_ff');
  const [isPathRemapped, setIsPathRemapped] = useState<Record<string, boolean>>({});
  const allFundIdsInPortfolio = getAllFundIds(portfolio);
  const columns: ColDef<RowData<Portfolio>>[] = compact([
    {
      headerName: 'Mapped?',
      sortable: false,
      cellRenderer: isHistorical
        ? MAPPED_HISTORICAL_PORTFOLIO_NODE_CELL_RENDERER
        : MAPPED_PRO_FORMA_PORTFOLIO_NODE_CELL_RENDERER,
      cellRendererParams: (params: ICellRendererParams) => {
        const { isFund, needsMapping } = nodeNeedsMapping(params.data, excludedInvestments);
        const onApplyMappingWrapper = (path: number[], fund: Fund) => {
          onApplyMapping(path, fund);
          setIsPathRemapped((isPathRemapped) => ({
            ...isPathRemapped,
            [serializePath(path).value]: true,
          }));
        };
        return {
          isFund,
          needsMapping,
          onApplyMapping: onApplyMappingWrapper,
          allFundIdsInPortfolio,
          isPathRemapped,
          isExcluded: excludedInvestments.has(serializePath(assertNotNil(params.data.path)).value),
        };
      },
      colId: 'mapped',
      flex: 1,
      cellClass: 'overflow-visible',
    },
    {
      headerName: 'Allocation',
      headerClass: ['ag-right-aligned-header'],
      cellRenderer: ALLOCATION_CELL_RENDERER,
      sortable: false,
      cellRendererParams: (params: ICellRendererParams) => {
        const { allocation, fund } = params.data.node;
        return {
          value: allocation,
          isRoot: params.node.level === 0,
          originalValue: allocation,
          isStrategy: isNil(fund),
          onUpdateAllocation: noop,
          isExcluded: excludedInvestments.has(serializePath(assertNotNil(params.data.path)).value),
        };
      },
      colId: 'allocation',
      flex: 1,
      minWidth: 130,
    },
    hasHistoricals
      ? {
          headerName: '',
          cellRenderer: MutliPortfolioActionsCell,
          cellRendererParams: ({ data }: ICellRendererParams<RowData<Portfolio>>) => {
            const isFund = !isNil(data?.node?.fund) && !isNil(data?.path);

            const includeThisInvestment = () => {
              includeInvestment(assertNotNil(data?.path));
            };

            const excludeThisInvestment = () => {
              excludeInvestment(assertNotNil(data?.path));
            };

            return {
              isHistorical,
              isFund,
              isExcluded: excludedInvestments.has(serializePath(assertNotNil(data?.path)).value),
              includeInvestment: includeThisInvestment,
              excludeInvestment: excludeThisInvestment,
            };
          },
          sortable: false,
          colId: 'actions',
          minWidth: 70,
          maxWidth: 70,
        }
      : null,
  ]);

  return columns;
};

const getHistoricalRowData = (node: Portfolio, selectedDate: Date): RowData<Portfolio>[] => {
  const rowData = getRowData(node);
  const timestamp = selectedDate.valueOf();
  let clearCache: undefined | (() => void);
  const filteredRows = rowData.filter((row) => {
    const { result, clear } = containsDate(row.node, timestamp);
    clearCache = clear;
    return result;
  });
  clearCache?.();
  return filteredRows;
};

const containsDate = (portfolio: Portfolio, timestamp: number) => {
  const _containsDate = memoize((portfolio: Portfolio): boolean => {
    const fundContainsDate = !!portfolio.closingAllocationsTs?.some(
      (closingAllocation) => closingAllocation[0] === timestamp,
    );
    return fundContainsDate || portfolio.children.some(_containsDate);
  });
  const result = _containsDate(portfolio);
  return {
    result,
    clear: () => _containsDate.cache.clear?.(),
  };
};

export const getRowData = (node: Portfolio): RowData<Portfolio>[] => {
  return getRowDataImpl(node, node, 0);
};

const getRowDataImpl = (node: Portfolio, root: Portfolio, nodeId: number): RowData<Portfolio>[] => {
  if (node.fund) {
    return [
      {
        path: [nodeId],
        node,
        root,
      },
    ];
  }
  return [
    {
      path: [nodeId],
      node,
      root,
    },
    ...node.children.flatMap((child, childIndex) => {
      const childData = getRowDataImpl(child, root, childIndex);
      return childData.map((row) => {
        return {
          path: [nodeId, ...row.path],
          node: row.node,
          root,
        };
      });
    }),
  ];
};

const portfolioAllocatorStylingProps = {
  root: {
    fontWeight: 700,
    fontSize: 16,
  },
  strategy: {
    fontWeight: 700,
    fontSize: 14,
  },
  investment: {
    fontWeight: 400,
    fontSize: 14,
  },
};

const OVERWRITE_TOOLTIP_MSG =
  'Replaces all investments, strategies, strategy hierarchy, and allocations from the existing portfolio in the Data Library with the information below.';
const CREATE_NEW_TOOLTIP_MSG =
  'Creates a new portfolio with all investments, strategies, strategy hierarchy, and allocations in the Data Library with the information below.';

const useCommonAutoGroupColumnDefs = (accessMode: PortfolioAccessMode) => ({
  flex: 5,
  minWidth: 200,
  wrapText: true,
  autoHeight: true,
  headerComponent: HeaderRendererWithLink,
  headerComponentParams: {
    displayName: accessMode === 'OVERWRITE_EXISTING' ? 'Overwrite Entire Portfolio' : 'Create New Portfolio',
    linkTo: MULTI_PORTFOLIO_UPLOADER_FAQ_HREF,
    tooltip: accessMode === 'OVERWRITE_EXISTING' ? OVERWRITE_TOOLTIP_MSG : CREATE_NEW_TOOLTIP_MSG,
    iconColor: GetColor.Black,
  },
  sortable: false,
  field: 'path' as const,
  valueGetter: (params: ValueGetterParams<RowData<Portfolio>>) => params.data?.node.name,
  cellStyle: (params: CellClassParams<RowData<Portfolio>>) => {
    const { level, data } = params.node;
    const baseStyle =
      level === 0
        ? portfolioAllocatorStylingProps.root
        : isNil(data?.node?.fund)
          ? portfolioAllocatorStylingProps.strategy
          : portfolioAllocatorStylingProps.investment;

    return {
      ...baseStyle,
      paddingLeft: 0,
    };
  },
  colId: 'name',
  cellRenderer: 'agGroupCellRenderer',
});

const useProFormaAutoGroupColumnDefs = ({
  accessMode,
  excludedInvestments,
}: {
  accessMode: PortfolioAccessMode;
  excludedInvestments: Set<string>;
}) => ({
  ...useCommonAutoGroupColumnDefs(accessMode),
  cellRendererParams: (params: ICellRendererParams<RowData<Portfolio>>) => {
    const portfolio = params.data?.node;
    return {
      suppressCount: true,
      suppressDoubleClickExpand: true,
      innerRenderer: NameWithMappingErrorRenderer,
      innerRendererParams: {
        name: portfolio?.name,
        isExcluded: excludedInvestments.has(serializePath(assertNotNil(params?.data?.path)).value),
        needsMapping: nodeNeedsMapping(assertNotNil(params?.data), excludedInvestments).needsMapping,
        duplicateInvestment: false,
      },
    };
  },
});

const useHistoricalAutoGroupColumnDefs = ({
  accessMode,
  selectedDate,
  excludedInvestments,
  rangeAnalysis,
}: {
  accessMode: PortfolioAccessMode;
  selectedDate: Date;
  excludedInvestments: Set<string>;
  rangeAnalysis: HistoricalFundRangeAnalysis;
}) => ({
  ...useCommonAutoGroupColumnDefs(accessMode),
  cellRendererParams: (params: ICellRendererParams<RowData<Portfolio>>) => {
    const data = assertNotNil(params.data);
    const portfolio = data.node;
    const fundId = portfolio.fund?.id;
    return {
      suppressCount: true,
      suppressDoubleClickExpand: true,
      innerRenderer: NameWithMappingErrorRenderer,
      innerRendererParams: {
        name: portfolio.name,
        isExcluded: excludedInvestments.has(serializePath(assertNotNil(params?.data?.path)).value),
        needsMapping: nodeNeedsMapping(assertNotNil(params.data), excludedInvestments).needsMapping,
        duplicateInvestment: historicalNodeIsDuplicated(data, selectedDate.getTime(), excludedInvestments)
          .duplicateInvestment,
        shortHistory:
          !isNil(fundId) && historicalNodeHasShortHistory(portfolio.closingAllocationsTs, rangeAnalysis[fundId]),
      },
    };
  },
});

const useRowClassRules = ({
  excludedInvestments,
  rangeAnalysis,
  selectedDate,
}: {
  excludedInvestments: Set<string>;
  rangeAnalysis?: HistoricalFundRangeAnalysis;
  selectedDate?: Date;
}) => {
  return useMemo<RowClassRules<RowData<Portfolio>>>(() => {
    const isErrorRow = (params: RowClassParams) => {
      return (
        params.data &&
        (nodeNeedsMapping(params.data, excludedInvestments).needsMapping ||
          historicalNodeIsDuplicated(params.data, selectedDate?.getTime(), excludedInvestments).duplicateInvestment)
      );
    };

    return {
      // row style function creating a CSS class to style the background of the row
      'error-row': (params) => {
        return isErrorRow(params);
      },
      'warning-row': (params) => {
        const { node, path } = params.data ?? {};
        if (excludedInvestments.has(serializePath(assertNotNil(path)).value)) {
          return false;
        }
        const isError = isErrorRow(params);
        const fundId = node?.fund?.id;
        return (
          !isError &&
          !isNil(fundId) &&
          historicalNodeHasShortHistory(node?.closingAllocationsTs, rangeAnalysis?.[fundId])
        );
      },
    };
  }, [excludedInvestments, rangeAnalysis, selectedDate]);
};

type SharedAllocatorProps = {
  portfolio: Portfolio;
  excludedInvestments: Set<string>;
  accessMode: PortfolioAccessMode;
  applyInvestmentRemap: (path: number[], fund: Fund) => void;
  excludeInvestment: (path: number[]) => void;
  includeInvestment: (path: number[]) => void;
};

type ProFormaAllocatorProps = SharedAllocatorProps;

type HistoricalAllocatorProps = SharedAllocatorProps & {
  selectedDate: Date;
  rangeAnalysis: HistoricalFundRangeAnalysis;
};

export const useUploadProFormaPortfolioAllocator = ({
  portfolio,
  excludedInvestments,
  accessMode,
  applyInvestmentRemap,
  includeInvestment,
  excludeInvestment,
}: ProFormaAllocatorProps) => {
  return {
    rowClassRules: useRowClassRules({ excludedInvestments }),
    autoGroupColumnDefs: useProFormaAutoGroupColumnDefs({
      accessMode,
      excludedInvestments,
    }),
    columnDefs: useUploadPortfolioAllocatorColumnDefs({
      portfolio,
      excludedInvestments,
      onApplyMapping: applyInvestmentRemap,
      isHistorical: false,
      includeInvestment,
      excludeInvestment,
    }),
    rowData: getRowData(portfolio),
  };
};

export const useUploadHistoricalPortfolioAllocator = ({
  portfolio,
  excludedInvestments,
  accessMode,
  applyInvestmentRemap,
  selectedDate,
  includeInvestment,
  excludeInvestment,
  rangeAnalysis,
}: HistoricalAllocatorProps) => {
  return {
    rowClassRules: useRowClassRules({
      excludedInvestments,
      rangeAnalysis,
      selectedDate,
    }),
    autoGroupColumnDefs: useHistoricalAutoGroupColumnDefs({
      accessMode,
      excludedInvestments,
      selectedDate,
      rangeAnalysis,
    }),
    columnDefs: useUploadPortfolioAllocatorColumnDefs({
      portfolio,
      excludedInvestments,
      onApplyMapping: applyInvestmentRemap,
      isHistorical: true,
      includeInvestment,
      excludeInvestment,
    }),
    rowData: getHistoricalRowData(portfolio, selectedDate),
  };
};
