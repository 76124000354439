import moment from 'moment';
import type { ComponentProps } from 'react';
import React, { useMemo } from 'react';
import { assertExhaustive } from 'venn-utils';
import DayPicker from './granularities/DayPicker';
import { constrainRangeToGranularity, getRangeFromType } from './granularities/logic';
import MonthPicker from './granularities/MonthPicker';
import YearPicker from './granularities/YearPicker';
import type { DateRange, Granularity, DatePickerProps } from './types';
import type { FactorLensWithReturns, FrequencyEnum } from 'venn-api';

type DateRangePickerContentProps = {
  granularity: Granularity;
  maxFrequency: FrequencyEnum;
} & DatePickerProps &
  Pick<ComponentProps<typeof DayPicker>, 'period' | 'layout'>;

export const DateRangePickerContent = ({
  granularity,
  layout = 'left',
  hideActions = false,
  ...passThroughProps
}: DateRangePickerContentProps) => {
  const pickerProps = {
    ...passThroughProps,
    hideActions,
    layout,
  };

  if (granularity === 'day') return <DayPicker {...pickerProps} />;
  if (granularity === 'month') return <MonthPicker {...pickerProps} />;
  if (granularity === 'year') return <YearPicker {...pickerProps} />;
  throw assertExhaustive(granularity);
};

/**
 * Helper that computes the current value, constrained to a max range, and converted to a specific granularity.
 *
 * Max range is converted to granularity before being used as a constraint.
 * If no current value is provided, the current max range is returned.
 * If no max range and no current value are provided, undefined is returned.
 */
export function useDateRangePickerContent(
  maxRange: DateRange | undefined,
  granularity: Granularity,
  currentValue: DateRange | undefined,
  maxFrequency: FrequencyEnum,
  factorLens: FactorLensWithReturns | undefined,
) {
  const computedMaxRange = useMemo(() => {
    return constrainRangeToGranularity(maxRange, granularity);
  }, [maxRange, granularity]);

  const computedValue = useMemo(() => {
    if (currentValue?.period && computedMaxRange) {
      return getRangeFromType(currentValue.period, computedMaxRange, granularity, maxFrequency, factorLens);
    }
    if (currentValue) {
      return {
        from: moment.utc(currentValue.from).startOf(granularity).valueOf(),
        to: moment.utc(currentValue.to).startOf(granularity).valueOf(),
      };
    }
    return undefined;
  }, [currentValue, computedMaxRange, granularity, maxFrequency, factorLens]);

  return { computedMaxRange, computedValue };
}
