import type AnalysisSubject from './AnalysisSubject';
import type {
  AnalysisSubject as ApiAnalysisSubject,
  AnalysisComparisonTypeEnum,
  Portfolio,
  AnalysisSubjectTypeEnum,
} from 'venn-api';
import { compact } from 'lodash';
import { FS } from '../feature-availability';

export default (
  subject: AnalysisSubject,
  categoryActive: boolean = false,
  excludeBenchmark?: boolean,
  customComparisonPortfolio?: Portfolio,
): ApiAnalysisSubject[] => {
  const comparisonId =
    subject.type === 'portfolio'
      ? subject.hasSecondarySubject
        ? subject.secondaryId?.toString()
        : undefined
      : subject.categoryGroup?.categoryId;
  const comparisonDraftPortfolio =
    subject.hasSecondarySubject && subject.secondaryPortfolio?.draft ? subject.secondaryPortfolio : undefined;
  const comparisonSpecificVersion = comparisonId ? subject.secondaryPortfolio : undefined;

  return compact([
    {
      id: subject.type === 'portfolio' ? undefined : subject.id.toString(),
      subjectType: subject.type.toUpperCase() as AnalysisSubjectTypeEnum,
      comparisonType: 'PRIMARY' as AnalysisComparisonTypeEnum,
      portfolio: subject.strategy!,
      primary: true,
      isPrivate: false,
    } as ApiAnalysisSubject,
    !excludeBenchmark && subject.activeBenchmarkId
      ? {
          id: subject.activeBenchmarkId as string,
          subjectType: subject.activeBenchmarkType.toUpperCase() as AnalysisSubjectTypeEnum,
          comparisonType: 'BENCHMARK' as AnalysisComparisonTypeEnum,
          primary: false,
          isPrivate: false,
        }
      : undefined,
    (subject.type === 'portfolio' && comparisonId) || comparisonDraftPortfolio
      ? ({
          id: comparisonDraftPortfolio ? undefined : comparisonId,
          subjectType: 'PORTFOLIO',
          comparisonType: 'COMPARISON' as AnalysisComparisonTypeEnum,
          portfolio: comparisonDraftPortfolio ?? comparisonSpecificVersion,
          primary: false,
          portfolioComparisonType: subject.secondaryPortfolioComparisonType,
          isPrivate: false,
        } as ApiAnalysisSubject)
      : undefined,
    !FS.has('test_hide_category_ff') && subject.type === 'investment' && comparisonId && categoryActive
      ? {
          id: comparisonId,
          subjectType: 'INVESTMENT',
          comparisonType: 'CATEGORY' as AnalysisComparisonTypeEnum,
          primary: false,
          isPrivate: false,
        }
      : undefined,
    customComparisonPortfolio
      ? {
          id: customComparisonPortfolio.id.toString(),
          subjectType: 'PORTFOLIO',
          comparisonType: 'COMPARISON' as AnalysisComparisonTypeEnum,
          portfolio: customComparisonPortfolio,
          primary: false,
          isPrivate: false,
        }
      : undefined,
  ]);
};
