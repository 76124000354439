import React, { useCallback, useContext, useMemo } from 'react';
import styled from 'styled-components';
import { ColorUtils, type DropMenuItem, GetColor, Icon, IconDropMenu } from 'venn-ui-kit';
import { hasUnmatchedFunds, historicalPortfolioHasDuplicatedInvestments } from './helpers';
import {
  MultiPortfolioReviewContext,
  type PortfolioParseResultWithReviewMetadata,
} from '../components/common/MultiPortfolioReviewContext';
import { compact } from 'lodash';
import { EXCLUDE_PORTFOLIO_DROPDOWN_MENU_WIDTH, INCLUDE_PORTFOLIO_DROPDOWN_MENU_WIDTH } from '../shared/layout';

type ParseResultProps = {
  data: PortfolioParseResultWithReviewMetadata;
  isNextItemSelected: boolean;
  setSelectedIndex: React.Dispatch<number>;
  selected: boolean;
};

type DropdownOptions = 'include' | 'exclude';

export const ParsedPortfolioOption = ({ data, isNextItemSelected, selected, setSelectedIndex }: ParseResultProps) => {
  const {
    actions: { excludePortfolio, includePortfolio },
  } = useContext(MultiPortfolioReviewContext);
  const hasError =
    hasUnmatchedFunds(data.parsedPortfolio, data.excludedInvestments) ||
    historicalPortfolioHasDuplicatedInvestments(data.parsedPortfolio, data.excludedInvestments);
  const options: DropMenuItem<DropdownOptions>[] = useMemo(
    () =>
      compact([
        data.category !== 'excluded' && {
          label: 'Exclude from upload',
          value: 'exclude',
        },
        data.category === 'excluded' && {
          label: 'Include in upload',
          value: 'include',
        },
      ]),
    [data.category],
  );
  const width = useMemo(() => {
    switch (data.category) {
      case 'excluded':
        return INCLUDE_PORTFOLIO_DROPDOWN_MENU_WIDTH;
      default:
        return EXCLUDE_PORTFOLIO_DROPDOWN_MENU_WIDTH;
    }
  }, [data.category]);
  const onChange = useCallback(
    (option: DropMenuItem<DropdownOptions>) => {
      if (option.value === 'exclude') {
        excludePortfolio(data.originalIndex);
      } else if (option.value === 'include') {
        includePortfolio(data.originalIndex);
      }
    },
    [data.originalIndex, excludePortfolio, includePortfolio],
  );
  return (
    <PortfolioNameContainer
      isNextItemSelected={isNextItemSelected}
      data-testid="parsed-portfolio-option"
      hasError={hasError}
      onClick={() => setSelectedIndex(data.originalIndex)}
      selected={selected}
    >
      <Left>
        {hasError && <ErrorIcon type="triangle-exclamation" prefix="far" iconLabel="Portfolio error" />}
        {data.parsedPortfolio.name}
      </Left>
      <Right>
        <IconDropMenu icon="ellipsis-h" items={options} onChange={onChange} usePortal width={width} />
      </Right>
    </PortfolioNameContainer>
  );
};

const ErrorIcon = styled(Icon)`
  padding-right: 2px;
`;

const Left = styled.div`
  display: flex;
  gap: 4px;
`;
const Right = styled.div`
  display: flex;
`;

const PortfolioNameContainer = styled.button<{
  isNextItemSelected: boolean;
  selected: boolean;
  hasError: boolean;
}>`
  box-sizing: border-box;
  // Previous sibling of selected element should not have border-bottom
  // Otherwise it creates double-border effect
  border-width: ${({ selected, isNextItemSelected }) => (selected ? '1px 0' : isNextItemSelected ? '0' : '0 0 1px 0')};
  border-style: solid;
  border-color: ${({ selected }) => (selected ? GetColor.DarkBlue : GetColor.GreyScale.Grey30)};
  background: ${({ selected }) => (selected ? ColorUtils.opacifyFrom(GetColor.DarkBlue, 0.1) : GetColor.White)};
  color: ${({ hasError }) => (hasError ? GetColor.Error : GetColor.Black)};
  display: flex;
  padding: 8px 16px 8px 24px;
  align-items: center;
  align-self: stretch;
  font-size: 14px;
  font-weight: ${({ selected }) => (selected ? 700 : 400)};
  min-height: 42px;
  text-align: left;
  justify-content: space-between;

  :hover {
    color: ${GetColor.DarkBlue};
    background: ${ColorUtils.opacifyFrom(GetColor.DarkBlue, 0.1)};
  }
`;
