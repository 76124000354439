import { FAStackWrapper, type PortfolioWithLetterIconProps, LetterIcon } from './shared';
import { useColorTheme } from '../../themes/src/useColorTheme';
import { Icon } from '../icon/Icon';
import React from 'react';
import styled from 'styled-components';

export const PortfolioWithLetterIcon = ({ dataTestId, color, className, letter }: PortfolioWithLetterIconProps) => {
  const Colors = useColorTheme();

  return (
    <FAStackWrapper data-testid={dataTestId} className={`fa-stack ${className ?? ''}`}>
      <Icon
        type="folder"
        className="fa-stack-2x"
        style={{ color: color ?? Colors.colors.DEPRECATED_DataLineColor.DarkBlue }}
      />
      <StyledLetterIcon type={letter} className="fa-stack-1x" />
    </FAStackWrapper>
  );
};

const StyledLetterIcon = styled(LetterIcon)`
  // Lower the P slightly, so that it is centered in the folder excluding the tab at the top of the folder
  top: 0.13em;
`;
