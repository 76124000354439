import React from 'react';
import { useSetRecoilState } from 'recoil';
import {
  forecastPanelViewSelector,
  ForecastTab,
  investmentOverrideSearchQuery,
  investmentOverrideShouldScrollToAddForm,
  selectedInvestmentOverrideAtomSyncedWithOverrideType,
} from 'venn-state';
import { ButtonIcon, TooltipPosition } from 'venn-ui-kit';
import type { MenuOpenerProps } from './ForecastsMenuLauncher';

interface ForecastsMenuLinkProps extends MenuOpenerProps {
  tooltip: string;
  usePortal?: boolean;
}

const ForecastsMenuLink = ({
  onClick,
  isReadOnly,
  openResidualForecastTarget,
  tooltip,
  usePortal,
}: ForecastsMenuLinkProps) => {
  const operation = openResidualForecastTarget?.isManualOverride ? 'EDIT' : 'CREATE';

  const setView = useSetRecoilState(forecastPanelViewSelector);
  const setSelectedResidualOverride = useSetRecoilState(
    selectedInvestmentOverrideAtomSyncedWithOverrideType(operation),
  );
  const setSearchQuery = useSetRecoilState(investmentOverrideSearchQuery);
  const setShouldScrollToAddForm = useSetRecoilState(investmentOverrideShouldScrollToAddForm);

  return (
    <ButtonIcon
      iconType="compass"
      onClick={() => {
        setView({
          tab: ForecastTab.InvestmentForecast,
          detail: {
            type: 'Empty',
          },
        });
        if (!isReadOnly) {
          setSelectedResidualOverride(openResidualForecastTarget);
          operation === 'EDIT' && setSearchQuery(openResidualForecastTarget?.fundName ?? '');
          operation === 'CREATE' && setShouldScrollToAddForm(true);
        }
        onClick();
      }}
      tooltip={tooltip}
      tooltipPosition={TooltipPosition.Top}
      className="qa-forecast-override-link"
      size={15}
      usePortal={usePortal}
    />
  );
};

export default ForecastsMenuLink;
