import React from 'react';

import { capitalize } from 'lodash';

import { Dates, type ComputedDateRange } from 'venn-utils';
import type { FrequencyEnum } from 'venn-api';

import { Block } from '../../core';

interface DefaultDateRangeProps {
  from?: number;
  to?: number;
  dateRange?: ComputedDateRange;
  frequency?: FrequencyEnum;
}

export const DefaultDateRange = ({
  dateRange,
  from: fromOverride,
  to: toOverride,
  frequency: frequencyOverride,
}: DefaultDateRangeProps) => {
  const from = fromOverride ?? dateRange?.range.from;
  const to = toOverride ?? dateRange?.range.to;
  const frequency = frequencyOverride ?? dateRange?.frequency;

  const frequencyText = capitalize(frequency ?? '--');
  const fromText = from && frequency ? Dates.toDDMMMYYYY(from, frequency) : '--';
  const toText = to && frequency ? Dates.toDDMMMYYYY(to, frequency) : '--';
  const rangeText = `${fromText} - ${toText}`;

  return (
    <>
      <Block.MetadataItem iconType="clock">{frequencyText}</Block.MetadataItem>
      <Block.MetadataItem iconType="calendar">{rangeText}</Block.MetadataItem>
    </>
  );
};
