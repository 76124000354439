import React, { useContext, forwardRef } from 'react';
import styled from 'styled-components';

import { StudioSidePanelContext } from '../../../../contexts';
import { buttonize, withSuspense } from 'venn-utils';

import { type HTMLProps } from '../factory';
import { useBlockContext } from '../../../contexts/block-context';
import { useRecoilValue } from 'recoil';
import { blockDisplayHeader } from 'venn-state';

export interface BlockRootProps extends HTMLProps<'div'> {
  id: string;
  children: React.ReactNode;
}

export const BLOCK_CONTAINER_CLASS = 'venn-block-container';

const InternalBlockRoot = forwardRef<HTMLDivElement, BlockRootProps>((props, ref) => {
  const { id, children } = props;

  const { blockRef } = useBlockContext();
  const { onSelectBlock } = useContext(StudioSidePanelContext);
  const title = useRecoilValue(blockDisplayHeader(id));

  return (
    <div
      ref={ref}
      id={id}
      key={id}
      data-testid={`qa-${lowerCaseHeaderName(title)}`}
      style={{
        width: '100%',
      }}
    >
      <div
        {...buttonize((e) => {
          e.stopPropagation();
          onSelectBlock(id, { scrollIntoView: false });
        })}
        ref={blockRef}
      >
        <Wrapper className={BLOCK_CONTAINER_CLASS} id={id}>
          {children}
        </Wrapper>
      </div>
    </div>
  );
});

InternalBlockRoot.displayName = 'BlockRoot';

export const BlockRoot = withSuspense(null, InternalBlockRoot);

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const lowerCaseHeaderName = (header: string) => header.toLowerCase().replace(/ /g, '-');
