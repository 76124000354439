import React, { useMemo } from 'react';
import styled from 'styled-components';
import type { ColDef, RowSelectionOptions, SelectionChangedEvent } from 'ag-grid-community';

import type { BulkManageRow } from './types';
import { DataGrid } from '../data-grid';
import { GetColor } from 'venn-ui-kit';
import type { AgGridReact } from 'ag-grid-react';

const defaultColumnStyling = {
  padding: '0 5px',
};

const rowSelection: RowSelectionOptions<RowData> = {
  mode: 'multiRow',
  groupSelects: 'descendants',
  headerCheckbox: false,
  checkboxes: false,
  hideDisabledCheckboxes: true,
  isRowSelectable: ({ data }) => !data?.secondary,
};

const defaultColDef: ColDef<RowData> = {
  resizable: false,
  suppressHeaderMenuButton: true,
  suppressMovable: true,
  editable: false,
  cellStyle: defaultColumnStyling,
};

const autoGroupColumnDef: ColDef<RowData> = {
  headerName: 'Name',
  flex: 5,
  cellRenderer: 'agGroupCellRenderer',
  cellRendererParams: {
    checkbox: true,
    suppressCount: true,
    suppressEnterExpand: true,
    suppressDoubleClickExpand: true,
  },
};

type RowData = BulkManageRow & { path: string[] };
const getDataPath = (data: RowData) => {
  return data.path;
};

const autoSizeStrategy = {
  type: 'fitCellContents',
} as const;

interface BulkDataGridProps {
  gridRef?: React.MutableRefObject<AgGridReact | null>;
  rowData: BulkManageRow[];
  columnDefs: ColDef[];
  onSelectionChanged: (event: SelectionChangedEvent) => void;
}

export const BulkDataGrid = React.memo(({ gridRef, rowData, columnDefs, onSelectionChanged }: BulkDataGridProps) => {
  const transformedRowData: RowData[] = useMemo(
    () =>
      rowData.map((row) => ({
        ...row,
        path: [...row.strategyPath, row.name],
      })),
    [rowData],
  );

  return (
    <GridContainer>
      <DataGrid<RowData>
        gridRef={gridRef}
        rowSelection={rowSelection}
        defaultColDef={defaultColDef}
        columnDefs={columnDefs}
        rowData={transformedRowData}
        domLayout="autoHeight"
        rowHeight={37}
        onSelectionChanged={onSelectionChanged}
        suppressContextMenu
        treeData
        autoSizeStrategy={autoSizeStrategy}
        autoGroupColumnDef={autoGroupColumnDef}
        getDataPath={getDataPath}
        groupDefaultExpanded={-1} // -1 expands all groups by default
      />
    </GridContainer>
  );
});
BulkDataGrid.displayName = 'BulkDataGrid';

const GridContainer = styled.div`
  font-family: ${(props) => props.theme.Typography.fontFamily};
  margin-bottom: 20px;

  .ag-root-wrapper {
    border: none;
    min-width: 1550px;
  }

  .ag-header-cell {
    font-weight: bold;
    font-size: 12px;
    padding-right: 0;
    padding-left: 5px;
    text-wrap: wrap;
  }

  .ag-header-cell-label {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-end;
  }

  .ag-header-group-cell .ag-react-container,
  .ag-header-cell .ag-react-container {
    width: 100%;
  }

  .ag-cell {
    border-right: 0.5px solid ${GetColor.Grey};
  }
`;
