import { useMutation, useQueryClient } from '@tanstack/react-query';
import { cloneDeep, groupBy, isNil, merge } from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  analysis,
  type AnalysisRequest,
  type MultiPortfolioParseResult,
  persistMultiPortfolioUpload,
  type Portfolio,
  type PortfolioParseResult,
} from 'venn-api';
import { AnalysisSubject, analyticsService, isHistoricalPortfolio } from 'venn-utils';
import getAnalysisSubjects from '../../../../../../../venn-utils/src/analysis/getAnalysisSubjects';
import UserContext from '../../../../../contexts/user-context';
import { DataUploaderMode, DataUploaderView, type MultiPortfolioUploadConfirmationData } from '../../../types';
import { uploadConfig } from '../../../utils';
import { getAllRanges, removeExcludedInvestments, serializePath, sortPortfolio } from '../../review/helpers';
import { MainUploadWrapper } from '../../shared/layout';
import type { HistoricalFundRangeAnalysis } from '../specialized/MultiHistoricalPortfolioContext';
import { ConfirmationModalType } from './MultiPortfolioReviewComponents.ConfirmationModal';
import {
  MultiPortfolioReviewContext,
  type MultiPortfolioReviewContextValue,
  type PortfolioParseResultCategory,
  type PortfolioParseResultWithReviewMetadata,
} from './MultiPortfolioReviewContext';

const useMutablePortfolios = (parsedData: MultiPortfolioParseResult) => {
  const getDefaultPortfolioParseResultCategory = (result: PortfolioParseResult): PortfolioParseResultCategory => {
    if (isNil(result.parsedPortfolio.id)) {
      return 'new';
    }
    return 'existing';
  };
  const initialState = useMemo<PortfolioParseResultWithReviewMetadata[]>(
    () =>
      cloneDeep(parsedData.portfolioParseResults).map((parsedData, originalIndex) => ({
        ...parsedData,
        parsedPortfolio: sortPortfolio(parsedData.parsedPortfolio),
        originalIndex,
        category: getDefaultPortfolioParseResultCategory(parsedData),
        excludedInvestments: new Set(),
      })),
    [parsedData.portfolioParseResults],
  );

  const [parsedResults, setParsedResults] = useState(initialState);

  const updatePortfolio = (index: number, portfolio: Portfolio) => {
    const newParseResults = [...parsedResults];
    newParseResults[index] = {
      ...newParseResults[index],
      parsedPortfolio: sortPortfolio(portfolio),
    };
    setParsedResults(newParseResults);
  };

  const excludePortfolio = (index: number) => {
    const newParseResults = [...parsedResults];
    newParseResults[index] = {
      ...newParseResults[index],
      category: 'excluded',
    };
    setParsedResults(newParseResults);
  };

  const includePortfolio = (index: number) => {
    const newParseResults = [...parsedResults];
    newParseResults[index] = {
      ...newParseResults[index],
      category: getDefaultPortfolioParseResultCategory(newParseResults[index]),
    };
    setParsedResults(newParseResults);
  };

  const excludeInvestment = (index: number, path: number[]) => {
    const newParseResults = [...parsedResults];

    const excludedInvestments = new Set(newParseResults[index].excludedInvestments);
    excludedInvestments.add(serializePath(path).value);

    newParseResults[index] = {
      ...newParseResults[index],
      excludedInvestments,
    };
    setParsedResults(newParseResults);
  };

  const includeInvestment = (index: number, path: number[]) => {
    const newParseResults = [...parsedResults];

    const excludedInvestments = new Set(newParseResults[index].excludedInvestments);
    excludedInvestments.delete(serializePath(path).value);

    newParseResults[index] = {
      ...newParseResults[index],
      excludedInvestments,
    };
    setParsedResults(newParseResults);
  };

  return {
    parsedResults,
    updatePortfolio,
    excludePortfolio,
    includePortfolio,
    excludeInvestment,
    includeInvestment,
  };
};

const useFetchRangeAnalysisForPortfolios = (): ((
  portfolios: Portfolio[],
) => Promise<HistoricalFundRangeAnalysis | undefined>) => {
  const queryClient = useQueryClient();

  return useCallback(
    async (portfolios: Portfolio[]) => {
      const isHistorical = portfolios.some((portfolio) => isHistoricalPortfolio(portfolio));

      if (!isHistorical) {
        return undefined;
      }

      const rangeAnalysisResponse = await queryClient.fetchQuery(
        [useFetchRangeAnalysisForPortfolios, portfolios],
        async () => {
          const subjects = portfolios
            .map((portfolio) => new AnalysisSubject(portfolio, 'portfolio'))
            .flatMap((subject) => getAnalysisSubjects(subject));

          const request: Partial<AnalysisRequest> = {
            analyses: [
              {
                analysisType: 'RANGE_ANALYSIS',
              },
            ],
            subjects,
          };

          return (await analysis(request)).content.analyses?.[0]?.rangeAnalysis;
        },
      );

      const allRanges = (rangeAnalysisResponse?.rangeAnalyses ?? []).map(getAllRanges);
      return allRanges.reduce((acc, ranges) => merge(acc, ranges), {});
    },
    [queryClient],
  );
};

const useSavePortfoliosMutation = () => {
  return useMutation(async (portfolios: Portfolio[]) => {
    return (
      await persistMultiPortfolioUpload({
        portfolioPersistInputs: portfolios.map((portfolio) => ({
          portfolio,
          writeMode: 'OVERWRITE',
        })),
      })
    ).content;
  });
};

const useMultiPortfolioConfirmationModals = () => {
  const [confirmationModalType, setConfirmationModalType] = useState<ConfirmationModalType>(ConfirmationModalType.None);

  const closeConfirmationModal = useCallback(() => {
    setConfirmationModalType(() => ConfirmationModalType.None);
  }, [setConfirmationModalType]);

  const openDiscardConfirmationModal = useCallback(() => {
    setConfirmationModalType(() => ConfirmationModalType.Discard);
  }, [setConfirmationModalType]);

  const openUploadConfirmationModal = useCallback(() => {
    setConfirmationModalType(() => ConfirmationModalType.Upload);
  }, [setConfirmationModalType]);

  return {
    confirmationModalType,
    closeConfirmationModal,
    openDiscardConfirmationModal,
    openUploadConfirmationModal,
  };
};

export type RootProps = Readonly<{
  parsedData: MultiPortfolioParseResult;
  goBackToUploadStep: () => void;
  goToUploadConfirmation: (data: MultiPortfolioUploadConfirmationData) => void;
  children: React.ReactNode;
}>;

export const Root = ({ parsedData, goBackToUploadStep, goToUploadConfirmation, children }: RootProps) => {
  const { confirmationModalType, closeConfirmationModal, openDiscardConfirmationModal, openUploadConfirmationModal } =
    useMultiPortfolioConfirmationModals();

  const { parsedResults, updatePortfolio, includePortfolio, excludePortfolio, excludeInvestment, includeInvestment } =
    useMutablePortfolios(parsedData);

  useEffect(() => {
    analyticsService.uploadStepViewed({
      dataType: uploadConfig[DataUploaderMode.Portfolios].dataType,
      step: 1,
      stepName: DataUploaderView.Review,
    });
  }, []);

  const groupedParsedData = useMemo(() => groupBy(parsedResults, 'category'), [parsedResults]);
  const [newParsedData, existingParsedData, excludedParsedData] = [
    groupedParsedData.new ?? [],
    groupedParsedData.existing ?? [],
    groupedParsedData.excluded ?? [],
  ];
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const savePortfoliosMutation = useSavePortfoliosMutation();

  const fetchRangeAnalysisForPortfolios = useFetchRangeAnalysisForPortfolios();

  const { currentContext } = useContext(UserContext);

  const savePortfolios = useCallback(() => {
    // Prepare portfolios to be saved. Here we make sure to ignore excluded portfolios
    // as well as make sure we also remove all excluded investments so that they aren't sent to the backend
    const portfoliosToSave = parsedResults
      .filter((result) => result.category !== 'excluded')
      .map((result) => ({
        ...removeExcludedInvestments(result.parsedPortfolio, result.excludedInvestments),
        ownerContextId: currentContext,
      }));

    Promise.allSettled([
      savePortfoliosMutation.mutateAsync(portfoliosToSave),
      fetchRangeAnalysisForPortfolios(portfoliosToSave),
    ]).then(([persistResultSettledResult, rangeAnalysisSettledResult]) => {
      if (persistResultSettledResult.status === 'rejected') {
        const failureReason = persistResultSettledResult.reason;
        analyticsService.uploadStepFailed({
          step: 1,
          stepName: DataUploaderView.Review,
          dataType: uploadConfig[DataUploaderMode.Portfolios].dataType,
          error: failureReason?.content?.message ?? failureReason?.message,
        });
        return;
      }

      const persistResult = {
        portfolioPersistResults: [
          ...persistResultSettledResult.value.portfolioPersistResults,
          ...excludedParsedData.map((result) => ({
            status: 'SKIPPED' as const,
            portfolio: result.parsedPortfolio,
          })),
        ],
      };
      // We are ok with range analysis failing because we still want to proceed
      // to confirmation page if portfolios were persisted.
      const rangeAnalysis =
        rangeAnalysisSettledResult.status === 'fulfilled' ? rangeAnalysisSettledResult.value : undefined;

      analyticsService.uploadStepCompleted({
        step: 1,
        dataType: uploadConfig[DataUploaderMode.Portfolios].dataType,
        stepName: DataUploaderView.Review,
      });

      goToUploadConfirmation({ persistResult, rangeAnalysis });
    });
  }, [
    currentContext,
    excludedParsedData,
    fetchRangeAnalysisForPortfolios,
    goToUploadConfirmation,
    parsedResults,
    savePortfoliosMutation,
  ]);

  const completeUpload = useCallback(() => {
    if (existingParsedData.length === 0) {
      savePortfolios();
    } else {
      openUploadConfirmationModal();
    }
  }, [existingParsedData.length, openUploadConfirmationModal, savePortfolios]);

  const onBackButtonClick = useCallback(() => {
    if (savePortfoliosMutation.isError) {
      savePortfoliosMutation.reset();
    } else {
      openDiscardConfirmationModal();
    }
  }, [openDiscardConfirmationModal, savePortfoliosMutation]);

  const selectAnotherPortfolio = useCallback(
    (index: number) => {
      setSelectedIndex(index);

      const portfolio = parsedResults[index].parsedPortfolio;
      analyticsService.multiPortfolioUploaderPortfolioChanged({
        portfolioId: portfolio?.id,
        portfolioName: portfolio?.name,
      });
    },
    [parsedResults],
  );
  const context: MultiPortfolioReviewContextValue = useMemo(
    () => ({
      actions: {
        closeConfirmationModal,
        completeUpload,
        excludePortfolio,
        goBackToUploadStep,
        includePortfolio,
        onBackButtonClick,
        openDiscardConfirmationModal,
        openUploadConfirmationModal,
        savePortfolios,
        selectAnotherPortfolio,
        updatePortfolio,
        excludeInvestment,
        includeInvestment,
      },
      data: {
        confirmationModalType,
        excludedParsedData,
        existingParsedData,
        newParsedData,
        parsedResults,
        selectedIndex,
        savePortfoliosMutationState: {
          isError: savePortfoliosMutation.isError,
          isLoading: savePortfoliosMutation.isLoading,
          isIdle: savePortfoliosMutation.isIdle,
        },
      },
    }),
    [
      closeConfirmationModal,
      completeUpload,
      excludePortfolio,
      goBackToUploadStep,
      includePortfolio,
      onBackButtonClick,
      openDiscardConfirmationModal,
      openUploadConfirmationModal,
      savePortfolios,
      selectAnotherPortfolio,
      updatePortfolio,
      excludeInvestment,
      includeInvestment,
      confirmationModalType,
      excludedParsedData,
      existingParsedData,
      newParsedData,
      parsedResults,
      selectedIndex,
      savePortfoliosMutation.isError,
      savePortfoliosMutation.isLoading,
      savePortfoliosMutation.isIdle,
    ],
  );
  return (
    <MultiPortfolioReviewContext.Provider value={context}>
      <MainUploadWrapper>{children}</MainUploadWrapper>
    </MultiPortfolioReviewContext.Provider>
  );
};
