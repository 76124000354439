import { isNil } from 'lodash';
import { v4 } from 'uuid';
import type { AnyObject } from '../type/any';

export type BroadcastMessageEvent<T> = MessageEvent<BroadcastMessage<T>>;

type BroadcastMessage<T> = T & {
  broadcastSenderId: string;
};

type RemovableListener = Readonly<{
  remove: () => void;
  type: keyof BroadcastChannelEventMap;
  options?: boolean | AddEventListenerOptions;
}>;

const broadcastSenderId = v4();

/**
 * Wraps BroadcastChannel with safer types and additional functionality.
 *
 * Returns undefined if environment does not support BroadcastChannel.
 */
export function createBroadcastChannel<T extends AnyObject>(
  channelName: string,
  {
    ignoreSelf,
  }: {
    /** Prevent this window from receiving its own messages */
    ignoreSelf?: boolean;
  },
) {
  if (isNil(global.BroadcastChannel)) return undefined;
  const channel = new global.BroadcastChannel(channelName);

  return {
    postMessage: (message: T) => {
      const broadcastMessage: BroadcastMessage<T> = { ...message, broadcastSenderId };
      channel.postMessage(broadcastMessage);
    },
    addEventListener: (
      type: 'message',
      originalListener: (ev: BroadcastMessageEvent<T>) => unknown,
      options?: boolean | AddEventListenerOptions,
    ) => {
      const listener = (event: BroadcastMessageEvent<T>) =>
        ignoreSelf && broadcastSenderId === event.data.broadcastSenderId ? undefined : originalListener(event);
      channel.addEventListener(type, listener, options);
      return {
        remove: () => channel.removeEventListener(type, listener, options),
        type,
        options,
      };
    },
    removeEventListener: (listener: RemovableListener) => listener.remove(),
  };
}
