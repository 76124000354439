import React from 'react';
import { compact } from 'lodash';
import styled, { css } from 'styled-components';
import type { ItemId, ProxyCategoryMapping, ProxyOption, ProxyTypeEnum } from 'venn-api';
import { getCustomProxyOptionForFund, getProxyOptionsForFund } from 'venn-api';
import {
  Body1,
  GetColor,
  Hint,
  Icon,
  Notifications,
  NotificationType,
  portalMenuIgnoreActivityClassName,
  Radio,
  Tooltip,
} from 'venn-ui-kit';
import InterpolationSnapshot from '../../../proxy-cell/InterpolationSnapshot';
import SearchMenuBar from '../../../../search-menu/SearchMenuBar';
import {
  createProxyOptionsKey,
  getFormattedProxyType,
  logExceptionIntoSentry,
  ReturnsCalculations,
  useFetchApiConditionally,
  useQuery,
} from 'venn-utils';
import ModalFooter from '../../../../modal/ModalFooter';
import type { FundProxy } from '../../types';
import { getCategoryProxyTypeMetadata, sortCategoryOptions, typeSupportsDesmooth } from '../utils';
import { InterpolationThresholdExceededWarning } from './InterpolationThresholdExceededWarning';

const defaultCustomProxyOption: ProxyOption = {
  category: {
    category: null!, // custom proxies have null even though they shouldn't
    displayName: 'Custom',
    indexId: '',
    indexName: '',
  },
  correlation: 1.0,
  interpolatedCumulativeReturn: [],
  proxiedCumulativeReturn: [],
  rawCumulativeReturn: [],
  valid: true,
};

export interface CategoryOptionProps {
  selectedProxyId?: string;
  setSelectedProxyId: (id: string | undefined) => void;
  onSelect: (category: ProxyCategoryMapping, numLags?: number) => void;
  fundId: string;
  proxy: FundProxy | null;
  proxyType: ProxyTypeEnum;
  numLags?: number;
  closePicker: () => void;
  onSelectedOptionLagUpdate: (value: number) => void;
  disableSave: boolean;
}

interface OptionRowProps {
  formattedProxyName: string;
  option: ProxyOption;
  selectedProxyId?: string;
  setSelectedProxyId: (id: string | undefined) => void;
  customProxyFundId?: string;
  classPrefix?: string;
  onOptionClick: (option: ProxyOption) => () => void;
  allCategoryIds: ItemId[];
  isCustom: boolean;
}
const CategoryOptionRow = ({
  option,
  selectedProxyId,
  setSelectedProxyId,
  classPrefix,
  onOptionClick,
  isCustom,
  allCategoryIds,
  formattedProxyName,
}: OptionRowProps) => {
  // If we can't display a cumulative return, it must be really bad so just don't allow it to be selected
  const isDisabled = !option.proxiedCumulativeReturn;
  return (
    <>
      <OptionRow key={option.category.category}>
        <OptionButton
          type="button"
          data-testid={`qa-${classPrefix}-option`}
          onClick={onOptionClick(option)}
          disabled={isDisabled}
        >
          <StyledRadio
            inputId={option.category.displayName}
            checked={selectedProxyId === option.category.indexId}
            disabled={isDisabled}
          />
          <OptionButtonContent>
            <OptionRowCol>
              <CategoryName disabled={isDisabled}>
                {option.category.displayName}
                {option.warning !== 'BELOW_THRESHOLD' &&
                  !isCustom && ( // only valid non-custom proxies can be recommended
                    <RecommendedPill className="category-recommended">
                      <Icon type="star-of-life" />
                      Recommended
                    </RecommendedPill>
                  )}
                {option.warning === 'BELOW_THRESHOLD' && (
                  <WarningIconWrapper className="category-warning">
                    <InterpolationThresholdExceededWarning />
                  </WarningIconWrapper>
                )}
              </CategoryName>
              <ProxyNameWrapper>
                <CategoryIndex disabled={isDisabled}>{option.category.indexName}</CategoryIndex>
              </ProxyNameWrapper>
            </OptionRowCol>
            <InterpolationWrapper className="option-row-col">
              <InterpolationSnapshot
                primarySeries={ReturnsCalculations.toDataPoints(option?.rawCumulativeReturn ?? [])}
                overlaySeries={ReturnsCalculations.toDataPoints(option?.interpolatedCumulativeReturn ?? [])}
              />
            </InterpolationWrapper>
          </OptionButtonContent>
        </OptionButton>
      </OptionRow>
      {isCustom && (
        <SearchMenuBarContainer>
          <StyledSearchMenuBar
            classNamePrefix={portalMenuIgnoreActivityClassName}
            excludedItems={allCategoryIds}
            autofocus={false}
            customPlaceholder={`Search for any investment to add or update custom ${formattedProxyName}.`}
            location="proxy-picker"
            onSelected={(item) => {
              setSelectedProxyId(item.searchResult?.fundId);
            }}
            investmentsOnly
            privateAssetSearchMode="PUBLIC_ONLY"
          />
        </SearchMenuBarContainer>
      )}
    </>
  );
};

const findCustomProxyId = (
  selectedProxyId: string | undefined,
  proxy: FundProxy | null,
  availableCategories: ProxyOption[] | undefined,
): string | undefined => {
  const candidate = selectedProxyId ?? proxy?.proxyId;
  if (!candidate) {
    return undefined;
  }

  if (availableCategories?.find((option) => option.category.indexId === selectedProxyId)) {
    return undefined; // if it is among the categories, it shouldn't be treated as custom, no matter what it is
  } // otherwise the candidate is correct custom proxy
  return candidate;
};
const CategoryOptions = ({
  selectedProxyId,
  setSelectedProxyId,
  onSelect,
  fundId,
  proxy,
  closePicker,
  proxyType,
  numLags,
  onSelectedOptionLagUpdate,
  disableSave,
}: CategoryOptionProps) => {
  const { data: proxyOptions = [] } = useQuery(
    createProxyOptionsKey(fundId, proxyType, numLags ?? 0),
    () =>
      getProxyOptionsForFund(fundId, proxyType, numLags ?? 0)
        .then((response) => response.content)
        .catch((e) => {
          if (e?.status === 404) {
            return [];
          }
          throw e;
        }),
    {
      onError: (error) => {
        Notifications.notify('Unable to load suggested proxy categories', NotificationType.ERROR);
        return logExceptionIntoSentry(error as Error);
      },
      suspense: true,
    },
  );

  const customProxyFundId = findCustomProxyId(selectedProxyId, proxy, proxyOptions);
  const sortedProxyOptions = proxyOptions.sort(sortCategoryOptions);

  const customProxyOption =
    useFetchApiConditionally(
      customProxyFundId !== undefined,
      getCustomProxyOptionForFund,
      fundId,
      customProxyFundId!,
      proxyType,
      numLags,
    )?.result ?? defaultCustomProxyOption;

  const selectedItem = compact([customProxyOption, ...sortedProxyOptions])
    .map((option) => option.category)
    .find((entity) => entity.indexId === selectedProxyId);
  const onOptionClick = (option: ProxyOption) => () => {
    setSelectedProxyId(option.category.indexId);
    if (typeSupportsDesmooth(proxyType) && option.suggestedNumLags) {
      onSelectedOptionLagUpdate(option.suggestedNumLags);
    }
  };

  const allCategoryIds = sortedProxyOptions.map((option): ItemId => {
    return {
      id: option.category.indexId,
      type: 'FUND',
    };
  });

  const noLagsForDesmoothing = !numLags && typeSupportsDesmooth(proxyType);
  const proxyTypeMetadata = getCategoryProxyTypeMetadata(proxyType);
  const lowerCaseProxyTypeName = getFormattedProxyType(proxyType).toLowerCase();
  return (
    <>
      <ContentContainer>
        <Options>
          <PreHeaderRow>
            <BoldHeaderSpan>Best Matches</BoldHeaderSpan>
          </PreHeaderRow>
          <HeaderRow>
            <div className="option-row-col">
              <Hint>
                <StyledHeaderSpan>Category </StyledHeaderSpan>
                <Tooltip
                  content={`Categories represent public indices that can be used for ${lowerCaseProxyTypeName}. Click to find out more.`}
                >
                  <StyledLink target="_blank" rel="noopener noreferrer" href={proxyTypeMetadata.helpLink}>
                    <Icon type="question-circle" />
                  </StyledLink>
                </Tooltip>
              </Hint>
            </div>
            <CumulativeHeaderWrapper className="option-row-col">
              <Hint>
                <StyledHeaderSpan>Cumulative Return</StyledHeaderSpan>
              </Hint>
            </CumulativeHeaderWrapper>
          </HeaderRow>
          {[customProxyOption].concat(compact([...sortedProxyOptions])).map((option) => (
            <CategoryOptionRow
              key={option?.category.displayName}
              formattedProxyName={lowerCaseProxyTypeName}
              option={option}
              onOptionClick={onOptionClick}
              selectedProxyId={selectedProxyId}
              setSelectedProxyId={setSelectedProxyId}
              customProxyFundId={customProxyFundId}
              classPrefix={proxyTypeMetadata.classPrefix}
              isCustom={customProxyOption === option}
              allCategoryIds={allCategoryIds}
            />
          ))}
        </Options>
      </ContentContainer>
      <StyledModalFooter
        submitOnEnter
        primaryLabel="Save"
        primaryDisabled={disableSave || noLagsForDesmoothing}
        primaryClassName="proxy-primary-save-button"
        onPrimaryClick={() => selectedItem && onSelect(selectedItem, numLags)}
        onSecondaryClick={closePicker}
        secondaryLabel="Cancel"
      />
    </>
  );
};

export default CategoryOptions;

const StyledLink = styled.a`
  color: ${GetColor.Black};
`;

const StyledHeaderSpan = styled.span`
  font-size: 12px;
  color: ${GetColor.Black};
`;

const BoldHeaderSpan = styled.span`
  font-size: 12px;
  font-weight: 700;
`;

const Options = styled.ul`
  list-style: none;
  padding: 0;
`;

const rowStyle = css`
  padding: 2px 0px;
  .option-row-col {
    height: 100%;
    line-height: 24px;
    padding: 4px;
  }
`;
const HeaderRow = styled.li`
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  height: 26px;

  ${rowStyle}

  .option-row-col {
    padding: 0px;
  }
`;

const PreHeaderRow = styled(HeaderRow)`
  margin-bottom: 0px;
  height: 20px;
  line-height: 20px;
`;

const OptionRow = styled.li`
  &:hover {
    background: ${GetColor.PaleGrey};

    .category-warning {
      opacity: 1;
    }
  }
  button {
    height: 45px;
    width: 100%;
    display: flex;
    color: inherit;
    &[disabled],
    &:disabled {
      color: ${GetColor.HintGrey};
    }
    transition: all 0.2s;
  }
  ${rowStyle}
`;

const OptionButton = styled.button`
  align-items: center;
`;

const OptionButtonContent = styled.div`
  justify-content: space-between;
  align-items: center;
  display: flex;
  width: 100%;
`;

const InterpolationWrapper = styled.div`
  width: 300px;
`;

const ProxyNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
`;

const OptionRowCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 300px;
`;

const CategoryName = styled(Body1)<{ disabled?: boolean }>`
  ${({ disabled }) =>
    disabled &&
    css`
      color: ${GetColor.Grey};
    `}
`;

const StyledRadio = styled(Radio)`
  margin: 0 10px;
`;

const RecommendedPill = styled.span`
  padding: 4px 6px;
  font-size: 10px;
  border-radius: 2px;
  font-weight: 700;
  margin-left: 5px;
  background-color: ${GetColor.Primary.Light};
`;

const CategoryIndex = styled.div<{ disabled?: boolean }>`
  font-size: 10px;
  line-height: 10px;
  font-style: italic;
  color: ${GetColor.HintGrey};
  ${({ disabled }) =>
    disabled &&
    css`
      color: ${GetColor.Grey};
    `}
`;

const StyledSearchMenuBar = styled(SearchMenuBar)`
  margin-bottom: 10px;
`;

const SearchMenuBarContainer = styled.div`
  margin: 4px 0px 0px 30px;
`;

const ContentContainer = styled.div`
  padding: 0px 28px 20px 28px;
`;

const CumulativeHeaderWrapper = styled.div`
  width: 300px;
`;

const WarningIconWrapper = styled.div`
  padding-left: 6px;
  display: inline-block;
  opacity: 0;
  transition: all 0.2s ease;
`;

export const StyledModalFooter = styled(ModalFooter)`
  margin-top: 0px;
`;
