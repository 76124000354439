import { ItemType } from './itemType';
import type { VennColors } from '../../../style';
import type { AnalysisViewTypeEnum } from 'venn-api';

export const getItemColor = (Colors: VennColors, item: ItemType | AnalysisViewTypeEnum): string => {
  switch (item) {
    case ItemType.Benchmark:
    case ItemType.Analysis:
      return Colors.DEPRECATED_DataLineColor.Pink;
    case ItemType.Portfolio:
    case ItemType.PrivatePortfolio:
    case ItemType.Strategy:
    case ItemType.HistoricalPortfolio:
      return Colors.DEPRECATED_DataLineColor.DarkBlue;
    case ItemType.File:
      return Colors.DEPRECATED_DataLineColor.Green;
    case ItemType.Investment:
    case ItemType.PrivateInvestment:
      return Colors.DEPRECATED_DataLineColor.Gold;
    case ItemType.Compare:
      return Colors.DEPRECATED_DataLineColor.DarkBlue;
    case ItemType.Custom:
      return Colors.DEPRECATED_DataLineColor.Gold;
    case ItemType.StudioReport:
    case ItemType.ReportTemplate:
      return Colors.DEPRECATED_DivergingColor.B4;
    case ItemType.EditTemplate:
      return Colors.DEPRECATED_DataLineColor.DarkBlue;
    case ItemType.Tag:
      return Colors.Primary.Dark;
    case ItemType.CommonBenchmark:
    case ItemType.Article:
      return Colors.Black;
    case ItemType.Studio:
    case ItemType.StudioTearsheet:
    case ItemType.TearsheetTemplate:
      return Colors.Gold;
    case ItemType.PeerGroup:
    case ItemType.SavedSearch:
      return Colors.DEPRECATED_DivergingColor.B4;
    default:
      return Colors.DEPRECATED_DataBarColor.LightGold;
  }
};
