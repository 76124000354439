import type { CustomizedBlock, Analysis, ReturnsFactorAnalysis, AnalysisTypeEnum } from 'venn-api';
import type { ExcelCell, CustomizableMetric, TabularDataTypeEnum, CategoryMetric } from 'venn-utils';
import { RESIDUAL_FACTOR_ID, RISK_FREE_RATE_FACTOR_ID, TOTAL_FACTOR_ID } from 'venn-utils';
import { get } from 'lodash';
import { ItemType } from 'venn-ui-kit';
import type { HorizontalBarsItem, BarValue, HeaderItem } from './types';
import { getRelativeValue } from '../../../logic/parsers';
import { EMPTY } from '../../../logic/exportUtils';
import { type StudioRequestSubject } from 'venn-state';

export const ITEM_WIDTH = 80;
export const TOOLTIP_ROW_HEIGHT = 14;
export const TOOLTIP_WIDTH = 310;
export const BOTTOM_MESSAGE_HEIGHT = 40;

/** Find the largest xAxis bound, exclude total in sum if it's a waterfall bar chart */
export const getLargestNumber = (items: HorizontalBarsItem[], isWaterFall: boolean) => {
  let sum = 0;
  let max = 0;
  items.forEach((item) => {
    const primaryValue = item.values[0]?.value;
    if (primaryValue) {
      let totalNum = 0;
      if (item.type !== 'TOTAL') {
        sum += primaryValue;
      } else {
        totalNum = primaryValue;
      }
      max = isWaterFall
        ? Math.max(max, Math.abs(sum), Math.abs(totalNum))
        : Math.max(max, Math.abs(primaryValue), Math.abs(totalNum));
    }
  });

  return max === 0 ? 0 : getBound(max);
};

const EPSILON = 0.000000001;
/** Existing logic to get the bound */
const getBound = (value: number) => {
  const size = value * 2;
  const order = Math.floor(Math.log(size) / Math.LN10 + EPSILON);
  const modifier = 10 ** order;
  const tickCount = size / modifier;
  let step = modifier;
  if (tickCount < 2) {
    step /= 4;
  } else if (tickCount < 6) {
    step /= 2;
  } else if (tickCount > 9) {
    step *= 2;
  }

  return Math.ceil(value / step) * step;
};

export const getFactorsItems = (
  metric: CustomizableMetric,
  benchmark: boolean,
  selectedBlock: CustomizedBlock,
  analysesGroup: (Analysis | undefined)[][] | undefined,
  availableFactorMetrics: CategoryMetric[],
): { items: HorizontalBarsItem[]; excludedFactors: number } => {
  const relativeToTotal = !!selectedBlock?.contributionToPercentage;

  const allIncludedFactors = removeAllExcludedFactors(
    availableFactorMetrics.map((f) => ({
      id: f.id,
      label: f.name,
      values: getFactorValues(relativeToTotal, f.id, metric, benchmark, analysesGroup),
      type:
        f.id === TOTAL_FACTOR_ID
          ? 'TOTAL'
          : f.id === RISK_FREE_RATE_FACTOR_ID
            ? 'RISK-FREE'
            : f.id === RESIDUAL_FACTOR_ID
              ? 'RESIDUAL'
              : 'NORMAL',
      description: f.description,
    })),
  );
  const items = allIncludedFactors.filter((factor) => selectedBlock.selectedFactors.includes(factor.id));

  const availableNormalFactors = availableFactorMetrics.filter(
    (f) => f.id !== TOTAL_FACTOR_ID && f.id !== RISK_FREE_RATE_FACTOR_ID && f.id !== RESIDUAL_FACTOR_ID,
  );
  const includedNormalFactors = allIncludedFactors.filter(
    (f) => f.id !== TOTAL_FACTOR_ID && f.id !== RISK_FREE_RATE_FACTOR_ID && f.id !== RESIDUAL_FACTOR_ID,
  );
  const excludedFactors = availableNormalFactors.length - includedNormalFactors.length;

  return { items, excludedFactors };
};

const getTotalValue = (analysisType: AnalysisTypeEnum, result?: ReturnsFactorAnalysis) => {
  switch (analysisType) {
    case 'FACTOR_CONTRIBUTION_TO_RISK':
      return result?.annualizedTotalRisk;
    case 'FACTOR_CONTRIBUTION_TO_RETURN':
      return result?.periodTotalReturn;
    default:
      return undefined;
  }
};

const removeAllExcludedFactors = (items: HorizontalBarsItem[]) =>
  items.filter((item) => item.values.find((v) => v.value !== undefined));

export const isSpecialRows = (id: string) => {
  const specialRows = [TOTAL_FACTOR_ID, RISK_FREE_RATE_FACTOR_ID, RESIDUAL_FACTOR_ID];
  return specialRows.includes(id);
};

const getFactorValues = (
  relativeToTotal: boolean,
  factorId: string,
  metric: CustomizableMetric,
  benchmark: boolean,
  analysesGroup?: (Analysis | undefined)[][],
): BarValue[] => {
  return (analysesGroup ?? []).map((analyses) => {
    const analysis = analyses.find((a) => a?.analysisType === metric.analysisType);
    const result = get(analysis, [metric.analysisResultKey!, benchmark ? 1 : 0]) as ReturnsFactorAnalysis | undefined;

    if (!analysis || !result) {
      return {};
    }
    const contribution = result.factors.find((factor) => String(factor.id) === factorId);
    let barValue: BarValue = {
      value: contribution?.marginalContribution,
      tValue: contribution?.tstat,
      significant: contribution?.significant,
    };
    const totalValue = getTotalValue(analysis.analysisType, result);

    if (factorId === TOTAL_FACTOR_ID) {
      barValue = { value: totalValue, significant: true };
    } else if (factorId === RISK_FREE_RATE_FACTOR_ID && analysis.analysisType === 'FACTOR_CONTRIBUTION_TO_RETURN') {
      barValue = { value: result?.riskFreeReturn, significant: true };
    } else if (factorId === RESIDUAL_FACTOR_ID) {
      switch (analysis.analysisType) {
        case 'FACTOR_CONTRIBUTION_TO_RISK':
          barValue = {
            value: result?.residualMarginalRisk,
            significant: result?.residualSignificant,
            tValue: result?.residualTStat,
          };
          break;
        case 'FACTOR_CONTRIBUTION_TO_RETURN':
          barValue = {
            value: result?.periodResidualReturn,
            significant: result?.residualSignificant,
            tValue: result?.residualTStat,
          };
      }
    }

    const relativeValue = getRelativeValue(relativeToTotal, totalValue, barValue.value);
    return { ...barValue, value: relativeValue };
  });
};

export const getWaterfallStartPositions = (items: HorizontalBarsItem[]) => {
  let sum = 0;
  const startPositions: number[] = [];
  items.forEach((item) => {
    if (item.type === 'TOTAL') {
      startPositions.push(0);
    } else {
      startPositions.push(sum);
      sum += item.values[0]?.value ?? 0;
    }
  });
  return startPositions;
};

export const getRelativeSize = (value?: number, maxValue?: number) => (value && maxValue ? value / maxValue / 2 : 0);

export const getItemTypeFromHeader = ({ id, isHistorical }: { id: string; isHistorical: boolean }) => {
  if (Number.isNaN(Number(id))) {
    return ItemType.Investment;
  }
  if (isHistorical) {
    return ItemType.HistoricalPortfolio;
  }
  return ItemType.Portfolio;
};

export const getFactorBarExportableExcel = (
  headers: HeaderItem[],
  items: HorizontalBarsItem[],
  dataType: TabularDataTypeEnum,
  benchmark: StudioRequestSubject | undefined,
  relativeToBenchmark: boolean,
) => {
  const header: ExcelCell[] = [EMPTY];
  const body: ExcelCell[][] = items.map((item) => {
    return [
      { value: item.label, bold: true },
      ...[
        ...item.values.flatMap((v) => [
          { value: v.value, percentage: dataType === 'PERCENTAGE', digits: 2 },
          { value: v.tValue, digits: 2 },
        ]),
      ],
    ];
  });
  headers.forEach((item) => {
    const benchmarkRelativeLabel = relativeToBenchmark && benchmark ? ` (Relative to ${benchmark.name})` : '';
    const tStatLabel = relativeToBenchmark && benchmark ? `: T-Stat (Relative to ${benchmark.name})` : ': T-Stat';
    header.push({ value: `${item.exportableLabel}${benchmarkRelativeLabel}`, bold: true });
    header.push({ value: `${item.exportableLabel}${tStatLabel}`, bold: true });
  });
  return [header, ...body];
};
