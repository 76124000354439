import type { QueryStatus, UseQueryResult } from '@tanstack/react-query';
import { useRef } from 'react';

export function getQueriesStatus(queries: UseQueryResult[]): QueryStatus {
  if (queries.some((query) => query.status === 'loading')) return 'loading';
  if (queries.some((query) => query.status === 'error')) return 'error';
  return 'success';
}

/**
 * Extract the queries' data, returning a stable array reference as long as the data references are stable.
 *
 * This is a workaround for workaround for the array result of useQueries being referentially unstable.
 * Once on v5 react-query we should use the `combine` feature instead
 */
export function useStableQueriesData<T>(input: UseQueryResult<T, unknown>[]) {
  const ref = useRef<(T | undefined)[]>();

  const cur = ref.current;
  if (cur && input.length === cur.length && input.every((v, i) => v.data === cur[i])) return cur;

  ref.current = input.map((query) => query.data);
  return ref.current;
}
