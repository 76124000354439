import { HistoricalPortfolioIcon, PrivateInvestmentIcon, PrivatePortfolioIcon, StudioIcon } from '../../custom-icon';
import type { InvestmentSourceTypeEnum } from 'venn-api';
import { Tooltip } from '../../tooltip/Tooltip';
import { ItemType } from './itemType';
import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { FundUtils } from 'venn-utils';
import Icon from '../../icon/Icon';
import { getItemColor } from './getItemColor';

export const ItemIcon = ({
  item,
  isDisabled,
  isUploaded,
  dataSource,
  investmentSource,
  className,
}: {
  item: ItemType;
  isDisabled?: boolean;
  isUploaded?: boolean;
  dataSource?: string;
  investmentSource?: InvestmentSourceTypeEnum;
  className?: string | undefined;
}) => {
  const { Colors } = useContext(ThemeContext);
  const itemColor = isDisabled ? Colors.MidGrey1 : getItemColor(Colors, item);

  if (isUploaded && item !== ItemType.Benchmark) {
    return (
      <StyledIcon className={className} color={isDisabled ? Colors.MidGrey1 : Colors.HighlightDark} type="upload" />
    );
  }

  if (
    ((item === ItemType.Portfolio || item === ItemType.Strategy) && dataSource) ||
    FundUtils.isUserIntegration(investmentSource)
  ) {
    return (
      <Tooltip content={dataSource}>
        <StyledIcon className={className} color={itemColor} type="exchange" />
      </Tooltip>
    );
  }

  if (item === ItemType.Tag) {
    return <StyledIcon className={className} color={itemColor} type="tag" />;
  }

  if (item === ItemType.Portfolio || item === ItemType.Strategy) {
    return <StyledIcon className={className} color={itemColor} type="th" />;
  }

  if (item === ItemType.PrivatePortfolio) {
    return <PrivatePortfolioIcon color={itemColor} />;
  }
  if (item === ItemType.PrivateInvestment) {
    return <PrivateInvestmentIcon color={itemColor} />;
  }

  if (item === ItemType.Benchmark || item === ItemType.CommonBenchmark) {
    return <StyledIcon className={className} color={itemColor} type="arrows-h" />;
  }

  if (item === ItemType.Investment) {
    return <StyledIcon className={className} color={itemColor} type="square" />;
  }

  if (item === ItemType.Analysis) {
    return <StyledIcon className={className} color={itemColor} type="calculator" />;
  }

  if ([ItemType.Studio, ItemType.StudioTearsheet, ItemType.TearsheetTemplate].includes(item)) {
    return <StudioIcon color={itemColor} size={14} top={2} />;
  }

  if ([ItemType.StudioReport, ItemType.ReportTemplate].includes(item)) {
    return <StyledIcon className={className} color={itemColor} type="file-chart-column" />;
  }

  if (item === ItemType.Compare) {
    return <StyledIcon className={className} color={itemColor} type="line-columns" />;
  }

  if (item === ItemType.Article) {
    return <StyledIcon className={className} color={itemColor} type="cloud-question" />;
  }
  if (item === ItemType.PeerGroup) {
    return <StyledIcon className={className} color={itemColor} type="user-group" />;
  }
  if (item === ItemType.HistoricalPortfolio) {
    return <HistoricalPortfolioIcon color={itemColor} />;
  }
  return null;
};
const StyledIcon = styled(Icon)<{ color: string }>`
  color: ${(props) => props.color};
`;
