import React from 'react';
import styled, { css } from 'styled-components';
import { useRecoilValue } from 'recoil';
import { isEmpty } from 'lodash';

import { DataModificationsModal, type DataModificationsCategory } from '../../../../modals/data-modifications-modal';
import { useModal, FootnoteSymbols } from 'venn-utils';
import { isReportState } from 'venn-state';

import { Block } from '../../core';

interface DataModificationsButtonProps {
  modifications: DataModificationsCategory[];
}

export const DataModificationsButton = ({ modifications }: DataModificationsButtonProps) => {
  const [isOpen, open, close] = useModal();
  const isReport = useRecoilValue(isReportState);

  if (isEmpty(modifications)) {
    return null;
  }

  return (
    <>
      <Button isReport={isReport} onClick={open}>
        <Block.MetadataItem iconType="info-circle">
          Contains Venn-Modified Data&nbsp;{`${isReport ? FootnoteSymbols.vennModifiedData : ''}`}
        </Block.MetadataItem>
      </Button>
      {isOpen && <DataModificationsModal onClose={close} modifications={modifications} />}
    </>
  );
};

const Button = styled.button<{ isReport: boolean }>`
  color: unset;
  ${(props) =>
    props.isReport &&
    css`
      pointer-events: none;
    `};
`;
