import React from 'react';
import type { DropMenuItem, MenuContainerProps } from '../types';
import BaseMenu from './BaseMenu';
import SimpleMenuItem from './SimpleMenuItem';
import SimpleMenuLink from './SimpleMenuLink';

export interface SimpleMenuProps<T = string> extends MenuContainerProps {
  /**
   * Array of dropmenu items.
   */
  items: DropMenuItem<T>[];
  /**
   * Currently selected item
   */
  selected?: T;
  /**
   * On selection, returns the selected item
   */
  onChange?: (item: DropMenuItem<T>) => void;
}

export interface SimpleMenuPropsWithLink<T = string> extends SimpleMenuProps<T> {
  /**
   * If provided, along with **onLinkClick**, displays a clickable link
   * at the bottom of the menu
   */
  link?: string;
  /**
   * Fires when the link is clicked
   */
  onLinkClick?: () => void;

  headerComponent?: JSX.Element;
  footerComponent?: JSX.Element;
  menuTrailingContent?: JSX.Element;
}

interface SimpleMenuPropsInner<T = string> extends SimpleMenuPropsWithLink<T> {
  /**
   * Currently highlighted item (with keyboard)
   */
  highlighted?: DropMenuItem<T>;
  /** Temporary flag enabling the new dropdown styles */
  newStyle?: boolean;
}

class SimpleMenu<T> extends React.PureComponent<SimpleMenuPropsInner<T>> {
  render() {
    const {
      items,
      selected,
      highlighted,
      onChange,
      link,
      width,
      height,
      onLinkClick,
      className,
      headerComponent,
      footerComponent,
      menuTrailingContent,
      newStyle,
    } = this.props;
    return (
      <BaseMenu
        className={className}
        items={items}
        width={width}
        height={height}
        headerComponent={headerComponent}
        menuTrailingContent={menuTrailingContent}
        renderItem={(item, index) => (
          <SimpleMenuItem<T>
            key={index}
            item={item}
            highlighted={!!highlighted && item.value === highlighted.value}
            selected={item.value === selected}
            onClick={() => !item.disabled && onChange?.(item)}
            newStyle={newStyle}
          />
        )}
        footerComponent={
          footerComponent ?? (link && onLinkClick ? <SimpleMenuLink label={link} onClick={onLinkClick} /> : undefined)
        }
        newStyle={newStyle}
      />
    );
  }
}

export default SimpleMenu;
