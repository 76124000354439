import React from 'react';
import { constSelector, useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { hasProxyState, isReturnsBasedBlockState, useCachedLoadableValue, type StudioRequestSubject } from 'venn-state';
import { FootnoteSymbols, getBaseFee, getComparisonLabelForBlockLegend, hasFeesExcluded, Numbers } from 'venn-utils';

interface NameProps {
  id: string;
  subject: StudioRequestSubject;
}

const falseState = constSelector(false);

export const Name = ({ id, subject }: NameProps) => {
  const name = getComparisonLabelForBlockLegend(subject);
  const isReturnsBasedBlock = useRecoilValue(isReturnsBasedBlockState(id));
  const hasProxy = useCachedLoadableValue(isReturnsBasedBlock ? hasProxyState(subject.id) : falseState, false);
  const fee = getBaseFee(subject);

  const feesExcluded = hasFeesExcluded(subject);
  return (
    <Wrapper>
      <Container>{[name, hasProxy && <sup>{FootnoteSymbols.proxyUsage}</sup>]}</Container>
      {!isReturnsBasedBlock || fee === 0 ? null : (
        <Fee>
          ({Numbers.safeFormatPercentage(fee)} Fee){feesExcluded && FootnoteSymbols.excludedInvestments}
        </Fee>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  min-width: 0;
  display: flex;
  align-content: center;
  gap: 4px;
`;

const Container = styled.span`
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Fee = styled.span`
  font-style: italic;
  font-weight: normal;
`;
