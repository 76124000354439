import React, { useContext } from 'react';
import { MultiPortfolioReviewContext } from '../common/MultiPortfolioReviewContext';
import { hasUnmatchedFunds } from '../../review/helpers';
import styled from 'styled-components';
import { ErrorMessage } from '../../../components/page-parts';

const NEEDS_MAPPING_INVESTMENTS_MESSAGE =
  'Some investment names/tickers/ISIN could not be matched to an investment in the Data Library. Please review below and ensure all investments are mapped in order to continue.';

export const ErrorBanner = () => {
  const {
    data: { parsedResults, selectedIndex },
  } = useContext(MultiPortfolioReviewContext);
  const openedPortfolio = parsedResults[selectedIndex].parsedPortfolio;
  const excludedInvestments = parsedResults[selectedIndex].excludedInvestments;
  if (hasUnmatchedFunds(openedPortfolio, excludedInvestments)) {
    return (
      <StyledErrorMessage data-testid="unmapped-investments-error">
        {NEEDS_MAPPING_INVESTMENTS_MESSAGE}
      </StyledErrorMessage>
    );
  }
  return null;
};

const StyledErrorMessage = styled(ErrorMessage)`
  margin-top: 0;
`;
