import React from 'react';
import Watermark from '../factor-chart/print/Watermark';
import { renderStaticMarkupWithTheme } from './helper';
import type { Padding, WatermarkPosition } from './types';
import { IS_PROD, SpecialCssClasses } from 'venn-utils';
import type { Sponsor } from 'venn-api';
import type { Theme } from 'venn-ui-kit';
import { getTextThemeProvider, GetColor } from 'venn-ui-kit';

export const DEFAULT_IMAGE_EXPORT_SCALE = 2;

export async function generateCanvasFromNode(
  container: HTMLElement,
  watermark: WatermarkPosition,
  padding: Padding,
  theme: Theme,
  width?: number,
  footer?: JSX.Element,
  header?: JSX.Element,
  sponsor?: Sponsor,
  scale?: number,
): Promise<HTMLCanvasElement> {
  const imageWidth = width === undefined ? (container.clientWidth ?? container.offsetWidth) : width;
  return generateCanvas(container.outerHTML, watermark, padding, theme, imageWidth, footer, header, sponsor, scale);
}

export async function generateCanvasFromHtml(
  content: string,
  watermark: WatermarkPosition,
  padding: Padding,
  theme: Theme,
  width?: number,
  footer?: JSX.Element,
  header?: JSX.Element,
  sponsor?: Sponsor,
  scale?: number,
): Promise<HTMLCanvasElement> {
  return generateCanvas(content, watermark, padding, theme, width, footer, header, sponsor, scale);
}

const setFontSizeRecursively = (element: Node) => {
  const SCALE = 0.99;
  if (element instanceof HTMLElement && element.style.fontSize) {
    element.style.fontSize = `calc(${element.style.fontSize} * ${SCALE})`;
  }
  Array.from(element.childNodes).forEach(setFontSizeRecursively);
};

async function generateCanvas(
  content: string,
  watermark: WatermarkPosition,
  padding: Padding,
  theme: Theme,
  width?: number,
  footer?: JSX.Element,
  header?: JSX.Element,
  sponsor?: Sponsor,
  scale?: number,
): Promise<HTMLCanvasElement> {
  const printedContainer = document.createElement('div');
  const year = new Date().getUTCFullYear();

  const outerHTML = content;

  const watermarkHTML = renderStaticMarkupWithTheme(<Watermark />, theme);
  const watermarkEl = document.createElement('div');
  const legalFooter = document.createElement('div');

  const footerHTML = footer ? renderStaticMarkupWithTheme(footer, theme) : null;
  const footerEl = footerHTML ? document.createElement('div') : null;
  if (footerEl && footerHTML) {
    footerEl.innerHTML = footerHTML;
  }

  const headerHTML = header ? renderStaticMarkupWithTheme(header, theme) : null;
  const headerEl = headerHTML ? document.createElement('div') : null;
  if (headerEl && headerHTML) {
    headerEl.innerHTML = headerHTML;
  }

  legalFooter.style.padding = '10px 0 0 0';
  legalFooter.style.fontSize = '9px';
  legalFooter.innerHTML = `© ${year} Two Sigma Investments, LP. This image is for informational purposes only. See ${
    getTextThemeProvider().MarcomSiteLinks.DISCLAIMERS
  } for more disclaimers and disclosures.`;

  watermarkEl.style.position = 'absolute';
  watermarkEl.style.top = `${watermark.top + padding.top}px`;
  watermarkEl.style.right = `${watermark.right + padding.right}px`;
  watermarkEl.innerHTML = watermarkHTML;

  printedContainer.style.position = 'absolute';
  printedContainer.style.top = '0px';
  printedContainer.style.left = '0px';
  // working around not being able to print a hidden element with the modern-screenshot library by
  // setting the background color to white and z-index to -10000
  // see: https://github.com/qq15725/modern-screenshot/issues/81
  printedContainer.style.backgroundColor = GetColor.White({ theme });
  printedContainer.style.zIndex = '-10000';
  printedContainer.style.padding = `${padding.top}px ${padding.right}px ${padding.bottom}px ${padding.left}px`;
  printedContainer.classList.add(SpecialCssClasses.ExportAsImage);
  // In theory style.width can't be undefined, in practice that's what we want sometimes
  printedContainer.style.width = width ? `${width + padding.left + padding.right}px` : undefined!;
  // Adding contain "content" to the container optimizes rendering without breaking sizing like contain "strict" would cause.
  printedContainer.style.contain = 'content';
  printedContainer.innerHTML = outerHTML;

  // Adds top-right corner Venn Watermark
  printedContainer.appendChild(watermarkEl);

  // Adds Footer and Header and legal disclaimer
  if (footerEl) {
    printedContainer.appendChild(footerEl);
  }
  if (headerEl) {
    printedContainer.appendChild(headerEl);
  }
  printedContainer.appendChild(legalFooter);

  // Force remove Highcharts' credits logo from download, since I can't remove it using css
  printedContainer.querySelectorAll('.highcharts-credits').forEach((ele) => ele.remove());

  // Remove all Not Downloadable elements
  printedContainer.querySelectorAll(`.${SpecialCssClasses.NotDownloadable}`).forEach((ele) => ele.remove());
  // Hide all Hidden-in-Downloadable elements
  printedContainer
    .querySelectorAll(`.${SpecialCssClasses.HiddenInDownloadable}`)
    .forEach((ele) => (ele.innerHTML = ''));

  printedContainer
    .querySelectorAll<HTMLElement>(`.${SpecialCssClasses.DownloadableHeightFitContent}`)
    .forEach((el) => (el.style.maxHeight = 'fit-content'));

  // Make all Only in Downloadable elements visible
  printedContainer
    .querySelectorAll<HTMLElement>(`.${SpecialCssClasses.OnlyInDownloadable}`)
    .forEach((el) => (el.style.display = 'inline-block'));

  document.body.appendChild(printedContainer);
  const { domToCanvas } = await import('modern-screenshot');

  const canvas = await domToCanvas<HTMLElement>(printedContainer, {
    filter: (node) => !(node instanceof HTMLElement && node.attributes.getNamedItem('data-html2canvas-ignore')),
    scale: scale ?? DEFAULT_IMAGE_EXPORT_SCALE,
    debug: !IS_PROD,
    onCreateForeignObjectSvg: setFontSizeRecursively,
  });
  document.body.removeChild(printedContainer);

  return canvas;
}
