import React from 'react';
import { type Country } from 'venn-api';
import { getSupportedCountries } from 'venn-api';
import styled from 'styled-components';
import { FormSelect } from 'venn-components';
import type { FormatOptionLabelMeta } from 'react-select/base';
import type { Props } from 'react-select';
import type { InputProps } from './types';
import { useQuery } from '@tanstack/react-query';
import { isRequestSuccessful, logExceptionIntoSentry } from 'venn-utils';

interface CountrySelectProps extends InputProps<Country> {
  disabled?: boolean;
}

const StyledFlag = styled.span`
  display: inline-block;
  transform: scale(0.7);
`;

const StyledOption = styled.div`
  display: flex;
  align-items: center;
  height: 23px;
`;

const DisplayFlag = styled(StyledFlag)`
  transform: scale(0.5);
  transform-origin: left;
`;

const StyledName = styled.span`
  line-height: 22px;
`;

const displayCountry = (country: Country, meta: FormatOptionLabelMeta<Country>) =>
  meta.context === 'value' ? displaySelectedCountry(country) : displayCountryOption(country);

const displaySelectedCountry = (country: Country) => (
  <StyledOption>
    <DisplayFlag className={`icon-${country.code}`} />
    <StyledName>{country.displayName}</StyledName>
  </StyledOption>
);

const displayCountryOption = (country: Country) => (
  <StyledOption className="qa-option">
    <StyledFlag className={`icon-${country.code}`} />
    <StyledName>{country.displayName}</StyledName>
  </StyledOption>
);

const priorityCountryCodes = new Set(['US']);

const sortCountries = (allCountries: Country[]): Country[] =>
  allCountries
    ? allCountries.sort((a, b) => {
        const hasA = priorityCountryCodes.has(a.code);
        const hasB = priorityCountryCodes.has(b.code);

        return hasA === hasB ? 0 : hasA ? -1 : 1;
      })
    : [];

const COUNTRIES_KEY = 'countries';
export const CountrySelectWithLabel = ({ field, disabled }: CountrySelectProps) => {
  const { data: countries } = useQuery<Country[]>(
    [COUNTRIES_KEY],
    async () => {
      const response = await getSupportedCountries();
      if (isRequestSuccessful(response)) {
        return sortCountries(response.content);
      }
      return [];
    },
    {
      onError: (error) => {
        return logExceptionIntoSentry(error as Error);
      },
      suspense: false,
    },
  );

  return (
    <FormSelect
      required
      disabled={disabled}
      errorHidden={disabled}
      infoIconText="Country where your company is located."
      label="Country"
      className="qa-country-select"
      options={countries ?? []}
      inputId="country"
      getOptionValue={(option: Country) => option.displayName}
      filterOption={filterCountry}
      formatOptionLabel={displayCountry}
      {...field}
      name="country"
    />
  );
};

type FilterOptionOption<T> = Parameters<NonNullable<Props<T>['filterOption']>>[0];
const filterCountry = (countryOption: FilterOptionOption<Country>, filter: string): boolean => {
  if (!filter) {
    return true;
  }
  const country: Country = countryOption.data;
  const upperCaseFilter = filter.toUpperCase();
  // special case of UK
  if (upperCaseFilter === 'UK') {
    // this will ignore Ukraine
    return country.iso2Code === 'GB';
  }
  return (
    country.displayName.toUpperCase().startsWith(upperCaseFilter) ||
    country.iso2Code.toUpperCase().startsWith(upperCaseFilter) ||
    country.iso3Code.toUpperCase().startsWith(upperCaseFilter)
  );
};
