import React from 'react';
import FiltersProvider from './components/FiltersProvider';
import type { GenericSearchMenuProps, SearchMenuItem } from './types';

import { StyledSelect } from './styled';
import ValueContainer from './components/ValueContainer';
import MenuList from './components/MenuList';
import Menu from './components/Menu';
import Option from './components/Option';
import { useSearchSingleSelect } from './useSearchSingleSelect';

interface SearchMenuProps extends GenericSearchMenuProps {
  forceHideResults?: boolean;
  onSelected: (selected: SearchMenuItem) => void;
}

const menuComponents = {
  ValueContainer,
  MenuList,
  Menu,
  Option,
};

const SearchMenuBar = (props: SearchMenuProps) => {
  const selectProps = useSearchSingleSelect({
    ...props,
    menuComponents,
    forceMenuIsOpen: false,
    shouldResetSearchOnSelect: true,
    pinSelected: false,
  });

  return <StyledSelect {...selectProps} />;
};

const MemoizedSearchMenuBar = React.memo((props: SearchMenuProps) => (
  <FiltersProvider>
    <SearchMenuBar {...props} />
  </FiltersProvider>
));

MemoizedSearchMenuBar.displayName = 'MemoizedSearchMenuBar';

export default MemoizedSearchMenuBar;
