import type { CSSProperties } from 'react';
import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { GetColor } from '../../../style/color';
import { Body1 } from '../../typography';
import type { DropMenuItem } from '../types';
import { OptionIconPosition } from '../types';
import { Tooltip } from '../../tooltip/Tooltip';
import { TooltipPosition } from '../../enums';
import type { LinkProps } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { isNil } from 'lodash';
import { ColorUtils } from '../../../style/colorUtils';

export interface SimpleMenuItemProps<T = string> {
  item: DropMenuItem<T>;
  selected: boolean;
  highlighted: boolean;
  onClick: () => void;
  descriptionStyle?: CSSProperties;
  /** Temporary flag enabling the new dropdown styles */
  newStyle?: boolean;
}

const SimpleMenuItem = <T,>({
  item,
  onClick,
  selected,
  highlighted,
  descriptionStyle,
  newStyle,
}: SimpleMenuItemProps<T>) => {
  const qaClass = item.className ? `qa-dropmenu-item ${item.className}` : 'qa-dropmenu-item';

  const itemsRef = useRef<HTMLDivElement>(null);
  const iconPosition = item.iconPosition ?? OptionIconPosition.LEFT;

  useEffect(() => {
    if (highlighted && itemsRef.current !== null && itemsRef.current.scrollIntoView) {
      itemsRef.current.scrollIntoView(false);
    }
  }, [highlighted]);

  return (
    <Tooltip
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        if (!item.disabled) {
          onClick();
        }
      }}
      className={qaClass}
      data-testid="qa-dropmenu-item"
      position={item.tooltipPosition ?? TooltipPosition.Left}
      usePortal
      block
      content={item.tooltipContent}
    >
      <OptionalLink to={item.link}>
        <Item
          className="simple-menu-item"
          data-testid="simple-menu-item"
          level={item.level}
          selected={selected}
          highlighted={highlighted}
          disabled={!!item.disabled}
          ref={itemsRef}
          newStyle={newStyle}
        >
          <Body1 style={item.style}>
            {iconPosition === OptionIconPosition.LEFT ? item.icon : null}
            {item.label}
            {iconPosition === OptionIconPosition.RIGHT ? item.icon : null}
          </Body1>
          {item.description && (
            <Description className="simple-menu-item-description" style={descriptionStyle}>
              {item.description}
            </Description>
          )}
        </Item>
      </OptionalLink>
    </Tooltip>
  );
};

interface OptionalLinkProps extends Omit<LinkProps, 'to'> {
  to?: string;
}

const OptionalLink = (props: OptionalLinkProps) =>
  !isNil(props.to) ? <Link {...props} to={props.to} /> : props.children;

interface ItemProps {
  level?: number;
}

const Description = styled.div`
  font-size: 10px;
  color: ${GetColor.HintGrey};
  line-height: 1.4;
  && {
    white-space: normal;
  }
`;

const Item = styled.div<ItemProps & { selected: boolean; highlighted: boolean; disabled: boolean; newStyle?: boolean }>`
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: ${(props) => 9 + (props.level || 0) * 10}px;
  padding-right: 9px;
  display: flex;
  flex-direction: column;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  ${({ newStyle, selected, disabled, highlighted }) =>
    newStyle
      ? css`
          background-color: ${selected
            ? ColorUtils.opacifyFrom(GetColor.DarkBlue, 0.1)
            : disabled
              ? GetColor.GreyScale.Grey10
              : highlighted
                ? GetColor.WhiteGrey
                : GetColor.White};
          &:hover {
            background-color: ${ColorUtils.opacifyFrom(GetColor.DarkBlue, 0.1)};
          }
        `
      : css`
          background-color: ${selected ? GetColor.PaleGrey : highlighted ? GetColor.WhiteGrey : GetColor.White};
          &:hover {
            background-color: ${GetColor.PaleGrey};
          }
        `}

  > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    ${({ disabled, newStyle }) =>
      disabled
        ? css`
            color: ${newStyle ? GetColor.GreyScale.Grey60 : GetColor.MidGrey1};
          `
        : undefined}
  }
`;

export default React.memo(SimpleMenuItem) as typeof SimpleMenuItem;
