import { isNil, negate } from 'lodash';
import type { AnalysisRequest, Message, RangeAnalysisResponse, RangeDebugResponse, FrequencyEnum } from 'venn-api';
import { analysis } from 'venn-api';
import type { AnalysisSubject } from 'venn-utils';
import { createRangeAnalysisKey, getAnalysisRequest, useQuery } from 'venn-utils';

export interface RangeDebugResult {
  response: RangeDebugResponse | null;
  subjectError: Message | null;
}

export interface RangeDebugGroup {
  primary: RangeDebugResult;
  secondary?: RangeDebugResult;
  benchmark?: RangeDebugResult;
  master?: RangeDebugResult;
  maxStartTime: number;
  maxEndTime: number;
  maxFrequency: FrequencyEnum;
}

export interface UseRangeAnalysisReturn {
  refresh: () => Promise<unknown>;
  loading: boolean;
  rangeAnalysis?: RangeAnalysisResponse;
  analysisRequest?: Partial<AnalysisRequest>;
}

const useRangeAnalysis = (subject: AnalysisSubject | undefined): UseRangeAnalysisReturn => {
  const { data, isLoading, refetch } = useQuery(createRangeAnalysisKey(subject), async ({ signal }) => {
    if (!subject || subject.private) {
      return null;
    }

    const analysisRequest = getAnalysisRequest(['RANGE_ANALYSIS'], subject, {}, false, true);
    const response = await analysis(analysisRequest, signal);
    const analysisResponse = response.content.analyses.map((a) => a.rangeAnalysis).find(negate(isNil));
    return { analysisRequest, analysisResponse };
  });

  return {
    loading: isLoading,
    rangeAnalysis: data?.analysisResponse,
    refresh: refetch,
    analysisRequest: data?.analysisRequest,
  };
};

export default useRangeAnalysis;
